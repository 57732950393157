define("inboxfront/controllers/forgotpassword", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    showSuccessMessage: false,
    actions: {
      forgotPassword: function forgotPassword() {
        var _this = this;

        this.ajax.query(_mutation.default.FORGOT_PASSWORD, 'forgotPassword', {
          username: this.get('email')
        }).then(function () {
          _this.set('showSuccessMessage', true);
        }).catch(function () {
          return _this.get('paperToaster').show(_this.intl.t('network_error'));
        });
      }
    }
  });

  _exports.default = _default;
});