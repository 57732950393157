define("inboxfront/helpers/url-user-avatar", ["exports", "inboxfront/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.urlUserAvatar = urlUserAvatar;
  _exports.default = void 0;

  function urlUserAvatar(params) {
    if (!params[0]) {
      return '';
    }

    if (params[0].attachment) {
      return "".concat(_environment.default.mediaUrl).concat(params[0].attachment.datafile);
    } else if (params[0].logo) {
      return "".concat(_environment.default.mediaUrl).concat(params[0].logo.datafile);
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(urlUserAvatar);

  _exports.default = _default;
});