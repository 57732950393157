define("inboxfront/routes/ticketfields", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
      return Ember.RSVP.all([settings]).then(function (res) {
        var settings = res[0];
        return {
          settings: settings,
          fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, settings),
          types: _this.get('customfields').types
        };
      });
    }
  });

  _exports.default = _default;
});