define("inboxfront/components/flow/block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component', function () {
      var component = this.get('component');
      return this.get('blocks').findBy('id', component.id);
    }),
    countComponents: Ember.computed('nextComponent', function () {
      var components = (this.get('nextComponent.children') || []).filter(function (child) {
        return child.blockType === 'BUTTON';
      });
      return components.length;
    }),
    countFormFields: Ember.computed('nextComponent', function () {
      if (this.get('nextComponent.blockType') === 'FORM') {
        var extraData = JSON.parse(this.get('nextComponent.extraData'));
        return extraData.dataCollectorsSelect.length;
      }

      return 0;
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      deleteComponent: function deleteComponent(component, event) {
        this.deleteComponent(component, event);
      },
      updateBranchName: function updateBranchName(component, index, value) {
        console.log('UP2', index, value);
        this.updateBranchName(component, index, value);
      },
      selectBranch: function selectBranch(component, index) {
        this.selectBranch(component, index, true);
      },
      deselectBranch: function deselectBranch(component, index) {
        this.deselectBranch(component, index, true);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      }
    }
  });

  _exports.default = _default;
});