define("inboxfront/routes/channels", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    fb: Ember.inject.service(),
    queryParams: {
      page: {},
      code: {
        refreshModel: true,
        replace: false
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments); //return this.fb.FBInit();

    },
    model: function model(params) {
      var user = this.ajax.query(_query.default.CURRENT_USER);

      if (params.code) {
        window.opener && window.opener.onInstagramAuth && window.opener.onInstagramAuth(params.code);
      }

      return Ember.RSVP.all([user]).then(function (res) {
        return {
          user: res[0]
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelUser = model.user;
      var user = {
        firstName: modelUser.firstName,
        lastName: modelUser.lastName,
        username: modelUser.username,
        attachment: modelUser.attachment
      };
      var companies = modelUser.staffprofile.estates;
      companies.map(function (company) {
        return _objectSpread(_objectSpread({}, company), {}, {
          flows: company.flows.unshift({
            id: null,
            title: 'Choose flow...'
          })
        });
      });
      controller.set('companies', companies);
      controller.set('selectedProperty', companies[0]);
      controller.set('user', user);
      controller.changeSelectedChannels(companies[0].id, companies[0].widgetSettings);
    }
  });

  _exports.default = _default;
});