define("inboxfront/routes/onboarding", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/gql/query"], function (_exports, _authenticatedRouteMixin, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: function model() {
      var locales = this.ajax.query(_query.default.LOCALES, 'locales');
      var roles = this.ajax.query(_query.default.CUSTOM_ROLES);
      return Ember.RSVP.all([locales, roles]).then(function (res) {
        return {
          locales: JSON.parse(res[0]),
          roles: res[1]
        };
      });
    },
    setupController: function setupController(c, m) {
      this._super(c, m);

      var companies = this.sessionAccount.loggeduser.staffprofile.estates;
      var user = this.sessionAccount.loggeduser;
      var domain = user.staffprofile && user.staffprofile.company && user.staffprofile.company.domain;
      c.set('companies', companies);
      c.set('customRoles', m.roles);
      c.set('selectedProperty', companies[0]);
      c.set('profile', user);
      c.set('domain', domain.replace('.raincheck.co', ''));
      c.set('urlAttachment', '/assets/images/upload_avatar.png');
    }
  });

  _exports.default = _default;
});