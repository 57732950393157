define("inboxfront/templates/components/popups/chats-delete-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aLV0Dbfa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"onClose\",\"origin\",\"clickOutsideToClose\",\"class\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null],[24,[\"dialogOrigin\"]],true,\"warning-dialog\"]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[\"alert-triangle\"],null],false],[0,\"\\n            \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"actions.delete_room\"],null],false],[7,\"br\",true],[8],[9],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"delete_room_msg\"],null],false],[9],[0,\"\\n            \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"delete_question\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-dialog-actions\",null,null,{\"statements\":[[4,\"common/buttons/secondary-2\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"actions.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"common/buttons/primary\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"onAction\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"actions.delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/popups/chats-delete-room.hbs"
    }
  });

  _exports.default = _default;
});