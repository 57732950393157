define("inboxfront/components/chat/ecommerce/order-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    orderCanBeCancelled: Ember.computed('platform', 'order.{status,financialStatus}', function () {
      var platform = this.get('platform');

      if (platform === 'shopify') {
        return !this.get('order.financialStatus');
      } else if (platform === 'bigcommerce') {
        var status = this.get('order.status');
        var financialStatus = this.get('order.financialStatus');
        return status !== 'cancelled' && status !== 'closed' && financialStatus !== 'Refunded' && financialStatus !== 'Shipped' && financialStatus !== 'Partially Shipped';
      }
    }),
    orderCanBeRefunded: Ember.computed('platform', 'order.{status,financialStatus}', function () {
      var platform = this.get('platform');

      if (platform === 'shopify') {
        var status = this.get('order.financialStatus');
        return status === 'paid' || status === 'partially_paid' || status === 'partially_refunded';
      } else if (platform === 'bigcommerce') {
        var _status = this.get('order.status');

        var financialStatus = this.get('order.financialStatus');
        return _status !== 'cancelled' && _status !== 'closed' && financialStatus !== 'Refunded' && financialStatus !== 'Shipped' && financialStatus !== 'Partially Shipped';
      }
    })
  });

  _exports.default = _default;
});