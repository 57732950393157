define("inboxfront/templates/components/tasks/id-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJmx9fs3",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"isEditMode\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"class\",\"options\",\"placeholder\",\"selected\",\"disabled\",\"required\",\"onChange\"],[[28,\"concat\",[\"id-selector \",[24,[\"class\"]]],null],[24,[\"options\"]],[24,[\"placeholder\"]],[24,[\"selected\"]],[24,[\"disabled\"]],[24,[\"required\"]],[28,\"action\",[[23,0,[]],\"changeValue\"],null]]],{\"statements\":[[0,\"        \"],[7,\"span\",true],[11,\"class\",[29,[[23,1,[\"id\"]]]]],[8],[1,[28,\"get\",[[23,1,[]],[24,[\"showProp\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[[24,[\"selected\",\"id\"]]]]],[8],[1,[28,\"get\",[[24,[\"selected\"]],[24,[\"showProp\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/tasks/id-selector.hbs"
    }
  });

  _exports.default = _default;
});