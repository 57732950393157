define("inboxfront/helpers/media-url", ["exports", "inboxfront/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mediaUrl = mediaUrl;
  _exports.default = void 0;

  function mediaUrl(params) {
    var url = _environment.default.mediaUrl;
    var param = params[0] || '';
    return url + param;
  }

  var _default = Ember.Helper.helper(mediaUrl);

  _exports.default = _default;
});