define("inboxfront/templates/signup/active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X/MXxP3v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sign-up-wrapper _register\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"intro-image\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/assets/images/welcome.png\"],[10,\"alt\",\"welcome\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"welcome_msg\"],null],false],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"signup_confirm_msg\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/signup/active.hbs"
    }
  });

  _exports.default = _default;
});