define("inboxfront/templates/components/flow/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mx/DCqWB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"item-flow-wrap\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"openStartConfigure\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item-flow-title\"],[8],[0,\"Set how you want this Orchestration to be trigger\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item-flow-info\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flow-icon\"],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"icon-bell\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"Trigger\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"disabled\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"add-component-flow\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"openAddComponent\",null],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"plus-square\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/flow/trigger.hbs"
    }
  });

  _exports.default = _default;
});