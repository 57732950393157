define("inboxfront/controllers/ecommerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    paperToaster: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    selectedECommerce: false,
    showLoginForm: false,
    showTempSidenav: false,
    queryParams: ['scope', 'code', 'context'],
    shopData: Ember.computed('selectedECommerce', 'selectedProperty', 'selectedProperty.accounts.edges.[]', function () {
      var platform = this.get('selectedECommerce');
      var company = this.get('selectedProperty');
      var ret = false;
      company.accounts && company.accounts.edges && company.accounts.edges.forEach(function (e) {
        var node = e.node;

        if (node.name === platform) {
          node.accountsettingSet.edges.forEach(function (e) {
            if (e.node.key === 'shop_name') {
              ret = {
                shop_name: e.node.value
              };
            } else if (e.node.key === 'bc_store_domain') {
              ret = {
                shop_name: e.node.value
              };
            }
          });
        }
      });
      return ret;
    }),
    isConnected: Ember.computed('shopData', function () {
      return !!this.get('shopData');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
      this.set('shopifyCallback', window.localStorage.getItem('RC_ShopifyCallbackDomain') || '');
      this.set('bigcommerceCallback', window.localStorage.getItem('RC_BigCommerceCallbackDomain') || '');
    },
    actions: {
      saveShopifyCallback: function saveShopifyCallback() {
        window.localStorage.setItem('RC_ShopifyCallbackDomain', this.get('shopifyCallback'));
      },
      saveBCCallback: function saveBCCallback() {
        window.localStorage.setItem('RC_BigCommerceCallbackDomain', this.get('bigcommerceCallback'));
      },
      onSelectProperty: function onSelectProperty(property) {
        this.set('selectedProperty', property);
      },
      showLogin: function showLogin() {
        this.set('showLoginForm', true);
        this.set('shopifyDomain', '');
        this.set('bcDomain', 'i2z63dmyxq');
      },
      saveShopifyForm: function saveShopifyForm() {
        var shopName = this.get('shopifyDomain') + '.myshopify.com';

        try {
          var apiKey = '0335e023bd1c093f9582b0e50fcde52c';
          var scope = ['read_products', 'write_script_tags', 'write_customers', 'write_orders', 'read_fulfillments', 'write_fulfillments', 'read_assigned_fulfillment_orders', 'read_merchant_managed_fulfillment_orders', 'read_third_party_fulfillment_orders', 'read_shipping'].join(',');
          var redirectURL = 'https://dev.raincheck.co/ecommerce';
          var nonce = 'qweasd';
          var accessMode = '';
          var authURL = "https://".concat(shopName, "/admin/oauth/authorize?client_id=").concat(apiKey, "&scope=").concat(scope, "&redirect_uri=").concat(redirectURL, "&state=").concat(nonce, "&grant_options[]=").concat(accessMode);
          window.localStorage.setItem('RC_PropertyId', this.get('selectedProperty.id'));
          window.localStorage.setItem('RC_WidgetId', this.get('selectedProperty.widgetSettings.id'));
          window.localStorage.setItem('RC_ShopName', shopName);
          window.location = authURL;
        } catch (e) {
          console.error(e);
        }
      },
      saveBCForm: function saveBCForm() {
        var shopName = 'store-' + this.get('bcDomain') + '.mybigcommerce.com';

        try {
          window.localStorage.setItem('RC_PropertyId', this.get('selectedProperty.id'));
          window.localStorage.setItem('RC_WidgetId', this.get('selectedProperty.widgetSettings.id'));
          window.localStorage.setItem('RC_ShopName', shopName);
          window.location = "https://".concat(shopName, "/manage/marketplace/apps/35931");
        } catch (e) {
          console.error(e);
        }
      }
    },
    sendCode: function sendCode(code, context, scope) {
      var _this = this;

      if (context) {
        this.set('selectedECommerce', 'bigcommerce');
      } else {
        this.set('selectedECommerce', 'shopify');
      }

      var widgetId = window.localStorage.getItem('RC_WidgetId');
      var shopName = window.localStorage.getItem('RC_ShopName');

      if (this.get('selectedECommerce') === 'shopify') {
        var hash = {
          code: code,
          shop_name: shopName,
          widgetID: widgetId
        };
        var url = 'https://' + window.localStorage.getItem('RC_ShopifyCallbackDomain');
        this.set('installationInProgress', true);
        this.ajax.raw("".concat(url, "/auth/callback"), {
          method: 'POST',
          data: hash,
          headers: {
            'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
          },
          crossDomain: true
        }).then(function (response) {}).catch(function (_ref) {
          var response = _ref.response,
              jqXHR = _ref.jqXHR,
              payload = _ref.payload;

          if (jqXHR.status === 200) {
            _this.get('selectedProperty.accounts.edges').addObject({
              node: {
                name: 'shopify',
                accountsettingSet: {
                  edges: [{
                    node: {
                      key: 'shop_name',
                      value: shopName
                    }
                  }]
                }
              }
            });

            _this.get('paperToaster').show('Shopify store connected');

            window.localStorage.removeItem('RC_PropertyId');
            window.localStorage.removeItem('RC_WidgetId');
            window.localStorage.removeItem('RC_ShopName');
          }
        }).catch(function (e) {
          console.error(e);
        }).finally(function () {
          _this.set('installationInProgress', false);
        });
      } else {
        var _hash = {
          code: code,
          context: context,
          scope: scope,
          shop_name: shopName,
          widgetID: widgetId
        };

        var _url = 'https://' + window.localStorage.getItem('RC_BigCommerceCallbackDomain');

        this.set('installationInProgress', true);
        this.ajax.raw("".concat(_url, "/bigcommerce/callback"), {
          method: 'POST',
          data: _hash,
          headers: {
            'authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
          },
          crossDomain: true
        }).then(function (response) {}).catch(function (_ref2) {
          var response = _ref2.response,
              jqXHR = _ref2.jqXHR,
              payload = _ref2.payload;

          if (jqXHR.status === 200) {
            _this.get('selectedProperty.accounts.edges').addObject({
              node: {
                name: 'bigcommerce',
                accountsettingSet: {
                  edges: [{
                    node: {
                      key: 'bc_store_domain',
                      value: shopName
                    }
                  }]
                }
              }
            });

            _this.get('paperToaster').show('BigCommerce store connected');

            window.localStorage.removeItem('RC_PropertyId');
            window.localStorage.removeItem('RC_WidgetId');
            window.localStorage.removeItem('RC_ShopName');
          }
        }).finally(function () {
          _this.set('installationInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});