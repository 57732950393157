define("inboxfront/routes/kb", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    kb: Ember.inject.service(),
    model: function model() {
      var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
      return Ember.RSVP.all([this.get('kb').getBases(), propertyUsers]).then(function (res) {
        return {
          users: res[1].errors ? [] : res[1]
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.get('bases.length') === 0) {
        controller.set('kbName', '');

        controller._showPage('kb-create-form');
      } else {
        controller._selectBase(controller.get('bases')[0]);
      }
    }
  });

  _exports.default = _default;
});