define("inboxfront/components/flow/settings", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    ajax: Ember.inject.service(),
    tagName: '',
    idleTime: 20,
    timeOut: 20,
    warningMessage: Ember.computed('idleTime', function () {
      return 'Hi, we have not heard anything from you for the past ' + this.get('idleTime') + ' seconds, ' + 'please type something to remain active in chat';
    }),
    timeOutMessage: Ember.computed('timeOut', function () {
      return 'This chat session has been terminated due to time-out, we are still here with you if you need anything';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.properties = this.get('sessionAccount.loggeduser.staffprofile.estates');
      this.checkTriggerAuto = this.get('flow.auto');
      this.isDisabled = this.get('flow.type') === 1;
      this.listTypes = [{
        id: 1,
        name: 'Chat Bot'
      }, {
        id: 2,
        name: 'Orchestration'
      }];
      this.selectedType = this.listTypes.findBy('id', this.get('flow.type')) || {
        id: 1,
        name: 'Chat Bot'
      };
    },
    actions: {
      onSelectProperty: function onSelectProperty(val) {
        if (this.get('flow.type') === 1) {
          var hash = {
            companyProperty: val.id
          };
          this.set('flow.companyProperty', val);
          this.mutationFlow(hash);
        } else {
          this.set('flow.properties', val);
          var _hash = {
            properties: val.map(function (p) {
              return p.id;
            })
          };
          this.mutationWorkflow(_hash);
        }
      },
      onBlurInputFlow: function onBlurInputFlow(name, value) {
        this.onBlurInputFlow(name, value);
      },
      onSelectType: function onSelectType(val) {
        var _this = this;

        var hash = {
          type: val.id,
          auto: val.id === 1,
          flowId: this.get('flow.id')
        };
        this.set('selectedType', val);
        this.set('isDisabled', val.id === 1);
        this.set('checkTriggerAuto', val.id === 1);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
          _this.set('flow.type', val.id);
        });
      },
      onCheckTriggerAuto: function onCheckTriggerAuto(val) {
        var hash = {
          type: this.get('selectedType.id'),
          auto: val,
          flowId: this.get('flow.id')
        };
        this.set('checkTriggerAuto', val);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash);
      }
    },
    mutationFlow: function mutationFlow() {
      var _this2 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        flowId: this.get('flow.id')
      });

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {
        _this2.onUpdate && _this2.onUpdate();
      });
    },
    mutationWorkflow: function mutationWorkflow() {
      var _this3 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        workflowId: this.get('flow.id')
      });

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data).then(function () {
        _this3.onUpdate && _this3.onUpdate();
      });
    }
  });

  _exports.default = _default;
});