define("inboxfront/components/flow/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component', function () {
      var component = this.get('component');
      var block = this.get('blocks').findBy('id', component.id);
      var extraData = JSON.parse(block.extraData);
      Ember.set(block, 'dataCollectorsSelect', extraData.dataCollectorsSelect);
      return block;
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      }
    }
  });

  _exports.default = _default;
});