define("inboxfront/helpers/replace-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceUrl = replaceUrl;
  _exports.default = void 0;

  function replaceUrl(params) {
    return params[0] && params[0].search('http') ? "//".concat(params[0]) : params[0];
  }

  var _default = Ember.Helper.helper(replaceUrl);

  _exports.default = _default;
});