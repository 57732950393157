define("inboxfront/routes/roles/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var roles = this.ajax.query(_query.default.COMPANY_CUSTOM_ROLES);
      var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
      return Ember.RSVP.all([roles, propertyUsers]).then(function (res) {
        return {
          roles: !res[0].errors ? res[0] : [],
          propertyUsers: !res[1].errors ? res[1] : []
        };
      });
    }
  });

  _exports.default = _default;
});