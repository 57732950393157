define("inboxfront/components/tasks/customfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    isEditMode: true,
    selected: Ember.computed('value', function () {
      return this.get('field.children').findBy('id', this.get('value'));
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          if (_this.get('field.required') && _this.get('required') !== false) {
            var type = _this.get('field.fieldType');

            var ret = inputValue && inputValue.trim() !== '';

            if (type === 'NUMBER' || type === 'DECIMALS') {}

            return ret;
          } else {
            return true;
          }
        }
      }];
    },
    actions: {
      onChange: function onChange(value) {
        this.set('value', value);
        this.onChange(value);
      },
      onSelect: function onSelect(value) {
        this.set('value', value.id);
        this.get('field.sections').forEach(function (s) {
          Ember.set(s, 'active', s.conditionFields.indexOf(+value.id) >= 0);
        });
        this.onChange(value.id);
      }
    }
  });

  _exports.default = _default;
});