define("inboxfront/components/popups/reply-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterReply: Ember.computed('replyList', function () {
      return this.get('replyList');
    }),
    actions: {
      onclickOutside: function onclickOutside() {
        this.onclickOutside();
      },
      searchText: function searchText(e) {
        var filter = this.get('replyList').filter(function (el) {
          return el.node.text.indexOf(e.target.value) !== -1 || el.node.shortcut.indexOf(e.target.value) !== -1;
        });
        this.set('filterReply', filter);
      },
      insertText: function insertText(reply) {
        this.chooseText(reply.node.text);
      }
    }
  });

  _exports.default = _default;
});