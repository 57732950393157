define("inboxfront/components/chat-pinned-items-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pinnedMessages: Ember.computed('messages', function () {
      return this.get('messages').filter(function (message) {
        return message.node.pinned;
      });
    }),
    actions: {
      pinMessage: function pinMessage(msgId) {
        this.pinMessage(msgId);
      }
    }
  });

  _exports.default = _default;
});