define("inboxfront/components/helpdesk/add-comment", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tasks: Ember.inject.service(),
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('mentioned', []);
    },
    currentText: Ember.computed('editCommentText', function () {
      var editCommentText = this.get('editCommentText');
      return editCommentText ? editCommentText.text : '';
    }),
    actions: {
      closeEditor: function closeEditor() {
        this.set('showEditor', false);
      },
      showCommentField: function showCommentField() {
        this.set('editCommentText', '');
        this.set('showEditor', true);
      },
      //TODO rework 'chat-note-user-popup', try to integrate it to component 'input-chat'
      chooseUser: function chooseUser(user) {
        var this_ = this;
        var span = document.createElement('span');
        span.className = 'wrap-user-group';
        span.setAttribute('contenteditable', false);
        span.innerHTML = "<span class=\"user-group\" data-id=\"".concat(user.id, "\" contenteditable=\"true\">").concat(user.firstName, "</span>");
        document.querySelector('.txt-input').appendChild(span);
        document.querySelector('.txt-input').innerHTML += '&nbsp;';
        document.querySelector('.txt-input').dispatchEvent(new Event('input'));
        span.addEventListener('input', function (event) {
          event.stopPropagation();

          if (event.inputType === 'deleteContentBackward') {
            //this_.get('mentioned').removeObject(this.children[0].getAttribute('data-id'));
            this.remove();
          }
        }); //this.get('mentioned').pushObject(user.pk);

        this.set('isOpenUserPopup', false);
      },
      addComment: function addComment() {
        var _this = this;

        var editCommentText = this.get('editCommentText');
        var hash = {
          ticketId: this.get('task.id')
        };

        if (this.get('comment')) {
          hash.comment = this.get('comment');
        }

        if (editCommentText) {
          var data = {
            commentId: editCommentText.id,
            text: this.get('comment')
          };
          var filterComment = this.get('task.comments').filter(function (el) {
            return el.id === editCommentText.id;
          })[0];
          this.ajax.query(_mutation.default.UPDATE_COMMENT, 'updateComment', data).then(function () {
            _this.updateTicket();

            Ember.set(filterComment, 'text', _this.get('comment'));

            _this.set('comment', '');

            _this.set('editCommentText', '');

            document.querySelector('.txt-input').innerHTML = '';

            _this.set('showEditor', false);
          });
          return;
        } //TODO get mentioned users from 'input-chat'


        var wrapUsers = document.querySelectorAll('.wrap-user-group');
        wrapUsers && wrapUsers.forEach(function (i) {
          _this.get('mentioned').pushObject(i.children[0].dataset.id);
        });

        if (this.get('mentioned').length) {
          hash.mentioned = this.get('mentioned');
        }

        this.tasks.addComment(hash, function (result) {
          _this.updateTicket();

          _this.get('task.comments').pushObject(result.ticketComment);

          _this.set('comment', '');

          document.querySelector('.txt-input').innerHTML = '';

          _this.set('mentioned', []);

          _this.set('showEditor', false);
        });
      }
    }
  });

  _exports.default = _default;
});