define("inboxfront/components/dashboard/responsetime-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countChats: Ember.computed('pickGraph', function () {
      var arr = this.get('pickGraph');
      var count = 0;
      arr.length && arr.forEach(function (i) {
        count += i.node.avgResponseTime;
      });
      return count;
    }),
    previousCount: Ember.computed('countChats', function () {
      return (Math.random() * 100).toFixed(2);
    })
  });

  _exports.default = _default;
});