define("inboxfront/components/popups/group-chat", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    isPrivate: true,
    isActiveSwitch: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedUsers = [];
      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    filterUsers: Ember.computed('rooms', function () {
      // const filterPrivate = this.get('rooms').map(el => !el.isGroup && el.isPrivate && el.username);
      // return this.get('users').filter(i => filterPrivate.indexOf(i.username) < 0);
      return this.get('users');
    }),
    actions: {
      closeDialog: function closeDialog() {
        this.clearForm();
      },
      statusChanged: function statusChanged(value) {
        this.set('isActiveSwitch', value);
        this.set('isPrivate', !value);
      },
      createGroupChat: function createGroupChat() {
        var _this = this;

        var selectedNames;

        if (this.get('groupChat')) {
          if (this.get('isPrivate')) {
            selectedNames = this.get('selectedUsers').map(function (val) {
              return val.pk;
            });
          }
        } else {
          selectedNames = String(this.get('user.pk')).split();
        }

        var hash = {
          isPrivate: true
        };

        if (this.get('groupChat')) {
          hash.isPrivate = this.get('isPrivate');
          hash.isGroup = true;
          hash.title = this.get('title');
        } else {
          hash.isGroup = false;
        }

        if (this.get('isPrivate') || !this.get('groupChat')) hash.participants = selectedNames;
        this.ajax.query(_mutation.default.CREATE_CHAT_ROOM, 'createChatRoom', hash).then(function (data) {
          _this.addRoom(data.room);

          _this.clearForm();
        });
      }
    },
    clearForm: function clearForm() {
      this.set('open', false);
      this.set('title', '');
      this.set('user', null);
      this.set('selectedUsers', []);
    }
  });

  _exports.default = _default;
});