define("inboxfront/templates/components/escalations/section-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MQ+vutRn",
    "block": "{\"symbols\":[\"condition\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-conditions\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title-section\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"conditions_desc\"],null],false],[0,\":\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"condition\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"conditions\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"escalations/condition-block\",null,[[\"condition\",\"conditionRules\",\"properties\",\"departments\",\"issueTypes\",\"channelTypes\",\"count\",\"onChange\",\"onDelete\"],[[23,1,[]],[24,[\"restRules\"]],[24,[\"properties\"]],[24,[\"departments\"]],[24,[\"issueTypes\"]],[24,[\"channelTypes\"]],[24,[\"conditions\",\"length\"]],[28,\"action\",[[23,0,[]],\"updateConditions\"],null],[28,\"action\",[[23,0,[]],\"deleteCondition\"],null]]]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"separator\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"block-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"addButtonVisible\"]]],null,{\"statements\":[[0,\"                \"],[7,\"span\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"addCondition\"],null]],[8],[1,[28,\"paper-icon\",[\"add_circle\"],null],false],[0,\" \"],[1,[28,\"t\",[\"new_condition\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/escalations/section-conditions.hbs"
    }
  });

  _exports.default = _default;
});