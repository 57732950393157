define("inboxfront/components/escalations/time-interval-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    day: Ember.computed('value', function () {
      return '' + Math.floor(this.get('value') / 1440);
    }),
    hour: Ember.computed('value', function () {
      return '' + Math.floor(this.get('value') / 60 % 24);
    }),
    minute: Ember.computed('value', function () {
      return '' + this.get('value') % 60;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var days = [];

      for (var i = 0; i < 31; i++) {
        days.push(i);
      }

      this.set('days', days);
      var hours = [];

      for (var _i = 0; _i < 24; _i++) {
        hours.push(_i);
      }

      this.set('hours', hours);
      var minutes = [];

      for (var _i2 = 0; _i2 < 60; _i2++) {
        minutes.push(_i2);
      }

      this.set('minutes', minutes);

      if (!this.get('value')) {
        this.set('value', 0);
      }
    },
    actions: {
      updateValue: function updateValue(name, value) {
        var day = +this.get('day');
        var hour = +this.get('hour');
        var minute = +this.get('minute');

        switch (name) {
          case 'day':
            day = +value;
            break;

          case 'hour':
            hour = +value;
            break;

          case 'minute':
            minute = +value;
            break;
        }

        var newValue = (day * 24 + hour) * 60 + minute;
        this.set('value', newValue);
        this.onChange(newValue);
      }
    }
  });

  _exports.default = _default;
});