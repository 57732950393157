define("inboxfront/controllers/contacts/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/helpers/countries-list", "moment"], function (_exports, _mutation, _query, _countriesList, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    limit: 10,
    page: 0,
    countriesList: _countriesList.COUNTRIES_LIST,
    chosenDetail: 'Contains',
    optionDate: Date,
    startDate: Date,
    endDate: Date,
    showDeleteDialog: false,
    isVisibleMergeButton: false,
    showMergeConfirmDialog: false,
    selectedCountry: null,
    selectedOrganization: null,
    selectedContactOwner: null,
    selectedFilterOptions: null,
    selectedProperty: null,
    incrementedPage: Ember.computed('page', 'cloneGuests', function () {
      return this.get('cloneGuests').length ? "".concat(this.page + 1) : 0;
    }),
    guestPage: Ember.computed('page', 'cloneGuests', function () {
      var guests = this.get('cloneGuests');
      var page = this.get('page');
      var limit = this.get('limit');
      return guests.slice(page * limit, (page + 1) * limit);
    }),
    cloneGuests: Ember.computed('guests', function () {
      return this.get('guests');
    }),
    lastPage: Ember.computed('page', function () {
      return parseInt(this.get('guests').length / 10);
    }),
    countPages: Ember.computed('guests', function () {
      return Math.ceil(this.get('guests').length / 10);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.listSelectedGuests = [];
      this.properties = [];
      this.organizations = [];
      this.listMergeSelectedGuests;
      this.listTitleMerge = ['Primary Email', 'Email', 'First Name', 'Last Name', 'Phone Number', 'Mobile Number', 'Home Address Line 1', 'Home Address Line 2', 'Home Postal Code', 'Home State', 'Home Country', 'Office Address Line 1', 'Office Address Line 2', 'Office Postal Code', 'Office State', 'Office Country', 'WhatsApp', 'Line', 'Telegram', 'Facebook', 'Twitter', 'WeChat'];
      this.listFilterFields = [{
        name: this.intl.t('firstname'),
        param: 'first_name'
      }, {
        name: this.intl.t('lastname'),
        param: 'last_name'
      }, {
        name: this.intl.t('email'),
        param: 'email'
      }, {
        name: this.intl.t('phone'),
        param: 'phone'
      }, {
        name: this.intl.t('tag'),
        param: 'tag'
      }, {
        name: this.intl.t('dob'),
        param: 'dob'
      }, {
        name: this.intl.t('country'),
        param: 'country'
      }, //{name: this.intl.t('company'), param: 'company'},
      {
        name: this.intl.t('organization'),
        param: 'organization'
      }];
      this.listFilterLookup = [{
        name: this.intl.t('filters.startswith'),
        param: 'startswith'
      }, {
        name: this.intl.t('filters.contains'),
        param: 'contains'
      }, {
        name: this.intl.t('filters.exact'),
        param: 'exact'
      }, {
        name: this.intl.t('filters.endswith'),
        param: 'endswith'
      }, {
        name: this.intl.t('filters.exclude'),
        param: 'exclude'
      }];
      this.selectedFilterField = this.listFilterFields[0];
      this.selectedFilterLookup = this.listFilterLookup[0];
      this.chosenProperty = {
        value: 'Full Name'
      };
      this.chosenProperties = [];
      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
      this.nameValidation = [{
        message: this.intl.t('forms.provide_name'),
        validate: function validate(inputValue) {
          var namePattern = /^[a-zA-Z0-9]/;
          return namePattern.test(inputValue);
        }
      }];
      this.emailValidation = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }];
    },
    actions: {
      updateColumns: function updateColumns() {
        try {
          var columnSettings = [];
          this.get('model.columns').forEach(function (c, i) {
            columnSettings.pushObject({
              id: c.id,
              pos: i,
              vis: c.visible
            });
          });
          var hash = {
            id: this.get('model.guestSettings.id'),
            fields: JSON.stringify(columnSettings)
          };
          this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
        } catch (e) {
          console.error('ON UPDATE GUEST COLUMNS', e);
        }
      },
      prevPage: function prevPage(page) {
        if (Number(page) > 0) {
          this.set('page', Number(page) - 1);
        }
      },
      nextPage: function nextPage(page) {
        if (Number(page) < this.get('lastPage')) {
          this.set('page', Number(page) + 1);
        }
      },
      allCheckContacts: function allCheckContacts(val) {
        this.set('allCheck', val);
        this.get('guestPage').map(function (el) {
          return Ember.set(el, 'checked', val);
        });
        this.set('isVisibleActions', val);
      },
      checkContact: function checkContact(val) {
        Ember.set(val, 'checked', !val.checked);
        this.set('isVisibleActions', this.get('guestPage').isAny('checked'));

        if (this.get('guestPage').filter(function (el) {
          return el.checked;
        }).length === 2) {
          this.set('isVisibleMergeButton', true);
        } else {
          this.set('isVisibleMergeButton', false);
        }
      },
      showDeleteContactsModal: function showDeleteContactsModal() {
        this.set('showDeleteDialog', true);
      },
      showAssignContactSidebar: function showAssignContactSidebar() {
        this.set('showPopupAssign', true);
      },
      mergeContactsConfirm: function mergeContactsConfirm() {
        var _this = this;

        var filterGuests = this.get('guestPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var guest = this.get('listMergeSelectedGuests');
        var hash = {
          secondaryGuestId: filterGuests[0],
          primaryGuestId: filterGuests[1],
          primaryEmail: guest[1],
          email: guest[2],
          firstName: guest[3],
          lastName: guest[4],
          personalPhone: guest[5],
          mobilePhone: guest[6],
          homeAddress1: guest[7],
          homeAddress2: guest[8],
          homeCity: guest[9],
          homeState: guest[10],
          homeCountry: guest[11],
          officeAddress1: guest[12],
          officeAddress2: guest[13],
          officeCity: guest[14],
          officeState: guest[15],
          officeCountry: guest[16]
        };
        this.ajax.query(_mutation.default.MERGE_GUESTS, 'mergeGuests', hash).then(function () {
          _this.set('showPopupMerge', false);

          _this.set('listSelectedGuests', []);

          _this.set('listMergeSelectedGuests', null);

          _this.set('isVisibleMergeButton', false);

          _this.set('showMergeConfirmDialog', false);

          _this.updateQueryGuests();
        });
      },
      showMergeContactSidebar: function showMergeContactSidebar() {
        var filterGuests = this.get('guestPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return [{
            avatar: {
              created: item.created,
              firstName: item.firstName,
              lastName: item.lastName
            }
          }, item.primaryEmail || '-', item.email || '-', item.firstName || '-', item.lastName || '-', item.personalPhone || '-', item.mobilePhone || '-', item.homeAddress ? item.homeAddress.line1 || '-' : '-', item.homeAddress ? item.homeAddress.line2 || '-' : '-', item.homeAddress ? item.homeAddress.city || '-' : '-', item.homeAddress ? item.homeAddress.state || '-' : '-', item.homeAddress ? item.homeAddress.country || '-' : '-', item.officeAddress ? item.officeAddress.line1 || '-' : '-', item.officeAddress ? item.officeAddress.line || '-' : '-', item.officeAddress ? item.officeAddress.city || '-' : '-', item.officeAddress ? item.officeAddress.state || '-' : '-', item.officeAddress ? item.officeAddress.country || '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'whatsapp') ? item.guestcontactSet.findBy('contactType', 'whatsapp').contactValue : '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'line') ? item.guestcontactSet.findBy('contactType', 'line').contactValue : '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'telegram') ? item.guestcontactSet.findBy('contactType', 'telegram').contactValue : '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'facebook') ? item.guestcontactSet.findBy('contactType', 'facebook').contactValue : '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'twitter') ? item.guestcontactSet.findBy('contactType', 'twitter').contactValue : '-' : '-', item.guestcontactSet[0] ? item.guestcontactSet.findBy('contactType', 'wechat') ? item.guestcontactSet.findBy('contactType', 'wechat').contactValue : '-' : '-'];
        });
        var firstFilterGuest = Object.assign([], filterGuests[0]);
        this.get('listSelectedGuests').pushObjects(filterGuests);
        this.set('listMergeSelectedGuests', firstFilterGuest);
        this.set('showPopupMerge', true);
        setTimeout(function () {
          document.querySelectorAll('.row-merge:nth-of-type(2) > div').forEach(function (el) {
            el.classList.add('active');
          });
        }, 300);
      },
      assignContacts: function assignContacts() {
        var _this2 = this;

        var filterContacts = this.get('guestPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          guestIds: filterContacts,
          contactOwner: this.get('selectedUser.id')
        };
        this.ajax.query(_mutation.default.ASSIGN_GUEST_USER, 'assignGuestUser', hash).then(function () {
          _this2.set('showPopupAssign', false);

          _this2.set('selectedUser', undefined);

          _this2.updateQueryGuests();
        });
      },
      mergeContacts: function mergeContacts() {
        this.set('showMergeConfirmDialog', true);
      },
      deleteContacts: function deleteContacts() {
        var _this3 = this;

        var guestIds = this.get('guestPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          guestIds: guestIds
        };
        this.ajax.query(_mutation.default.DELETE_GUESTS, 'deleteGuest', hash).then(function (res) {
          if (res.status === 400) {
            _this3.get('paperToaster').show(_this3.intl.t('contacts_not_found'));

            return;
          }

          _this3.get('paperToaster').show(_this3.intl.t('contacts_deleted'));

          _this3.updateQueryGuests();
        }).finally(function () {
          return _this3.set('showDeleteDialog', false);
        });
      },
      chooseGuest: function chooseGuest(val, index, indexWrap) {
        var mapGuests = this.get('listMergeSelectedGuests').map(function (el, i) {
          return index === i ? val : el;
        });
        var previousIndex = indexWrap === 1 ? 0 : 1;
        this.set('listMergeSelectedGuests', mapGuests);
        document.querySelectorAll(".row-merge:nth-of-type(".concat(indexWrap + 2, ") > div"))[index].classList.add('active');
        document.querySelectorAll(".row-merge:nth-of-type(".concat(previousIndex + 2, ") > div"))[index].classList.remove('active');
      },
      closeAssignDialog: function closeAssignDialog() {
        this.set('selectedUser', undefined);
        this.set('showPopupAssign', false);
      },
      closeMergeDialog: function closeMergeDialog() {
        this.set('listSelectedGuests', []);
        this.set('listMergeSelectedGuests', null);
        this.set('showPopupMerge', false);
      },
      clearFilter: function clearFilter() {
        this.set('searchContacts', null);
        this.set('isShowClearFilterBtn', false);
        this.updateQueryGuests();
      },
      filterContacts: function filterContacts() {
        var _this4 = this;

        var search = this.get('searchContacts');

        if (search.length > 1) {
          var hash = {
            search: [{
              field: this.get('selectedFilterField.param'),
              lookup: this.get('selectedFilterLookup.param'),
              value: search
            }]
          };
          this.set('isLoader', true);
          this.set('isShowClearFilterBtn', true);
          this.ajax.query(_query.default.GUEST_CONTACTS, 'guests', hash).then(function (data) {
            _this4.set('guestPage', data);

            _this4.set('isLoader', false);
          });
        }
      },
      searchGuest: function searchGuest(e) {
        var search = e.target.value;

        if (e.target.value.length > 1) {
          var chosenProperty = this.get('chosenProperty');
          var chosenDetail = this.get('chosenDetail');
          var guests = this.get('cloneGuests');

          if (chosenProperty.value === 'Full Name') {
            if (chosenDetail === 'Contains') {
              var filteredGuests = guests.filter(function (guest) {
                var fullName = guest.firstName + ' ' + guest.lastName;

                if (fullName.toUpperCase().includes(search.toUpperCase())) {
                  return guest;
                }
              });
              this.set('cloneGuests', filteredGuests);
            }
          }
        } else {
          this.set('cloneGuests', this.get('guests'));
        }
      },
      createGuest: function createGuest() {
        var _this5 = this;

        var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var hash = {
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          primaryEmail: this.get('email')
        };
        if (this.get('phone')) hash.personalPhone = this.get('phone');
        if (this.get('selectedCountry')) hash.country = this.get('selectedCountry.name');
        if (this.get('selectedContactOwner')) hash.contactOwner = this.get('selectedContactOwner.id');
        if (this.get('selectedOrganization')) hash.organizationId = this.get('selectedOrganization.id');
        if (this.get('selectedProperty')) hash.propertyId = this.get('selectedProperty.id');
        this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_GUEST, 'createOrUpdateGuest', hash, function () {
          _this5.set('showAddContact', false);

          _this5.clearForm();

          _this5.get('paperToaster').show(_this5.intl.t('contact_created'));

          _this5.updateQueryGuests(isOpen);
        });
      },
      showAddContact: function showAddContact() {
        var _this6 = this;

        this.set('showAddContact', true);
        this.ajax.query(_query.default.ORGANIZATIONS_SELECT, 'organizations').then(function (data) {
          _this6.set('organizations', data);
        });
      },
      showFilterContact: function showFilterContact() {
        this.set('filterPopup', true);
        this.set('isFilterInserted', true);
        this.set('showFilterDialog', true);
      },
      closeContactDialog: function closeContactDialog() {
        this.set('filterPopup', false);
        this.set('showAddContact', false);
        this.clearForm();
      }
    },
    updateQueryGuests: function updateQueryGuests() {
      var _this7 = this;

      var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set('isLoader', true);
      this.ajax.query(_query.default.GUEST_CONTACTS).then(function (data) {
        _this7.set('isVisibleActions', false);

        _this7.set('guests', data);

        isOpen && _this7.set('showAddContact', true);

        _this7.set('isLoader', false);
      });
    },
    filterGridContacts: function filterGridContacts(params) {
      var column = this.get('column');
      var arr = [];
      column.map(function (c) {
        var id = c.id;

        if (id === 'phone') {
          return params[1].personalPhone;
        } else if (id === 'country') {
          var _params$1$homeAddress, _params$1$officeAddre;

          var filterCountry = _countriesList.COUNTRIES_LIST.findBy('iso2', params[1].citizenship);

          return ((_params$1$homeAddress = params[1].homeAddress) === null || _params$1$homeAddress === void 0 ? void 0 : _params$1$homeAddress.country) || ((_params$1$officeAddre = params[1].officeAddress) === null || _params$1$officeAddre === void 0 ? void 0 : _params$1$officeAddre.country) || filterCountry;
        } else if (id === 'company') {
          return params[1].companyProperty.title;
        } else if (id === 'organization') {
          if (!params[1].organization) return '';
          return params[1].organization.name;
        } else if (id === 'contactOwner') {
          if (!params[1].contactOwner) return 'Unassigned';
          return "".concat(params[1].contactOwner.firstName, " ").concat(params[1].contactOwner.lastName);
        } else if (id === 'created') {
          return (0, _moment.default)(params[1].created).format('L');
        } else if (id === 'mobile') {
          return params[1].mobilePhone;
        } else {
          return '';
        }
      });
      console.log('column', column);
    },
    clearForm: function clearForm() {
      this.set('firstName', '');
      this.set('lastName', '');
      this.set('email', '');
      this.set('phone', '');
      this.set('selectedCountry', null);
      this.set('selectedOrganization', null);
      this.set('selectedContactOwner', null);
    }
  });

  _exports.default = _default;
});