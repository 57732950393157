define("inboxfront/components/chat-bot", ["exports", "inboxfront/gql/query", "jquery"], function (_exports, _query, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    isLoading: false,
    nextBlockButton: false,
    currentIndex: 0,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.blockList = [];
      this.ajax.query(_query.default.FLOW, 'flow', {
        flowId: this.get('flowId')
      }).then(function (data) {
        _this.set('blocks', data.blocks);

        _this.runFlow(data.blocks[0]);
      });
    },
    actions: {
      sendChat: function sendChat(button) {
        if (button.id) {
          if (button.children.length) {
            var nextBlock = this.get('blocks').findBy('id', button.children[0].id);
            this.set('nextBlock', nextBlock);
            this.set('nextBlockButton', true);
          } else {
            return;
          }
        } else {
          this.get('blockList').pushObject({
            mainTitle: this.get('messageInput'),
            position: 'right'
          });
          this.set('messageInput', '');
        }

        this.renderMessage();
      }
    },
    runFlow: function runFlow(firstBlock) {
      var _this2 = this;

      if (!firstBlock) {
        return;
      }

      this.set('isLoading', true);

      if (firstBlock.blockType === 'FORM') {
        this.set('nextBlock', firstBlock);
      } else if (firstBlock.blockType === 'SLIDER') {
        var nextBlock = firstBlock.children[0] ? firstBlock.children.filter(function (child) {
          return child.blockType !== 'BUTTON';
        })[0] : {};
        this.set('nextBlock', nextBlock);
      } else {
        this.set('nextBlock', firstBlock && firstBlock.children[0] && firstBlock.children[0]);
      }

      setTimeout(function () {
        _this2.set('isLoading', false);

        if (firstBlock) {
          var _nextBlock = _objectSpread(_objectSpread({}, firstBlock), {}, {
            position: 'left'
          });

          _this2.get('blockList').pushObject(_nextBlock);

          if (firstBlock.blockType === 'FORM') {
            _this2.renderMessage();
          }
        }
      }, 1000);
    },
    renderMessage: function renderMessage() {
      var _this3 = this;

      var listWrapper = (0, _jquery.default)('.list-wrapper');

      if (!this.get('nextBlockButton')) {
        var nextBlockId = this.get('nextBlock.id');

        if (nextBlockId) {
          if (this.get('nextBlock.blockType') === 'FORM') {
            this.renderFormMessage();
          } else if (this.get('nextBlock.blockType') === 'SLIDER') {
            this.set('nextBlock', this.get('nextBlock.children').filter(function (child) {
              return child.blockType !== 'BUTTON';
            })[0]);
          } else {
            this.set('nextBlock', this.get('blocks').findBy('id', nextBlockId));
          }
        } else {
          return;
        } // this.get('nextBlock.blockType') !== 'FORM' && this.set('nextBlock', this.get('nextBlock'));

      } else {
        /*if (this.get('nextBlock.blockType') === 'FORM') {
        	this.renderFormMessage();
        }*/
        this.set('nextBlockButton', false);
      }

      this.set('isLoading', true);
      listWrapper.stop().animate({
        scrollTop: listWrapper[0].scrollHeight
      }, 300);
      setTimeout(function () {
        _this3.set('isLoading', false);

        console.log('nextBlock', _this3.get('nextBlock'));
        _this3.get('nextBlock') && _this3.get('blockList').pushObject(_this3.get('nextBlock'));

        if (_this3.get('nextBlock.blockType') === 'FORM' && _this3.get('currentIndex') === 0) {
          _this3.renderMessage();
        }

        listWrapper.stop().animate({
          scrollTop: listWrapper[0].scrollHeight
        }, 500);
      }, 500);
    },
    renderFormMessage: function renderFormMessage() {
      var nextBlock = this.get('nextBlock');

      var currentBlock = _objectSpread({}, nextBlock);

      var index = this.get('currentIndex');
      var extraData = JSON.parse(nextBlock.extraData);
      currentBlock.position = 'left';
      currentBlock.mainTitle = extraData.dataCollectorsSelect[index] && extraData.dataCollectorsSelect[index].text;
      this.set('currentIndex', index + 1);

      if (!currentBlock.mainTitle) {
        currentBlock = nextBlock.children[0] && this.get('blocks').findBy('id', nextBlock.children[0].id);
        this.set('currentIndex', 0);
      }

      this.set('nextBlock', currentBlock);
    }
  });

  _exports.default = _default;
});