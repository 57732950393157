define("inboxfront/templates/components/chat-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EyX2a/zJ",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"message-item\",null,[[\"tagName\",\"selectedThread\",\"starredList\",\"message\",\"showReplyInput\",\"selectedMessage\"],[\"\",[24,[\"selectedThread\"]],[24,[\"starredList\"]],[23,1,[]],[24,[\"showReplyInput\"]],[24,[\"selectedMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/chat-list.hbs"
    }
  });

  _exports.default = _default;
});