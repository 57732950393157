define("inboxfront/controllers/departments", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    showAddDepartment: false,
    showDeleteDialog: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    actions: {
      showCreateDialog: function showCreateDialog() {
        this.toggleShowPopup();
      },
      showEditDialog: function showEditDialog(department) {
        this.set('editDisplayName', department.displayName);
        this.set('showAddDepartment', true);
        this.set('isUserDefined', false);

        if (department.category === 'A_2') {
          this.set('editDepartmentName', department.name);
          this.set('editDescription', department.description);
          this.set('isUserDefined', true);
        }

        this.set('editDepartmentId', department.id);
        this.set('showEditDepartment', true);
      },
      closeDepartmentDialog: function closeDepartmentDialog() {
        this.clearForm();
        this.set('showEditDepartment', false);
      },
      saveDepartment: function saveDepartment() {
        var _this = this;

        var toaster = this.get('paperToaster');
        var hash = {
          name: this.get('departmentName'),
          displayName: this.get('displayName') || this.get('departmentName'),
          description: this.get('description')
        };
        this.ajax.query(_mutation.default.CREATE_DEPARTMENT, 'createUserDefinedDepartment', hash).then(function (result) {
          _this.get('model.departments').pushObject(result.department);

          toaster.show(_this.intl.t('department_created', {
            name: hash.displayName
          }));

          _this.clearForm();
        }).catch(function () {
          return toaster.show(_this.intl.t('network_error'));
        });
      },
      updateDepartment: function updateDepartment() {
        var _this2 = this;

        var toaster = this.get('paperToaster');
        var isUserDefined = this.get('isUserDefined');
        var query = {};
        var hash = {};
        var nameMutation = '';

        if (isUserDefined) {
          query = _mutation.default.UPDATE_USER_DEFINED_DEPARTMENT;
          nameMutation = 'updateUserDefinedDepartment';
          hash = {
            departmentId: this.get('editDepartmentId'),
            name: this.get('editDepartmentName'),
            displayName: this.get('editDisplayName'),
            description: this.get('editDescription')
          };
        } else {
          query = _mutation.default.UPDATE_SYSTEM_DEFINED_DEPARTMENT;
          nameMutation = 'updateSystemDefinedDepartment';
          hash = {
            departmentId: this.get('editDepartmentId'),
            displayName: this.get('editDisplayName')
          };
        }

        this.ajax.query(query, nameMutation, hash).then(function (result) {
          var department = _this2.get('model.departments').findBy('id', _this2.get('editDepartmentId'));

          Ember.set(department, 'name', result.department.name);
          Ember.set(department, 'displayName', result.department.displayName);
          Ember.set(department, 'description', result.department.description);

          _this2.set("editDepartmentName", "");

          _this2.set("editDisplayName", "");

          _this2.set("editDescription", "");

          _this2.set("editDepartmentId", "");

          toaster.show(_this2.intl.t('department_updated'));

          _this2.set("showEditDepartment", false);

          _this2.set("showAddDepartment", false);
        }).catch(function () {
          return toaster.show(_this2.intl.t('network_error'));
        });
      },
      updateStatus: function updateStatus(status, id) {
        var _this3 = this;

        var toaster = this.get('paperToaster');
        var query = {};
        var name = '';
        var hash = {
          departmentId: id
        };

        if (status) {
          query = _mutation.default.DEACTIVATE_DEPARTMENT;
          name = 'deactivateUserDefinedDepartment';
        } else {
          query = _mutation.default.ACTIVATE_DEPARTMENT;
          name = 'activateUserDefinedDepartment';
        }

        this.ajax.query(query, name, hash).then(function (result) {
          if (result.department) {
            var department = _this3.get('model.departments').findBy('id', id);

            Ember.set(department, 'active', result.department.active);
            toaster.show(_this3.intl.t(status ? 'department_deactivated' : 'department_activated'));

            _this3.set('showEditDepartment', false);
          } else {
            var error = JSON.parse(result.formErrors);
            toaster.show(error.error);
          }
        }).catch(function () {
          return toaster.show(_this3.intl.t('network_error'));
        });
      },
      deleteDialog: function deleteDialog(department) {
        this.set('selectedDepartment', department);
        this.set('showDeleteDialog', true);
      },
      deleteDepartment: function deleteDepartment() {
        var _this4 = this;

        var hash = {
          departmentId: this.get('selectedDepartment.id')
        };
        this.ajax.mutation(_mutation.default.DELETE_USER_DEFINED_DEPARTMENT, 'deleteUserDefinedDepartment', hash, function (res) {
          _this4.get('paperToaster').show('Department removed');

          _this4.get('model.departments').removeObject(_this4.get('selectedDepartment'));

          _this4.set('selectedDepartment', null);
        });
      }
    },
    toggleShowPopup: function toggleShowPopup() {
      this.set('showAddDepartment', !this.get('showAddDepartment'));
    },
    clearForm: function clearForm() {
      this.set('departmentName', '');
      this.set('displayName', '');
      this.set('description', '');
      this.toggleShowPopup();
    }
  });

  _exports.default = _default;
});