define("inboxfront/controllers/kb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    kb: Ember.inject.service(),
    showButton: true,
    selectedBase: null,
    sections: Ember.computed('selectedBase', 'selectedBase.sections.[]', function () {
      return this.get('selectedBase.sections');
    }),
    categories: Ember.computed('selectedBase', 'selectedBase.categories.[]', function () {
      return this.get('selectedBase.categories');
    }),
    articles: Ember.computed('selectedBase', 'selectedBase.articles.[]', function () {
      return this.get('selectedBase.articles');
    }),
    pageIcon: Ember.computed('subPage', function () {
      switch (this.get('subPage')) {
        case 'sections':
          return 'sections';

        case 'edit_section':
          return 'create-section';

        case 'categories':
          return 'categories';

        case 'edit_category':
          return 'create-category';

        case 'articles':
          return 'articles';

        case 'edit_article':
          return 'create-article';

        case 'magic_styling':
          return 'magic-styles';

        case 'kb-create-form':
          return 'create-kb';

        case 'kb-create-article':
          return 'create-article';

        case 'kb-create-section':
          return 'create-section';

        case 'settings':
        case 'insights':
          return this.get('subPage');

        default:
          return 'knowledge-base';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bases', this.get('kb.bases'));
      this.set('pageTitle', this.get('intl').t('kb'));
    },
    actions: {
      showCreateKB: function showCreateKB() {
        if (this.get('subPage') === 'kb-create-form') {
          return;
        }

        this.set('kbName', '');

        this._showPage('kb-create-form');

        this.set('pageTitle', this.get('intl').t('kb'));
      },
      createKB: function createKB() {
        var _this = this;

        this.get('kb').addBase(this.get('kbName')).then(function (base) {
          _this._selectBase(base);

          _this.set('errorDescription', null);
        }).catch(function (error) {
          if (error.status === 400) {
            _this.set('errorDescription', 'Knowledge Base with this name already exists, please try to enter another name');

            setTimeout(function () {
              _this.set('errorDescription', null);
            }, 4000);
          }
        });
      },
      onChangeNameKb: function onChangeNameKb(val) {
        var slugUrl = this.get('kb').formatSlug(val);
        this.set('kbName', val);
        this.set('slugUrl', slugUrl + '.raincheck.co');
      },
      deleteKB: function deleteKB() {
        var _this2 = this;

        this.get('kb').deleteBase(this.get('selectedBase')).then(function (bases) {
          if (bases[0]) {
            _this2._selectBase(bases[0], 'kb-landing');
          } else {
            _this2.set('kbName', '');

            _this2._showPage('kb-create-form');

            _this2.set('pageTitle', _this2.get('intl').t('kb'));
          }
        });
      },
      closeCreateKB: function closeCreateKB() {
        this._selectBase(this.get('selectedBase'));
      },
      showKBLanding: function showKBLanding() {
        this._selectBase(this.get('selectedBase'), 'kb-landing');
      },
      selectKB: function selectKB(base) {
        this._selectBase(base);
      },
      showLanding: function showLanding(page) {
        this._showPage(page, true);
      },
      showCreateSection: function showCreateSection() {
        this.set('selectedSection', this.get('kb').createSection(this.get('selectedBase')));

        this._showPage('edit_section');
      },
      changeSectionStatus: function changeSectionStatus(value) {
        this.set('selectedSection.status', value ? 'PUBLISHED' : 'DRAFT');
        this.get('selectedSection').validate();
      },
      changeCategoryStatus: function changeCategoryStatus(value) {
        this.set('selectedCategory.status', value ? 'PUBLISHED' : 'DRAFT');
        this.get('selectedCategory').validate();
      },
      changeArticleStatus: function changeArticleStatus(value) {
        this.set('selectedArticle.status', value ? 'PUBLISHED' : 'DRAFT');
        this.get('selectedArticle').validate();
      },
      moveToBase: function moveToBase(base, sectionId) {
        var section = this.get('kb').findSection(sectionId);

        if (section.base === base) {
          return;
        }

        this.set('selectedSection', section);
        this.set('newBase', base);
        this.set('showSectionBaseUpdateDialog', true);
      },
      onChangeSectionBase: function onChangeSectionBase(base) {
        var section = this.get('selectedSection');

        if (section.id) {
          this.set('newBase', base);
          this.set('showSectionBaseUpdateDialog', true);
        } else {
          this.set('selectedBase', base);
          section.setBase(base);
        }
      },
      updateSectionBase: function updateSectionBase() {
        var _this3 = this;

        this.get('kb').saveSection(this.get('selectedSection'), this.get('newBase')).then(function () {
          _this3.set('selectedBase', _this3.get('newBase'));

          _this3.set('showSectionBaseUpdateDialog', false);

          _this3.get('paperToaster').show('Base changed');
        });
      },
      moveToSection: function moveToSection(section, categoryId) {
        var category = section.base.categories.findBy('id', categoryId);

        if (category.section === section) {
          return;
        }

        this.set('selectedCategory', category);
        this.set('newSection', section);
        this.set('showCategorySectionUpdateDialog', true);
      },
      onChangeCategorySection: function onChangeCategorySection(section, category) {
        this.set('selectedCategory', category);
        this.set('newSection', section);
        this.set('showCategorySectionUpdateDialog', true);
      },
      updateSection: function updateSection() {
        var _this4 = this;

        this.get('kb').saveCategory(this.get('selectedCategory'), this.get('newSection')).then(function () {
          _this4.get('paperToaster').show('Section changed');
        });
      },
      prevPage: function prevPage() {
        console.log('GO PREV PAGE');
      },
      nextPage: function nextPage() {
        console.log('GO NEXT PAGE');
      },
      dragEndSection: function dragEndSection(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;

        if (sourceList !== targetList) {
          return;
        }

        var field = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, field);
        this.get('kb').updateSectionOrders(this.get('selectedBase'));
      },
      dragEndCategories: function dragEndCategories(_ref2) {
        var sourceList = _ref2.sourceList,
            sourceIndex = _ref2.sourceIndex,
            targetList = _ref2.targetList,
            targetIndex = _ref2.targetIndex;

        if (sourceList !== targetList) {
          return;
        }

        var field = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, field);
        this.get('kb').updateCategoriesOrders(this.get('selectedBase'));
      },
      dragEndArticles: function dragEndArticles(_ref3) {
        var sourceList = _ref3.sourceList,
            sourceIndex = _ref3.sourceIndex,
            targetList = _ref3.targetList,
            targetIndex = _ref3.targetIndex;

        if (sourceList !== targetList) {
          return;
        }

        var field = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, field);
        this.get('kb').updateArticlesOrders(this.get('selectedBase'));
      },
      showEditSection: function showEditSection(section) {
        this.set('selectedSection', section);

        this._showPage('edit_section');
      },
      showEditCategory: function showEditCategory(section, category, fromAddArticles) {
        this.set('selectedCategory', category || this.get('kb').createCategory(this.get('selectedBase'), section));
        this.set('selectedSection', category ? category.section : section);

        if (fromAddArticles === true) {
          this._showBurgerMenu();
        }

        this.set('pageTitle', '');
        this.set('backToSection', section);

        this._showPage('edit_category');
      },
      backFromCategoryEditor: function backFromCategoryEditor() {
        if (this.get('backToSection')) {
          if (this.get('selectedSection') !== this.get('selectedCategory.section')) {
            this.set('selectedSection', this.get('selectedCategory.section'));
          }

          this._showPage('edit_section');
        } else {
          this._showPage('categories', true);
        }

        this.set('backToSection', false);
      },
      confirmSectionDeletion: function confirmSectionDeletion(section) {
        if (section.isEmpty()) {
          this.set('selectedSection', section);
          this.set('showSectionDeleteDialog', true);
        } else {
          this.set('showSectionIsNotEmptyDialog', true);
        }
      },
      deleteSection: function deleteSection() {
        var _this5 = this;

        this.get('kb').deleteSection(this.get('selectedSection')).then(function () {
          _this5.set('selectedSection', null);

          _this5._showPage('sections', true);
        }).catch(function (e) {}).finally(function () {
          _this5.set('showSectionDeleteDialog', false);
        });
      },
      showCategories: function showCategories() {
        this.set('selectedSection', null);

        this._showPage('categories', true);
      },
      confirmCategoryDeletion: function confirmCategoryDeletion(category, fromSection) {
        if (category.isEmpty()) {
          this.set('fromSection', fromSection === true);
          this.set('selectedCategory', category);
          this.set('showCategoryDeleteDialog', true);
        } else {
          this.set('showCategoryIsNotEmptyDialog', true);
        }
      },
      deleteCategory: function deleteCategory() {
        this.get('kb').deleteCategory(this.get('selectedCategory'), this.get('selectedBase'));
        this.set('selectedCategory', null);
        this.set('showCategoryDeleteDialog', false);

        if (this.get('fromSection')) {
          this._showPage('edit_section');
        } else {
          this._showPage('categories', true);
        }
      },
      removeArticle: function removeArticle(article, category) {
        if (article.categories.length === 1) {
          this.set('selectedArticle', article);
          this.set('isShowDeleteArticleDialog', true);
        } else {
          this.get('kb').removeArticle(article, category);
        }
      },
      duplicateCategory: function duplicateCategory(category) {
        this.get('kb').duplicateCategory(category, this.get('selectedBase'));
      },
      showAddArticle: function showAddArticle() {
        this._hideBurgerMenu();

        var categoryTitle = this.get('selectedCategory.title');
        this.set('pageTitle', 'Add Articles to ' + (categoryTitle ? categoryTitle : ' this category'));

        this._showPage('add_articles');
      },
      addArticles: function addArticles(selected) {
        this.get('kb').addArticles(selected, this.get('selectedCategory'));

        this._showBurgerMenu();

        this.set('pageTitle', '');

        this._showPage('edit_category');
      },
      addToCategory: function addToCategory(articleId, category) {
        var article = this.get('selectedBase.articles').findBy('id', articleId);
        article && this.get('kb').addArticles([article], category);
      },
      showEditArticle: function showEditArticle(category, article, fromAddArticles) {
        this.set('selectedArticle', article || this.get('kb').createArticle(category));
        this.set('selectedCategory', category || (article ? article.categories[0] : null));
        this.set('selectedSection', category ? category.section : article && article.categories[0] ? article.categories[0].section : null);

        if (fromAddArticles === true) {
          this._showBurgerMenu();
        }

        this.set('pageTitle', '');
        this.set('backToCategory', category);

        this._showPage('edit_article');
      },
      backFromArticleEditor: function backFromArticleEditor() {
        if (this.get('backToCategory')) {
          this._showPage('edit_category');
        } else {
          this._showPage('articles', true);
        }

        this.set('backToCategory', false);
      },
      confirmArticleDeletion: function confirmArticleDeletion(article) {
        this.set('selectedArticle', article);
        this.set('isShowDeleteArticleDialog', true);
      },
      deleteArticle: function deleteArticle() {
        this.get('kb').deleteArticle(this.get('selectedArticle'), this.get('selectedBase'));
        this.set('isShowDeleteArticleDialog', false);
        this.set('selectedArticle', null);
      },
      duplicateArticle: function duplicateArticle(article) {
        this.get('kb').duplicateArticle(article, this.get('selectedBase'));
      },
      showSectionPreview: function showSectionPreview(section) {
        this.set('previewArticle', null);
        this.set('previewCategory', null);
        this.set('previewSection', section);
        this.set('isShowKbPreview', true);
      },
      showCategoryPreview: function showCategoryPreview(category) {
        this.set('previewArticle', null);
        this.set('previewCategory', category);
        this.set('previewSection', category.section);
        this.set('isShowKbPreview', true);
      },
      showArticlePreview: function showArticlePreview(article) {
        this.set('previewArticle', article);
        this.set('previewCategory', article.categories[0]);
        this.set('previewSection', (article.categories[0] || {}).section);
        this.set('isShowKbPreview', true);
      },
      discardArticleChanges: function discardArticleChanges() {
        this.get('selectedArticle').restore();
        this.get('discardCallback') && this.get('discardCallback')();
      }
    },
    _selectBase: function _selectBase(base, subPage) {
      switch (this.get('subPage')) {
        case 'sections':
        case 'categories':
        case 'articles':
        case 'magic_styling':
        case 'settings':
        case 'insights':
          subPage = subPage || this.get('subPage');
          break;

        default:
          subPage = subPage || 'kb-landing';
      }

      this.set('selectedBase', base);
      this.set('pageTitle', base.name);

      this._showPage(subPage);
    },
    _showPage: function _showPage(subPage, updateTitle) {
      var _this6 = this;

      return this._checkEntityUpdates(function () {
        _this6.set('subPage', subPage);

        updateTitle && _this6.set('pageTitle', _this6.get('intl').t(subPage));
      });
    },
    _checkEntityUpdates: function _checkEntityUpdates(callback) {
      if (this.get('selectedArticle.isUpdated')) {
        this.set('discardCallback', callback);
        this.set('showDiscardChangesDialog', true);
        return false;
      }

      callback && callback();
      return true;
    },
    _showBurgerMenu: function _showBurgerMenu() {
      this.set('showButton', true);
      this.set('isCollapsed', this.get('isCollapsedPrev'));
      this.set('isCollapsedPrev', null);
    },
    _hideBurgerMenu: function _hideBurgerMenu() {
      this.set('showButton', false);
      this.set('isCollapsedPrev', this.get('isCollapsed'));
      this.set('isCollapsed', true);
    }
  });

  _exports.default = _default;
});