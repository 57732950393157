define("inboxfront/templates/components/common/buttons/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1aoV94I",
    "block": "{\"symbols\":[\"@action\",\"@class\"],\"statements\":[[4,\"common/buttons/secondary-2\",null,[[\"class\",\"onClick\"],[[23,2,[]],[28,\"or\",[[23,1,[]],[24,[\"action\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,\"defaultText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/buttons/cancel.hbs"
    }
  });

  _exports.default = _default;
});