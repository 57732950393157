define("inboxfront/components/common/dialogs/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    clickOutsideToClose: true,
    actions: {
      cancel: function cancel() {
        this.onClose && this.onClose();
        this.set('open', false);
      },
      confirm: function confirm() {
        this.onConfirm && this.onConfirm();
        this.set('open', false);
      }
    }
  });

  _exports.default = _default;
});