define("inboxfront/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    sessionAccount: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._loadCurrentUser(true);

      this._super.apply(this, arguments);
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var isAuth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return this.get('sessionAccount').load(isAuth);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('userID', this.get('sessionAccount.loggeduser.pk'));
    }
  });

  _exports.default = _default;
});