define("inboxfront/helpers/flow-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flowDisabled = flowDisabled;
  _exports.default = void 0;

  function flowDisabled(params) {
    if (params.length) {
      var selectedBlock = params[0];
      var component = params[1];
      var optionsForm = params[2];

      if (!optionsForm && component.type === 'data' || selectedBlock.children.length && selectedBlock.children.some(function (item) {
        return item.blockType !== 'BUTTON';
      }) && component.type === 'action') {
        return 'disabled';
      }
    }

    return '';
  }

  var _default = Ember.Helper.helper(flowDisabled);

  _exports.default = _default;
});