define("inboxfront/routes/dashboard", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "highcharts", "highcharts/modules/heatmap.js", "moment"], function (_exports, _common, _query, _highcharts, _heatmap, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: function model() {
      var properties = this.ajax.query(_query.default.PROPERTY_LIST);
      var customGroups = this.ajax.query(_query.default.CUSTOM_GROUPS);
      return Ember.RSVP.all([properties, customGroups]).then(function (res) {
        return {
          properties: !res[0].errors ? res[0] : [],
          customGroups: !res[1].errors ? res[1] : []
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var filterCompanies = model.properties.filter(function (el) {
        return !el.parent && el.children.length;
      }).map(function (item) {
        return {
          groupName: item.title,
          options: item.children
        };
      });
      var groups = model.customGroups.map(function (el) {
        return {
          groupName: el.name,
          options: el.properties
        };
      });
      var mergeObj = [].concat(_toConsumableArray(filterCompanies), _toConsumableArray(groups));
      var dateNow = (0, _moment.default)(new Date());
      var dateJoined = (0, _moment.default)(new Date(this.get('sessionAccount.loggeduser.dateJoined')));

      if (mergeObj.length) {
        mergeObj.unshift({
          id: '*',
          title: this.intl.t('all_companies')
        });
        controller.set('selectedProperty', {
          id: '*',
          title: this.intl.t('all_companies')
        });
      } else {
        var estate = this.get('sessionAccount.loggeduser') ? this.get('sessionAccount.loggeduser.properties')[0] : {};
        groups.push(estate);
        mergeObj = this.get('sessionAccount.loggeduser.properties');
        controller.set('selectedProperty', estate);
      }

      (0, _heatmap.default)(_highcharts.default);
      controller.set('customGroups', mergeObj);
      controller.set('isShowStartScreen', false);
      controller.set('isJoinedUser', Boolean(dateNow.diff(dateJoined, 'days')));
    }
  });

  _exports.default = _default;
});