define("inboxfront/controllers/escalations/index", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    router: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('targetModules', ['tasks', 'conversation']);
      this.nameValidation = [{
        message: this.intl.t('forms.provide_name'),
        validate: function validate(inputValue) {
          var namePattern = /^[a-zA-Z0-9]/;
          return namePattern.test(inputValue);
        }
      }];
    },
    actions: {
      showDialog: function showDialog() {
        this.clearForm();
        this.set('showCreateDialog', true);
      },
      closeDialog: function closeDialog() {
        this.set('showCreateDialog', false);
      },
      updateField: function updateField(name, value) {
        this.set(name, value);
        this.checkForm();
      },
      updateTargetType: function updateTargetType(value) {
        this.set('targetType', value);
        this.set('conditions', [{
          values: []
        }]);

        if (value === 'tasks') {
          this.set('slaTarget', [{
            priority: 'U',
            frt: 0,
            nrt: 0,
            rt: 0,
            hours: '24 hours'
          }, {
            priority: 'H',
            frt: 0,
            nrt: 0,
            rt: 0,
            hours: '24 hours'
          }, {
            priority: 'M',
            frt: 0,
            nrt: 0,
            rt: 0,
            hours: '24 hours'
          }, {
            priority: 'L',
            frt: 0,
            nrt: 0,
            rt: 0,
            hours: '24 hours'
          }]);
        } else {
          this.set('slaTarget', [{
            frt: 0,
            nrt: 0,
            rt: 0,
            hours: '24 hours'
          }]);
        }

        this.checkForm();
      },
      updateConditions: function updateConditions() {
        this.checkForm();
      },
      updateSLATarget: function updateSLATarget() {
        this.checkForm();
      },
      updateReminders: function updateReminders() {
        this.checkForm();
      },
      updateEscalations: function updateEscalations() {
        this.checkForm();
      },
      createEscalation: function createEscalation() {
        var _this = this;

        var targetType = this.get('targetType');
        var hash = {
          name: this.get('name'),
          description: this.get('description'),
          targetType: targetType,
          slaTarget: JSON.stringify(this.get('slaTarget')),
          conditions: JSON.stringify(this.serializeConditions(this.get('conditions'), targetType, this.get('model.properties')))
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function (res) {
          var mutations = [];

          _this.get('reminders').forEach(function (r) {
            if (!_this.validateReminder(r)) return;
            mutations.push(_this.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this.serializeReminder(r, res.escalation.id)));
          });

          _this.get('escalations').forEach(function (r) {
            if (!_this.validateReminder(r)) return;
            mutations.push(_this.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this.serializeReminder(r, res.escalation.id)));
          });

          return Ember.RSVP.all(mutations).then(function () {
            _this.get('paperToaster').show(_this.intl.t('Escalations.escalation_created'));

            _this.set('showCreateDialog', false);

            _this.refreshRoute();
          });
        });
      },
      changeEscalationStatus: function changeEscalationStatus(escalation) {
        var _this2 = this;

        var status = !escalation.isActive;
        var hash = {
          escalationId: escalation.id,
          isActive: status
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function () {
          Ember.set(escalation, 'isActive', status);

          if (status) {
            _this2.get('paperToaster').show(_this2.intl.t('Escalations.escalation_activated'));
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('Escalations.escalation_deactivated'));
          }
        });
      },
      deleteEscalationDialog: function deleteEscalationDialog(escalation) {
        this.set('selectedEscalation', escalation);
        this.set('showDeleteEscalationDialog', true);
      },
      deleteEscalation: function deleteEscalation() {
        var _this3 = this;

        this.ajax.mutation(_mutation.default.DELETE_ESCALATION, 'deleteEscalation', {
          escalationId: this.get('selectedEscalation.id')
        }, function () {
          _this3.get('paperToaster').show(_this3.intl.t('Escalations.escalation_deleted'));

          _this3.get('model.escalations').removeObject(_this3.get('selectedEscalation'));

          _this3.set('selectedEscalation', undefined);

          _this3.set('showDeleteEscalationDialog', false);
        });
      },
      duplicateEscalationDialog: function duplicateEscalationDialog(escalation) {
        this.set('selectedEscalation', escalation);
        this.set('showDuplicateEscalationDialog', true);
      },
      duplicateEscalation: function duplicateEscalation() {
        var _this4 = this;

        this.ajax.mutation(_mutation.default.DUPLICATE_ESCALATION, 'duplicateEscalation', {
          escalationId: this.get('selectedEscalation.id')
        }, function (res) {
          _this4.set('selectedEscalation', undefined);

          _this4.set('showDuplicateEscalationDialog', false);

          _this4.transitionToRoute('escalations.edit', {
            queryParams: {
              id: res.escalation.id
            }
          });
        });
      }
    },
    clearForm: function clearForm() {
      this.set('name', '');
      this.set('description', '');
      this.set('targetType', '');
      this.set('conditions', [{
        values: []
      }]);
      this.set('slaTarget', [{
        frt: 0,
        nrt: 0,
        rt: 0,
        hours: '24 hours'
      }]);
      this.set('reminders', [{
        violated: false,
        values: []
      }]);
      this.set('escalations', [{
        violated: true,
        values: []
      }]);
      this.set('formIsInvalid', true);
    },
    checkForm: function checkForm() {
      this.set('formIsInvalid', true);
      var name = this.get('name');

      if (!this.trimValidation[0].validate(name)) {
        return;
      }

      var targetType = this.get('targetType');

      if (!targetType) {
        return;
      }

      var conditions = this.get('conditions');
      var valid = true;

      for (var i = 0; i < conditions.length && valid; i++) {
        if (!conditions[i].rule) valid = false;
        if (conditions[i].values.length < 1) valid = false;
      }

      if (!valid) {
        return;
      }

      var slaTarget = this.get('slaTarget');
      valid = true;

      for (var _i = 0; _i < slaTarget.length && valid; _i++) {
        if (!slaTarget[_i].frt) valid = false;
        if (!slaTarget[_i].rt) valid = false;
      }

      if (!valid) {
        return;
      }

      var reminders = this.get('reminders');
      valid = true;

      for (var _i2 = 0; _i2 < reminders.length && valid; _i2++) {
        if (reminders[_i2].rule) {
          valid = this.validateReminder(reminders[_i2]);
        }
      }

      if (!valid) {
        return;
      }

      var escalations = this.get('escalations');
      valid = true;

      for (var _i3 = 0; _i3 < escalations.length && valid; _i3++) {
        valid = this.validateReminder(escalations[_i3]);
      }

      if (!valid) {
        return;
      }

      this.set('formIsInvalid', false);
    },
    validateReminder: function validateReminder(r) {
      return r.rule && r.minutes && r.values.length > 0;
    },
    serializeConditions: function serializeConditions(conditions, targetType, properties) {
      var ret = {};
      var propertyMissed = true;
      conditions.forEach(function (c) {
        var name = '';

        switch (c.rule) {
          case 'Issue Type':
            name = 'issueType';
            break;

          case 'Department':
            name = 'departments';
            break;

          case 'Property':
            name = 'properties';
            propertyMissed = false;
            break;

          case 'Channel':
            name = 'channels';
        }

        ret[name] = c.values.map(function (v) {
          return v.id;
        });
      });

      if (targetType === 'conversation' && propertyMissed) {
        ret['properties'] = properties.map(function (p) {
          return p.id;
        });
      }

      return ret;
    },
    serializeReminder: function serializeReminder(reminder, escalationId) {
      var assigned = false;
      var users = [];
      reminder.values.forEach(function (u) {
        if (u.pk === 0) {
          assigned = true;
        } else {
          users.push(u.pk);
        }
      });
      var rules = {};
      rules[reminder.rule.id] = reminder.minutes || 0;
      var ret = {
        rules: JSON.stringify(rules),
        assigned: assigned,
        users: users,
        violated: reminder.violated
      };

      if (escalationId !== undefined) {
        ret.escalationId = escalationId;
      }

      if (reminder.id !== undefined) {
        ret.reminderId = reminder.id;
      }

      return ret;
    },
    refreshRoute: function refreshRoute() {
      var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name));
      route.refresh();
    }
  });

  _exports.default = _default;
});