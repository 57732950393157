define("inboxfront/components/page-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: '',
    showButton: true,
    isCollapsed: false,
    currentIcon: Ember.computed('router.currentRoute.queryParams', function () {
      var _this = this;

      var currentRoute = this.router.currentRouteName;
      var icon = 'admin-company-settings';

      if (this.get('menuCollection')) {
        this.get('menuCollection').forEach(function (m) {
          return m.subMenu.forEach(function (s) {
            if (s.link === currentRoute) {
              if (!_this.get('router.currentRoute.queryParams.page')) {
                icon = s.icon;
              } else {
                icon = _this.get('router.currentRoute.queryParams.page') === 'widget' ? 'configure' : 'channels2';
              }
            } else if ('ecommerce' === currentRoute) {
              icon = 'admin-marketplace-settings';
            }
          });
        });
      }

      return icon;
    }),
    currentLink: Ember.computed('router.currentRoute.queryParams', function () {
      var page = this.router.currentRouteName.split('.')[0];
      return page !== 'channels' ? page : this.router.currentRoute.queryParams.page;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.menuCollection = [{
        title: this.intl.t('company_settings'),
        icon: 'admin-company-settings',
        subMenu: [{
          title: this.intl.t('company'),
          icon: 'company',
          link: 'companies.index',
          type: 'companies'
        }, {
          title: this.intl.t('company_group'),
          icon: 'group',
          link: 'group.index',
          type: 'group'
        }, {
          title: this.intl.t('departments'),
          icon: 'departments',
          link: 'departments'
        }]
      }, {
        title: this.intl.t('contact_settings'),
        icon: 'admin-contacts-settings',
        subMenu: [{
          title: this.intl.t('contact_fields'),
          icon: 'contact-fields',
          link: 'contactfields'
        }, {
          title: this.intl.t('org_fields'),
          icon: 'organization-fields',
          link: 'orgfields'
        }]
      }, {
        title: this.intl.t('task_settings'),
        icon: 'admin-task-settings',
        subMenu: [{
          title: this.intl.t('issue_types'),
          icon: 'issue-types',
          link: 'issuetypes'
        }, {
          title: this.intl.t('ticket_fields'),
          icon: 'ticket-fields',
          link: 'ticketfields'
        }]
      }, {
        title: this.intl.t('security'),
        icon: 'admin-security-settings',
        subMenu: [{
          title: this.intl.t('users'),
          icon: 'users',
          link: 'users.index',
          type: 'users'
        }, {
          title: this.intl.t('roles'),
          icon: 'roles',
          link: 'roles.index',
          type: 'roles'
        }, {
          title: this.intl.t('permissions'),
          icon: 'lock2',
          link: 'permissions'
        }]
      }, {
        title: this.intl.t('channel_settings'),
        icon: 'admin-channels-settings',
        subMenu: [{
          title: this.intl.t('widget_cfg'),
          icon: 'configure',
          link: 'channels',
          type: 'widget',
          query: 'widget'
        }, {
          title: this.intl.t('channels'),
          icon: 'channels2',
          link: 'channels',
          query: 'channels'
        }]
      }, {
        title: this.intl.t('Templates'),
        icon: 'admin-templates-settings',
        subMenu: [{
          title: this.intl.t('quick_response'),
          icon: 'quick-response',
          link: 'response'
        }]
      }];
    }
  });

  _exports.default = _default;
});