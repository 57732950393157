define("inboxfront/templates/components/channels/telegram-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T6ubypGI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"channels/name-field\",null,[[\"channel\",\"selectedChannelType\"],[[24,[\"channel\"]],[24,[\"selectedChannelType\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"actions.create_bot\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alignCenter\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"extra-text\"],[8],[1,[28,\"t\",[\"telegram_bot_info\"],null],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"create-bot-btn\"],[8],[0,\"\\n            \"],[7,\"a\",true],[10,\"href\",\"https://telegram.me/botfather\"],[10,\"target\",\"_blank\"],[8],[1,[28,\"t\",[\"actions.create_bot\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"telegram_bot_token\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"paper-input\",null,[[\"required\",\"value\",\"onChange\"],[true,[24,[\"channel\",\"telegram_auth_token\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"channel\",\"telegram_auth_token\"]]],null]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"extra-text\"],[8],[1,[28,\"t\",[\"telegram_bot_token_info\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/channels/telegram-controls.hbs"
    }
  });

  _exports.default = _default;
});