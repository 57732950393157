define("inboxfront/components/dashboard/ticket-category", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      _highcharts.default.chart({
        chart: {
          type: 'bar',
          renderTo: 'ticket-category',
          height: 315
        },
        title: {
          text: this.intl.t('tickets_by_category'),
          align: 'left'
        },
        xAxis: {
          categories: this.get('listDateMin'),
          title: null
        },
        yAxis: {
          min: 0,
          title: null
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        series: [{
          name: this.intl.t('general_enquiry'),
          data: [5, 3, 42, 7, 20, 4, 22]
        }, {
          name: this.intl.t('incident'),
          data: [4, 3, 4, 47, 2, 41, 2]
        }, {
          name: this.intl.t('cancellation'),
          data: [5, 31, 4, 72, 2, 4, 28]
        }, {
          name: this.intl.t('complaints'),
          data: [1, 3, 43, 7, 28, 4, 52]
        }]
      });
    },
    calcDate: function calcDate() {
      var pickGraph = this.get('pickGraph');
      var listDate = this.get('listDate');
      var arr = [[], [], [], []];

      var _loop = function _loop(l) {
        var filterDate = pickGraph.filter(function (el) {
          return el.node.date === listDate[l];
        });
        var enquiryCount = 0;
        var incidentCount = 0;
        var cancel = 0;
        var complaint = 0;

        if (filterDate.length) {
          filterDate.forEach(function (el) {
            enquiryCount += el.node.enquiryTickets;
            incidentCount += el.node.incidentTickets;
            cancel += el.node.cancellationTickets;
            complaint += el.node.complaintTickets;
          });
        }

        arr[0].push(enquiryCount);
        arr[1].push(incidentCount);
        arr[2].push(cancel);
        arr[3].push(complaint);
      };

      for (var l = 0; l < 7; l++) {
        _loop(l);
      }

      return arr;
    }
  });

  _exports.default = _default;
});