define("inboxfront/helpers/filter-grid-contacts", ["exports", "moment", "inboxfront/helpers/countries-list"], function (_exports, _moment, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterGridContacts = filterGridContacts;
  _exports.default = void 0;

  function filterGridContacts(params) {
    if (params[0] === 'phone') {
      return params[1].personalPhone;
    } else if (params[0] === 'country') {
      var _params$1$homeAddress, _params$1$officeAddre;

      var filterCountry = _countriesList.COUNTRIES_LIST.findBy('iso2', params[1].citizenship);

      return ((_params$1$homeAddress = params[1].homeAddress) === null || _params$1$homeAddress === void 0 ? void 0 : _params$1$homeAddress.country) || ((_params$1$officeAddre = params[1].officeAddress) === null || _params$1$officeAddre === void 0 ? void 0 : _params$1$officeAddre.country) || filterCountry;
    } else if (params[0] === 'company') {
      return params[1].companyProperty.title;
    } else if (params[0] === 'organization') {
      if (!params[1].organization) return '';
      return params[1].organization.name;
    } else if (params[0] === 'contactOwner') {
      if (!params[1].contactOwner) return 'Unassigned';
      return "".concat(params[1].contactOwner.firstName, " ").concat(params[1].contactOwner.lastName);
    } else if (params[0] === 'created') {
      return (0, _moment.default)(params[1].created).format('L');
    } else if (params[0] === 'mobile') {
      return params[1].mobilePhone;
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(filterGridContacts);

  _exports.default = _default;
});