define("inboxfront/components/page-header", ["exports", "inboxfront/gql/query", "inboxfront/config/environment", "ember-intl/hydrate"], function (_exports, _query, _environment, _hydrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modulePrefix = _environment.default.modulePrefix;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: '',
    progress: 100,
    intl: Ember.inject.service(),
    activeLocale: Ember.computed.readOnly('intl.locale'),
    locales: Ember.computed(function () {
      return (0, _hydrate.lookupByFactoryType)('translations', modulePrefix).map(function (moduleName) {
        return moduleName.split('/').pop();
      });
    }).readOnly(),
    selections: Ember.computed('locales.[]', 'activeLocale', function () {
      var active = Ember.get(this, 'activeLocale');
      return Ember.get(this, 'locales').map(function (locale) {
        return {
          locale: locale,
          active: active.indexOf(locale) > -1
        };
      });
    }).readOnly(),
    direction: 'ltr',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.query(_query.default.ONBOARDING_PROGRESS, 'onboardingProgress').then(function (progress) {
        _this.set('progress', progress[0]);
      });
    },
    actions: {
      changeLocale: function changeLocale(locale) {
        // if (locale === 'ar-ar') {
        //     document.body.style.direction = 'rtl';
        // } else {
        //     document.body.style.direction = 'ltr';
        // }
        return Ember.get(this, 'intl').set('locale', locale);
      },
      changeDirection: function changeDirection() {
        if (this.get('direction') === 'ltr') {
          document.body.style.direction = 'rtl';
          this.set('direction', 'rtl');
        } else {
          document.body.style.direction = 'ltr';
          this.set('direction', 'ltr');
        }
      }
    }
  });

  _exports.default = _default;
});