define("inboxfront/routes/departments", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var departments = this.ajax.query(_query.default.DEPARTMENT);
      return Ember.RSVP.all([departments]).then(function (res) {
        return {
          departments: res[0]
        };
      });
    }
  });

  _exports.default = _default;
});