define("inboxfront/controllers/contactfields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customfields: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    showSystemFields: false,
    isEditMode: Ember.computed('editedField', 'editedSection', function () {
      return this.get('editedField') || this.get('editedSection');
    }),
    actions: {
      addFieldByType: function addFieldByType(type) {
        if (this.get('isEditMode')) {
          return;
        }

        this.addNewField(type);
      },
      onDeleteField: function onDeleteField(field) {
        if (field.parentSection) {
          field.parentSection.subfields.removeObject(field);
        } else {
          this.get('model.fields').removeObject(field);
        }
      },
      onSaveField: function onSaveField() {
        this.updateOrders();
      },
      onSaveSystemField: function onSaveSystemField() {
        this.get('customfields').updateSystemFields(this.get('model.fields'), this.get('model.settings.id'));
      },
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;

        if (targetList === this.get('model.types')) {
          return;
        }

        if (this.get('isEditMode')) {
          return;
        }

        if (sourceList === this.get('model.types')) {
          var type = sourceList.objectAt(sourceIndex);
          this.addNewField(type, targetList, targetIndex);
          return;
        }

        this.replaceField(sourceList, sourceIndex, targetList, targetIndex);
      },
      choicesDragEnd: function choicesDragEnd(_ref2) {
        var sourceList = _ref2.sourceList,
            sourceIndex = _ref2.sourceIndex,
            sourceArgs = _ref2.sourceArgs,
            targetList = _ref2.targetList,
            targetIndex = _ref2.targetIndex,
            targetArgs = _ref2.targetArgs;
        this.replaceField(sourceList, sourceIndex, targetList, targetIndex);
      }
    },
    replaceField: function replaceField(sourceList, sourceIndex, targetList, targetIndex) {
      if (sourceList !== targetList) {
        return;
      }

      var field = sourceList.objectAt(sourceIndex);
      sourceList.removeAt(sourceIndex);
      targetList.insertAt(targetIndex, field);
      this.updateOrders();
    },
    updateOrders: function updateOrders() {
      this.get('customfields').updateOrders(this.get('model.fields'), this.get('model.settings.id'));
    },
    addNewField: function addNewField(type, targetList, targetIndex) {
      var field = {
        type: type,
        fieldType: type.type,
        label: 'New ' + type.label,
        _edited: {
          label: 'New ' + type.label,
          description: '',
          required: false
        }
      };

      if (targetList !== undefined) {
        field.parentSection = targetList.parentSection;
        targetList.insertAt(targetIndex, field);
      } else {
        this.get('model.fields').addObject(field);
      }

      if (type.type === 'BOOLEAN' || type.type === 'SELECTION') {
        field.children = [{
          parentField: field,
          type: this.get('model.types.0')
        }, {
          parentField: field,
          type: this.get('model.types.0')
        }];
        field.sections = [];
      }

      this.set('editedField', field);
    }
  });

  _exports.default = _default;
});