define("inboxfront/components/tasks/source-selector", ["exports", "inboxfront/components/tasks/id-selector"], function (_exports, _idSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _idSelector.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.options = [{
        id: "NA",
        title: "NA"
      }, {
        id: "PHONE",
        title: "Phone"
      }, {
        id: "EMAIL",
        title: "Email"
      }, {
        id: "LIVECHAT",
        title: "Live Chat"
      }, {
        id: "WECHAT",
        title: "WeChat"
      }, {
        id: "TELEGRAM",
        title: "Telegram"
      }, {
        id: "LINE",
        title: "LINE"
      }, {
        id: "TWITTER",
        title: "Twitter DM"
      }, {
        id: "WHATSAPP",
        title: "WhatsApp"
      }, {
        id: "FACEBOOK",
        title: "Facebook"
      }, {
        id: 'INSTAGRAM',
        title: 'Instagram'
      }];
    }
  });

  _exports.default = _default;
});