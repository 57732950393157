define("inboxfront/components/flow/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component', function () {
      var component = this.get('component') && this.get('blocks').findBy('id', this.get('component.id'));
      return component.children && component.children.filter(function (child) {
        return child.blockType !== 'BUTTON';
      })[0];
    }),
    sliders: Ember.computed('component', function () {
      var component = this.get('component') && this.get('blocks').findBy('id', this.get('component.id'));
      return component.children && component.children.filter(function (child) {
        return child.blockType === 'BUTTON';
      });
    }),
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        this.deleteComponentAll(component, event);
      },
      openConfigure: function openConfigure(component) {
        this.openConfigure(component);
      }
    }
  });

  _exports.default = _default;
});