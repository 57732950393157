define("inboxfront/routes/roles/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('roles');
      } else {
        var role = this.ajax.query(_query.default.CUSTOM_ROLE, 'customRole', {
          roleId: params.id
        });
        var users = this.ajax.query(_query.default.PROPERTY_USERS);
        return Ember.RSVP.all([role, users]).then(function (res) {
          if (res[0].errors) {
            return _this.transitionTo('roles');
          }

          return {
            role: res[0],
            users: !res[1].errors ? res[1] : []
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var usersRole = model.role.group.userSet.map(function (el) {
        return Number(el.id);
      });
      var filterUsers = model.users.filter(function (f) {
        return usersRole.includes(f.pk);
      });
      controller.set('role', model.role);
      controller.set('users', model.users);
      controller.set('currentUsers', filterUsers);
    }
  });

  _exports.default = _default;
});