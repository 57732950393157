define("inboxfront/templates/components/popups/reply-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SUTdD0Ly",
    "block": "{\"symbols\":[\"reply\"],\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"onclickOutside\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"apps-popup chat reply\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[28,\"t\",[\"quick_response\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"filterReply\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"popup-list-item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"insertText\",[23,1,[]]],null]],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"shortcut\"],[8],[1,[23,1,[\"node\",\"shortcut\"]],false],[9],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"text-reply\"],[8],[1,[23,1,[\"node\",\"text\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/popups/reply-popup.hbs"
    }
  });

  _exports.default = _default;
});