define("inboxfront/templates/components/escalations/section-escalations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+elQR70Y",
    "block": "{\"symbols\":[\"escalation\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-escalations\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title-section\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"escalations_desc\"],null],false],[0,\":\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"escalation\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"escalations\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"escalations/escalation-block\",null,[[\"escalation\",\"escalationRules\",\"users\",\"count\",\"onChange\",\"onDelete\"],[[23,1,[]],[24,[\"restRules\"]],[24,[\"users\"]],[24,[\"escalations\",\"length\"]],[28,\"action\",[[23,0,[]],\"updateEscalations\"],null],[28,\"action\",[[23,0,[]],\"deleteEscalation\"],null]]]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"separator\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"block-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"addButtonVisible\"]]],null,{\"statements\":[[0,\"                \"],[7,\"span\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"addEscalation\"],null]],[8],[1,[28,\"paper-icon\",[\"add_circle\"],null],false],[0,\" \"],[1,[28,\"t\",[\"new_escalation\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/escalations/section-escalations.hbs"
    }
  });

  _exports.default = _default;
});