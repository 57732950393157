define("inboxfront/templates/components/common/grid/action-dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5/NcNVf5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[24,[\"permission\"]]],null],[28,\"user-has-permission\",[[24,[\"permission\"]]],null]],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"content\",\"menu-item\"]],\"expected `content.menu-item` to be a contextual component but found a string. Did you mean `(component content.menu-item)`? ('inboxfront/templates/components/common/grid/action-dropdown-item.hbs' @ L2:C7) \"],null]],[[\"onClick\"],[[24,[\"onClick\"]]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/grid/action-dropdown-item.hbs"
    }
  });

  _exports.default = _default;
});