define("inboxfront/components/message-emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isAuthorFieldOpen: false,
    isCurrentUser: false,
    actions: {
      onMouseEnter: function onMouseEnter() {
        this.set('isAuthorFieldOpen', true);
      },
      onMouseLeave: function onMouseLeave() {
        this.set('isAuthorFieldOpen', false);
      },
      onDeleteEmoji: function onDeleteEmoji(data) {
        data.notFromPicker = true;

        if (data.isCurrentUser) {
          this.onDeleteEmoji(data);
        } else {
          this.onAddEmoji(data);
        }
      }
    }
  });

  _exports.default = _default;
});