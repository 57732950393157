define("inboxfront/components/common/grid/action-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});