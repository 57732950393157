define("inboxfront/controllers/preference/notification", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    isActiveSnooze: false,
    isActive: true,
    check: Ember.observer('sessionAccount.loggeduser', function () {
      //eslint-disable-line
      if (this.get('sessionAccount.loggeduser.notificationSettings.snooze') !== null) {
        this.set('isActiveSnooze', true);
      }
    }),
    isActiveNote: Ember.observer('isActive', function () {
      var _this = this;

      //eslint-disable-line
      var today = new Date();
      var d = today.setDate(today.getDate() - 1);
      this.set('isActiveSnooze', false);
      this.ajax.mutation(_mutation.default.UPDATE_NOTIFICATION_SETTINGS, 'updateNotificationSettings', {
        snooze: new Date(d).toISOString()
      }).then(function () {
        return _this.get('paperToaster').show('Snooze off');
      });
    }),
    profile: Ember.computed('sessionAccount.loggeduser', function () {
      return this.get('sessionAccount.loggeduser');
    }),
    isVisibleNote: Ember.computed('sessionAccount.loggeduser', function () {
      return Notification.permission !== 'denied';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.pauseTime = {
        value: null,
        title: 'null'
      };
      this.timeList = [{
        value: 'Not value...',
        title: 'Not value...'
      }, {
        value: 30,
        title: '30 minutes'
      }, {
        value: 1,
        title: '1 hour'
      }, {
        value: 2,
        title: '2 hours'
      }, {
        value: 4,
        title: '4 hours'
      }, {
        value: 8,
        title: '8 hours'
      }, {
        value: 'custom',
        title: 'custom'
      }];
    },
    actions: {
      onChangeSound: function onChangeSound(el) {
        Ember.set(el, 'sound', !el.sound);

        if (el.sound) {
          var audio = new Audio('/assets/audio/all-eyes-on-me.mp3');
          audio.play();
        }
      },
      saveProfile: function saveProfile() {
        var _this2 = this;

        var profile = this.get('profile.notificationSettings');
        var pauseTime = this.get('pauseTime.value');
        var today = new Date();
        var hash = {
          active: profile.active,
          messages: profile.messages,
          escalations: profile.escalations,
          tasks: profile.tasks,
          sound: profile.sound
        };

        if (pauseTime) {
          if (pauseTime === 30) {
            var d = today.setMinutes(today.getMinutes() + 30);
            hash.snooze = new Date(d).toISOString();
          } else if (pauseTime === 'custom') {
            hash.snooze = new Date(this.get('pauseDate')).toISOString();
          } else {
            var _d = today.setHours(today.getHours() + pauseTime);

            hash.snooze = new Date(_d).toISOString();
          }
        }

        this.ajax.mutation(_mutation.default.UPDATE_NOTIFICATION_SETTINGS, 'updateNotificationSettings', hash).then(function () {
          _this2.set('isActiveSnooze', true);

          _this2.get('paperToaster').show(_this2.intl.t('status.changes_saved'));
        });
      },
      onSelectTime: function onSelectTime(val) {
        this.set('pauseTime', val);
      }
    }
  });

  _exports.default = _default;
});