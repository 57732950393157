define("inboxfront/components/escalations/reminder-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    tagName: '',
    restValues: Ember.computed('reminder.values.length', function () {
      var values = _toConsumableArray(this.get('users'));

      this.get('reminder.values').forEach(function (v) {
        values.removeObject(v);
      });
      return values;
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('reminder.values').pushObject(value);
        this.onChange();
      },
      deselectValue: function deselectValue(value) {
        this.get('reminder.values').removeObject(value);
        this.onChange();
      },
      changeRule: function changeRule(value) {
        this.set('reminder.values', [this.get('users')[0]]);
        this.set('reminder.rule', value);
        this.onChange();
      },
      updateMinutes: function updateMinutes() {
        this.onChange();
      },
      deleteReminder: function deleteReminder() {
        this.onDelete(this.get('reminder'));
      }
    }
  });

  _exports.default = _default;
});