define("inboxfront/services/chats", ["exports", "inboxfront/gql/query", "inboxfront/config/environment", "jquery"], function (_exports, _query, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.sockets = [];
      this.selectedRoom = null;
      this.bookmarks = [];
      this.socketsByRoomId = {};
    },
    load: function load() {
      var _this = this;

      this.ajax.querySessionKey().then(function () {
        _this.ajax.query(_query.default.ROOMS).then(function (rooms) {
          var currentUser = _this.get('sessionAccount.loggeduser.username');

          rooms.map(function (room) {
            room.status = 'offline';
            var filterParticipants = room.participants.filter(function (el) {
              return el.username !== currentUser;
            })[0];

            if (!room.isGroup && filterParticipants) {
              room.firstName = filterParticipants.firstName;
              room.lastName = filterParticipants.lastName;
              room.username = filterParticipants.username;

              if (room.username === 'raincheck.bot') {
                //TODO remove code duplication
                room.firstName = 'Braincheck';
                room.user = {
                  username: 'raincheck.bot'
                };
              } else {
                room.user = filterParticipants.staffprofile.user;
              }
            }

            if (room.added.length && room.added.findBy('id', String(_this.get('sessionAccount.loggeduser.pk')))) {
              room.isAddedChat = true;
            } else if (room.mentioned.length && room.mentioned.findBy('id', String(_this.get('sessionAccount.loggeduser.pk')))) {
              room.isMentionedChat = true;
            }
          });

          _this.set('rooms', rooms); //this.initSockets(rooms);

        });
      });
      this.loadBookmarks();
    },
    addMessage: function addMessage(packet) {
      var _this2 = this;

      this.ajax.query(_query.default.ROOM_MESSAGE, 'chatMessage', {
        messageId: packet.message_id
      }).then(function (data) {
        var messages = _this2.get('selectedRoom.messages.edges');

        messages.pushObject({
          node: data
        });

        if (data.inReplyTo) {
          var message = messages.findBy('node.id', data.inReplyTo.id);

          if (message) {
            if (!Array.isArray(message.node.replies)) {
              Ember.set(message.node, 'replies', []);
            }

            message.node.replies.addObject(data);
          }
        }

        _this2.scrollToMessage(packet.message_id);
      });
    },
    updateRooms: function updateRooms() {
      var _this3 = this;

      this.ajax.query(_query.default.ROOMS).then(function (rooms) {
        var currentUser = _this3.get('sessionAccount.loggeduser.username');

        rooms.map(function (room) {
          room.status = 'offline';
          var filterParticipants = room.participants.filter(function (el) {
            return el.username !== currentUser;
          })[0];

          if (!room.isGroup && filterParticipants) {
            room.firstName = filterParticipants.firstName;
            room.lastName = filterParticipants.lastName;
            room.username = filterParticipants.username;

            if (room.username === 'raincheck.bot') {
              //TODO remove code duplication
              room.firstName = 'Braincheck';
              room.user = {
                username: 'raincheck.bot'
              };
            } else {
              room.user = filterParticipants.staffprofile.user;
            }
          }
        });

        _this3.set('rooms', rooms);
      });
    },
    addRoom: function addRoom(room) {
      var currentUser = this.get('sessionAccount.loggeduser.username');
      room.status = 'offline';
      var filterParticipants = room.participants.filter(function (el) {
        return el.username !== currentUser;
      })[0];

      if (!room.isGroup && filterParticipants) {
        room.firstName = filterParticipants.firstName;
        room.lastName = filterParticipants.lastName;
        room.username = filterParticipants.username;

        if (room.username === 'raincheck.bot') {
          //TODO remove code duplication
          room.firstName = 'Braincheck';
          room.user = {
            username: 'raincheck.bot'
          };
        } else {
          room.user = filterParticipants.staffprofile.user;
        }
      }

      var ws = new WebSocket("".concat(_environment.default.socketUrl).concat(room.owner.id, "/").concat(room.id, "/"));
      this.callSocket(ws, room);
      this.get('sockets').pushObject({
        webSocket: ws,
        opponentUser: room.username,
        roomId: room.id
      });
      this.get('socketsByRoomId')[room.id] = ws;
      this.get('rooms').addObject(room);
    },
    deleteRoom: function deleteRoom(room) {
      var filterSocket = this.get('sockets').findBy('roomId', room.id);
      filterSocket.webSocket.close();
      this.get('rooms').removeObject(room);
      this.get('sockets').removeObject(filterSocket);
      this.get('socketsByRoomId')[room.id] = false;
      this.set('selectedRoom', {});
    },
    initSockets: function initSockets(rooms) {
      var _this4 = this;

      var socketsByRoomId = this.get('socketsByRoomId');
      rooms.forEach(function (room) {
        if (socketsByRoomId[room.id]) {
          return;
        }

        var ws = new WebSocket("".concat(_environment.default.socketUrl).concat(room.owner.id, "/").concat(room.id, "/"));
        console.log('OPEN SOCKET room#', room.id, ws);

        _this4.callSocket(ws, room);

        _this4.get('sockets').pushObject({
          webSocket: ws,
          opponentUser: room.username,
          roomId: room.id
        });

        socketsByRoomId[room.id] = ws;
      });
    },
    changeStatusUser: function changeStatusUser(packet) {
      var privateDialogs = this.get('rooms').filterBy('isGroup', false);
      privateDialogs.forEach(function (user) {
        user.participants.map(function (el) {
          if (Number(el.id) === packet.userId) {
            Ember.set(user, 'status', packet.type);
          }
        });
      });
    },
    deleteEditMessage: function deleteEditMessage(packet) {
      this.get('selectedRoom.messages.edges').map(function (mes) {
        if (mes.node.id === packet.message_id) {
          Ember.set(mes, 'node.status', packet.status.toUpperCase());
          if (packet.type === 'edit') Ember.set(mes, 'node.text', packet.message);
        }
      });
    },
    addEmoji: function addEmoji(packet) {
      var _this5 = this;

      this.ajax.query(_query.default.ROOM_MESSAGE, 'chatMessage', {
        messageId: packet.message_id
      }).then(function (data) {
        _this5.get('selectedRoom.messages.edges').map(function (mes) {
          if (mes.node.id === String(packet.message_id)) {
            Ember.set(mes, 'node', data);
          }
        });
      });
    },
    deleteEmoji: function deleteEmoji(packet) {
      this.get('selectedRoom.messages.edges').map(function (mes) {
        if (Number(mes.node.id) === packet.message_id) {
          var filterEmoji = mes.node.reaction.findBy('id', String(packet.emoji_id));
          mes.node.reaction.removeObject(filterEmoji);
        }
      });
    },
    readMessage: function readMessage(id) {
      var msg = document.getElementById('mes' + id);

      if (msg && msg.classList.contains('unread')) {
        (0, _jquery.default)(msg).removeClass('unread').addClass('read');
      }
    },
    scrollToMessage: function scrollToMessage(id) {
      setTimeout(function () {
        document.getElementById('mes' + id).scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    },
    callSocket: function callSocket(ws, room) {
      var _this6 = this;

      var user = this.get('sessionAccount.loggeduser.username');

      ws.onmessage = function (event) {
        var packet;

        try {
          packet = JSON.parse(event.data);
        } catch (e) {
          throw Error('error parse');
        }

        switch (packet.type) {
          case 'online':
          case 'offline':
            if (packet.username !== user) {
              _this6.changeStatusUser(packet);
            }

            break;

          case 'message':
            _this6.addMessage(packet);

            break;

          case 'read_message':
            _this6.readMessage(packet.message_id);

            break;

          case 'typing':
            if (packet.username !== user) {
              _this6.set('typingUser', packet.username);

              var typingElem = (0, _jquery.default)('.typing-text');

              if (!typingElem.is(':visible')) {
                typingElem.fadeIn(500);
              } else {
                typingElem.stop(true);
                typingElem.fadeIn(0);
              }

              typingElem.fadeOut(2000);
            }

            break;

          case 'edit':
          case 'delete':
            if (packet.status === 'edited' || packet.status === 'deleted') {
              _this6.deleteEditMessage(packet);
            } else if (packet.status === 'expired') {
              _this6.get('paperToaster').show("You cannot ".concat(packet.type, " this message now, it's too late"));
            }

            break;

          case 'add_emoji':
            _this6.addEmoji(packet);

            break;

          case 'delete_emoji':
            _this6.deleteEmoji(packet);

            break;

          default:
        }
      };

      ws.onclose = function (e) {
        console.log('Socket is closed. Room:', room, e);

        var filterSocket = _this6.get('sockets').findBy('roomId', room.id);

        _this6.get('sockets').removeObject(filterSocket);

        _this6.get('socketsByRoomId')[room.id] = false;
        setTimeout(function () {
          _this6.load();
        }, 5000);
      };

      ws.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        ws.close();
      };
    },
    loadBookmarks: function loadBookmarks() {
      var _this7 = this;

      this.ajax.query(_query.default.GET_BOOKMARKS).then(function (res) {
        var bookmarks = Array.isArray(res) ? res : [];
        bookmarks.forEach(function (bookmark) {
          var created = new Date(bookmark.message.created);
          var now = new Date();
          var delta = Math.round(now.getTime() / 1000) - Math.round(created.getTime() / 1000);
          bookmark.message.expired = Math.abs(delta) > 900;
          bookmark.message.bookmarked = true;
        });

        _this7.set('bookmarks', bookmarks);
      });
    }
  });

  _exports.default = _default;
});