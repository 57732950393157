define("inboxfront/templates/components/flow/single-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5WiK5JKH",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"single-options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"nextComponent\",\"children\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"wrap-single-option\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"single-option\"],[8],[0,\"\\n                \"],[1,[28,\"paper-input\",null,[[\"value\",\"disabled\",\"onChange\",\"class\"],[[23,1,[\"mainTitle\"]],true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"mainTitle\"]]],null]],null],\"item-flow-input\"]]],false],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"add-component-flow\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"openAddComponent\",[23,1,[]]],null]],[8],[0,\"\\n                    \"],[1,[28,\"svg-icon\",[\"plus-square\"],null],false],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"children\",\"length\"]]],null,{\"statements\":[[0,\"                \"],[1,[28,\"flow/message\",null,[[\"component\",\"blocks\",\"openAddComponent\",\"deleteComponentAll\",\"openConfigure\"],[[23,1,[\"children\",\"firstObject\"]],[24,[\"blocks\"]],[28,\"action\",[[23,0,[]],\"openAddComponent\"],null],[28,\"action\",[[23,0,[]],\"deleteComponentAll\"],null],[28,\"action\",[[23,0,[]],\"openConfigure\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/flow/single-options.hbs"
    }
  });

  _exports.default = _default;
});