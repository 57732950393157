define("inboxfront/components/tasks/filter-panel", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var systemFormFields = ['issueType', 'source', 'category', 'assignedBy', 'assignedTo', 'department', 'priority', 'company', 'status', 'created', 'modified', 'closedDate', 'dueDate', 'guest'];
      var fields = [];
      this.get('fields') && this.get('fields').forEach(function (f) {
        if (!f.isSystem || systemFormFields.indexOf(f.id) >= 0) {
          fields.pushObject(f);
        }
      });
      this.set('filteredFields', fields);
      this.ajax.query(_query.default.PROPERTY_LIST).then(function (properties) {
        _this.set('properties', properties);
      });
      this.resetForm();
    },
    actions: {
      closePanel: function closePanel() {
        this.set('open', false);
        this.onClose();
      },
      filterTasks: function filterTasks() {
        var data = this.get('data');
        var filterData = {};
        var fields = {};
        var searchCustomFields = false;
        this.get('filteredFields').forEach(function (f) {
          var value = data[f.id];

          if (!value && f.id !== 'source') {
            return;
          }

          if (f.isSystem) {
            if (f.id === 'department' || f.id === 'assignedBy' || f.id === 'assignedTo') {
              value = value.id;
            } else if (f.id === 'source' && data.category) {
              filterData.category = data.category;
            }

            filterData[f.id] = value;
          } else {
            fields[f.id] = value;
            searchCustomFields = true;
          }
        });

        if (searchCustomFields) {
          filterData.fields = JSON.stringify(fields);
        }

        this.onAction(filterData);
      },
      updateAssignedTo: function updateAssignedTo(value) {
        console.log(value);
        this.set('data.assignedTo', value);

        this._checkForm();
      },
      updateAssignedBy: function updateAssignedBy(value) {
        this.set('data.assignedBy', value);

        this._checkForm();
      },
      updateCategory: function updateCategory(value) {
        this.set('data.category', value);

        this._checkForm();
      },
      updateCompany: function updateCompany(value) {
        this.set('data.company', value);

        this._checkForm();
      },
      checkForm: function checkForm() {
        this._checkForm();
      },
      clearForm: function clearForm() {
        this.resetForm();
      }
    },
    _checkForm: function _checkForm() {
      var valid = false;
      var data = this.get('data');
      this.get('filteredFields').forEach(function (f) {
        if (f.id === 'guest') {
          f.subfields.forEach(function (sf) {
            if (!valid) {
              var value = data[sf.id];

              if (value || typeof value === 'string' && value.trim() !== '') {
                valid = true;
              }
            }
          });
        } else if (f.id === 'source') {
          var value = data[f.id];

          if (value || typeof value === 'string' && value.trim() !== '') {
            valid = true;
          }

          value = data.category;

          if (value || typeof value === 'string' && value.trim() !== '') {
            valid = true;
          }
        } else if (!valid) {
          var _value = data[f.id];

          if (_value || typeof _value === 'string' && _value.trim() !== '') {
            valid = true;
          }
        }

        !valid && f.sections.forEach(function (s) {
          if (valid || !s.active) {
            return;
          }

          s.subfields.forEach(function (sf) {
            var value = data[sf.id];

            if (value || typeof value === 'string' && value.trim() !== '') {
              valid = true;
            }
          });
        });
      });
      this.set('formIsValid', valid);
    },
    resetForm: function resetForm() {
      this.set('formIsValid', false);
      this.set('data', {});
      this.set('departmentUsers', []);
    }
  });

  _exports.default = _default;
});