define("inboxfront/components/channels/line-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paperToaster: Ember.inject.service(),
    isContinue: false,
    actions: {
      copyTxt: function copyTxt(name, title) {
        this.copyTxt(name, title);
      },
      toggleWebhooks: function toggleWebhooks() {},
      createAndContinue: function createAndContinue(isClose, isNew) {
        var toaster = this.get('paperToaster');
        var errors = [];

        if (!this.get('channel.line_channel_id') || this.get('channel.line_channel_id').trim() === '') {
          errors.push('Line Access Token');
        }

        if (!this.get('channel.line_secret') || this.get('channel.line_secret').trim() === '') {
          errors.push('Line Secret');
        }

        if (!this.get('channel.name') || this.get('channel.name').trim() === '') {
          errors.push('Name');
        }

        if (errors.length > 0) {
          var message = 'Please fill these value(s)';

          for (var i = errors.length - 1; i >= 0; i--) {
            message += " " + errors[i] + ",";
          }

          message = message.replace(/,\s*$/, "");
          toaster.show(message);
          this.set('isContinue', false);
        } else {
          this.set('isContinue', true);
          this.createAndContinue(isClose, isNew);
        }
      }
    }
  });

  _exports.default = _default;
});