define("inboxfront/routes/chat", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "inboxfront/config/environment"], function (_exports, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: '/chats/',
    model: function model(params) {
      return {
        chatId: params.chat_id
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var staticPath = _environment.default.apollo.apiURL.replace("graphql", "static/");

      var environment = {
        staging: 'dev/',
        production: 'prod/',
        demo: 'demo/',
        development: 'dev/'
      };
      var url = staticPath + environment[_environment.default.environment] + 'sdk.js';
      var t = document.createElement("script");
      t.type = "text/javascript";
      t.async = true;
      t.setAttribute("id", "RC-widget-script");
      t.setAttribute("data-widget-id", "V2lkZ2V0U2V0dGluZ3NUeXBlOjcyNTFmYjM3LTlkNTktNDcwZS05YmNmLWFjNDIyYjgwZjEwMQ==");
      t.setAttribute("data-thread-id", model.chatId);
      t.src = url;
      document.body.appendChild(t);
    }
  });

  _exports.default = _default;
});