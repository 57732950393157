define("inboxfront/routes/assistants/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    model: function model() {
      var departments = this.ajax.query(_query.default.DEPARTMENT);
      var accounts = this.ajax.query(_query.default.CHANNEL);
      var users = this.ajax.query(_query.default.PROPERTY_USERS);
      return Ember.RSVP.all([departments, accounts, users]).then(function (res) {
        var emailAccounts = res[1].errors ? [] : res[1].filter(function (acc) {
          return acc.application === 'EMAIL' && acc.active;
        });
        var users = res[2].errors ? [] : res[2];
        return {
          departments: res[0],
          emailAccounts: emailAccounts,
          users: users,
          flows: []
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('companyId', this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id);
      controller.set('collectionFlows', []);
      controller.queryFlows();
    }
  });

  _exports.default = _default;
});