define("inboxfront/components/kb/categories-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onDragStart: function onDragStart(category, event) {
        Ember.set(category.section, 'hilighted', true);
        event.dataTransfer.setData('categoryId', category.id);
      },
      onDragEnd: function onDragEnd(category) {
        Ember.set(category.section, 'hilighted', false);
      },
      onDragOver: function onDragOver(category, event) {
        if (category.hilighted || category.isSystem) {
          return;
        }

        event.preventDefault();
      },
      onDrop: function onDrop(category, event) {
        event.preventDefault();

        if (event.dataTransfer.getData('articleId')) {
          this.addToCategory(event.dataTransfer.getData('articleId'), category);
        }
      }
    }
  });

  _exports.default = _default;
});