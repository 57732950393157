define("inboxfront/services/tasks", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Task = Ember.Object.extend({
    init: function init() {
      this._super();

      this.id = undefined;
      this._original = {};
      this.isValid = false;
    },
    setData: function setData(fields, data) {
      var _this = this;

      var users = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      this.id = data.id;
      this.slug = data.slug;
      this.propertyId = (data.companyProperty || {}).id;
      this.companyName = data.companyProperty && data.companyProperty.title;
      this.category = data.category;
      this.comments = (data.comments || []).sortBy('created');
      this.activities = data.activities || [];
      var attachments = [];

      if (data.attachments && data.attachments.edges) {
        attachments = data.attachments.edges.map(function (e) {
          return e.node;
        });
      }

      this.attachments = attachments;
      var watchers = [];

      if (data.watchers) {
        data.watchers.forEach(function (pk) {
          var user = users.findBy('pk', pk);

          if (user) {
            watchers.pushObject(user);
          }
        });
      }

      this.watchers = watchers;
      this._original.watchers = [];

      if (!data.customFields) {
        data.customFields = [];
      }

      if (!data.guest) {
        data.guest = {};
      }

      if (this.propertyId && !data.guest.propertyId) {
        Ember.set(data.guest, 'propertyId', this.propertyId);
      }

      this._fields = fields;
      fields && fields.forEach(function (field) {
        if (field.isSystem) {
          if (field.id === 'issueType') {
            _this[field.id] = (data[field.id] || {}).id;
          } else {
            _this[field.id] = data[field.id] || '';
          }

          if (field.id === 'guest') {
            _this.setGuest(data.guest);
          }
        } else {
          var v = data.customFields.findBy('field.id', field.id);

          if (v) {
            _this[field.id] = v.value;
            field.sections.forEach(function (section) {
              var active = section.conditionFields.indexOf(+v.value) >= 0;
              Ember.set(section, 'active', active);
              section.subfields.forEach(function (subfield) {
                var v = data.customFields.findBy('field.id', subfield.id);

                if (v) {
                  _this[subfield.id] = v.value;
                } else {
                  _this[subfield.id] = '';
                }

                if (subfield.fieldType === 'DATE' && !_this[subfield.id]) {
                  _this[subfield.id] = new Date();
                }
              });
            });
          } else {
            _this[field.id] = '';
          }
        }

        if (field.fieldType === 'DATE' && !_this[field.id] && field.id !== 'closedDate') {
          _this[field.id] = new Date();
        }
      });

      if (this.dueDate) {
        this.dueDateExpired = new Date(this.dueDate) < new Date();
      }
    },
    updateData: function updateData(data) {
      var _this2 = this;

      this._fields && this._fields.forEach(function (field) {
        if (data[field.id] === undefined) {
          return;
        }

        Ember.set(_this2, field.id, data[field.id]);

        if (!field.isSystem) {
          field.sections.forEach(function (section) {
            section.active && section.subfields.forEach(function (subfield) {
              if (data[subfield.id] === undefined) {
                return;
              }

              Ember.set(_this2, subfield.id, data[subfield.id]);
            });
          });
        }
      });
    },
    setGuest: function setGuest(guest) {
      if (guest) {
        Ember.set(this, 'guest', guest);
        Ember.set(this, 'firstName', guest.firstName || '');
        Ember.set(this, 'lastName', guest.lastName || '');
        Ember.set(this, 'email', guest.primaryEmail || '');
        Ember.set(this, 'phone', guest.personalPhone || '');
      } else {
        Ember.set(this, 'guest', {});
        Ember.set(this, 'firstName', '');
        Ember.set(this, 'lastName', '');
        Ember.set(this, 'email', '');
        Ember.set(this, 'phone', '');
      }
    },
    getData: function getData() {
      var _this3 = this;

      var hash = {
        propertyId: this.propertyId,
        category: this.category
      };
      var customFields = [];

      this._fields.forEach(function (field) {
        var value = _this3[field.id];

        if (field.id === 'assignedBy') {
          //required for query 'createTicket'
          hash[field.id] = value.id;
        } else if (value !== _this3._original[field.id]) {
          if (field.id === 'assignedTo') {
            hash[field.id] = value.id;

            _this3.watchers.removeObject(_this3.watchers.findBy('id', value.id));
          } else if (field.id === 'department') {
            hash[field.id] = value.id;
          } else if (field.isSystem) {
            if (field.fieldType === 'DATE' && field.id !== 'closedDate') {
              value = new Date(value).toISOString();
            }

            if (field.id === 'guest') {
              if (_this3.guest && _this3.guest.id) {
                hash.guestId = _this3.guest.id;

                if (_this3.firstName !== _this3.guest.firstName) {
                  hash.firstName = _this3.firstName;
                }

                if (_this3.lastName !== _this3.guest.lastName) {
                  hash.lastName = _this3.lastName;
                }

                if (_this3.email !== _this3.guest.primaryEmail) {
                  hash.email = _this3.email;
                }

                if (_this3.phone !== _this3.guest.personalPhone) {
                  hash.phone = _this3.phone;
                }
              } else {
                field.subfields.forEach(function (sf) {
                  hash[sf.id] = _this3[sf.id] || '';
                });
              }
            } else {
              hash[field.id] = value;
            }
          } else {
            //if custom field
            if (field.fieldType === 'DATE') {
              value = new Date(value).toISOString();
            }

            customFields.addObject({
              id: field.id,
              value: value
            });
            field.sections.forEach(function (section) {
              section.active && section.subfields.forEach(function (subfield) {
                var value = _this3[subfield.id];

                if (value !== _this3._original[subfield.id]) {
                  if (subfield.fieldType === 'DATE') {
                    value = new Date(value).toISOString();
                  }

                  customFields.addObject({
                    id: subfield.id,
                    value: value
                  });
                }
              });
            });
          }
        }
      });

      if (customFields.length) {
        hash.customFields = customFields;
      }

      if (this.watchersUpdated()) {
        hash.watchers = this.watchers.map(function (u) {
          return u.id;
        });
      }

      return hash;
    },
    validate: function validate() {
      var _this4 = this;

      console.groupCollapsed('VALIDATE TASK', this);
      var valid = this.propertyId !== undefined;

      if (valid && !this.category) {
        valid = false;
      }

      valid && this._fields && this._fields.forEach(function (field) {
        if (valid && field.required && field.visible) {
          if (_this4.isInvalid(_this4[field.id])) {
            valid = false;
          }
        }

        if (valid && field.id === 'guest') {
          if (_this4.guest && _this4.guest.id) {//     hash.guestId = this.guest.id;
            //     if (this.firstName !== this.guest.firstName) {
            //         hash.firstName = this.firstName;
            //     }
            //     if (this.lastName !== this.guest.lastName) {
            //         hash.lastName = this.lastName;
            //     }
            //     if (this.email !== this.guest.primaryEmail) {
            //         hash.email = this.email;
            //     }
            //     if (this.phone !== this.guest.personalPhone) {
            //         hash.phone = this.phone;
            //     }
          } else {
            field.subfields.forEach(function (sf) {
              var value = _this4[sf.id] || '';
            });
          }
        } else {
          valid && field.sections.forEach(function (section) {
            if (!valid || !section.active) {
              return;
            }

            section.subfields.forEach(function (subfield) {
              var value = _this4[subfield.id];

              if (subfield.required && _this4.isInvalid(value)) {
                valid = false;
              }
            });
          });
        }
      });
      console.groupEnd();
      this.set('isValid', valid);
    },
    isInvalid: function isInvalid(value) {
      return value === undefined || typeof value === 'string' && value.trim() === '';
    },
    commit: function commit() {
      var _this5 = this;

      this._original.propertyId = this.propertyId;
      this._original.category = this.category;
      this._original.watchers = _toConsumableArray(this.watchers);

      this._fields.forEach(function (field) {
        _this5._original[field.id] = _this5[field.id];

        if (field.id === 'guest') {
          field.subfields.forEach(function (subfield) {
            _this5._original[subfield.id] = _this5[subfield.id];
          });
        }

        field.sections.forEach(function (section) {
          section.subfields.forEach(function (subfield) {
            _this5._original[subfield.id] = _this5[subfield.id];
          });
        });
      });
    },
    restore: function restore() {
      var _this6 = this;

      Ember.set(this, 'propertyId', this._original.propertyId);
      Ember.set(this, 'category', this._original.category);
      Ember.set(this, 'watchers', _toConsumableArray(this._original.watchers));

      this._fields.forEach(function (field) {
        Ember.set(_this6, field.id, _this6._original[field.id]);

        if (field.id === 'guest') {
          field.subfields.forEach(function (subfield) {
            Ember.set(_this6, subfield.id, _this6._original[subfield.id]);
          });
        }

        field.sections.forEach(function (section) {
          section.subfields.forEach(function (subfield) {
            Ember.set(_this6, subfield.id, _this6._original[subfield.id]);
          });
        });
      });
    },
    setActivities: function setActivities(activities) {
      Ember.set(this, 'activities', Array.isArray(activities) ? activities : []);
    },
    setComments: function setComments(comments) {
      Ember.set(this, 'comments', Array.isArray(comments) ? comments.sortBy('created') : []);
    },
    setWatchers: function setWatchers(watchers) {
      watchers = _toConsumableArray(watchers);
      watchers.removeObject(watchers.findBy('id', this.get('assignedTo.id')));
      Ember.set(this, 'watchers', watchers);
    },
    watchersUpdated: function watchersUpdated() {
      var _this7 = this;

      var updated = this.watchers.length !== this._original.watchers.length;

      if (!updated) {
        var diff = this._original.watchers.filter(function (user) {
          return !_this7.watchers.includes(user);
        });

        updated = diff.length > 0;
      }

      return updated;
    }
  });

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    get systemFields() {
      return [{
        id: 'title',
        label: this.intl.t('subject'),
        description: this.intl.t('subject_desc'),
        fieldType: 'SINGLE_LINE',
        required: true
      }, {
        id: 'description',
        label: this.intl.t('description'),
        description: this.intl.t('description_desc'),
        fieldType: 'RICH_TEXT',
        required: false
      }, {
        id: 'issueType',
        label: this.intl.t('issue_type'),
        description: this.intl.t('issue_type_desc'),
        fieldType: 'SELECTION',
        required: true,
        icon: 'icon-ticket'
      }, {
        id: 'source',
        label: this.intl.t('source'),
        description: this.intl.t('source_desc'),
        fieldType: 'SELECTION',
        required: true
      }, {
        id: 'assignedBy',
        label: this.intl.t('assigned_by'),
        description: this.intl.t('assigned_by_desc'),
        fieldType: 'SELECTION',
        required: true
      }, {
        id: 'assignedTo',
        label: this.intl.t('assigned_to'),
        description: this.intl.t('assigned_to_desc'),
        fieldType: 'SELECTION',
        required: true,
        icon: 'headphones'
      }, {
        id: 'department',
        label: this.intl.t('department'),
        description: this.intl.t('department_desc'),
        fieldType: 'SELECTION',
        required: true,
        icon: 'copy'
      }, {
        id: 'company',
        label: this.intl.t('company'),
        fieldType: 'SELECTION',
        required: true
      }, {
        id: 'priority',
        label: this.intl.t('priority'),
        description: this.intl.t('priority_desc'),
        fieldType: 'SELECTION',
        required: true,
        icon: 'alert-triangle'
      }, {
        id: 'status',
        label: this.intl.t('Status'),
        description: this.intl.t('Status_desc'),
        fieldType: 'SELECTION',
        required: true,
        icon: 'loader'
      }, {
        id: 'created',
        label: this.intl.t('time.creation_date'),
        description: this.intl.t('time_creation_date_desc'),
        fieldType: 'DATE',
        required: true
      }, {
        id: 'modified',
        label: this.intl.t('time.last_updated_date'),
        description: this.intl.t('time_last_updated_date_desc'),
        fieldType: 'DATE',
        required: true
      }, {
        id: 'closedDate',
        label: this.intl.t('time.closed_date'),
        description: this.intl.t('time_closed_date_desc'),
        fieldType: 'DATE',
        required: true
      }, {
        id: 'dueDate',
        label: this.intl.t('time.due_date'),
        description: this.intl.t('time_due_date_desc'),
        fieldType: 'DATE',
        required: true,
        icon: 'icon-bell'
      }, {
        id: 'guest',
        label: this.intl.t('contact_info'),
        fieldType: 'GROUP',
        subfields: [{
          id: 'firstName',
          label: this.intl.t('contact_firstname'),
          description: this.intl.t('contact_firstname_desc'),
          fieldType: 'SINGLE_LINE',
          required: true
        }, {
          id: 'lastName',
          label: this.intl.t('contact_lastname'),
          description: this.intl.t('contact_lastname_desc'),
          fieldType: 'SINGLE_LINE',
          required: true
        }, {
          id: 'email',
          label: this.intl.t('contact_email'),
          description: this.intl.t('contact_email_desc'),
          fieldType: 'SINGLE_LINE',
          required: true
        }, {
          id: 'phone',
          label: this.intl.t('contact_phone'),
          description: this.intl.t('contact_phone_desc'),
          fieldType: 'SINGLE_LINE',
          required: false
        }]
      }];
    },

    create: function create(fields, data) {
      var task = Task.create();
      task.setData(fields, data);
      return task;
    },
    deserialize: function deserialize(fields, data) {
      var users = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var task = Task.create();
      task.setData(fields, data, users);
      task.commit();
      return task;
    },
    save: function save(task, onSave) {
      var _this8 = this;

      var hash = task.getData();

      if (task.id !== undefined) {
        hash.ticketIds = [task.id];
      }

      if (!hash.assignedBy) {
        hash.assignedBy = this.get('sessionAccount.loggeduser.id');
      }

      return this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_TASK, 'createTicket', hash, function (result) {
        if (!result || !result.ticket) {
          _this8.get('paperToaster').show(_this8.intl.t('internal_error') + ' ' + _this8.intl.t('try_later'));

          return;
        }

        if (task.id === undefined) {
          Ember.set(task, 'id', result.ticket.id);
        }

        task.setActivities(result.ticket.activities);
        task.setComments(result.ticket.comments);
        task.commit();
        onSave && onSave(result);
      });
    },
    delete: function _delete(tasks) {
      return this.ajax.mutation(_mutation.default.DELETE_TASK, 'deleteTicket', {
        ticketIds: tasks.map(function (task) {
          return task.id;
        })
      });
    },
    merge: function merge(primaryTask, secondaryTasks, callback) {
      var hash = {
        primaryId: primaryTask.id,
        secondaryIds: secondaryTasks.map(function (t) {
          return t.id;
        })
      };
      return this.ajax.mutation(_mutation.default.MERGE_TICKETS, 'mergeTickets', hash, callback);
    },
    filter: function filter(filterOptions, callback) {
      var useData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var variables = {
        first: filterOptions.first
      };

      if (filterOptions.searchString !== '') {
        variables.title = filterOptions.searchString;
      }

      if (filterOptions.status !== '') {
        variables.status = filterOptions.status;
      }

      if (filterOptions.company) {
        variables.companyProperty = filterOptions.company.id;
      }

      if (filterOptions.priority !== '') {
        variables.priority = filterOptions.priority;
      }

      if (filterOptions.reporter !== '') {
        variables.assignedBy = filterOptions.reporter;
      }

      if (filterOptions.assignee !== '') {
        variables.assignedTo = filterOptions.assignee;
      }

      if (filterOptions.id !== '') {
        variables.id = filterOptions.id;
      }

      if (filterOptions.after !== '') {
        variables.after = filterOptions.after;
      } else if (filterOptions.before !== '') {
        variables.before = filterOptions.before;
      }

      if (filterOptions.creation && filterOptions.creation.startDate && filterOptions.creation.endDate && filterOptions.creation.startDate !== "" && filterOptions.creation.endDate !== "") {
        var createdStartDate = this.formatDate(this.stringToDate(filterOptions.creation.startDate, filterOptions.dataformat, "-"));
        var createdEndDate = this.formatDate(this.stringToDate(filterOptions.creation.endDate, filterOptions.dataformat, "-"));
        variables.created = "[\"" + createdStartDate + "\", \"" + createdEndDate + "\"]";
      } else {
        variables.created = "[]";
      }

      if (filterOptions.closed && filterOptions.closed.startDate && filterOptions.closed.endDate && filterOptions.closed.startDate !== "" && filterOptions.closed.endDate !== "") {
        var closedStartDate = this.formatDate(this.stringToDate(filterOptions.closed.startDate, filterOptions.dataformat, "-"));
        var closedEndDate = this.formatDate(this.stringToDate(filterOptions.closed.endDate, filterOptions.dataformat, "-"));
        variables.closedDate = "[\"" + closedStartDate + "\", \"" + closedEndDate + "\"]";
      } else {
        variables.closedDate = "[]";
      }

      if (filterOptions.updated && filterOptions.updated.startDate && filterOptions.updated.endDate && filterOptions.updated.startDate !== "" && filterOptions.updated.endDate !== "") {
        var updatedStartDate = this.formatDate(this.stringToDate(filterOptions.updated.startDate, filterOptions.dataformat, "-"));
        var updatedEndDate = this.formatDate(this.stringToDate(filterOptions.updated.endDate, filterOptions.dataformat, "-"));
        variables.modified = "[\"" + updatedStartDate + "\", \"" + updatedEndDate + "\"]";
      } else {
        variables.modified = "[]";
      }

      this.ajax.query(_query.default.TICKETS, 'tickets', variables).then(function (result) {
        if (result.errors) {
          callback({
            edges: [],
            pageInfo: {
              startCursor: 0,
              endCursor: 0
            }
          });
        } else {
          callback(result);
        }
      }).catch(function (error) {
        return callback(error);
      });
    },
    stringToDate: function stringToDate(date, format, delimiter) {
      var formatLowerCase = format.toLowerCase();
      var formatItems = formatLowerCase.split(delimiter);
      var dateItems = date.split(delimiter);
      var monthIndex = formatItems.indexOf('mm');
      var dayIndex = formatItems.indexOf('dd');
      var yearIndex = formatItems.indexOf('yyyy');
      var month = parseInt(dateItems[monthIndex]);
      month -= 1;
      return new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    },
    formatDate: function formatDate(inputDate) {
      var d = new Date(inputDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    addComment: function addComment(comment, callback) {
      this.ajax.query(_mutation.default.ADD_COMMENT, 'addComment', comment).then(function (result) {
        return callback(result);
      }).catch(function (error) {
        return callback(error);
      });
    }
  });

  _exports.default = _default;
});