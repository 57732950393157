define("inboxfront/components/user-status-popup", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    profile: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('noteStatus', this.get('sessionAccount.loggeduser.notificationSettings.snooze') ? 'On' : '');
      this.profile.getStatusUser();
    },
    user: Ember.computed('sessionAccount.loggeduser', function () {
      return this.get('sessionAccount.loggeduser');
    }),
    userStatus: Ember.computed('profile.userStatus', function () {
      return this.profile.userStatus;
    }),
    actions: {
      changeOnlineStatus: function changeOnlineStatus(status) {
        this.profile.changeStatusUser(status);
      },
      changeNoteStatus: function changeNoteStatus(val) {
        var _this = this;

        var today = new Date();
        var hash = {};

        if (val === '30') {
          var d = today.setMinutes(today.getMinutes() + 30);
          hash.snooze = new Date(d).toISOString();
        } else if (val === 'custom') {
          location.href = location.origin + '/preference/notification';
        } else if (val === 'Off') {
          var _d = today.setDate(today.getDate() - 1);

          hash.snooze = new Date(_d).toISOString();
        } else {
          var _d2 = today.setHours(today.getHours() + val);

          hash.snooze = new Date(_d2).toISOString();
        }

        this.ajax.query(_mutation.default.UPDATE_NOTIFICATION_SETTINGS, 'updateNotificationSettings', hash).then(function () {
          _this.set('noteStatus', val === 'Off' ? '' : 'On');
        });
      },
      onclickOutside: function onclickOutside() {
        this.set('isShowPopup', false);
      },
      logout: function logout() {
        this.get('session').invalidate();
      },
      toggleUserMenuPopup: function toggleUserMenuPopup() {
        this.toggleProperty('isShowPopup');
      }
    }
  });

  _exports.default = _default;
});