define("inboxfront/routes/setpassword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model(params) {
      this.get('session').invalidate();

      if (params.key == null) {
        this.transitionTo('login');
      } else {
        return Ember.RSVP.hash({
          key: params.key
        });
      }
    }
  });

  _exports.default = _default;
});