define("inboxfront/components/flow/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nextComponent: Ember.computed('component', function () {
      var component = this.get('component');
      return this.get('blocks').findBy('id', component.id);
    })
  });

  _exports.default = _default;
});