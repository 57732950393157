define("inboxfront/components/chat/bookmarks", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // ajax: service(),
    // paperToaster: service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    // chats: service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {}
  });

  _exports.default = _default;
});