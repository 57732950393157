define("inboxfront/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  function missingMessage(key, locales) {
    console.error("[ember-intl] Missing translation for key: \"".concat(key, "\" for locales: \"").concat(locales, "\""));
    return "[".concat(key, "]");
  }
});