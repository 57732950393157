define("inboxfront/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    limit: 10,
    page: 0,
    incrementedPage: Ember.computed('page', 'cloneData', function () {
      return this.cloneData.length ? "".concat(this.page + 1) : 0;
    }),
    cloneData: Ember.computed('data', function () {
      return this.data;
    }),
    pageDataNew: Ember.observer('cloneData', 'page', function () {
      var page = this.page,
          limit = this.limit,
          cloneData = this.cloneData;
      console.log('cloneData', cloneData);
      this.set('pageData', cloneData.slice(page * limit, (page + 1) * limit));
    }),
    lastPage: Ember.computed('page', function () {
      return parseInt(this.data.length / 10);
    }),
    countPages: Ember.computed('data', function () {
      return Math.ceil(this.data.length / 10);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var page = this.page,
          limit = this.limit,
          cloneData = this.cloneData;
      this.pageDataNew(); //this.set('pageData', cloneData.slice(page * limit, (page + 1) * limit));
    },
    actions: {
      prevPage: function prevPage(page) {
        if (page > 0) {
          this.set('page', page - 1);
        }
      },
      nextPage: function nextPage(page) {
        if (page < this.lastPage) {
          this.set('page', page + 1);
        }
      }
    }
  });

  _exports.default = _default;
});