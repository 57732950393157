define("inboxfront/components/tasks/cf-choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    newChoiceLabel: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== "";
        }
      }];
    },
    actions: {
      updateChoice: function updateChoice(choice, value) {
        this.onUpdateChoice(choice, value);
      },
      deleteChoice: function deleteChoice(choice) {
        this.onDeleteChoice(choice);
      },
      updateNewChoice: function updateNewChoice(value) {
        this.set('newChoiceLabel', value);
        this.onUpdateChoice();
      },
      addChoice: function addChoice() {
        var label = this.get('newChoiceLabel');

        if (this.trimValidation[0].validate(label)) {
          this.set('newChoiceLabel', '');
          this.onAddChoice(label);
        }
      },
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;
        this.onChoicesDragEnd({
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          sourceArgs: sourceArgs,
          targetList: targetList,
          targetIndex: targetIndex,
          targetArgs: targetArgs
        });
      }
    }
  });

  _exports.default = _default;
});