define("inboxfront/controllers/users/edit", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    showDeactivateUserDialog: false,
    showDeleteUserDialog: false,
    showResetPasswordDialog: false,
    queryParams: ['id'],
    init: function init() {
      this._super.apply(this, arguments);

      this.systemRoles = [{
        id: 'admin',
        name: this.intl.t('user_role_admin')
      }, {
        id: 'owner',
        name: this.intl.t('user_role_owner')
      }, {
        id: 'member',
        name: this.intl.t('user_role_member')
      }];
    },
    systemRole: Ember.computed('model.user.systemGroup.name', function () {
      var _this = this;

      return this.systemRoles.find(function (item) {
        return item.id === _this.get('model.user.systemGroup.name');
      });
    }),
    availableCustomRoles: Ember.computed('model.user.customGroups.length', function () {
      var userCustomGroups = this.get('model.user.customGroups');
      return this.get('model.companyCustomRoles').filter(function (companyRole) {
        return !userCustomGroups.find(function (userRole) {
          return userRole.id === companyRole.id;
        });
      });
    }),
    actions: {
      resendInvite: function resendInvite() {
        var _this2 = this;

        this.ajax.mutation(_mutation.default.RESEND_INVITE, 'resendActivation', {
          userId: this.get('model.user.id')
        }, function () {
          _this2.get('paperToaster').show(_this2.intl.t('invite_sent'));
        });
      },
      deleteUserDialog: function deleteUserDialog() {
        var user = this.get('model.user');
        var users = [];
        this.get('model.users').forEach(function (profile) {
          if (profile.user.id !== user.id) {
            users.push({
              id: profile.user.id,
              name: "".concat(profile.user.firstName, " ").concat(profile.user.lastName)
            });
          }
        });
        this.set('assigneeUser', null);
        this.set('assigneeUsers', users);
        this.set('showDeleteUserDialog', true);
      },
      deleteUser: function deleteUser() {
        var _this3 = this;

        var data = {
          userId: this.get('model.user.id')
        };

        if (this.get('assigneeUser')) {
          data.assignee = this.get('assigneeUser.id');
        }

        this.ajax.mutation(_mutation.default.DELETE_USER, 'deleteUser', data, function () {
          _this3.set('showDeleteUserDialog', false);

          _this3.get('paperToaster').show(_this3.intl.t('user_deleted'));

          _this3.transitionToRoute('users.index');
        });
      },
      resetPasswordDialog: function resetPasswordDialog() {
        var _this4 = this;

        var user = this.get('model.user');
        this.set('resetHeader', this.intl.t('actions.reset_password'));
        this.set('resetUser', user);
        this.set('resetLink', '');
        this.set('resetPasswordText', this.intl.t('status.loading'));
        this.set('showResetPasswordDialog', true);
        this.ajax.mutation(_mutation.default.RESET_PASSWORD, 'resetPassword', {
          userId: this.get('model.user.id')
        }, function (result) {
          if (result.resetLink) {
            _this4.set('resetLink', result.resetLink);

            _this4.set('resetPasswordText', _this4.intl.t('reset_user_password', {
              link: result.resetLink,
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          } else {
            _this4.set('resetPasswordText', _this4.intl.t('reset_user_password_email', {
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          }
        }, function () {
          _this4.set('resetPasswordText', _this4.intl.t('internal_error'));
        });
      },
      deactivateUserDialog: function deactivateUserDialog() {
        if (this.get('model.user.isActive')) {
          this.set('showDeactivateUserDialog', true);
        } else {
          this.changeStatus();
        }
      },
      deactivateUser: function deactivateUser() {
        this.changeStatus();
      },
      changeAccess: function changeAccess(field) {
        var _this5 = this;

        var modelKey = "model.user.staffprofile.".concat(field);
        var value = !this.get(modelKey);
        var hash = {};
        hash[field] = value;
        this.saveUserAdmin(function () {
          if (value) {
            _this5.get("paperToaster").show(_this5.intl.t('user_access_enabled'));
          } else {
            _this5.get("paperToaster").show(_this5.intl.t('user_access_revoked'));
          }

          _this5.set(modelKey, value);
        }, hash);
      },
      changeSystemRole: function changeSystemRole(group) {
        var _this6 = this;

        var value = group.id;
        var hash = {
          systemGroup: value
        };

        if (value === 'admin' || value === 'owner') {
          hash.hasHelpdeskAccess = true;
          hash.hasInboxAccess = true;
        }

        this.saveUser(function () {
          _this6.get('paperToaster').show(_this6.intl.t('user_product_role_changed'));

          _this6.set('model.user.systemGroup.name', value);

          if (hash.hasHelpdeskAccess) {
            _this6.set('model.user.staffprofile.hasHelpdeskAccess', true);

            _this6.set('model.user.staffprofile.hasInboxAccess', true);
          }
        }, hash);
      },
      addCustomRole: function addCustomRole() {
        var _this7 = this;

        if (!this.get('selectedCustomRole')) {
          return;
        }

        var hash = {
          roleId: this.get('selectedCustomRole.id'),
          users: [this.get('model.user.id')]
        };
        this.ajax.mutation(_mutation.default.ADD_USERS, 'addUsers', hash, function () {
          _this7.get('model.user.customGroups').pushObject(_this7.get('selectedCustomRole'));

          _this7.set('selectedCustomRole', null);

          _this7.get('paperToaster').show(_this7.intl.t('custom_role_added'));
        });
      },
      removeCustomRole: function removeCustomRole(group) {
        var _this8 = this;

        var hash = {
          roleId: group.id,
          userId: [this.get('model.user.id')]
        };
        this.ajax.mutation(_mutation.default.REMOVE_USER, 'removeUser', hash, function () {
          _this8.get('model.user.customGroups').removeObject(group);

          _this8.get('paperToaster').show(_this8.intl.t('custom_role_removed'));
        });
      }
    },
    changeStatus: function changeStatus() {
      var _this9 = this;

      var status = !this.get('model.user.isActive');
      var hash = {
        isActive: status
      };
      this.saveUserAdmin(function () {
        if (status) {
          _this9.get('paperToaster').show(_this9.intl.t('user_activated'));
        } else {
          _this9.get('paperToaster').show(_this9.intl.t('user_deactivated'));
        }

        _this9.set('model.user.isActive', status);

        _this9.set('showDeactivateUserDialog', false);
      }, hash);
    },
    saveUser: function saveUser(callback, hash) {
      hash.userId = this.get('model.user.id');
      this.ajax.mutation(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash, callback);
    },
    saveUserAdmin: function saveUserAdmin(callback, hash) {
      hash.userId = this.get('model.user.id');
      this.ajax.mutation(_mutation.default.UPDATE_USER_ADMIN, 'updateUserAdmin', hash, callback);
    }
  });

  _exports.default = _default;
});