define("inboxfront/components/tasks/bulk-update-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.resetForm();
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.resetForm();
      var systemFormFields = ['issueType', 'source', 'assignedBy', 'assignedTo', 'department', 'company', 'priority', 'status', 'dueDate'];
      var fields = [];
      this.get('fields') && this.get('fields').forEach(function (f) {
        if (!f.isSystem || systemFormFields.indexOf(f.id) >= 0) {
          fields.pushObject(f);
        }
      });
      this.set('filteredFields', fields);
    },
    actions: {
      closePanel: function closePanel() {
        this.set('open', false);
        this.onClose();
      },
      updateTasks: function updateTasks() {
        this.onAction(this.get('data'));
      },
      updateAssignedTo: function updateAssignedTo(value) {
        this.set('data.assignedTo', value);

        this._checkForm();
      },
      updateAssignedBy: function updateAssignedBy(value) {
        this.set('data.assignedBy', value);

        this._checkForm();
      },
      updateDepartmentUsers: function updateDepartmentUsers() {
        this._checkForm();
      },
      checkForm: function checkForm() {
        this._checkForm();
      }
    },
    _checkForm: function _checkForm() {
      var valid = false;
      var data = this.get('data');
      this.get('filteredFields').forEach(function (f) {
        if (!valid) {
          var value = data[f.id];

          if (value || typeof value === 'string' && value.trim() !== '') {
            valid = true;
          }
        }

        !valid && f.sections.forEach(function (s) {
          if (valid || !s.active) {
            return;
          }

          s.subfields.forEach(function (sf) {
            var value = data[sf.id];

            if (value || typeof value === 'string' && value.trim() !== '') {
              valid = true;
            }
          });
        });
      });
      this.set('formIsValid', valid);
    },
    resetForm: function resetForm() {
      this.set('formIsValid', false);
      this.set('data', {});
      this.set('departmentUsers', []);
    }
  });

  _exports.default = _default;
});