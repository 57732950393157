define("inboxfront/components/editor", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment", "quill", "quill-emoji"], function (_exports, _mutation, _environment, Quill, _quillEmoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FontAttributor = Quill.import('attributors/class/font');
  FontAttributor.whitelist = ['roboto', 'arial', 'lato'];
  Quill.register({
    FontAttributor: FontAttributor,
    'formats/emoji': _quillEmoji.default.EmojiBlot,
    'modules/emoji-shortname': _quillEmoji.default.ShortNameEmoji,
    'modules/emoji-toolbar': _quillEmoji.default.ToolbarEmoji,
    'modules/emoji-textarea': _quillEmoji.default.TextAreaEmoji
  }, true);

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: '',
    optionsUpload: Ember.computed('quillEditor', function () {
      return {
        paramName: 'file',
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        clickable: '.ql-image',
        acceptedFiles: 'image/*',
        method: 'POST',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "livechat", "model": "flowblockmptt"}'
        }
      };
    }),
    uploadSuccess: Ember.computed(function () {
      var quillEditor = this.get('quillEditor');
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        var range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', _environment.default.mediaUrl + result.data.file.attachment.datafile);
      };
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var options = {
        modules: {
          toolbar: '#toolbar-container',
          'emoji-toolbar': true,
          'emoji-shortname': true,
          'emoji-textarea': true
        },
        placeholder: 'Typing here...',
        theme: 'snow'
      };
      var quillEditor = new Quill('#editor-container', options);
      var toolbarModule = quillEditor.getModule('toolbar');
      this.set('quillEditor', quillEditor);
      toolbarModule.addHandler('image', function () {}); // cancel default event

      try {
        JSON.parse(this.get('body'));
      } catch (e) {
        return this.initEditor(quillEditor, '');
      }

      this.initEditor(quillEditor, JSON.parse(this.get('body')));
    },
    initEditor: function initEditor(quillEditor, body) {
      var _this = this;

      quillEditor.root.innerHTML = body;
      quillEditor.on('editor-change', function () {
        var body = JSON.stringify(quillEditor.root.innerHTML);

        if (body === '"<p><br></p>"') {
          //add regexp to check editor content is empty
          body = '';
        }

        _this.set('body', body);

        _this.onChange && _this.onChange(body);
      });

      document.getElementById("editor-container").firstChild.onfocus = function () {
        document.querySelector('.grid-content .tse-scrollable').classList.add('focus');
        document.querySelector('.ql-toolbar').classList.add('focus');
      };

      document.getElementById("editor-container").firstChild.onblur = function () {
        document.querySelector('.grid-content .tse-scrollable').classList.remove('focus');
        document.querySelector('.ql-toolbar').classList.remove('focus');
      };
    }
  });

  _exports.default = _default;
});