define("inboxfront/gql/fragment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import gql from 'graphql-tag';
  var Fragment = {
    WIDGET_FRAGMENT: "\n\t\tid\n\t\tthemeColor\n\t\tactionButtonColor\n\t\tsound\n\t\tposition\n\t\tpreChatFormEnabled\n\t\twelcomeMessage\n\t\taboutTeamMessage\n\t\ttriggerTime\n\t\tresponseTime\n\t\tsatisfactionEnabled\n\t\trequiredVisitorEmail\n\t\trequiredVisitorFullName\n\t\trequiredVisitorPhoneNumber\n\t\tofflineMessage\n\t\tofflineMessageEnabled\n\t\tinboxChannelsEnabled\n\t\twhitelistedDomainEnabled\n\t\tvisibilityByPage\n\t\tlineDisplay\n\t\tlineContactUrl\n\t\temailDisplay\n\t\temailContactUrl\n\t\twechatDisplay\n\t\twechatContactUrl\n\t\tfacebookDisplay\n\t\tfacebookContactUrl\n\t\ttelegramDisplay\n\t\ttelegramContactUrl\n\t\ttwitterDisplay\n\t\ttwitterContactUrl\n\t\twhatsappDisplay\n\t\twhatsappContactUrl\n\t\tinstagramDisplay\n\t\tcreated\n\t\tmodified\n\t\tcompanyProperty {\n\t\t\tid\n\t\t}\n\t\tvisibleOnPages {\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\turlPath\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\ttrustedDomains {\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\tdomain\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tdefaultFlow {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tisActive\n\t\t}\n\t\tlogo {\n\t\t\tid\n\t\t\tdatafile\n\t\t}\n\t\tkbDisplay\n\t\tlauncher {\n\t\t\tid\n\t\t\tdatafile\n\t\t}\n\t\tdefaultKb {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t",
    ROLE_FRAGMENT: "\n\t\tid\n\t\tcompany {\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t\tname\n\t\tdescription\n\t\tisActive\n\t\troleType\n\t\tgroup {\n\t\t\tid\n\t\t\tname\n\t\t\tpermissions {\n\t\t\t\tname\n\t\t\t}\n\t\t\tuserSet {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t\treadyGrid\n\t",
    ACCOUNT_FRAGMENT: "\n\t\taccount {\n\t\t\tpk\n\t\t\tid\n\t\t\tname\n\t\t\tapplication\n\t\t\tactive\n\t\t\tverified\n\t\t\tcompanyProperty {\n\t\t\t\tid\n\t\t\t}\n\t\t\taccountsettingSet {\n\t\t\t\tedges {\n\t\t\t\t\tnode {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tkey\n\t\t\t\t\t\tvalue\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t",
    FLOW_FRAGMENT: "\n\t\tid\n\t\ttitle\n\t\tcompanyProperty {\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t\textraData\n\t\tauto\n\t\ttype\n\t\twarningMessage\n\t\tidleTime\n\t\ttimeOut\n\t\ttimeOutMessage\n\t\trules {\n\t\t\tid\n\t\t\tapp\n\t\t\tname\n\t\t\tdescription\n\t\t\trules\n\t\t\tisActive\n\t\t}\n\t\tdescription\n\t\tisActive\n\t\tblocks {\n\t\t\tid\n\t\t\tname\n\t\t\ticon\n\t\t\tmainTitle\n\t\t\tblockType\n\t\t\textraData\n\t\t\tsubtitle\n\t\t\tbuttonText\n\t\t\tbuttonType\n\t\t\tactions\n\t\t\tflow {\n\t\t\t\tid\n\t\t\t}\n\t\t\tredirectUrl\n\t\t\tparent {\n\t\t\t\tid\n\t\t\t}\n\t\t\tchildren {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tmainTitle\n\t\t\t\tsubtitle\n\t\t\t\tblockType\n\t\t\t\textraData\n\t\t\t\tbuttonText\n\t\t\t\tbuttonType\n\t\t\t\tactions\n\t\t\t\tredirectUrl\n\t\t\t\tattachments {\n                    id\n                    filename\n                    datafile\n                }\n                children {\n                    id\n                    name\n                }\n                parent {\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t\tattachments {\n\t\t\t\tid\n\t\t\t\tfilename\n\t\t\t\tdatafile\n\t\t\t}\n\t\t}\n\t\ttriggered\n\t\tcomplited\n\t\tengaged\n\t",
    ESCALATION_FRAGMENT: "\n\t\tid\n\t\tname\n\t\tdescription\n\t\ttargetType\n\t\tisActive\n\t\tconditions\n\t\tslaTarget\n\t\treminderSet {\n\t\t\tid\n\t\t\trules\n\t\t\tusers\n\t\t\tassigned\n\t\t\tviolated\n\t\t\tlevel\n\t\t}\n\t",
    TICKET_FRAGMENT: "\n\t\tid\n\t\ttitle\n\t\tslug\n\t\tguest {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tprimaryEmail\n\t\t\tpersonalPhone\n\t\t}\n\t\tassignedBy {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tusername\n\t\t\tattachment {\n\t\t\t\tdatafile\n\t\t\t}\n\t\t}\n\t\tassignedTo {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tusername\n\t\t\tattachment {\n\t\t\t\tdatafile\n\t\t\t}\n\t\t}\n\t\tdepartment {\n\t\t\tid\n\t\t\tdisplayName\n\t\t}\n\t\tcompanyProperty {\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t\tcompany {\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\t\tlastEditor {\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t}\n\t\tdescription\n\t\tbookingReference\n\t\troomType\n\t\troomNumber\n\t\tloyaltyNumber\n\t\tpriority\n\t\tcategory\n\t\tdueDate\n\t\tclosedDate\n\t\tarrivalDate\n\t\tdepartureDate\n\t\tlastEscalation\n\t\tstatus\n\t\ttaskType\n\t\tsource\n\t\tissueType {\n\t\t\tid\n\t\t}\n\t\tactive\n\t\tcreated\n\t\tmodified\n\t\tcomments {\n\t\t\tid\n\t\t\tauthor {\n\t\t\t\tid\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t}\n\t\t\ttext\n\t\t\tcreated\n\t\t\tisNote\n\t\t}\n\t\tactivities {\n\t\t\tid\n\t\t\ttext\n\t\t\tcreated\n\t\t}\n\t\tattachments {\n\t\t\tedges {\n\t\t\t\tnode {\n\t\t\t\t\tid\n\t\t\t\t\tdatafile\n\t\t\t\t\tfilename\n\t\t\t\t\textension\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\tcustomFields {\n\t\t\tid\n\t\t\tfield {\n\t\t\t\tid\n\t\t\t\tlabel\n\t\t\t}\n\t\t\tvalue\n\t\t}\n\t\twatchers\n\t"
  };
  var _default = Fragment;
  _exports.default = _default;
});