define("inboxfront/helpers/url-company-logo", ["exports", "inboxfront/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.urlCompanyLogo = urlCompanyLogo;
  _exports.default = void 0;

  function urlCompanyLogo(params) {
    return params[0].logo ? "".concat(_environment.default.mediaUrl).concat(params[0].logo.datafile) : '';
  }

  var _default = Ember.Helper.helper(urlCompanyLogo);

  _exports.default = _default;
});