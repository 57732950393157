define("inboxfront/templates/components/channels/whatsapp-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMBYL4sS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"channels/name-field\",null,[[\"channel\",\"selectedChannelType\"],[[24,[\"channel\"]],[24,[\"selectedChannelType\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"whatsapp_number\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"paper-input\",null,[[\"required\",\"value\",\"onChange\"],[true,[24,[\"channel\",\"whatsapp_number\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"channel\",\"whatsapp_number\"]]],null]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/channels/whatsapp-controls.hbs"
    }
  });

  _exports.default = _default;
});