define("inboxfront/routes/common", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/mixins/permissions"], function (_exports, _authenticatedRouteMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _permissions.default, {
    intl: Ember.inject.service(),
    actions: {
      loading: function loading(transition) {
        var applicationController = Ember.getOwner(this).lookup('controller:application');
        Ember.set(applicationController, 'isLoading', false);
        Ember.set(applicationController, 'textLoading', this.get('textLoading') || this.get('intl').t('status.loading'));
        transition.promise.finally(function () {
          Ember.set(applicationController, 'isLoading', true);
        });
      }
    }
  });

  _exports.default = _default;
});