define("inboxfront/templates/components/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NrLv9lZx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"page-title \",[28,\"if\",[[24,[\"class\"]],[24,[\"class\"]]],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showButton\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"onClick\"],[\"nav-item expand-button\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isCollapsed\"]]],null],[28,\"not\",[[24,[\"isCollapsed\"]]],null]],null]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"                \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],[[\"class\"],[[28,\"concat\",[\"icon-\",[24,[\"icon\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[28,\"paper-icon\",[\"menu\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/page-title.hbs"
    }
  });

  _exports.default = _default;
});