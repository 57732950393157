define("inboxfront/components/popups/group-chat-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterUsers: Ember.computed('participants.[]', function () {
      var _this = this;

      var ret = [];
      (this.users || []).forEach(function (user) {
        var f = (_this.participants || []).every(function (item) {
          return item.username !== user.username;
        });
        if (f) ret.push({
          id: user.pk,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          staffprofile: {
            user: user.staffprofile.user
          }
        });
      });
      return ret;
    }),
    actions: {
      addMember: function addMember() {
        this.addMember(this.user);
        this.set('user', {});
      },
      closeDialog: function closeDialog() {
        this.set('user', {});
        this.set('open', false);
      }
    }
  });

  _exports.default = _default;
});