define("inboxfront/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DhyBgD+i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"main-wrapper\"],[8],[0,\"\\n        \"],[1,[22,\"compact-menu\"],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"page-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"                \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[28,\"application-loading\",null,[[\"text\"],[\"status.loading\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isToastOpenNote\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"custom-toast\"],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"notification.message\"],null],false],[0,\"\\n\"],[4,\"common/buttons/secondary-2\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"sendRequestNotification\"],null]]],{\"statements\":[[0,\"                \"],[1,[28,\"t\",[\"notification.confirm\"],null],false],[0,\"!\\n\"]],\"parameters\":[]},null],[4,\"common/buttons/secondary-2\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"closeToast\"],null]]],{\"statements\":[[0,\"                \"],[1,[28,\"t\",[\"notification.cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[1,[22,\"paper-toaster\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/application.hbs"
    }
  });

  _exports.default = _default;
});