define("inboxfront/routes/users/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('users.index');
      }

      var users = this.ajax.query(_query.default.COMPANY);
      var user = this.ajax.query(_query.default.GET_USER, 'getUser', {
        profileId: params.id
      });
      var roles = this.ajax.query(_query.default.CUSTOM_ROLES);
      return Ember.RSVP.all([users, user, roles]).then(function (res) {
        if (res[1].errors) {
          return _this.transitionTo('users');
        }

        if (!res[1].systemGroup) {
          res[1].systemGroup = {};
        }

        return {
          users: !res[0].errors ? res[0].staffProfiles : [],
          user: res[1],
          companyCustomRoles: !res[2].errors ? res[2] : []
        };
      });
    }
  });

  _exports.default = _default;
});