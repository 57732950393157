define("inboxfront/components/flow/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      openAddComponent: function openAddComponent(component, event) {
        this.openAddComponent(component, event);
      },
      openStartConfigure: function openStartConfigure() {
        this.openStartConfigure();
      }
    }
  });

  _exports.default = _default;
});