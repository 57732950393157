define("inboxfront/templates/components/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOb84lDZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"application-loading-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"textLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[22,\"textLoading\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/application-loading.hbs"
    }
  });

  _exports.default = _default;
});