define("inboxfront/components/chat/ecommerce/shopify-info", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    selectedTab: 'orders',
    totalPrice: Ember.computed('orders.[]', function () {
      var price = 0;
      (this.get('orders') || []).forEach(function (o) {
        price += +o.totalPrice;
      });
      return price;
    }),
    currency: Ember.computed('storeInfo.currency', function () {
      return this.get('storeInfo.currency');
    }),
    storeLocationId: Ember.computed('storeInfo.locations.[]', function () {
      var locations = this.get('storeInfo.locations');
      return locations && locations[0] && locations[0].locationId || 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedOrder', null);
    },
    actions: {
      showOrder: function showOrder(order) {
        this.set('selectedOrder', order);
      },
      createCancellation: function createCancellation(order) {
        var platform = this.get('platform');
        platform === 'shopify' && this.createShopifyOrderCancellation(order);
        platform === 'bigcommerce' && this.createBigCommerceOrderCancellation(order);
      },
      createRefund: function createRefund(order) {
        var platform = this.get('platform');
        platform === 'shopify' && this.createShopifyOrderRefund(order);
        platform === 'bigcommerce' && this.createBigCommerceOrderRefund(order);
      }
    },
    createShopifyOrderCancellation: function createShopifyOrderCancellation(order) {
      var _this = this;

      var id = order.id,
          currency = order.currency,
          reason = order.reason;
      var hash = {
        action: 'cancel',
        currency: currency,
        eCommerce: 'shopify',
        locationId: this.get('storeLocationId'),
        orderId: id,
        propertyId: this.get('guest.companyProperty.id'),
        reason: reason
      };
      this.ajax.mutation(_mutation.default.ECOMMERCE_ACTION, 'eCommerceAction', hash, function (res) {
        if (res.status === 200) {
          _this.orderCancelled(order, reason);
        } else {
          _this.get('paperToaster').show(_this.intl.t('internal_error') + ' ' + _this.intl.t('try_later'));
        }
      });
    },
    createShopifyOrderRefund: function createShopifyOrderRefund(order) {
      var _this2 = this;

      var id = order.id,
          currency = order.currency,
          items = order.items,
          reason = order.reason,
          refundAmount = order.refundAmount,
          restockType = order.restockType;
      var hash = {
        action: 'refund',
        currency: currency,
        eCommerce: 'shopify',
        items: items,
        locationId: this.get('storeLocationId'),
        notify: true,
        orderId: id,
        propertyId: this.get('guest.companyProperty.id'),
        reason: reason,
        refundAmount: refundAmount,
        restockType: restockType
      };
      this.ajax.mutation(_mutation.default.ECOMMERCE_ACTION, 'eCommerceAction', hash, function (res) {
        if (res.status === 200) {
          _this2.orderRefunded(order, reason);
        } else {
          _this2.get('paperToaster').show(_this2.intl.t('internal_error') + ' ' + _this2.intl.t('try_later'));
        }
      });
    },
    createBigCommerceOrderCancellation: function createBigCommerceOrderCancellation(order) {
      var _this3 = this;

      var id = order.id,
          reason = order.reason;
      var hash = {
        orderId: id,
        propertyId: this.get('guest.companyProperty.id'),
        status: 'Cancelled'
      };
      this.ajax.mutation(_mutation.default.UPDATE_BIGCOMMERCE_ORDER, 'updateBcOrder', hash, function (res) {
        if (res.status === 200) {
          _this3.orderCancelled(order, reason);
        } else {
          _this3.get('paperToaster').show(_this3.intl.t('internal_error') + ' ' + _this3.intl.t('try_later'));
        }
      });
    },
    createBigCommerceOrderRefund: function createBigCommerceOrderRefund(order) {
      var _this4 = this;

      var id = order.id,
          items = order.items,
          reason = order.reason;
      var hash = {
        items: items,
        orderId: id,
        propertyId: this.get('guest.companyProperty.id'),
        status: 'Refunded'
      };

      if (order.providerId) {
        hash.providerId = order.providerId;
        hash.status = 'Partially Refunded';
      }

      this.ajax.mutation(_mutation.default.UPDATE_BIGCOMMERCE_ORDER, 'updateBcOrder', hash, function (res) {
        if (res.status === 200) {
          _this4.orderRefunded(order, reason);
        } else {
          _this4.get('paperToaster').show(_this4.intl.t('internal_error') + ' ' + _this4.intl.t('try_later'));
        }
      });
    },
    orderCancelled: function orderCancelled(order, reason) {
      this.set('showCancel', false);
      this.get('paperToaster').show(this.intl.t('order_cancelled'));
      this.onCancel({
        reason: reason,
        guest: this.get('guest'),
        order: order
      });
    },
    orderRefunded: function orderRefunded(order, reason) {
      this.set('showRefund', false);
      this.get('paperToaster').show(this.intl.t('order_refunded'));
      this.onRefund({
        reason: reason,
        guest: this.get('guest'),
        order: order
      });
    }
  });

  _exports.default = _default;
});