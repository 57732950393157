define("inboxfront/components/chat/reply-in-thread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    chats: Ember.inject.service(),
    tagName: '',
    webSocket: Ember.computed('chats.{sockets.[],selectedRoom}', function () {
      return this.chats.sockets;
    }),
    currentText: Ember.computed('msgBody', function () {
      var target = document.querySelector('.reply-in-thread-input');

      if (!this.get('msgBody')) {
        target && (target.innerHTML = '');
      }

      target && (target.innerHTML += this.get('msgBody'));
    }),
    scrollTo: Ember.computed('message.node.replies.[]', function () {
      var replies = this.get('message.node.replies');

      if (replies.length > 0) {
        setTimeout(function () {
          document.getElementById('reply' + replies[replies.length - 1].id) && document.getElementById('reply' + replies[replies.length - 1].id).scrollIntoView({
            behavior: 'smooth'
          });
        }, 500);
      }

      return '';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('msgBody', '');
    },
    actions: {
      sendMessage: function sendMessage() {
        var input = document.querySelector('.reply-in-thread-input');
        var innerText = input.innerHTML.trim();

        if (innerText.length === 0) {
          return;
        }

        var roomId = this.get('room.id');
        var filteredWebSocket = this.get('webSocket').filterBy('roomId', roomId)[0];
        var msg = window.linkify(innerText, {
          callback: function callback(text, href) {
            if (href) {
              return "<a target=\"_blank\" class=\"link\" href=\"".concat(href, "\" title=\"").concat(href, "\">").concat(text, "</a>");
            } else {
              return text;
            }
          }
        });
        var newMessagePacket = {
          type: 'message',
          message: msg,
          in_reply_to: this.get('message.node.id')
        };

        if (filteredWebSocket.webSocket.readyState === 1) {
          filteredWebSocket.webSocket.send(JSON.stringify(newMessagePacket));
        } //this.set('msgBody', '');


        input.innerHTML = '';
        this.set('isLoadingMessage', false);
        this.set('isMessageEmpty', true);
      },
      openReply: function openReply() {},
      openKBSearch: function openKBSearch() {},
      updateDateRange: function updateDateRange() {},
      openPopupUsers: function openPopupUsers() {},
      keyUpEventInput: function keyUpEventInput() {},
      scrollMessages: function scrollMessages() {}
    }
  });

  _exports.default = _default;
});