define("inboxfront/templates/components/chat/ecommerce/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t28dWRTY",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"cart\",\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"data\"],[8],[0,\"\\n            \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"image\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"product-image\"],[11,\"style\",[29,[\"background-image: url(\",[23,1,[\"image\"]],\");\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"span\",true],[8],[7,\"b\",true],[8],[1,[23,1,[\"quantity\"]],false],[0,\" x \"],[1,[23,1,[\"price\"]],false],[0,\" \"],[1,[22,\"currency\"],false],[9],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"data\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"Total:\"],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[7,\"b\",true],[8],[1,[22,\"totalPrice\"],false],[0,\" \"],[1,[22,\"currency\"],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/chat/ecommerce/cart.hbs"
    }
  });

  _exports.default = _default;
});