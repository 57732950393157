define("inboxfront/components/helpdesk/department-selector", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    departments: null,
    isEditMode: true,
    actions: {
      onSelectChange: function onSelectChange(value) {
        this.set('value', value);

        if (this.get('getusers')) {
          this.setDepartmentUsers();
        } else {
          this.onChange(value);
        }
      },
      selectDepartment: function selectDepartment() {
        if (this.get('multiple')) {
          var selected = [];

          for (var i = this.get('departments').length - 1; i >= 0; i--) {
            if (this.get('departments')[i].selected) {
              selected.push(this.get('departments')[i]);
            }
          }

          this.set('value', selected);
        }

        this.onChange();
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.ajax.query(_query.default.DEPARTMENT).then(function (res) {
        var departments = !res.errors ? res.filter(function (obj) {
          return obj.active === true;
        }).sort(function (a, b) {
          var x = a.name.toLowerCase();
          var y = b.name.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        }) : [];

        _this.set('departments', departments);

        if (_this.get('getusers')) {
          _this.setDepartmentUsers();
        }
      });
    },
    setDepartmentUsers: function setDepartmentUsers() {
      var _this2 = this;

      if (this.get('value') && this.get('departments')) {
        var department = this.get('departments').find(function (obj) {
          return obj.id === _this2.get('value').id;
        });

        if (department) {
          this.onChange(department.staffProfiles.filter(function (obj) {
            return obj.user.isActive === true;
          }));
        }
      }
    }
  });

  _exports.default = _default;
});