define("inboxfront/components/tasks/assign-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selectedUser: null,
    formIsValid: Ember.computed('selectedUser', function () {
      return !!this.get('selectedUser');
    }),
    actions: {
      selectUser: function selectUser(user) {
        this.set('selectedUser', user);
      },
      assignTasks: function assignTasks() {
        this.onAction(this.get('selectedUser'));
      }
    }
  });

  _exports.default = _default;
});