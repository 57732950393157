define("inboxfront/controllers/profile", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedLocale: Ember.computed('profile.staffprofile.language', function () {
      var _this = this;

      if (this.get('model.locales')) {
        return this.get('model.locales').find(function (obj) {
          return obj.code === _this.get('profile.staffprofile.language');
        });
      }
    }),
    profile: Ember.computed('sessionAccount', function () {
      return this.get('sessionAccount.loggeduser');
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
        controller.get('sessionAccount').update();
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token');
      var userId = this.get('profile.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        clickable: '.edit-icon',
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "auth", "model": "user", "objectId": "' + userId + '"}'
        }
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.cloneProfile = Ember.copy(this.get('sessionAccount.loggeduser'), true);
    },
    actions: {
      resetFields: function resetFields() {
        if (JSON.stringify(this.get('cloneProfile')) !== JSON.stringify(this.get('profile'))) {
          this.set('profile', Ember.copy(this.get('cloneProfile'), true));
        }
      },
      saveProfile: function saveProfile() {
        var _this2 = this;

        var profile = this.get('profile');
        var hash = {
          userId: profile.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: profile.staffprofile.phone,
          position: profile.staffprofile.position,
          displayName: profile.staffprofile.displayName
        };

        if (profile.staffprofile.department) {
          hash.department = profile.staffprofile.department.id;
        }

        if (this.get('selectedLocale')) {
          hash.language = this.get('selectedLocale').code;
        }

        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          _this2.paperToaster.show(_this2.intl.t('profile_updated'));

          _this2.set('cloneProfile', Ember.copy(profile, true));
        });
      },
      deleteAvatar: function deleteAvatar() {
        var _this3 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('attachmentId')
        }).then(function () {
          _this3.set('urlAttachment', null);

          _this3.set('attachmentId', null);

          _this3.get('sessionAccount').update();
        });
      }
    }
  });

  _exports.default = _default;
});