define("inboxfront/routes/contacts/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "inboxfront/config/environment"], function (_exports, _common, _query, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    contacts: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('contacts');
      } else {
        var guest = this.ajax.query(_query.default.GUEST, 'guest', {
          guestId: params.id
        });
        var users = this.ajax.query(_query.default.PROPERTY_USERS);
        var locales = this.ajax.query(_query.default.LOCALES, 'locales');
        var organizations = this.ajax.query(_query.default.ORGANIZATIONS_SELECT, 'organizations');
        var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
        var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
        var properties = this.ajax.query(_query.default.PROPERTY_LIST);
        var guestSettings = this.ajax.query(_query.default.GUEST_SETTINGS, 'guestSettings');
        return Ember.RSVP.all([guest, users, locales, organizations, settings, issueTypes, properties, guestSettings]).then(function (res) {
          var issueTypes = res[5].filter(function (t) {
            return t.isActive;
          });
          return {
            guest: res[0],
            users: res[1],
            locales: JSON.parse(res[2]),
            organizations: res[3],
            issueTypes: issueTypes,
            taskFields: _this.get('customfields').prepare(_this.get('tasks').systemFields, res[4]),
            properties: res[6],
            id: params.id,
            contactFields: _this.get('customfields').prepare([], res[7])
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _guest$homeAddress, _guest$officeAddress;

      this._super(controller, model);

      var guest = model.guest;
      controller.set('id', model.id);
      controller.set('guest', guest);
      controller.set('selectedOrganization', model.organizations.find(function (o) {
        var _guest$organization;

        return o.id === ((_guest$organization = guest.organization) === null || _guest$organization === void 0 ? void 0 : _guest$organization.id);
      }));
      controller.set('selectedProperty', model.properties.find(function (p) {
        var _guest$companyPropert;

        return p.id === ((_guest$companyPropert = guest.companyProperty) === null || _guest$companyPropert === void 0 ? void 0 : _guest$companyPropert.id);
      }));
      controller.set('selectedLocale', model.locales.find(function (l) {
        return l.code === guest.language;
      }));
      controller.set('selectedContactOwner', model.users.find(function (u) {
        var _guest$contactOwner;

        return u.id === ((_guest$contactOwner = guest.contactOwner) === null || _guest$contactOwner === void 0 ? void 0 : _guest$contactOwner.id);
      }));
      controller.set('homeAddressContact', {
        id: 0,
        name: (_guest$homeAddress = guest.homeAddress) === null || _guest$homeAddress === void 0 ? void 0 : _guest$homeAddress.country
      });
      controller.set('officeAddressContact', {
        id: 0,
        name: (_guest$officeAddress = guest.officeAddress) === null || _guest$officeAddress === void 0 ? void 0 : _guest$officeAddress.country
      });
      controller.set('cloneGuest', _objectSpread({}, guest));
      controller.set('cloneGuestAddressHome', _objectSpread({}, guest.homeAddress));
      controller.set('cloneGuestAddressOffice', _objectSpread({}, guest.officeAddress));
      controller.set('customFieldsData', this.get('contacts').deserialize(model.contactFields, guest || {}));
      var url = guest.logo !== null ? "".concat(_environment.default.mediaUrl).concat(guest.logo.datafile) : null;
      controller.set('urlAttachment', url);
      controller.set('attachmentId', guest.logo !== null ? guest.logo.id : null);
    }
  });

  _exports.default = _default;
});