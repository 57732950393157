define("inboxfront/controllers/permissions", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.titleTabs = [this.intl.t('Permissions.item'), this.intl.t('Permissions.full_control'), this.intl.t('Permissions.view_read'), this.intl.t('Permissions.create_add'), this.intl.t('Permissions.edit'), this.intl.t('Permissions.delete'), this.intl.t('Permissions.delegate_assign'), this.intl.t('Permissions.respond_reply')];
    },
    customTabs: Ember.computed('grid', function () {
      return this.get('grid').map(function (el) {
        return el.name;
      });
    }),
    actions: {
      onChangeAllCheckbox: function onChangeAllCheckbox(el) {
        Ember.set(el, 'enabled', !el.enabled);
        el.subArr.forEach(function (item, i) {
          Ember.set(el.subArr[i], 'enabled', el.enabled);
        });

        if (this.get('selectedTab') === 'Conversation') {
          var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
          filterContactsView.forEach(function (item, i) {
            Ember.set(filterContactsView[i], 'enabled', el.enabled);
          });
        }
      },
      onChangeTabs: function onChangeTabs(val) {
        var selectedRole = this.get('selectedRole');
        if (val === this.get('selectedTab')) return;
        var currentGrid = selectedRole.readyGrid.filter(function (el) {
          return el.name === val;
        })[0];
        Ember.set(this, 'currentGrid', currentGrid.arr);
        Ember.set(this, 'selectedTab', currentGrid.name);
      },
      onSelectRole: function onSelectRole(val) {
        if (val === this.get('selectedRole')) return;
        Ember.set(this, 'currentGrid', val.readyGrid[0].arr);
        Ember.set(this, 'selectedTab', val.readyGrid[0].name);
        Ember.set(this, 'selectedRole', val);
      },
      checkPermission: function checkPermission(val) {
        var currentGrid = this.get('currentGrid');

        if (!val.enabled) {
          var filterSubTypeView = currentGrid.filter(function (el) {
            return el.name === val.type;
          })[0].subArr.filter(function (el) {
            return el.name === 'view';
          })[0];

          if (val.name === 'add') {
            var filterSubTypeChange = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'change';
            })[0];
            var filterSubTypeDelete = currentGrid.filter(function (el) {
              return el.name === val.type;
            })[0].subArr.filter(function (el) {
              return el.name === 'delete';
            })[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterSubTypeChange, 'enabled', true);
            Ember.set(filterSubTypeDelete, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr;
              filterContacts.forEach(function (item, i) {
                Ember.set(filterContacts[i], 'enabled', true);
              });
            }
          } else if (val.name === 'delete' || val.name === 'change' || val.name === 'assign') {
            Ember.set(filterSubTypeView, 'enabled', true);

            if (this.get('selectedTab') === 'Conversation') {
              var _filterContacts = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr.findBy('name', val.name);

              var filterContactsView = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[0];
              Ember.set(_filterContacts, 'enabled', true);
              Ember.set(filterContactsView, 'enabled', true);
            }
          } else if (this.get('selectedTab') === 'Tasks' && val.name === 'view') {
            var filterSubTypeTask = currentGrid.filter(function (el) {
              return el.name === 'Task';
            })[0].subArr.filter(function (el) {
              return el.name === 'view';
            })[0];
            var filterSubTypeComment = currentGrid.filter(function (el) {
              return el.name === 'Comment';
            })[0].subArr.filter(function (el) {
              return el.name === 'view';
            })[0];

            if (val.type === 'Task') {
              Ember.set(filterSubTypeComment, 'enabled', true);
            } else if (val.type === 'Comment') {
              Ember.set(filterSubTypeTask, 'enabled', true);
            }
          } else if (this.get('selectedTab') === 'Conversation' && val.name === 'reply') {
            var filterContactsEdit = this.get('selectedRole.readyGrid').findBy('name', 'Contacts').arr[0].subArr[2];
            var filterTemplateRead = this.get('selectedRole.readyGrid').findBy('name', 'Templates').arr[0].subArr[0];
            Ember.set(filterSubTypeView, 'enabled', true);
            Ember.set(filterTemplateRead, 'enabled', true);
            Ember.set(filterContactsEdit, 'enabled', true);
          }
        }

        Ember.set(val, 'enabled', !val.enabled);
      },
      updatePermissions: function updatePermissions() {
        var _this = this;

        var hash = {
          roleId: this.get('selectedRole.id'),
          permissions: this.sortCheckBox(),
          readyGrid: JSON.stringify(this.get('selectedRole.readyGrid'))
        };
        this.ajax.mutation(_mutation.default.ADD_REMOVE_PERMISSIONS, 'addRemovePermissions', hash).then(function () {
          _this.paperToaster.show(_this.intl.t('permissions_updated'));
        });
      }
    },
    sortCheckBox: function sortCheckBox() {
      var arr = [];
      this.get('selectedRole.readyGrid').forEach(function (el) {
        el.arr.forEach(function (el) {
          el.subArr.forEach(function (el) {
            el.enabled && arr.push(el.id);
          });
        });
      });
      return arr;
    }
  });

  _exports.default = _default;
});