define("inboxfront/components/chat-note-user-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    filterUsers: Ember.computed('popupUsers', 'searchName', function () {
      var users = this.get('popupUsers');
      var str = this.get('searchName').toLowerCase();
      return users ? users.filter(function (u) {
        return (u._fullName || (u._fullName = u.firstName + ' ' + u.lastName)).toLowerCase().indexOf(str) >= 0;
      }).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          status: item.staffprofile && item.staffprofile.chatAgent && item.staffprofile.chatAgent.length ? item.staffprofile.chatAgent[0].status.toLowerCase() : 'offline'
        });
      }) : [];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('searchName', '');
    },
    actions: {
      onclickOutside: function onclickOutside() {
        this.onclickOutside();
      },
      chooseUser: function chooseUser(user) {
        this.chooseUser(user);
      }
    }
  });

  _exports.default = _default;
});