define("inboxfront/components/chat/ecommerce/catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    filteredProducts: Ember.computed('searchProduct', 'products.[]', function () {
      var search = this.get('searchProduct');
      var products = this.get('products') || [];
      var ret = products;

      if (search) {
        search = search.toLowerCase();
        ret = products.filter(function (o) {
          return o.title.toLowerCase().indexOf(search) >= 0;
        });
      }

      return ret;
    }),
    actions: {
      selectProduct: function selectProduct(product) {
        Ember.set(product, 'selected', !product.selected);
        this.set('selectedProductsCount', (this.get('products') || []).filter(function (p) {
          return p.selected;
        }).length);
      },
      deselectAllProducts: function deselectAllProducts() {
        (this.get('products') || []).forEach(function (p) {
          if (p.selected) {
            Ember.set(p, 'selected', false);
          }
        });
        this.set('selectedProductsCount', 0);
      },
      sendProductCardsToChat: function sendProductCardsToChat() {
        var _this = this;

        var selectedCards = [];
        (this.get('products') || []).forEach(function (p) {
          if (p.selected) {
            selectedCards.pushObject(p);
            p.currency || Ember.set(p, 'currency', _this.get('currency') || '???');
          }
        });
        this.onSendCards(selectedCards);
      }
    }
  });

  _exports.default = _default;
});