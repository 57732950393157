define("inboxfront/services/profile", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    userStatus: 'offline',
    changeStatusUser: function changeStatusUser(status) {
      var _this = this;

      this.ajax.query(_mutation.default.UPDATE_AGENT_STATUS, 'updateAgentStatus', {
        status: status.toUpperCase()
      }).then(function () {
        _this.set('userStatus', status);
      });
    },
    getStatusUser: function getStatusUser() {
      var _this2 = this;

      this.ajax.query(_query.default.AGENT_PROFILE, 'agentProfile').then(function (user) {
        _this2.set('userStatus', (user.status || '').toLowerCase());
      });
    }
  });

  _exports.default = _default;
});