define("inboxfront/routes/import", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    customfields: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: {
      tab: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var contactFields = [//variants - header's values for automapping
      {
        label: 'First Name',
        variants: ['first name', 'firstname', 'fname'],
        id: 'first_name',
        required: true
      }, {
        label: 'Last Name',
        variants: ['last name', 'lastname', 'lname', 'surname', 'family name'],
        id: 'last_name',
        required: true
      }, {
        label: 'Email',
        variants: ['email', 'email address'],
        id: 'email',
        required: true
      }, {
        label: 'Phone Number',
        variants: ['phone', 'telephone', 'telephone number', 'phone number'],
        id: 'personal_phone'
      }, {
        label: 'Mobile Number',
        variants: ['mobile', 'mobile number'],
        id: 'mobile_phone'
      }, {
        label: 'Postal Code',
        variants: ['postal code', 'postcode'],
        id: 'postalcode'
      }, {
        label: 'State',
        variants: ['state', 'province'],
        id: 'state'
      }, {
        label: 'Country',
        variants: [],
        id: 'country'
      }, {
        label: 'City',
        variants: [],
        id: 'city'
      }, {
        label: 'Address 1',
        variants: [],
        id: 'line_1'
      }, {
        label: 'Organisation',
        variants: [],
        id: 'organization'
      }, {
        label: 'Office Address 1',
        variants: [],
        id: 'office_line_1'
      }, {
        label: 'Office Address 2',
        variants: [],
        id: 'office_line_2'
      }, {
        label: 'Home Address 1',
        variants: [],
        id: 'home_line_1'
      }, {
        label: 'Home Address 2',
        variants: [],
        id: 'home_line_2'
      }, {
        label: 'Date of Birth',
        variants: [],
        id: 'dob'
      }];
      var organizationFields = [//variants - header's values for automapping
      {
        label: 'Organisation Name',
        variants: ['name', 'company name', 'organization name', 'organisation name'],
        id: 'name',
        required: true
      }, {
        label: 'Phone Number',
        variants: ['phone', 'telephone', 'telephone number', 'phone number'],
        id: 'phone'
      }, {
        label: 'Fax Number',
        variants: ['fax'],
        id: 'fax'
      }, {
        label: 'Email',
        variants: ['email', 'email address'],
        id: 'email'
      }, {
        label: 'Website',
        variants: [],
        id: 'website'
      }, {
        label: 'Industry',
        variants: [],
        id: 'industry'
      }, {
        label: 'Postal Code',
        variants: ['postal code', 'postcode'],
        id: 'postalcode'
      }, {
        label: 'State',
        variants: ['state', 'province'],
        id: 'state'
      }, {
        label: 'Country',
        variants: [],
        id: 'country'
      }, {
        label: 'City',
        variants: [],
        id: 'city'
      }, {
        label: 'Office Address 1',
        variants: [],
        id: 'office_line_1'
      }, {
        label: 'Office Address 2',
        variants: [],
        id: 'office_line_2'
      }];
      var guestSettings = this.ajax.query(_query.default.GUEST_SETTINGS);
      var organizationSettings = this.ajax.query(_query.default.ORGANIZATION_SETTINGS);
      return Ember.RSVP.all([guestSettings, organizationSettings]).then(function (res) {
        var fields = _this.get('customfields').prepare([], res[0]);

        fields.forEach(function (f) {
          contactFields.pushObject({
            label: f.label,
            variants: [],
            id: f.id
          });
        });
        fields = _this.get('customfields').prepare([], res[1]);
        fields.forEach(function (f) {
          organizationFields.pushObject({
            label: f.label,
            variants: [],
            id: f.id
          });
        });
        return {
          guestSettings: res[0],
          organizationSettings: res[1],
          fieldTypes: _this.get('customfields').types,
          moduleName: params.tab === 'Organizations' ? 'Organizations' : 'Contacts',
          contactFields: contactFields,
          organizationFields: organizationFields,
          properties: _this.get('sessionAccount.loggeduser.properties')
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.updateImportData();
    }
  });

  _exports.default = _default;
});