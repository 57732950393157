define("inboxfront/templates/components/menu-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QmD97MdQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"user-profile\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"profile-title\"],[8],[1,[28,\"t\",[\"preferences\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"profile-menu\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"preference.notification\"]],{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[\"notification\"],[[\"class\"],[\"icon-bell\"]]],false],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[1,[28,\"t\",[\"Notification\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/menu-profile.hbs"
    }
  });

  _exports.default = _default;
});