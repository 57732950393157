define("inboxfront/components/escalations/section-slatarget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('hoursOptions', ['24 hours', 'Business hours']);
    },
    actions: {
      updateHours: function updateHours(target, value) {
        Ember.set(target, 'hours', value);
        this.onChange();
      },
      checkForm: function checkForm() {
        this.onChange();
      }
    }
  });

  _exports.default = _default;
});