define("inboxfront/components/input-chat", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.exec = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var exec = function exec(command) {
    var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return document.execCommand(command, false, value);
  };

  _exports.exec = exec;

  var _default = Ember.Component.extend({
    placeholder: '',
    showEditor: false,
    isShowTextEditor: true,
    inputClass: '',
    currentText: Ember.computed('msgBody', function () {
      var target = document.querySelector('.txt-input');

      if (!this.get('msgBody')) {
        target && (target.innerHTML = '');
      }

      target && (target.innerHTML += this.get('msgBody'));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var input = this.$('.txt-input')[0];

      if (input) {
        input.innerHTML = '';
        input.addEventListener('input', function (event) {
          if (event.target.firstChild && event.target.firstChild.nodeType === 3) {//exec('formatBlock', '<div>')
          } else if (input.innerHTML === '<br>') {
            input.innerHTML = '';
          }

          if (event.inputType === 'deleteContentBackward' && !event.target.innerText) {
            _this.set('mentioned', []);
          }

          var str = '';
          input.childNodes.forEach(function (i) {
            str += i.innerHTML || i.textContent;
          });
          var clone = (0, _jquery.default)("<div>".concat(str, "</div>"));
          clone.find('[contenteditable]').removeAttr('contenteditable'); //this.set('msgBody', clone[0].innerHTML);

          _this.set('isMessageEmpty', Boolean(!event.target.innerText));
        });
      }
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.actionsEditor = [{
        icon: '<b>B</b>',
        title: 'Bold',
        isActive: false,
        state: function state() {
          return _this2.isSelectionTag('B');
        },
        result: function result() {
          return exec('bold');
        }
      }, {
        icon: '<i>I</i>',
        title: 'Italic',
        isActive: false,
        state: function state() {
          return _this2.isSelectionTag('I');
        },
        result: function result() {
          return exec('italic');
        }
      }, {
        icon: '<u>U</u>',
        title: 'Underline',
        isActive: false,
        state: function state() {
          return _this2.isSelectionTag('U');
        },
        result: function result() {
          return exec('underline');
        }
      }, {
        icon: '<s>S</s>',
        title: 'Strike-through',
        isActive: false,
        state: function state() {
          return _this2.isSelectionTag('STRIKE');
        },
        result: function result() {
          return exec('strikeThrough');
        }
      }, {
        icon: '<b>H<sub>1</sub></b>',
        title: 'Heading 1',
        isActive: false,
        result: function result() {
          return exec('formatBlock', '<h1>');
        }
      }, {
        icon: '<b>H<sub>2</sub></b>',
        title: 'Heading 2',
        isActive: false,
        result: function result() {
          return exec('formatBlock', '<h2>');
        }
      }];
    },
    actions: {
      openEditor: function openEditor() {
        this.toggleProperty('isShowTextEditor');
      },
      toggleActionBar: function toggleActionBar(action) {
        var input = this.$('.txt-input')[0];
        var selectedAction = this.get('actionsEditor').findBy('title', action.title);

        if (action.state) {
          Ember.set(selectedAction, 'isActive', !action.isActive);
        }

        action.result() && input.focus();
      },
      onBlur: function onBlur() {
        this.set('isFocus', false);
      },
      onKeyUp: function onKeyUp(event) {
        if (event.keyCode === 13 && !event.target.textContent.trim()) {
          event.preventDefault();
          event.target.innerHTML = '';
        }

        if (event.target.textContent.trim()) {
          this.keyUpEventInput(event.keyCode, event.key);
        }

        this.toggleActions();
      },
      onMouseUp: function onMouseUp() {
        this.set('isFocus', true);
        this.toggleActions();
      },
      openCalendar: function openCalendar() {
        this.flatpickrRef.toggle();
      },
      updateDateRange: function updateDateRange(date) {
        this.updateDateRange(date);
      },
      openPopupUsers: function openPopupUsers() {
        this.openPopupUsers();
      },
      openKBSearch: function openKBSearch() {
        this.openKBSearch();
      },
      openReply: function openReply() {
        this.openReply();
      },
      sendMessage: function sendMessage() {
        this.sendMessage();
      }
    },
    isSelectionTag: function isSelectionTag(tag) {
      var currentNode = window.getSelection().focusNode;

      while (currentNode && currentNode.id !== 'editor') {
        if (currentNode.tagName === tag) return true;
        currentNode = currentNode.parentNode;
      }

      return false;
    },
    toggleActions: function toggleActions() {
      var mappingActions = this.get('actionsEditor').map(function (a) {
        return _objectSpread(_objectSpread({}, a), {}, {
          isActive: a.state ? a.state() : a.isActive
        });
      });
      this.set('actionsEditor', mappingActions);
    }
  });

  _exports.default = _default;
});