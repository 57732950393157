define("inboxfront/components/tasks/id-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showProp: 'title',
    isEditMode: true,
    selected: Ember.computed('value', function () {
      return this.get('options').findBy('id', this.get('value'));
    }),
    actions: {
      changeValue: function changeValue(value) {
        if (!value.disabled) {
          this.set('value', value.id);
          this.onChange && this.onChange(value.id);
        }
      }
    }
  });

  _exports.default = _default;
});