define("inboxfront/controllers/escalations/edit", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    escalationChanged: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    actions: {
      updateField: function updateField(field, name) {
        var _this = this;

        var value = this.get("model.edit.".concat(field));

        if (value !== this.get("escalation.".concat(field))) {
          if (field === 'name' && !this.trimValidation[0].validate(value)) {
            return;
          } else if (field === 'description' && value.length > 250) {
            return;
          }

          var hash = {
            escalationId: this.get('escalation.id')
          };
          hash[field] = value;
          this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function () {
            _this.set("escalation.".concat(field), value);

            _this.set("originalEscalation.".concat(field), value);

            _this.get('paperToaster').show("".concat(name, " ").concat(_this.intl.t('status.updated')));
          });
        }
      },
      changeEscalationStatus: function changeEscalationStatus() {
        var _this2 = this;

        var status = !this.get('escalation.isActive');
        var hash = {
          escalationId: this.get('escalation.id'),
          isActive: status
        };
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash, function () {
          _this2.set('escalation.isActive', status);

          _this2.set('originalEscalation.isActive', status);

          if (status) {
            _this2.get('paperToaster').show(_this2.intl.t('Escalations.escalation_activated'));
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('Escalations.escalation_deactivated'));
          }
        });
      },
      deleteEscalation: function deleteEscalation() {
        var _this3 = this;

        this.ajax.mutation(_mutation.default.DELETE_ESCALATION, 'deleteEscalation', {
          escalationId: this.get('escalation.id')
        }, function () {
          _this3.get('paperToaster').show(_this3.intl.t('Escalations.escalation_deleted'));

          _this3.set('showDeleteEscalationDialog', false);

          _this3.transitionToRoute('escalations.index');
        });
      },
      discardChanges: function discardChanges() {
        this.set('escalation', this.copyEscalation(this.get('originalEscalation')));
        this.set('escalationChanged', false);
      },
      saveChanges: function saveChanges() {
        var _this4 = this;

        var diff = this.get('diff');
        var escalationId = this.get('escalation.id');
        var mutations = [];

        if (!diff.reminders.isEqual) {
          diff.reminders.deleted.forEach(function (id) {
            mutations.push(_this4.ajax.mutation(_mutation.default.DELETE_REMINDER, 'deleteReminder', {
              reminderId: id
            }));
          });
          diff.reminders.added.forEach(function (r) {
            if (!_this4.validateReminder(r)) return;
            mutations.push(_this4.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this4.serializeReminder(r, escalationId), function (res) {
              Ember.set(r, 'id', res.reminder.id);
            }));
          });
          this.get('escalation.reminders').forEach(function (r) {
            if (r.updated === true) {
              if (!_this4.validateReminder(r)) return;
              mutations.push(_this4.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this4.serializeReminder(r, escalationId)));
            }
          });
        }

        if (!diff.escalations.isEqual) {
          diff.escalations.deleted.forEach(function (id) {
            mutations.push(_this4.ajax.mutation(_mutation.default.DELETE_REMINDER, 'deleteReminder', {
              reminderId: id
            }));
          });
          diff.escalations.added.forEach(function (r) {
            if (!_this4.validateReminder(r)) return;
            mutations.push(_this4.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this4.serializeReminder(r, escalationId), function (res) {
              Ember.set(r, 'id', res.reminder.id);
            }));
          });
          this.get('escalation.escalations').forEach(function (r) {
            if (r.updated === true) {
              if (!_this4.validateReminder(r)) return;
              mutations.push(_this4.ajax.mutation(_mutation.default.CREATE_UPDATE_REMINDER, 'createUpdateReminder', _this4.serializeReminder(r, escalationId)));
            }
          });
        }

        if (!diff.conditions || !diff.slaTarget) {
          var hash = {
            escalationId: this.get('escalation.id')
          };

          if (!diff.conditions) {
            hash.conditions = JSON.stringify(this.serializeConditions(this.get('escalation.conditions'), this.get('escalation.targetType'), this.get('model.properties')));
          }

          if (!diff.slaTarget) {
            hash.slaTarget = JSON.stringify(this.get('escalation.slaTarget'));
          }

          mutations.push(this.ajax.mutation(_mutation.default.CREATE_UPDATE_ESCALATION, 'createUpdateEscalation', hash));
        }

        Ember.RSVP.all(mutations).then(function () {
          _this4.set('originalEscalation', _this4.copyEscalation(_this4.get('escalation')));

          _this4.get('paperToaster').show('Escalation updated');

          _this4.set('escalationChanged', false);
        });
      },
      testChanges: function testChanges() {
        var diff = {};
        var conditions = this.get('escalation.conditions');
        var originalConditions = this.get('originalEscalation.conditions');
        var isEqual = conditions.length === originalConditions.length;

        for (var i = 0; i < conditions.length && isEqual; i++) {
          var c = originalConditions.findBy('rule', conditions[i].rule);

          if (!c || conditions[i].values.length !== c.values.length) {
            isEqual = false;
          } else {
            for (var k = 0; k < conditions[i].values.length && isEqual; k++) {
              var v = c.values.findBy('id', conditions[i].values[k].id);

              if (!v) {
                isEqual = false;
              }
            }
          }
        }

        diff.conditions = isEqual;
        diff.slaTarget = JSON.stringify(this.get('escalation.slaTarget')) === JSON.stringify(this.get('originalEscalation.slaTarget'));
        diff.reminders = this.testReminders(this.get('escalation.reminders'), this.get('originalEscalation.reminders'));
        diff.escalations = this.testReminders(this.get('escalation.escalations'), this.get('originalEscalation.escalations'));
        this.set('diff', diff);
        this.set('escalationChanged', !(diff.conditions && diff.slaTarget && diff.reminders.isEqual && diff.escalations.isEqual));

        if (this.get('escalationChanged')) {
          this.checkForm();
        }
      }
    },
    checkForm: function checkForm() {
      this.set('formIsInvalid', true);
      var conditions = this.get('escalation.conditions');
      var valid = true;

      for (var i = 0; i < conditions.length && valid; i++) {
        if (!conditions[i].rule) valid = false;
        if (conditions[i].values.length < 1) valid = false;
      }

      if (!valid) {
        return;
      }

      var slaTarget = this.get('escalation.slaTarget');
      valid = true;

      for (var _i = 0; _i < slaTarget.length && valid; _i++) {
        if (!slaTarget[_i].frt) valid = false;
        if (!slaTarget[_i].rt) valid = false;
      }

      if (!valid) {
        return;
      }

      var reminders = this.get('escalation.reminders');
      valid = true;

      for (var _i2 = 0; _i2 < reminders.length && valid; _i2++) {
        if (reminders[_i2].rule) {
          valid = this.validateReminder(reminders[_i2]);
        }
      }

      if (!valid) {
        return;
      }

      var escalations = this.get('escalation.escalations');
      valid = true;

      for (var _i3 = 0; _i3 < escalations.length && valid; _i3++) {
        valid = this.validateReminder(escalations[_i3]);
      }

      if (!valid) {
        return;
      }

      this.set('formIsInvalid', false);
    },
    testReminders: function testReminders(reminders, originalReminders) {
      var isEqual = true;
      var diff = {
        deleted: [],
        added: []
      };
      originalReminders.forEach(function (reminder) {
        if (!reminders.findBy('rule', reminder.rule)) {
          diff.deleted.push(reminder.id);
          isEqual = false;
        }
      });
      reminders.forEach(function (reminder) {
        Ember.set(reminder, 'updated', false);
        var r = originalReminders.findBy('rule', reminder.rule);

        if (!r) {
          diff.added.push(reminder);
          isEqual = false;
        } else {
          if (reminder.id === undefined) {
            Ember.set(reminder, 'id', r.id);
          }

          if (reminder.values.length !== r.values.length || reminder.minutes !== r.minutes) {
            Ember.set(reminder, 'updated', true);
            isEqual = false;
          } else if (reminder.values.length === r.values.length) {
            for (var k = 0; k < reminder.values.length; k++) {
              var v = r.values.findBy('id', reminder.values[k].id);

              if (!v) {
                isEqual = false;
                Ember.set(reminder, 'updated', true);
                break;
              }
            }
          }
        }
      });
      diff.isEqual = isEqual;
      return diff;
    },
    deserializeEscalation: function deserializeEscalation(rawEscalation) {
      var escalation = rawEscalation;
      escalation.targetType = escalation.targetType.toLowerCase();
      escalation.conditions = this.deserializeConditions(escalation.conditions, this.get('model.issueTypes'), this.get('model.departments'), this.get('model.properties'), this.get('model.channelTypes'));
      escalation.slaTarget = JSON.parse(escalation.slaTarget);
      escalation.reminders = this.deserializeReminders(escalation.reminderSet.filter(function (r) {
        return !r.violated;
      }), this.get('model.rules'), this.get('model.users'));
      escalation.escalations = this.deserializeReminders(escalation.reminderSet.filter(function (r) {
        return r.violated;
      }), this.get('model.rules'), this.get('model.users'));
      return escalation;
    },
    deserializeConditions: function deserializeConditions(rawConditions, issueTypes, departments, properties, channelTypes) {
      rawConditions = JSON.parse(rawConditions);
      var conditions = [];

      if (rawConditions.issueType) {
        conditions.push({
          rule: 'Issue Type',
          values: this.deserializeConditionValues(rawConditions.issueType, issueTypes)
        });
      }

      if (rawConditions.departments) {
        conditions.push({
          rule: 'Department',
          values: this.deserializeConditionValues(rawConditions.departments, departments)
        });
      }

      if (rawConditions.properties) {
        conditions.push({
          rule: 'Property',
          values: this.deserializeConditionValues(rawConditions.properties, properties)
        });
      }

      if (rawConditions.channels) {
        conditions.push({
          rule: 'Channel',
          values: this.deserializeConditionValues(rawConditions.channels, channelTypes)
        });
      }

      return conditions;
    },
    deserializeConditionValues: function deserializeConditionValues(rawValues, allValues) {
      var values = [];
      rawValues.forEach(function (id) {
        var value = allValues.findBy('id', id);

        if (value) {
          values.push(value);
        }
      });
      return values;
    },
    deserializeReminders: function deserializeReminders(reminderSet, allRules, allUsers) {
      var _this5 = this;

      var reminders = reminderSet.map(function (r) {
        return _this5.deserializeReminder(r, allRules, allUsers);
      });

      if (reminders.length === 0) {
        reminders.push({
          values: []
        });
      }

      return reminders;
    },
    deserializeReminder: function deserializeReminder(rawReminder, allRules, allUsers) {
      var rule = {};
      var minutes = 0;
      var rules = JSON.parse(rawReminder.rules);

      if (rules.frt) {
        rule = allRules.findBy('id', 'frt');
        minutes = +rules.frt;
      } else if (rules.nrt) {
        rule = allRules.findBy('id', 'nrt');
        minutes = +rules.nrt;
      } else {
        rule = allRules.findBy('id', 'rt');
        minutes = +rules.rt;
      }

      var values = [];

      if (rawReminder.assigned) {
        values.push(allUsers[0]);
      }

      rawReminder.users.forEach(function (userPK) {
        var user = allUsers.findBy('pk', userPK);

        if (user) {
          values.push(user);
        }
      });
      return {
        id: rawReminder.id,
        rule: rule,
        minutes: minutes,
        values: values,
        violated: rawReminder.violated
      };
    },
    validateReminder: function validateReminder(r) {
      return r.rule && r.minutes && r.values.length > 0;
    },
    serializeConditions: function serializeConditions(conditions, targetType, properties) {
      var ret = {};
      var propertyMissed = true;
      conditions.forEach(function (c) {
        var name = '';

        switch (c.rule) {
          case 'Issue Type':
            name = 'issueType';
            break;

          case 'Department':
            name = 'departments';
            break;

          case 'Property':
            name = 'properties';
            propertyMissed = false;
            break;

          case 'Channel':
            name = 'channels';
        }

        ret[name] = c.values.map(function (v) {
          return v.id;
        });
      });

      if (targetType === 'conversation' && propertyMissed) {
        ret['properties'] = properties.map(function (p) {
          return p.id;
        });
      }

      return ret;
    },
    serializeReminder: function serializeReminder(reminder, escalationId) {
      var assigned = false;
      var users = [];
      reminder.values.forEach(function (u) {
        if (u.pk === 0) {
          assigned = true;
        } else {
          users.push(u.pk);
        }
      });
      var rules = {};
      rules[reminder.rule.id] = reminder.minutes || 0;
      var ret = {
        rules: JSON.stringify(rules),
        assigned: assigned,
        users: users,
        violated: reminder.violated
      };

      if (escalationId !== undefined) {
        ret.escalationId = escalationId;
      }

      if (reminder.id !== undefined) {
        ret.reminderId = reminder.id;
      }

      return ret;
    },
    copyEscalation: function copyEscalation(escalation) {
      var conditions = escalation.conditions.map(function (c) {
        return {
          rule: c.rule,
          values: _toConsumableArray(c.values)
        };
      });
      return {
        id: escalation.id,
        name: escalation.name,
        description: escalation.description,
        targetType: escalation.targetType,
        isActive: escalation.isActive,
        conditions: conditions,
        slaTarget: JSON.parse(JSON.stringify(escalation.slaTarget)),
        reminders: escalation.reminders.map(this.copyReminder),
        escalations: escalation.escalations.map(this.copyReminder)
      };
    },
    copyReminder: function copyReminder(r) {
      return {
        id: r.id,
        minutes: r.minutes,
        rule: r.rule,
        values: _toConsumableArray(r.values),
        violated: r.violated
      };
    }
  });

  _exports.default = _default;
});