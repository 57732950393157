define("inboxfront/helpers/user-fullname", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userFullName = userFullName;
  _exports.default = void 0;

  function userFullName(params) {
    if (!params[0]) {
      return '';
    }

    return params[0]._fullName || (params[0]._fullName = params[0].firstName + ' ' + params[0].lastName);
  }

  var _default = Ember.Helper.helper(userFullName);

  _exports.default = _default;
});