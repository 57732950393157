define("inboxfront/components/chat/ecommerce/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    selectedStatus: 'any',
    filteredOrders: Ember.computed('searchOrder', 'selectedStatus', 'orders.[]', function () {
      var search = this.get('searchOrder');
      var orders = this.get('orders') || [];
      var ret = orders;

      if (search) {
        ret = orders.filter(function (o) {
          return o.name.indexOf(search) >= 0 || ('' + o.id).indexOf(search) >= 0;
        });
      } else {
        var status = this.get('selectedStatus');

        if (status !== 'any') {
          ret = orders.filter(function (o) {
            return o.status === status;
          });
        }
      }

      return ret;
    }),
    totalPrice: Ember.computed('filteredOrders.[]', function () {
      var price = 0;
      this.get('filteredOrders').forEach(function (o) {
        price += +o.totalPrice;
      });
      return price;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedStatus', 'any');
    },
    actions: {
      selectStatus: function selectStatus(status) {
        this.set('selectedStatus', status);
      }
    }
  });

  _exports.default = _default;
});