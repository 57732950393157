define("inboxfront/helpers/filter-grid-organization", ["exports", "moment", "inboxfront/helpers/countries-list"], function (_exports, _moment, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterGridContacts = filterGridContacts;
  _exports.default = void 0;

  function filterGridContacts(params) {
    if (params[0] === 'phone') {
      return params[1].phone;
    } else if (params[0] === 'country') {
      return params[1].address ? params[1].address.country : ''; //return COUNTRIES_LIST.findBy('iso2', params[1].address.country).name;
    } else if (params[0] === 'company') {
      return params[1].companyProperty.title;
    } else if (params[0] === 'state') {
      if (!params[1].address) return '';
      return params[1].address.state;
    } else if (params[0] === 'industry') {
      return params[1].industry;
    } else if (params[0] === 'website') {
      return params[1].website;
    } else if (params[0] === 'fax') {
      return params[1].fax;
    } else if (params[0] === 'contactOwner') {
      if (!params[1].contactOwner) return 'Unassigned';
      return "".concat(params[1].contactOwner.firstName, " ").concat(params[1].contactOwner.lastName);
    } else if (params[0] === 'created') {
      return (0, _moment.default)(params[1].createdAt).format('L');
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(filterGridContacts);

  _exports.default = _default;
});