define("inboxfront/routes/group/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var groups = this.ajax.query(_query.default.CUSTOM_GROUPS);
      var users = this.ajax.query(_query.default.PROPERTY_USERS);
      return Ember.RSVP.all([groups, users]).then(function (res) {
        var group = res[0].find(function (_ref) {
          var id = _ref.id;
          return id === params.id;
        });

        if (!params.id || res[0].errors || !group) {
          return _this.transitionTo('group');
        }

        return {
          group: group,
          users: !res[1].errors ? res[1] : []
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var users = [];
      model.users.forEach(function (u) {
        var user = model.group.group.userSet.find(function (user) {
          return Number(user.id) === u.pk;
        });
        u.fullName = "".concat(u.firstName, " ").concat(u.lastName);

        if (user) {
          user.attachment = u.attachment;
          user.username = u.username;
          users.push(user);
        }
      });
      var modelUsers = model.users.map(function (user) {
        return _objectSpread(_objectSpread({}, user), {}, {
          id: user.pk
        });
      });
      controller.set('companyId', this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id);
      controller.set('properties', this.get('sessionAccount.loggeduser.properties'));
      controller.set('group', model.group.group);
      controller.set('groupProperties', model.group.properties);
      controller.set('users', users);
      controller.set('modelUsers', modelUsers);
    }
  });

  _exports.default = _default;
});