define("inboxfront/components/message-emojis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    emojisArr: Ember.computed('message.reaction.length', function () {
      var _this = this;

      var arr = this.get('message.reaction');

      if (arr.length > 0) {
        var beforeFilter = arr.map(function (emoji) {
          return _this.createOneEmojiArray(arr, emoji);
        });
        var purified = this.purifyEmojis(beforeFilter);
        purified.forEach(function (item) {
          return item.isCurrentUser = item.authors.some(function (i) {
            return Number(i.id) === _this.get('currentUser.pk');
          });
        });
        return purified;
      }

      return [];
    }),
    createOneEmojiArray: function createOneEmojiArray(array, emoji) {
      var oneEmoji = {
        value: emoji.emoji,
        ids: [],
        authors: []
      };
      array.forEach(function (item) {
        if (item.emoji === emoji.emoji) {
          if (!oneEmoji.authors.includes(item.user)) {
            oneEmoji.authors.push(item.user);
          }

          oneEmoji.ids.push(item.id);
        }
      });
      return oneEmoji;
    },
    purifyEmojis: function purifyEmojis(arr) {
      var _this2 = this;

      var result = [];

      var _iterator = _createForOfIteratorHelper(arr),
          _step;

      try {
        var _loop = function _loop() {
          var str = _step.value;

          if (!result.some(function (i) {
            return i.value === str.value;
          })) {
            str.authors = _this2.purifyAuthors(str.authors);
            result.push(str);
          }
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return result;
    },
    purifyAuthors: function purifyAuthors(arr) {
      var result = [];

      var _iterator2 = _createForOfIteratorHelper(arr),
          _step2;

      try {
        var _loop2 = function _loop2() {
          var str = _step2.value;

          if (!result.some(function (i) {
            return i.id === str.id;
          })) {
            result.push(str);
          }
        };

        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          _loop2();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return result;
    },
    filterByCurrentUser: function filterByCurrentUser(ids) {
      var _this3 = this;

      var emojis = this.get('message.reaction');
      var result = [];
      ids.forEach(function (id) {
        for (var i = 0; i < emojis.length; i++) {
          if (emojis[i].id === id) {
            if (Number(emojis[i].user.id) === _this3.get('currentUser.pk')) {
              result.push(id);
            }

            break;
          }
        }
      });
      return result;
    },
    actions: {
      onDeleteEmoji: function onDeleteEmoji(data) {
        data.ids = this.filterByCurrentUser(data.ids);
        this.onDeleteEmoji(data, this.get('message').id);
      },
      onAddEmoji: function onAddEmoji(data) {
        this.onAddEmoji(data, this.get('message').id);
      },
      onSelectEmoji: function onSelectEmoji(emoji, messageId) {
        this.onSelectEmoji(emoji, messageId);
      }
    }
  });

  _exports.default = _default;
});