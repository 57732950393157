define("inboxfront/components/kb/add-articles-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    articlesNotInCategory: Ember.computed('category.articles.[]', 'selectedBase', 'selectedBase.articles.[]', function () {
      var arts = this.get('category.articles');
      return this.get('selectedBase.articles').filter(function (a) {
        return !arts.includes(a);
      });
    }),
    checkAll: Ember.computed('selectedArticles.length', 'articlesNotInCategory.[]', function () {
      return this.get('selectedArticles.length') === this.get('articlesNotInCategory.length');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedArticles', []);
      this.get('articlesNotInCategory').forEach(function (c) {
        Ember.set(c, 'selected', false);
      });
    },
    actions: {
      select: function select(article) {
        var selected = !article.selected;
        Ember.set(article, 'selected', selected);

        if (!selected) {
          this.get('selectedArticles').removeObject(article);
        } else {
          this.get('selectedArticles').pushObject(article);
        }
      },
      selectAll: function selectAll(checkAll) {
        var selectedArticles = [];
        this.get('articlesNotInCategory').forEach(function (c) {
          Ember.set(c, 'selected', checkAll);

          if (checkAll) {
            selectedArticles.pushObject(c);
          }
        });
        this.set('selectedArticles', selectedArticles);
      }
    }
  });

  _exports.default = _default;
});