define("inboxfront/helpers/industries-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INDUSTRIES_LIST = void 0;
  var INDUSTRIES_LIST = ["Education", "Energy", "Entertainment & Gaming", "Financial & Insurance Services", "Government & Non-profit", "Healthcare", "IT Services & Consultancy", "Manufacturing & Computer Hardware", "Media & Telecommunications", "Marketing & Advertising", "Professional & Business Support Services", "Real Estate", "Retail", "Social Media", "Software", "Travel, Hospitality, & Tourism", "Web Applications", "Web Hosting", "Other"];
  _exports.INDUSTRIES_LIST = INDUSTRIES_LIST;
  var _default = {
    INDUSTRIES_LIST: INDUSTRIES_LIST
  };
  _exports.default = _default;
});