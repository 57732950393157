define("inboxfront/routes/users/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var users = this.ajax.query(_query.default.PROPERTY_USERS);
      var departments = this.ajax.query(_query.default.DEPARTMENT);
      return Ember.RSVP.all([users, departments]).then(function (res) {
        return {
          users: !res[0].errors ? res[0] : [],
          properties: _this.get('sessionAccount.loggeduser.properties'),
          departments: !res[1].errors ? res[1] : []
        };
      });
    }
  });

  _exports.default = _default;
});