define("inboxfront/routes/chats", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    chats: Ember.inject.service(),
    emoji: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    intl: Ember.inject.service(),
    kb: Ember.inject.service(),
    textLoading: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('textLoading', this.get('intl').t('status.loading_msg'));
    },
    model: function model() {
      var _this = this;

      var threads = this.ajax.query(_query.default.THREADS);
      var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
      var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
      var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
      var reminders = this.ajax.query(_query.default.GET_MESSAGE_REMINDERS);
      this.chats.load();
      this.emoji.initEmojis();
      this.get('kb').getBases();
      return Ember.RSVP.all([threads, propertyUsers, settings, issueTypes, reminders]).then(function (res) {
        var issueTypes = res[3].filter(function (t) {
          return t.isActive;
        });
        var threads = !res[0].errors ? res[0] : {
          edges: []
        };
        threads.edges.forEach(function (e) {
          if (!e.node) {
            return;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'shopify_product_card') {
            var cards;

            try {
              cards = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              cards = [];
            }

            if (!Array.isArray(cards)) {
              cards = [];
            }

            e.node.lastMessage.body = cards[0] && cards[0].title || e.node.lastMessage.body;
          }

          if (e.node.lastMessage && e.node.lastMessage.subject === 'kb_article') {
            var data;

            try {
              data = JSON.parse(e.node.lastMessage.body);
            } catch (e) {
              data = {};
            }

            e.node.lastMessage.body = data.title;
          }
        });
        var remindersByRoom = {};
        var reminders = Array.isArray(res[4]) ? res[4] : [];
        reminders.forEach(function (reminder) {
          if (!remindersByRoom[reminder.message.room.id]) {
            remindersByRoom[reminder.message.room.id] = [];
          }

          remindersByRoom[reminder.message.room.id].addObject(reminder);
          var created = new Date(reminder.createdAt);
          var now = new Date();
          var delta = Math.round(now.getTime() / 1000) - Math.round(created.getTime() / 1000);
          reminder.expired = Math.abs(delta) > 3 * 24 * 3600;
        });
        return {
          threads: threads,
          propertyUsers: !res[1].errors ? res[1] : [],
          fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, res[2]),
          issueTypes: issueTypes,
          reminders: remindersByRoom,
          remindersById: reminders
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var currentUser = this.get('sessionAccount.loggeduser.username');
      var filterUsers = model.propertyUsers.filter(function (el) {
        return el.username !== currentUser;
      });
      controller.set('filterUsers', filterUsers);
      controller.set('threads', model.threads.edges);
      controller.updateThreadQueryTrigger();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.stopUpdateECommerceStoreInfo();
      }
    }
  });

  _exports.default = _default;
});