define("inboxfront/templates/components/common/buttons/primary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KwnK4O1C",
    "block": "{\"symbols\":[\"&default\",\"@disabled\",\"@iconButton\",\"@class\",\"@permission\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[23,5,[]]],null],[28,\"user-has-permission\",[[23,5,[]]],null]],null]],null,{\"statements\":[[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"disabled\",\"onClick\"],[[28,\"concat\",[\"primary-button \",[23,4,[]]],null],[28,\"or\",[[23,3,[]],false],null],[23,2,[]],[24,[\"onClick\"]]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/buttons/primary.hbs"
    }
  });

  _exports.default = _default;
});