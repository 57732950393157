define("inboxfront/templates/components/common/dialogs/discard-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vKlTH/Ly",
    "block": "{\"symbols\":[\"dialog\"],\"statements\":[[4,\"common/dialogs/warning\",null,[[\"open\",\"onConfirm\"],[[24,[\"open\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onConfirm\"]]],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `dialog.title` to be a contextual component but found a string. Did you mean `(component dialog.title)`? ('inboxfront/templates/components/common/dialogs/discard-changes.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"custom_fields.unsaved_changes\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `dialog.body` to be a contextual component but found a string. Did you mean `(component dialog.body)`? ('inboxfront/templates/components/common/dialogs/discard-changes.hbs' @ L10:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"eraser2\"],null],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[1,[28,\"t\",[\"custom_fields.unsaved_changes_warn\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"btn-cancel\"]],false],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"btn-confirm\"]],\"expected `dialog.btn-confirm` to be a contextual component but found a string. Did you mean `(component dialog.btn-confirm)`? ('inboxfront/templates/components/common/dialogs/discard-changes.hbs' @ L18:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"actions.discard\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/dialogs/discard-changes.hbs"
    }
  });

  _exports.default = _default;
});