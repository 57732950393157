define("inboxfront/templates/components/company-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rgKVLXdU",
    "block": "{\"symbols\":[\"@class\",\"@company\",\"@size\"],\"statements\":[[1,[28,\"ember-initials\",null,[[\"size\",\"name\",\"title\",\"alt\",\"seedText\",\"textColor\",\"fontSize\",\"fontWeight\",\"fontFamily\",\"image\",\"class\"],[[23,3,[]],[28,\"or\",[[23,2,[\"title\"]],[23,2,[\"name\"]]],null],[23,2,[\"title\"]],[23,2,[\"title\"]],[28,\"or\",[[23,2,[\"title\"]],[23,2,[\"name\"]]],null],\"white\",40,400,\"Helvetica Neue Light, Arial, sans-serif\",[28,\"url-company-logo\",[[23,2,[]]],null],[28,\"concat\",[\"mini-avatar \",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/company-logo.hbs"
    }
  });

  _exports.default = _default;
});