define("inboxfront/controllers/login", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    inputType: 'password',
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      function jwtDecode(token) {
        if (typeof token !== "string") {
          throw new Error("Invalid token specified");
        }

        try {
          return JSON.parse(base64_url_decode(token.split(".")[1]));
        } catch (e) {
          throw new Error("Invalid token specified: " + e.message);
        }
      }

      function base64_url_decode(str) {
        var output = str.replace(/-/g, "+").replace(/_/g, "/");

        switch (output.length % 4) {
          case 0:
            break;

          case 2:
            output += "==";
            break;

          case 3:
            output += "=";
            break;

          default:
            throw "Illegal base64url string!";
        }

        try {
          return b64DecodeUnicode(output);
        } catch (err) {
          return atob(output);
        }
      }

      function b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
          var code = p.charCodeAt(0).toString(16).toUpperCase();

          if (code.length < 2) {
            code = "0" + code;
          }

          return "%" + code;
        }));
      }

      var _this = this;

      var session = this.get('session');

      window.googleSignInCallback = function (response) {
        var hash = {
          token: response.credential
        };

        _this2.ajax.query(_mutation.default.GOOGLE_AUTH, 'googleJwtAuthentication', hash).then(function (res) {
          if (res.okay === 'true') {
            var responsePayload = jwtDecode(response.credential);
            var email = responsePayload.email;
            var password = response.credential;
            session.authenticate('authenticator:oauth2', email, password).catch(function (reason) {
              if (reason.responseJSON.error_description) {
                _this.get('paperToaster').show(_this2.intl.t('forms.login_failed'));
              }
            });
          }
        });
      };
    },
    actions: {
      authenticate: function authenticate() {
        var _this3 = this;

        var _this$getProperties = this.getProperties('email', 'password'),
            email = _this$getProperties.email,
            password = _this$getProperties.password;

        this.get('session').authenticate('authenticator:oauth2', email, password).catch(function (reason) {
          if (reason.responseJSON.error_description) {
            _this3.get('paperToaster').show(_this3.intl.t('forms.login_failed'));
          }
        });
      },
      togglePassword: function togglePassword() {
        if (this.inputType === "password") {
          this.set('inputType', 'text');
        } else {
          this.set('inputType', 'password');
        }
      }
    }
  });

  _exports.default = _default;
});