define("inboxfront/components/chat/create-redirect-chat", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    propertyId: null,
    agent: null,
    guest: null,
    guestpropertyId: null,
    firstName: '',
    lastName: '',
    formIsValid: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    actions: {
      updateUsers: function updateUsers() {
        var _this = this;

        var hash = {
          propertyId: this.get('propertyId'),
          application: 'LIVECHAT'
        };
        var guestPropertyId = this.get('guestPropertyId');

        if (guestPropertyId && guestPropertyId !== this.get('propertyId')) {
          this._deselectContact();

          this.set('filterContacts', []);
          this.set('showSearch', false);

          this._checkForm();
        }

        this.ajax.query(_query.default.HAVE_INBOX_ACCESS, 'haveInboxAccess', hash).then(function (res) {
          var currentUser = _this.get('sessionAccount.loggeduser');

          var users = res.errors ? [] : res;

          var agent = _this.get('agent');

          if (agent) {
            if (!users.findBy('id', agent.id)) {
              _this.set('agent', null);
            }
          } else {
            agent = users.findBy('id', currentUser.id);

            if (agent) {
              _this.set('agent', agent);
            }
          }

          _this.set('users', users);

          _this._checkForm();
        });
      },
      chooseAgent: function chooseAgent(user) {
        this.set('agent', user);

        this._checkForm();
      },
      searchContact: function searchContact(fieldId, value) {
        var _this2 = this;

        var propertyId = this.get('propertyId');
        this.set(fieldId, value);

        if (propertyId) {
          if (value && value.length > 2) {
            this.set('showSearch', true);
            var hash = {
              page: 'GUEST',
              q: value,
              properties: [propertyId]
            };
            this.ajax.query(_query.default.SEARCH_GUEST, 'search', hash).then(function (data) {
              _this2.set('filterContacts', data);

              _this2.set('guestPropertyId', propertyId);
            });
          } else {
            this.set('filterContacts', []);
            this.set('showSearch', false);
          }
        }

        this._checkForm();
      },
      selectContact: function selectContact(contact) {
        this.set('firstName', contact.firstName);
        this.set('lastName', contact.lastName);
        this.set('guest', contact);
        this.set('filterContacts', []);
        this.set('showSearch', false);

        this._checkForm();
      },
      deselectContact: function deselectContact() {
        this._deselectContact();
      },
      createChatLink: function createChatLink() {
        var _this3 = this;

        var hash = {
          agent: this.get('agent.id'),
          propertyId: this.get('propertyId')
        };

        if (this.get('guest')) {
          hash.guestId = this.get('guest.id');
        } else {
          hash.firstName = this.get('firstName');
          hash.lastName = this.get('lastName');
        }

        return this.ajax.mutation(_mutation.default.CHAT_REDIRECT, 'chatRedirect', hash, function (res) {
          if (res.link) {
            _this3.onAction(res.link);
          } else {
            _this3.get("paperToaster").show(_this3.intl.t('internal_error') + ' ' + _this3.intl.t('try_later'));
          }
        });
      },
      closeDialog: function closeDialog() {
        this.set('propertyId', null);
        this.set('agent', null);
        this.set('guest', null);
        this.set('guestPropertyId', null);
        this.set('firstName', '');
        this.set('lastName', '');
        this.onClose();
      }
    },
    _deselectContact: function _deselectContact() {
      this.set('guest', null);
      this.set('guestPropertyId', null);
    },
    _checkForm: function _checkForm() {
      var guestSelected = this.get('guest') || this.get('firstName') && this.get('lastName');
      this.set('formIsValid', this.get('propertyId') && this.get('agent') && guestSelected);
    }
  });

  _exports.default = _default;
});