define("inboxfront/components/tasks/merge-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    primaryTask: null,
    formIsValid: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('primaryTask', this.get('tasks.0'));
      this.set('allTasks', this.get('tasks').toArray());

      this._checkForm();
    },
    actions: {
      searchTask: function searchTask(value) {
        this.set('searchText', value);
      },
      removeTask: function removeTask(task) {
        if (task === this.get('primaryTask')) {
          return;
        }

        this.get('allTasks').removeObject(task);

        this._checkForm();
      },
      setPrimary: function setPrimary(task) {
        this.set('primaryTask', task);
      },
      mergeTasks: function mergeTasks() {
        var primaryTask = this.get('primaryTask');
        var secondaryTasks = this.get('allTasks').toArray();
        secondaryTasks.removeObject(primaryTask);
        this.onAction(primaryTask, secondaryTasks);
      }
    },
    _checkForm: function _checkForm() {
      this.set('formIsValid', this.get('allTasks.length') > 1);
    }
  });

  _exports.default = _default;
});