define("inboxfront/routes/escalations/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('escalations.index');
      } else {
        var escalation = this.ajax.query(_query.default.ESCALATION, 'escalation', {
          id: params.id
        });
        var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
        var departments = this.ajax.query(_query.default.DEPARTMENT);
        var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
        return Ember.RSVP.all([escalation, propertyUsers, departments, issueTypes]).then(function (res) {
          var departments = !res[2].errors ? res[2] : [];
          departments.forEach(function (d) {
            d.id = d.pk;
            d.title = d.displayName;
          });
          var users = !res[1].errors ? res[1] : [];
          users.forEach(function (u) {
            return u.fullName = u.firstName + ' ' + u.lastName;
          });
          users.unshift({
            pk: 0,
            fullName: _this.intl.t('assigned_agent')
          });
          var issueTypes = [];
          res[3].forEach(function (t) {
            if (t.isActive) {
              t.title = t.issueType;
              issueTypes.pushObject(t);
            }
          });
          return {
            users: users,
            departments: departments,
            properties: _this.get('sessionAccount.loggeduser.properties'),
            issueTypes: issueTypes,
            channelTypes: [{
              title: 'Facebook',
              id: 'facebook'
            }, {
              title: 'LINE',
              id: 'line'
            }, {
              title: 'WeChat',
              id: 'wechat'
            }, {
              title: 'Telegram',
              id: 'telegram'
            }, {
              title: 'Twitter',
              id: 'twitter'
            }, {
              title: 'WhatsApp',
              id: 'whatsapp'
            }],
            rules: [{
              title: _this.intl.t('Escalations.first_response_target'),
              id: 'frt'
            }, {
              title: _this.intl.t('Escalations.next_response_target'),
              id: 'nrt'
            }, {
              title: _this.intl.t('Escalations.resolution_target'),
              id: 'rt'
            }],
            escalation: res[0],
            edit: {
              name: res[0].name,
              description: res[0].description
            }
          };
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var escalation = controller.deserializeEscalation(model.escalation);
      controller.set('escalation', escalation);
      controller.set('originalEscalation', controller.copyEscalation(escalation));
      controller.set('escalationChanged', false);
    }
  });

  _exports.default = _default;
});