define("inboxfront/components/message-item", ["exports", "inboxfront/gql/mutation", "moment", "autolinker"], function (_exports, _mutation, _moment, _autolinker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    messageClass: Ember.computed('message', function () {
      return this.get('message').direction === "INBOUND" ? "message-recieved" : "message-sent";
    }),
    body: Ember.computed('message', function () {
      var autoLinker = new _autolinker.default();
      var messageBody = this.get('message.body');
      return autoLinker.link(messageBody);
    }),
    mediaExtension: Ember.computed('message', function () {
      return this.get('message.attachments.edges').length && this.get('message.attachments.edges')[0].node.extension;
    }),
    mediaPathName: Ember.computed('message', function () {
      return this.get('message.attachments.edges').length && this.get('message.attachments.edges')[0].node.datafile;
    }),
    mediaSize: Ember.computed('message', function () {
      var size = this.get('message.attachments.edges').length && this.get('message.attachments.edges')[0].node.size;
      var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var level = 0,
          bytes = parseInt(size, 10) || 0;

      while (bytes >= 1024 && ++level) {
        bytes = bytes / 1024;
      }

      return bytes.toFixed(bytes < 10 && level > 0 ? 1 : 0) + ' ' + units[level];
    }),
    isToday: Ember.computed('message', function () {
      var currentDate = new Date(this.get('message.created')).getDate();
      var now = new Date().getDate();
      return currentDate === now;
    }),
    currentDate: Ember.computed('message', function () {
      var date = new Date();
      var currentDate = new Date(this.get('message.created')).getDate();
      var today = date.getDate();
      var yesterday = date.setDate(date.getDate() - 1);

      if (currentDate === today) {
        return 'Today';
      } else if (currentDate === yesterday) {
        return 'Yesterday';
      } else {
        return (0, _moment.default)(this.get('message.created')).format('LL');
      }
    }),
    replyToBody: Ember.computed('message', function () {
      var message = this.get('message.inReplyTo');
      var ret = message && message.body || '';

      if (message && message.subject === 'shopify_product_card') {
        var cards;

        try {
          cards = JSON.parse(message.body);
        } catch (e) {
          cards = [];
        }

        if (!Array.isArray(cards)) {
          cards = [];
        }

        if (cards.length > 0) {
          ret = '';
          cards.forEach(function (c) {
            ret += c.title + ', ';
          });
          ret = ret.substring(0, ret.length - 2);
        }
      }

      return ret;
    }),
    actions: {
      triggerStarred: function triggerStarred(message) {
        var _this = this;

        var hash = {
          messageId: message.id,
          starred: !message.starred
        };
        this.ajax.query(_mutation.default.STARRED_MESSAGE, 'starredMessage', hash).then(function () {
          if (message.starred) {
            var selectedItem = _this.get('starredList').findBy('id', message.id);

            _this.get('starredList').removeObject(selectedItem);
          } else {
            _this.get('starredList').pushObject(message);
          }

          Ember.set(message, 'node.starredUser', !message.starredUser);
          Ember.set(message, 'node.starred', !message.starred);
        });
      },
      openReplyMessage: function openReplyMessage(thread) {
        this.set('showReplyInput', true);
        this.set('selectedMessage', thread);
      }
    }
  });

  _exports.default = _default;
});