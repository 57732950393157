define("inboxfront/controllers/settings", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedTimezone: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.timeFormats = [{
        id: 1,
        name: '24 hours'
      }, {
        id: 2,
        name: '12 hours'
      }];
      this.dateFormats = [{
        id: 1,
        name: 'DD-MM-YYYY'
      }, {
        id: 2,
        name: 'MM-DD-YYYY'
      }, {
        id: 3,
        name: 'MM-DD-YY'
      }];
      this.employeeCounts = [{
        value: "0 - 10",
        id: "0_10"
      }, {
        value: "11 - 50",
        id: "11_50"
      }, {
        value: "51 - 100",
        id: "51_100"
      }, {
        value: "101 - 500",
        id: "101_500"
      }, {
        value: "501 - 1000",
        id: "501_1000"
      }, {
        value: "1001+",
        id: "1001_MAX"
      }];
      this.industries = [{
        id: 1,
        name: "Education"
      }, {
        id: 2,
        name: "Energy"
      }, {
        id: 3,
        name: "Entertainment & Gaming"
      }, {
        id: 4,
        name: "Financial & Insurance Services"
      }, {
        id: 5,
        name: "Government & Non-profit"
      }, {
        id: 6,
        name: "Healthcare"
      }, {
        id: 7,
        name: "IT Services & Consultancy"
      }, {
        id: 8,
        name: "Manufacturing & Computer Hardware"
      }, {
        id: 9,
        name: "Media & Telecommunications"
      }, {
        id: 10,
        name: "Marketing & Advertising"
      }, {
        id: 11,
        name: "Professional & Business Support Services"
      }, {
        id: 12,
        name: "Real Estate"
      }, {
        id: 13,
        name: "Retail"
      }, {
        id: 14,
        name: "Social Media"
      }, {
        id: 15,
        name: "Software"
      }, {
        id: 16,
        name: "Travel, Hospitality, & Tourism"
      }, {
        id: 17,
        name: "Web Applications"
      }, {
        id: 18,
        name: "Web Hosting"
      }, {
        id: 19,
        name: "Other"
      }];
      this.selectedIndustry = this.industries[15];
    },
    actions: {
      saveSettings: function saveSettings() {
        var _this = this;

        var hash = {
          timezone: this.get('selectedTimezone').name,
          timeformat: this.get('selectedTimeFormat').name,
          dateformat: this.get('selectedDateFormat').name,
          locale: this.get('selectedLocale').name
        };

        if (this.get('selectedEmployeeCount')) {
          hash.employeeCount = this.get('selectedEmployeeCount').id;
        }

        this.ajax.query(_mutation.default.UPDATE_COMPANY_SETTINGS, 'updateCompanySettings', hash).then(function () {
          _this.get('paperToaster').show(_this.intl.t('settings_saved'));
        });
      }
    }
  });

  _exports.default = _default;
});