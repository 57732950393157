define("inboxfront/controllers/users/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query"], function (_exports, _mutation, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    showCreateDialog: false,
    inboxAccess: false,
    chatAccess: false,
    taskAccess: false,
    modalStep: null,
    showDeactivateUserDialog: false,
    showDeleteUserDialog: false,
    showResetPasswordDialog: false,
    selectedUser: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
      this.emailValidation = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          if (_this.withEmail) {
            var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return emailPattern.test(inputValue);
          }

          return true;
        }
      }, {
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          if (_this.withEmail) {
            return inputValue && inputValue.trim() !== '';
          }

          return true;
        }
      }];
      this.passwordValidation = [{
        message: this.intl.t('forms.minimum_password_length'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.length > 7;
        }
      }];
      this.systemRoles = [{
        id: 'admin',
        name: this.intl.t('user_role_admin')
      }, {
        id: 'owner',
        name: this.intl.t('user_role_owner')
      }, {
        id: 'member',
        name: this.intl.t('user_role_member')
      }];
    },
    actions: {
      resendInvite: function resendInvite(user) {
        var _this2 = this;

        this.ajax.mutation(_mutation.default.RESEND_INVITE, 'resendActivation', {
          userId: user.id
        }, function () {
          _this2.get('paperToaster').show(_this2.intl.t('invite_sent'));
        });
      },
      deleteUserDialog: function deleteUserDialog(user) {
        var users = [];
        this.get('model.users').forEach(function (u) {
          if (u.id !== user.id) {
            users.push({
              id: u.id,
              name: "".concat(u.firstName, " ").concat(u.lastName)
            });
          }
        });
        this.set('selectedUser', user);
        this.set('assigneeUser', null);
        this.set('assigneeUsers', users);
        this.set('showDeleteUserDialog', true);
      },
      deleteUser: function deleteUser() {
        var _this3 = this;

        var data = {
          userId: this.get('selectedUser.id')
        };

        if (this.get('assigneeUser')) {
          data.assignee = this.get('assigneeUser.id');
        }

        this.ajax.mutation(_mutation.default.DELETE_USER, 'deleteUser', data, function () {
          _this3.refreshRoute();

          _this3.set('showDeleteUserDialog', false);

          _this3.get('paperToaster').show(_this3.intl.t('user_deleted'));
        });
      },
      resetPasswordDialog: function resetPasswordDialog(user) {
        var _this4 = this;

        this.set('resetHeader', this.intl.t('actions.reset_password'));
        this.set('resetUser', user);
        this.set('resetLink', '');
        this.set('resetPasswordText', this.intl.t('status.loading'));
        this.set('showResetPasswordDialog', true);
        this.ajax.mutation(_mutation.default.RESET_PASSWORD, 'resetPassword', {
          userId: user.id
        }, function (result) {
          if (result.resetLink) {
            _this4.set('resetLink', result.resetLink);

            _this4.set('resetPasswordText', _this4.intl.t('reset_user_password', {
              link: result.resetLink,
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          } else {
            _this4.set('resetPasswordText', _this4.intl.t('reset_user_password_email', {
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));
          }
        }, function () {
          _this4.set('resetPasswordText', _this4.intl.t('internal_error'));
        });
      },
      deactivateUserDialog: function deactivateUserDialog(user) {
        this.set('selectedUser', user);
        this.set('showDeactivateUserDialog', true);
      },
      showDialog: function showDialog() {
        this.set('modalStep', 1);
        this.clearField();
        this.set('showCreateDialog', true);
      },
      closeDialog: function closeDialog() {
        this.set('showCreateDialog', false);
        this.set('modalStep', null);
        this.clearField();
      },
      nextStep: function nextStep() {
        var _this5 = this;

        this.ajax.query(_query.default.CUSTOM_ROLES).then(function (data) {
          return _this5.set('customRoles', !data.errors ? data : []);
        });
        this.set('modalStep', 2);
      },
      previousPage: function previousPage() {
        this.set('modalStep', 1);
      },
      createUser: function createUser() {
        var _this6 = this;

        var hash = {
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          department: this.get('userDep').id,
          propertiesId: [this.get('userProp').id],
          isAgent: this.get('isAgent'),
          systemGroup: this.get('systemRole').id
        };

        if (this.get('withEmail')) {
          hash.email = this.get('email');
        } else {
          hash.defaultPassword = this.generatePassword();
        }

        if (this.get('systemRole').id === 'member') {
          hash.hasInboxAccess = this.get('inboxAccess');
          hash.hasHelpdeskAccess = this.get('taskAccess');

          if (this.get('customRole')) {
            hash.customGroup = this.get('customRole').id;
          }
        } else {
          hash.hasInboxAccess = true;
          hash.hasHelpdeskAccess = true;
        }

        this.ajax.mutation(_mutation.default.CREATE_USER, 'createUser', hash, function (res) {
          _this6.set('showCreateDialog', false);

          _this6.set('modalStep', 1);

          _this6.refreshRoute();

          if (!hash.email) {
            var user = res.profile.user;

            _this6.set('resetHeader', _this6.intl.t('user_created'));

            _this6.set('resetUser', user);

            _this6.set('resetLink', user.resetPasswordLink);

            _this6.set('resetPasswordText', _this6.intl.t('new_user_without_email', {
              link: user.resetPasswordLink,
              user: "".concat(user.firstName, " ").concat(user.lastName)
            }));

            _this6.set('showResetPasswordDialog', true);
          }
        }, function () {
          _this6.get('paperToaster').show(_this6.intl.t('email_exists'));
        });
      },
      changeStatus: function changeStatus(user, status) {
        var _this7 = this;

        var hash = {
          userId: user.id,
          isActive: status
        };
        this.ajax.mutation(_mutation.default.UPDATE_USER_ADMIN, 'updateUserAdmin', hash, function () {
          if (status) {
            _this7.get('paperToaster').show(_this7.intl.t('user_activated'));
          } else {
            _this7.get('paperToaster').show(_this7.intl.t('user_deactivated'));
          }

          Ember.set(user, 'isActive', hash.isActive);

          _this7.set('showDeactivateUserDialog', false);
        });
      },
      toggleWithEmail: function toggleWithEmail() {
        this.set('withEmail', !this.withEmail);
      }
    },
    generatePassword: function generatePassword() {
      var chars = 'abcdefghijklmnopqrstuvwxyz'.split('');
      var res = [];
      res.push(chars[Math.floor(Math.random() * chars.length)].toUpperCase());

      for (var i = 0; i < 6; i++) {
        res.push(chars[Math.floor(Math.random() * chars.length)]);
      }

      res.push(Math.floor(Math.random() * 10));
      return res.join('');
    },
    clearField: function clearField() {
      this.set('firstName', '');
      this.set('lastName', '');
      this.set('withEmail', true);
      this.set('email', '');
      this.set('userDep', undefined);
      this.set('userProp', undefined);
      this.set('isAgent', true);
      this.set('inboxAccess', true);
      this.set('taskAccess', true);
      this.set('systemRole', this.systemRoles.find(function (item) {
        return item.id === 'member';
      }));
      this.set('customRole', undefined);
    },
    refreshRoute: function refreshRoute() {
      var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name));
      route.refresh();
    }
  });

  _exports.default = _default;
});