define("inboxfront/templates/components/dashboard/satisfaction-rating-combo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sop3bqM0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"satisfaction-rating\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/satisfaction-rating-combo.hbs"
    }
  });

  _exports.default = _default;
});