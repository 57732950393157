define("inboxfront/templates/components/chat/redirect-chat-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cwrl51ud",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"onClose\",\"origin\",\"clickOutsideToClose\",\"class\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null],[24,[\"dialogOrigin\"]],false,\"warning-dialog\"]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"            \"],[1,[28,\"svg-icon\",[\"alert-triangle\"],null],false],[0,\"\\n            \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"chat_link\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-dialog-content\",null,null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"chat_link_info\"],null],false],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[1,[22,\"link\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-dialog-actions\",null,null,{\"statements\":[[4,\"common/buttons/primary\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"            Ok\\n\"]],\"parameters\":[]},null],[4,\"common/buttons/secondary-2\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"copyLinkToClipboard\"],null]]],{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"actions.copy_link\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/chat/redirect-chat-link.hbs"
    }
  });

  _exports.default = _default;
});