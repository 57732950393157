define("inboxfront/components/kb/category-editor", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    kb: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: '',
    categoryType: Ember.computed('category.entityType', function () {
      return this.get('entityTypes').findBy('id', this.get('category.entityType'));
    }),
    categoryLogo: Ember.computed('category.image.datafile', function () {
      if (this.get('category.image.datafile')) {
        return _environment.default.mediaUrl + this.get('category.image.datafile');
      } else {
        return '/assets/images/upload_avatar.png';
      }
    }),
    uploadSuccess: Ember.computed(function () {
      var category = this.get('category');
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        Ember.set(category, 'image', result.data.file.attachment);
        category.validate();
      };
    }),
    options: Ember.computed('category.id', function () {
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        dictDefaultMessage: this.intl.t('upload_image'),
        clickable: '.avatar-upload',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "kb", "model": "category"}'
        }
      };
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('entityTypes', [{
        id: 'PUBLIC',
        title: 'Public (visible on public site)'
      }, {
        id: 'PRIVATE',
        title: 'Private (visible to Raincheck users)',
        disabled: true
      }]);
    },
    actions: {
      saveCategory: function saveCategory() {
        var _this = this;

        this.get('kb').checkSlug(this.get('category'), 'category', this.get('selectedBase')).then(function () {
          _this.get('kb').saveCategory(_this.get('category'));
        }).catch(function () {
          _this.set('isSlugError', true);
        });
      },
      checkSlug: function checkSlug() {
        var _this2 = this;

        this.get('kb').checkSlug(this.get('category'), 'category', this.get('selectedBase')).then(function () {
          _this2.set('isSlugError', false);
        }).catch(function (e) {
          _this2.set('isSlugError', true);
        });
      },
      changeSection: function changeSection(section) {
        if (this.get('category.id')) {
          this.onChangeCategorySection(section, this.get('category'));
        } else {
          this.get('category').setSection(section);
          this.get('category').validate();
        }
      },
      changeEntityType: function changeEntityType(type) {
        this.set('category.entityType', type.id);
        this.get('category').validate();
      },
      updateField: function updateField(name, value) {
        var category = this.get('category');

        if (name === 'title') {
          Ember.set(category, 'slug', this.get('kb').formatSlug(value));
        } else if (name === 'slug') {
          value = this.get('kb').formatSlug(value);
          this.set('isSlugError', false);
        }

        Ember.set(category, name, value);
        category.validate();
      },
      discardCategoryChanges: function discardCategoryChanges(goBack) {
        var _this3 = this;

        var isClear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.get('category.image.id')) {
          this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
            attachmentId: this.get('category.image.id')
          }).then(function () {
            if (isClear) {
              var category = _this3.get('category');

              Ember.set(category, 'image', {});
            }
          });
        }

        this.get('category').restore();
        this.set('isSlugError', false);
        goBack && this.onBack();
      },
      goBack: function goBack() {
        if (this.get('category.isUpdated')) {
          this.set('showDiscardChangesDialog', true);
        } else {
          this.onBack();
        }
      },
      deleteAttachment: function deleteAttachment() {
        var _this4 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('category.image.id')
        }).then(function () {
          var category = _this4.get('category');

          Ember.set(category, 'image', {});

          _this4.get('category').validate();
        });
      },
      onDragStart: function onDragStart(article, event) {
        article.categories.forEach(function (c) {
          return Ember.set(c, 'hilighted', true);
        });

        if (article.featured) {
          Ember.set(article.categories[0].section.base.Featured, 'hilighted', true);
        }

        event.dataTransfer.setData('articleId', article.id);
      },
      onDragEnd: function onDragEnd(article) {
        article.categories.forEach(function (c) {
          return Ember.set(c, 'hilighted', false);
        });

        if (article.featured) {
          Ember.set(article.categories[0].section.base.Featured, 'hilighted', false);
        }
      }
    }
  });

  _exports.default = _default;
});