define("inboxfront/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A3auOmjh",
    "block": "{\"symbols\":[\"@class\",\"@user\",\"@userAvatar\",\"@size\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"user\",\"username\"]],\"raincheck.bot\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"braincheck-avatar\"],[8],[1,[28,\"svg-icon\",[\"note2\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"ember-initials\",null,[[\"size\",\"name\",\"title\",\"alt\",\"seedText\",\"textColor\",\"fontSize\",\"fontWeight\",\"fontFamily\",\"image\",\"class\"],[[23,4,[]],[28,\"user-fullname\",[[23,2,[]]],null],[28,\"user-fullname\",[[23,2,[]]],null],[28,\"user-fullname\",[[23,2,[]]],null],[28,\"or\",[[23,2,[\"username\"]],[28,\"user-fullname\",[[23,2,[]]],null]],null],\"white\",40,400,\"Helvetica Neue Light, Arial, sans-serif\",[28,\"url-user-avatar\",[[28,\"or\",[[23,3,[]],[23,2,[]]],null]],null],[28,\"concat\",[\"user-avatar md-avatar \",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});