define("inboxfront/components/chat/ecommerce/dialog-refund-order", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    tagName: '',
    platform: '',
    provider: null,
    providers: null,
    refundData: '',
    maxRefund: Ember.computed('order', function () {
      var price = 0;
      (this.get('order.products') || []).forEach(function (p) {
        price += +p.totalPrice;
      });
      return price;
    }),
    providerRequired: Ember.computed('providers', function () {
      return !!this.get('providers');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('reason', '');
      this.set('restock', true);
      this.set('refundAmount', 0);
      this.get('order.products').forEach(function (p) {
        Ember.set(p, 'refundQuantity', 0);
      });
      this.set('formIsValid', false);
    },
    actions: {
      updateRefundAmount: function updateRefundAmount(value) {
        var maxRefund = this.get('maxRefund');

        if (value < 0) {
          value = 0;
        } else if (value > maxRefund) {
          value = maxRefund;
        }

        this.set('refundAmount', value);
        this.checkRefundForm();
      },
      updateRefundQuantity: function updateRefundQuantity(product, value) {
        if (value < 0) {
          value = 0;
        } else if (value > product.quantity) {
          value = product.quantity;
        }

        Ember.set(product, 'refundQuantity', +value);
        var refundAmount = 0;
        (this.get('order.products') || []).forEach(function (p) {
          refundAmount += +p.price * p.refundQuantity;
        });
        this.set('refundAmount', refundAmount);
        this.checkRefundForm();
      },
      setFullRefund: function setFullRefund() {
        (this.get('order.products') || []).forEach(function (p) {
          Ember.set(p, 'refundQuantity', p.quantity);
        });
        this.set('refundAmount', this.get('maxRefund'));
        this.set('formIsValid', true);
      },
      createRefund: function createRefund() {
        var _this = this;

        if (this.get('refundData')) {
          var _data = _objectSpread({}, this.get('refundData'));

          _data.providerId = this.get('provider.providerId');
          this.createRefund(_data);
          this.set('provider', null);
          this.set('providers', null);
          this.set('refundData', null);
          return;
        }

        var data = _objectSpread({}, this.get('order'));

        var items = [];
        data.products.forEach(function (p) {
          if (p.refundQuantity > 0) {
            if (_this.get('platform') === 'shopify') {
              items.addObject({
                positionId: p.possitionId,
                quantity: p.refundQuantity
              });
            } else if (_this.get('platform') === 'bigcommerce') {
              items.addObject({
                productId: p.productId,
                quantity: p.refundQuantity,
                refundAmount: +p.price * p.refundQuantity
              });
            }
          }
        });
        data.items = items;
        data.refundAmount = this.get('refundAmount');
        data.restockType = this.get('restock') ? 'return' : 'no_restock';
        data.reason = this.get('reason');

        if (this.get('platform') === 'bigcommerce' && this.get('maxRefund') - data.refundAmount > 0.001) {
          this.set('refundData', data);
          this.set('provider', null);
          this.set('providers', null);
          var hash = {
            items: items,
            orderId: data.id,
            propertyId: this.get('propertyId')
          };
          this.ajax.mutation(_mutation.default.GET_BIGCOMMERCE_PROVIDERS, 'getOrderProviders', hash, function (res) {
            if (res.status === 200) {
              _this.set('providers', res.providers);
            } else {
              console.error('getOrderProviders status=', res.status, 'DATA=', hash, 'RESULT=', res);

              _this.get('paperToaster').show(_this.intl.t('internal_error') + ' ' + _this.intl.t('try_later'));
            }

            _this.checkRefundForm();
          });
        } else {
          this.createRefund(data);
        }
      },
      selectProvider: function selectProvider(value) {
        this.set('provider', value);
        this.checkRefundForm();
      }
    },
    checkRefundForm: function checkRefundForm() {
      var valid = false;

      if (this.get('refundAmount') > 0) {
        valid = true;
      }

      if (this.get('providerRequired') && !this.get('provider.providerId')) {
        valid = false;
      }

      this.set('formIsValid', valid);
    }
  });

  _exports.default = _default;
});