define("inboxfront/components/helpdesk/task-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    tasks: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;

        if (sourceList === targetList) {
          return;
        }

        var task = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, task);
        Ember.set(task, 'status', targetList.status);
        Ember.set(task, '_parentList', targetList);
        this.onUpdateStatus(task);
      },
      checkTask: function checkTask(task) {
        this.onSelectTask(task);
      },
      selectWatcher: function selectWatcher(task) {
        this.onSelectWatcher(task);
      }
    }
  });

  _exports.default = _default;
});