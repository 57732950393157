define("inboxfront/components/helpdesk/priority-selector", ["exports", "inboxfront/components/tasks/id-selector"], function (_exports, _idSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _idSelector.default.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.options = [{
        id: "URGENT",
        title: this.intl.t('priorities.urgent')
      }, {
        id: "HIGH",
        title: this.intl.t('priorities.high')
      }, {
        id: "MEDIUM",
        title: this.intl.t('priorities.medium')
      }, {
        id: "LOW",
        title: this.intl.t('priorities.low')
      }];
    }
  });

  _exports.default = _default;
});