define("inboxfront/templates/components/chat-note-user-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fCV00WD",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"onclickOutside\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"apps-popup chat\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[1,[28,\"paper-input\",null,[[\"class\",\"placeholder\",\"value\",\"onChange\"],[\"search-input-chat\",[28,\"t\",[\"search_members\"],null],[24,[\"searchName\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"searchName\"]]],null]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"filterUsers\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"popup-list-item\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"chooseUser\",[23,1,[]]],null]],[8],[0,\"\\n                \"],[1,[28,\"user-avatar\",null,[[\"size\",\"user\"],[30,[23,1,[]]]]],false],[0,\"\\n                \"],[1,[28,\"user-fullname\",[[23,1,[]]],null],false],[0,\"\\n                \"],[7,\"span\",true],[11,\"class\",[29,[\"user-status-icon \",[23,1,[\"status\"]]]]],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/chat-note-user-popup.hbs"
    }
  });

  _exports.default = _default;
});