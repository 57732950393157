define("inboxfront/controllers/assistants/edit", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/config/environment", "jquery"], function (_exports, _mutation, _query, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    workflows: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    isActiveFlow: false,
    isShowComponent: false,
    isShowListComponents: false,
    isActiveStartBlockFlow: true,
    reRender: true,
    showPopupDeleteFlow: false,
    showPopupActivateFlow: false,
    showStatusSave: false,
    selectedFlowTab: 'settings',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.emailValidation = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }];
      this.set('uploadQuery', _mutation.default.UPLOAD_ATTACHMENT);
      this.checkConditions = [{
        id: 1,
        title: this.intl.t('matches_all_conditions')
      }, {
        id: 2,
        title: this.intl.t('matches_any_conditions')
      }];
      this.checkCondition = {
        id: 1,
        title: this.intl.t('matches_all_conditions')
      };
      this.ruleStruct = {
        conditions: {
          all: []
        },
        actions: []
      };
      this.rule = {};
      this.flowComponents = [{
        name: 'Message',
        type: 'message',
        icon: 'message-circle',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Options (Single select)',
        type: 'single',
        icon: 'options-single',
        data: null,
        blockType: 'TEXT'
      }, {
        name: 'Slider',
        type: 'slider',
        icon: 'slider',
        data: null,
        blockType: 'SLIDER'
      }, {
        name: 'Data Collector',
        type: 'data',
        icon: 'data-collector',
        data: null,
        blockType: 'FORM'
      }, {
        name: 'Action',
        type: 'action',
        icon: 'play',
        data: null,
        blockType: 'TEXT'
      }];
      this.commentModules = [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      }];
      this.chatModules = [{
        id: 'rooms',
        label: 'Teams'
      }, {
        id: 'users',
        label: 'Direct Messages'
      }];
      this.tagModules = [{
        id: 'tickets',
        label: 'Ticket/Task'
      }, {
        id: 'contacts',
        label: 'Contact'
      } // {id: 'external', label: 'External Conversation'},
      ];
      this.optionsEditor = {
        actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'olist', 'ulist']
      };
      this.actionsFlow = [{
        type: 'assign',
        label: 'Assign to'
      }, {
        type: 'tag',
        label: 'Tag the visitor'
      }, {
        type: 'contacts',
        label: 'Add visitor to Contacts'
      }, {
        type: 'close',
        label: 'Close the conversation'
      }];
      this.triggerOptions = {
        variables: ['Ticket/Task', 'Conversation']
      };
      this.someText = '{{..}}';
      this.subjVariables = ['Ticket Subject', 'Order ID', 'Ticket Issue Type', 'Ticket Source'];
      this.bodyVariables = ['Customer Name', 'Customer Email', 'Issue Type', 'Source', 'Assignee'];
      this.toVariables = ['Customer Email'];
      this.periodTimes = ['days', 'hours', 'minutes'];
      this.set('conditionRules', {
        'Ticket/Task': ['Status', 'Priority', 'Issue Type', 'Source', 'Department', 'Assignee', // 'Subject',
        'Attachment'],
        'Conversation': ['Channel/source', 'Assignee', 'Country', 'Refund', 'Cancel', 'Profile qualification']
      });
      this.conditionOperators = ['AND', 'OR'];
      setTimeout(function () {
        _this.dragCanvas();
      }, 3000);
    },
    firstBlock: Ember.computed('blocks.[]', function () {
      if (this.get('flow.type') === 1) {
        return this.get('blocks').findBy('parent', null);
      } else {
        return this.get('blocks').findBy('parent.id', null);
      }
    }),
    componentsFlow: Ember.computed('flow.{type,objectType}', 'flow', 'selectedComponent', function () {
      if (this.get('flow.type') === 1) {
        return this.get('flowComponents');
      }

      return this.get('workflows').getComponents(this.get('flow'), this.get('selectedComponent'));
    }),
    conditionModules: Ember.computed('flow.objectType', function () {
      if (this.get('flow.objectType') === 'CONVERSATION') {
        return ['Conversation'];
      } else {
        return ['Ticket/Task'];
      }
    }),
    isDisabledFlowActive: Ember.computed('blocks.[]', 'selectedComponent.{children.@each.attachments.0.filename}', 'selectedComponent.{children.@each.mainTitle}', 'selectedComponent.{children.@each.subtitle}', 'selectedComponent.{children.@each.name}', 'selectedComponent.{children.@each.redirectUrl}', 'selectedComponent.{mainTitle,checkAction}', 'flowName', 'flow.isValid', function () {
      if (this.get('flow.type') !== 1) {
        return !this.get('flow.isValid');
      }

      var blocks = this.get('blocks');
      var countActions = blocks.filter(function (c) {
        return c.name === 'Action';
      }).length;
      var countOptions = blocks.filter(function (c) {
        return c.name === 'Options (Single select)';
      }).length;
      var countOptionsChildren = blocks.filter(function (c) {
        return c.name === 'Options (Single select)';
      }).reduce(function (total, item) {
        return total + item.children.length;
      }, 0);
      var actions = blocks.filter(function (c) {
        return c.name === 'Action';
      }).map(function (a) {
        return typeof a.actions === 'string' ? JSON.parse(a.actions).actions[0] : a.actions[0];
      });
      var actionsRules = blocks.filter(function (c) {
        return c.name === 'Action';
      }).map(function (a) {
        return typeof a.actions === 'string' ? JSON.parse(a.actions) : {
          alwaysBtn: a.alwaysBtn,
          rules: a.rules
        };
      });
      return blocks.some(function (c) {
        return !c.mainTitle;
      }) || this.get('flowName') === '' || countOptionsChildren + (countOptions === 0 ? 0 : 1) - countOptions !== countActions || actions.filter(function (a) {
        return !a.checkAction;
      }).length || actionsRules.filter(function (c) {
        return !c.alwaysBtn && !c.rules;
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.attachments[0].filename;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.subtitle;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.name;
        });
      }).length || blocks.filter(function (c) {
        return c.blockType === 'SLIDER' && c.children.some(function (card) {
          return c.blockType === 'BUTTON' && !card.redirectUrl;
        });
      }).length;
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        this.removeAllFiles();
        var hash1 = {
          attachmentId: controller.get('cardAttachmentId')
        };
        var hash2 = {
          blockId: controller.get('cardId'),
          attachments: [result.data.file.attachment.id]
        };
        controller.get('cardAttachmentId') && controller.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', hash1);
        controller.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash2).then(function () {
          var parentId = controller.get('blocks').find(function (block) {
            return block.id === controller.get('cardId');
          }).parent.id;
          controller.updateFlow(parentId);
        });
      };
    }),
    optionsUpload: Ember.computed('blocks.[]', function () {
      var uploadQuery = this.get('uploadQuery');
      var variables = '{"appLabel": "livechat", "model": "flowblockmptt"}';
      var token = this.get("session").get("data.authenticated.access_token") || undefined;
      return {
        paramName: 'file',
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        clickable: '.icon-file-icon',
        acceptedFiles: "image/*",
        method: "POST",
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': "uploadAttachment",
          'query': uploadQuery,
          'variables': variables
        }
      };
    }),
    changeParamModule: function changeParamModule(flow) {
      var parent = flow.parent;
      var commentModule = this.get('commentModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(commentModule, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModule(parent);
        } else {
          Ember.set(commentModule, 'disabled', true);
        }
      }
    },
    changeParamModuleTags: function changeParamModuleTags(flow) {
      var parent = flow.parent;
      var tagModuleTicket = this.get('tagModules').findBy('id', 'tickets');
      var triggerRules = this.get('triggerRules');

      if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK' || triggerRules.findBy('selectedColumn', 'Ticket/Task'))) {
        Ember.set(tagModuleTicket, 'disabled', false);
      } else {
        if (parent) {
          this.changeParamModuleTags(parent);
        } else {
          Ember.set(tagModuleTicket, 'disabled', true);
        }
      }
    },
    resetCommentModule: function resetCommentModule(flow) {
      var triggerRules = this.get('triggerRules');

      if (flow.module && flow.module.id === 'tickets' && triggerRules.findBy('selectedColumn', 'Conversation')) {
        Ember.set(flow, 'module', {});
      }
    },

    /* changeParamModuleSelect(flow) {
    	const parent = flow.parent;
    
    	if (parent && (parent.blockType === 'TICKET' || parent.blockType === 'TASK')) {
    		const subjectsSelect = this.get('selectedComponent.subjectsSelect');
    		parent.subject && subjectsSelect && subjectsSelect.pushObject({ id: parent.id, title: parent.subject });
    		this.changeParamModuleSelect(parent);
    	} else {
    		if (parent) {
    			this.changeParamModuleSelect(parent);
    		}
    	}
    }, */
    actions: {
      backMainPage: function backMainPage() {
        this.transitionToRoute('assistants');
      },
      closeFlowFormSidebar: function closeFlowFormSidebar() {
        this.set('showPopupDeleteFlow', true);
      },
      openStartConfigure: function openStartConfigure() {
        this.triggerOpenStartConfigure();
      },
      togglePopupDeleteFlow: function togglePopupDeleteFlow() {
        this.set('showPopupDeleteFlow', !this.get('showPopupDeleteFlow'));
      },
      togglePopupActivateFlow: function togglePopupActivateFlow() {
        this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
      },
      activateFlow: function activateFlow() {
        var _this2 = this;

        if (this.get('flow.type') === 1) {
          var hash = {
            flowId: this.get('flowId'),
            isActive: !this.get('isActiveFlow')
          };
          this.set('isActiveFlow', !this.get('isActiveFlow'));
          this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
            var status = hash.isActive ? 'activate_flow' : 'deactivate_flow';

            _this2.paperToaster.show(_this2.intl.t(status, {
              name: _this2.get('flowName')
            }));
          });
        } else {
          var _hash = {
            isActive: !this.get('isActiveFlow')
          };
          this.set('isActiveFlow', !this.get('isActiveFlow'));
          this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          this.mutationWorkflow(_hash).then(function () {
            var status = _hash.isActive ? 'activate_workflow' : 'deactivate_workflow';

            _this2.set('flow.isActive', _hash.isActive);

            _this2.paperToaster.show(_this2.intl.t(status, {
              name: _this2.get('flowName')
            }));
          });
        }
      },
      deleteFlow: function deleteFlow() {
        var _this3 = this;

        this.ajax.query(_mutation.default.DELETE_FLOW, 'deleteFlow', {
          flowId: this.get('flowId')
        }).then(function () {
          _this3.transitionToRoute('assistants');

          _this3.paperToaster.show(_this3.intl.t('delete_flow', {
            name: _this3.get('flowName')
          }));
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(value) {
        var _this4 = this;

        if (this.get('isActiveFlow')) {
          if (this.get('flow.type') === 1) {
            var hash = {
              flowId: this.get('flowId'),
              isActive: !this.get('isActiveFlow')
            };
            this.set('isActiveFlow', value);
            this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
              var status = value ? 'activate_flow' : 'deactivate_flow';

              _this4.paperToaster.show(_this4.intl.t(status, {
                name: _this4.get('flowName')
              }));
            });
          } else {
            this.set('isActiveFlow', !this.get('isActiveFlow'));
            this.set('flow.isActive', this.get('isActiveFlow'));
            var _hash2 = {
              isActive: this.get('isActiveFlow')
            };
            this.mutationWorkflow(_hash2).then(function () {
              var status = value ? 'activate_flow' : 'deactivate_flow';

              _this4.paperToaster.show(_this4.intl.t(status, {
                name: _this4.get('flowName')
              }));
            });
          }
        } else {
          this.set('isActiveFlow', !value);
          this.set('flow.isActive', !value);
          this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
        }
      },
      openConfigure: function openConfigure(component) {
        //if (component.id === this.get('selectedComponent.id')) return;
        this.set('isShowComponent', true);
        this.openConfigureComponent(component.id);
      },
      deleteComponentAll: function deleteComponentAll(component, event) {
        var _this5 = this;

        if (this.get('flow.type') !== 1) {
          return this.get('workflows').deleteBlock(component, this.get('flow'), false, true);
        }

        event.stopPropagation();
        (0, _jquery.default)('.item-flow').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        document.querySelector('.canvas-block').scrollTo(0, 0);
        this.set('isLoadingComponent', true);
        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: component.id
        }).then(function () {
          _this5.updateFlow(component.parent && component.parent.id);
        });
      },
      deleteComponent: function deleteComponent(component) {
        var _this6 = this;

        if (this.get('flow.type') !== 1) {
          this.get('workflows').deleteBlock(component, this.get('flow'), function () {
            _this6.showStatus();

            _this6.triggerOpenStartConfigure();
          });
        }
      },
      chooseComponent: function chooseComponent(component) {
        var _this7 = this;

        var selectedComponent = this.get('selectedComponent');
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: '',
          blockType: component.blockType,
          name: component.name,
          icon: component.icon
        };
        this.set('isShowComponent', true);
        this.set('isShowListComponents', false);
        this.set('isActiveStartBlockFlow', false);
        this.set('isLoadingComponent', true);
        document.querySelector('.canvas-block').scroll(0, 0);

        if (this.get('flow.type') !== 1) {
          return this.get('workflows').addBlock(component, this.get('selectedComponent'), this.get('flow'), function (block) {
            _this7.showStatus();

            _this7.openConfigureComponent(block.id);
          });
        }

        if (component.type === 'data') {
          hash.extraData = JSON.stringify({
            dataCollectorsSelect: []
          });
        } else if (component.type === 'action') {
          var actions = {
            actions: [{
              tag_visitor: [],
              assign_to: null,
              close_conversation: false,
              checkAction: null
            }],
            actionsFlow: this.get('actionsFlow'),
            rules: [],
            alwaysBtn: false,
            checkCondition: {
              id: 1,
              title: this.intl.t('matches_all_conditions')
            }
          };
          hash.actions = JSON.stringify(actions);
        }

        if (selectedComponent && this.get('blocks').length) {
          hash.prevBlockId = selectedComponent.id;
        }

        if (selectedComponent && selectedComponent.children.length) {
          if (selectedComponent.parent && selectedComponent.blockType !== 'SLIDER' && component.type !== 'single' && component.type !== 'BUTTON') {
            hash.nextBlockId = selectedComponent.children[0].id;
          } else if (!selectedComponent.parent && !selectedComponent.id && this.get('blocks').length) {
            hash.nextBlockId = this.get('blocks').findBy('parent', null).id;
          } else if (selectedComponent.blockType === 'SLIDER' && selectedComponent.children.filter(function (child) {
            return child.blockType !== 'BUTTON';
          })[0]) {
            hash.nextBlockId = selectedComponent.children.filter(function (child) {
              return child.blockType !== 'BUTTON';
            })[0].id;
          }
        }

        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function (_ref) {
          var block = _ref.block;

          if (component.type === 'slider') {
            _this7.set('reRender', false);

            _this7.updateCardsComponent(block.id);
          } else if (component.type === 'single') {
            for (var i = 0; i < 2; i++) {
              var childrenId = null;

              if (i === 0) {
                if (selectedComponent && selectedComponent.children.length && selectedComponent.blockType !== 'SLIDER') {
                  childrenId = selectedComponent.children[0].id;
                } else if (_this7.get('isActiveStartBlockFlow') && _this7.get('blocks').length) {
                  childrenId = _this7.get('blocks')[0].id;
                }
              }

              _this7.updateOptionsComponent(block.id, childrenId, i === 1);
            }
          } else {
            _this7.updateFlow(block.id);
          }
        });
      },
      openListComponents: function openListComponents() {
        var component = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        event.stopPropagation();

        if (component) {
          this.set('selectedComponent', component);
        } else {
          var blocks = this.get('blocks');

          if (this.get('flow.type') === 1) {
            this.set('selectedComponent', {
              children: blocks[0] ? [{
                id: blocks[0].id
              }] : [],
              parent: null
            });
          } else {
            this.set('selectedComponent', this.get('flow.triggerBlock'));
          }
        }

        this.set('selectedFlowTab', 'configure');
        this.set('isShowListComponents', true);
        this.set('isActiveStartBlockFlow', false);
        this.set('isShowComponent', false);
        (0, _jquery.default)('.item-flow, .item-flow-wrap').removeClass('active');
        (0, _jquery.default)('.add-component-flow').removeClass('active');
        (0, _jquery.default)(event.target).closest('.add-component-flow').addClass('active');
      },
      addCard: function addCard() {
        this.set('reRender', false);
        this.updateCardsComponent(this.get('selectedComponent.id'));
      },
      removeCard: function removeCard(card) {
        var _this8 = this;

        this.set('reRender', false);
        var parentFlow = this.get('blocks').findBy('id', card.parent.id);
        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: card.id
        }).then(function () {
          _this8.mutationFlow();

          _this8.updateFlow(parentFlow.id);
        });
      },
      addOption: function addOption() {
        this.updateOptionsComponent(this.get('selectedComponent.id'));
      },
      removeOption: function removeOption(option) {
        var _this9 = this;

        this.ajax.query(_mutation.default.DELETE_FLOW_BLOCK, 'deleteFlowBlock', {
          blockId: option.id
        }).then(function () {
          _this9.mutationFlow();

          _this9.updateFlow(option.parent.id);
        });
      },
      addRule: function addRule() {
        this.get('selectedComponent.rules').pushObject({});
      },
      removeRule: function removeRule(rule) {
        this.get('selectedComponent.rules').removeObject(rule);
        this.updateActionsComponent();
      },
      addAction: function addAction() {
        this.get('selectedComponent.actions').pushObject({});
      },
      removeAction: function removeAction(action) {
        this.get('selectedComponent.actions').removeObject(action);
        this.updateSelectActions(null, action.checkAction);
        this.updateActionsComponent();
      },
      onSelectField: function onSelectField(field) {
        var _this10 = this;

        var dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        dataCollectorsSelect.pushObject(field);
        this.get('dataCollectorsSelect').removeObject(field);
        var hash = {
          flowId: this.get('flowId'),
          blockId: this.get('selectedComponent.id'),
          extraData: JSON.stringify({
            dataCollectorsSelect: dataCollectorsSelect
          })
        };
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
          _this10.updateFlow(_this10.get('selectedComponent.id'));
        });
      },
      removeField: function removeField(field) {
        var _this11 = this;

        var dataCollectorsSelect = this.get('selectedComponent.dataCollectorsSelect');
        dataCollectorsSelect.removeObject(field);
        this.get('dataCollectorsSelect').pushObject(field);
        var hash = {
          flowId: this.get('flowId'),
          blockId: this.get('selectedComponent.id'),
          extraData: JSON.stringify({
            dataCollectorsSelect: dataCollectorsSelect
          })
        };
        this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
          _this11.updateFlow(_this11.get('selectedComponent.id'));
        });
      },
      addCardId: function addCardId(card) {
        this.set('cardId', card.id);
        card.attachments[0] && this.set('cardAttachmentId', card.attachments[0].id);
      },
      addTag: function addTag(tag, index) {
        var action = this.get('selectedComponent.actions')[index];
        action.tag_visitor.pushObject(tag);
        this.updateActionsComponent();
      },
      removeTagAtIndex: function removeTagAtIndex(tagIndex, index) {
        var action = this.get('selectedComponent.actions')[index];
        action.tag_visitor.removeAt(tagIndex);
        this.updateActionsComponent();
      },
      onAddRule: function onAddRule() {
        this.get('rulesConditions').pushObject({});
      },
      onRemoveRule: function onRemoveRule(rule) {
        this.get('rulesConditions').removeObject(rule);
      },
      onSelectUser: function onSelectUser(index, value) {
        var action = this.get('selectedComponent.actions')[index];
        Ember.set(action, 'assign_to', value);
        this.updateActionsComponent();
      },
      selectActionCondition: function selectActionCondition(val) {
        this.set('checkCondition', val);
        this.saveConditionsMutation();
      },
      selectColumnRule: function selectColumnRule(index, rule) {
        var ruleOptions = this.get('ruleOptions.variable_type_operators')[rule.field_type];
        var rulesConditions = this.get('rulesConditions')[index];
        Ember.set(rulesConditions, 'selectedColumn', rule);
        Ember.set(rulesConditions, 'ruleOptions', ruleOptions);
        this.saveConditionsMutation();
      },
      selectConditionRule: function selectConditionRule(index, rule) {
        var rulesConditions = this.get('rulesConditions')[index];
        Ember.set(rulesConditions, 'selectedCondition', rule);
        this.saveConditionsMutation();
      },
      selectVariablesActions: function selectVariablesActions(index, rule) {
        var ruleOptions = this.get('ruleOptions.variable_type_operators')[rule.field_type];
        var rulesConditions = this.get('selectedComponent.rules')[index];
        Ember.set(rulesConditions, 'selectedColumn', rule);
        Ember.set(rulesConditions, 'ruleOptions', ruleOptions);
        this.saveConditionsMutation();
      },
      checkActionComponent: function checkActionComponent(index, val) {
        var action = this.get('selectedComponent.actions')[index];
        this.updateSelectActions(val, action.checkAction);
        Ember.set(action, 'checkAction', val);
        this.updateActionsComponent();
      },
      actionCloseStatus: function actionCloseStatus(index, value) {
        var action = this.get('selectedComponent.actions')[index];
        Ember.set(action, 'close_conversation', value);
        this.updateActionsComponent();
      },
      onBlurInputFlow: function onBlurInputFlow(name, val) {
        var hash = _defineProperty({}, name, val);

        if (val && val.length) {
          if (this.get('flow.type') === 1) {
            this.mutationFlow(hash);
          } else {
            this.mutationWorkflow(hash);
          }
        }
      },
      onBlurInputMessage: function onBlurInputMessage(flowBlockId, mainTitle) {
        var _this12 = this;

        var block = this.get('blocks').findBy('id', flowBlockId);
        var hash = {
          flowId: this.get('flowId'),
          mainTitle: mainTitle,
          blockId: flowBlockId
        };
        Ember.set(block, 'mainTitle', mainTitle);

        if (this.get('flow.type') === 1) {
          this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
            _this12.showStatus();

            _this12.mutationFlow();
          });
        } else {
          if (block.blockType === 'IFELSE') {
            Ember.set(block, 'description', mainTitle);
          }

          this.saveBlock(block);
        }
      },
      onBlurInputSlider: function onBlurInputSlider(name, val, blockId) {
        var _hash4;

        var hash = (_hash4 = {}, _defineProperty(_hash4, name, val), _defineProperty(_hash4, "flowId", this.get('flowId')), _defineProperty(_hash4, "blockId", blockId), _hash4);

        if (val && val.length) {
          this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash);
        }
      },
      onBlurRulesAction: function onBlurRulesAction() {
        this.updateActionsComponent();
      },
      toggleAlwaysBtn: function toggleAlwaysBtn() {
        var selectedComponent = this.get('selectedComponent');
        Ember.set(selectedComponent, 'alwaysBtn', !selectedComponent.alwaysBtn);
        this.updateActionsComponent();
      },
      saveConditions: function saveConditions() {
        this.saveConditionsMutation();
      },
      showSaveStatus: function showSaveStatus() {
        this.showStatus();
      },
      selectBranch: function selectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', true);

        if (index === 0) {
          Ember.set(component.children[1], 'active', false);
        } else {
          Ember.set(component.children[0], 'active', false);
        }

        if (input === true) {
          this.set('inputFocused', true);
        }
      },
      deselectBranch: function deselectBranch(component, index, input) {
        if (input !== true && this.get('inputFocused')) {
          return;
        }

        Ember.set(component.children[index], 'active', false);

        if (input === true) {
          this.set('inputFocused', false);
        }
      },
      updateBranchName: function updateBranchName(component, index, value) {
        Ember.set(component.children[index], 'name', value);
        this.saveBlock(component);
      },
      addIfCondition: function addIfCondition(component, module, operator) {
        if (!component.ifConditions) {
          Ember.set(component, 'ifConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.ifConditions.length > 0) {
            Ember.set(component.ifConditions[component.ifConditions.length - 1], 'operator', 'AND');
          }
        }

        component.ifConditions.addObject(cond);
        Ember.set(component.children[0], 'active', false);
      },
      addThenCondition: function addThenCondition(component, module, operator) {
        if (!component.thenConditions) {
          Ember.set(component, 'thenConditions', []);
        }

        var cond = {};

        if (module) {
          cond.selectedModule = module;
        }

        if (typeof operator === 'string') {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', operator);
          }
        } else {
          if (component.thenConditions.length > 0) {
            Ember.set(component.thenConditions[component.thenConditions.length - 1], 'operator', 'AND');
          }
        }

        component.thenConditions.addObject(cond);
        Ember.set(component.children[1], 'active', false);
      },
      onRemoveIfCondition: function onRemoveIfCondition(cond, component) {
        component.ifConditions.removeObject(cond);

        if (component.ifConditions.length === 1) {
          Ember.set(component.ifConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onRemoveThenCondition: function onRemoveThenCondition(cond, component) {
        component.thenConditions.removeObject(cond);

        if (component.thenConditions.length === 1) {
          Ember.set(component.thenConditions[0], 'operator', '');
        }

        this.saveIfElseConditions(component);
      },
      onConditionOperator: function onConditionOperator(component, cond, value) {
        Ember.set(cond, 'operator', value);
        this.saveIfElseConditions(component);
      },
      selectIfThenConditionModule: function selectIfThenConditionModule(cond, value) {
        if (cond.selectedModule !== value) {
          Ember.set(cond, 'selectedRule', '');
          Ember.set(cond, 'selectedOption', '');
        }

        Ember.set(cond, 'selectedModule', value);
      },
      selectIfThenConditionRule: function selectIfThenConditionRule(cond, value) {
        if (cond.selectedRule !== value) {
          Ember.set(cond, 'selectedOption', '');
        }

        Ember.set(cond, 'selectedRule', value);
      },
      selectIfThenConditionOption: function selectIfThenConditionOption(component, cond, value) {
        Ember.set(cond, 'selectedOption', value);
        this.saveIfElseConditions(component);
      },
      showPreviewEmailDialog: function showPreviewEmailDialog() {
        this.get('recipientEmail') || this.set('recipientEmail', this.get('sessionAccount.loggeduser.email'));
        this.get('recipientAccount') || this.set('recipientAccount', this.get('selectedComponent.sender'));
        this.set('showPreviewDialog', true);
      },
      sendEmailPreview: function sendEmailPreview() {
        var _this13 = this;

        var workflowsService = this.get('workflows');
        var hash = {
          accountId: this.get('recipientAccount.pk'),
          to: this.get('recipientEmail')
        };
        var body = this.get('selectedComponent.body');
        var subject = this.get('selectedComponent.subject');
        hash.body = workflowsService.clearVariables(body);
        hash.subject = workflowsService.clearVariables(subject);
        this.ajax.mutation(_mutation.default.SEND_TEST_EMAIL, 'sendTestEmail', hash, function () {
          _this13.paperToaster.show('Email sent, check your mailbox');
        });
      },
      selectTriggerColumn: function selectTriggerColumn(rule, value) {
        var options = [];

        switch (value) {
          case 'Ticket/Task':
            options = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            options = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (rule.selecteColumn !== value) {
          Ember.set(rule, 'selectedColumn', value);
          Ember.set(rule, 'selectedCondition', '');
          Ember.set(rule, 'ruleOptions', options);
        }
      },
      selectTriggerConditionRule: function selectTriggerConditionRule(rule, value) {
        Ember.set(rule, 'selectedCondition', value);

        if (value !== 'Is idled') {
          this.saveTriggerMutation();
        }
      },
      selectTriggerPeriodTimes: function selectTriggerPeriodTimes(rule, value) {
        Ember.set(rule, 'idledPeriodTimes', value);
        this.saveTriggerMutation();
      },
      saveTrigger: function saveTrigger() {
        this.saveTriggerMutation();
      },
      onAddTriggerRule: function onAddTriggerRule(module, operator) {
        var variables = this.get('triggerOptions.variables');
        var selectedColumn = typeof module === 'string' ? module : '';
        var ruleOptions = [];
        var rules = this.get('triggerRules');

        if (this.get('triggerRules.length') > 0) {
          var firstRuleColumn = this.get('triggerRules.0.selectedColumn');
          variables = [firstRuleColumn];
          selectedColumn = firstRuleColumn;
          ruleOptions = this.get('triggerRules.0.ruleOptions');
          this.set('triggerRules.0.variables', variables);
        }

        switch (selectedColumn) {
          case 'Ticket/Task':
            ruleOptions = ['Is created', 'Is updated', 'Is deleted', 'Is idled'];
            break;

          case 'Conversation':
            ruleOptions = ['New incoming', 'Is idled', 'Visitor has responded'];
        }

        if (typeof operator === 'string') {
          Ember.set(rules[rules.length - 1], 'operator', operator);
        }

        rules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: '',
          idledPeriod: 0,
          idledPeriodTimes: 'minutes',
          variables: variables,
          operator: '',
          ruleOptions: ruleOptions
        });
      },
      onRemoveTriggerRule: function onRemoveTriggerRule(rule) {
        var rules = this.get('triggerRules');
        rules.removeObject(rule);

        if (rules.length === 1) {
          this.set('triggerRules.0.variables', this.get('triggerOptions.variables'));
        }

        if (rules.length > 0) {
          Ember.set(rules[rules.length - 1], 'operator', '');
        }

        this.saveTriggerMutation();
      },
      saveCursorPosition: function saveCursorPosition() {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var start = input.prop('selectionStart');
        var end = input.prop('selectionEnd');

        if (start || start == '0') {
          this.set('cursor', {
            start: start,
            end: end
          });
        } else {
          this.set('cursor', {
            start: -1,
            end: -1
          });
        }
      },
      addVariable: function addVariable(variable) {
        var input = (0, _jquery.default)('.email-subj-input > input');
        var cursor = this.get('cursor');

        if (!cursor || cursor.start < 0) {
          input.val(input.val() + '{{' + variable + '}}');
        } else {
          var value = input.val();
          var before = value.substring(0, cursor.start);
          var after = value.substring(cursor.end);
          input.val(before + ' {{' + variable + '}} ' + after);
        }

        this.set('selectedComponent.subject', input.val());
        this.set('selectedComponent.mainTitle', input.val());
        input.focus();
        this.saveBlock(this.get('selectedComponent'));
      },
      addBodyVariable: function addBodyVariable(variable) {
        this.set('selectedComponent.body', this.get('selectedComponent.body') + '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      addToVariable: function addToVariable(variable) {
        this.set('selectedComponent.to', '{{' + variable + '}}');
        this.saveBlock(this.get('selectedComponent'));
      },
      updateEmailParams: function updateEmailParams(component, name, value) {
        if (name === 'subject') {
          var parts = value.split('{{');
          var subjVariables = this.get('subjVariables');
          parts.forEach(function (p, i) {
            if (p.indexOf('}}') === p.length - 2) {
              if (subjVariables.indexOf(p.substring(0, p.length - 2)) < 0) {
                parts[i] = '';
              }
            } else {
              if (p.indexOf('{') >= 0) {
                var tmp = p.split('{');
                parts[i] = tmp.join('');
              }

              if (parts[i].indexOf('}') >= 0) {
                var _tmp = parts[i].split('}');

                parts[i] = _tmp.join('');
              }
            }
          });
          Ember.set(component, 'mainTitle', value);
        }

        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTargetChatParams: function updateTargetChatParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'description') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'users' || name === 'rooms') {
          Ember.set(component, 'recipients', value);
        }

        if (name === 'model') {
          var modelValue = value.id === 'users' ? this.get('selectedComponent.users') : this.get('selectedComponent.rooms');
          Ember.set(component, 'recipients', modelValue);
        }

        this.saveBlock(component);
      },
      updateAddCommentParams: function updateAddCommentParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'comment') {
          Ember.set(component, 'mainTitle', value);
        }

        this.saveBlock(component);
      },
      updateTicketParams: function updateTicketParams(component, name, value) {
        Ember.set(component, name, value);

        if (name === 'subject') {
          Ember.set(component, 'mainTitle', value);
        }

        if (name === 'department') {
          this.updateDepartmentUsers(value.id);
        }

        this.saveBlock(component);
      },
      removeTagItem: function removeTagItem(component, tag) {
        if (this.get('flow.isActive')) {
          return;
        }

        component.tags && component.tags.removeObject(tag);
        this.saveBlock(component);
      },
      addTagToTicket: function addTagToTicket(component) {
        var tag = this.get('newTag');

        if (tag) {
          if (!component.tags) {
            Ember.set(component, 'tags', []);
          }

          component.tags.addObject(tag);
          this.saveBlock(component);
        }
      },
      addTagToBlock: function addTagToBlock(component, tag) {
        if (component.tag_list !== undefined) {
          component.tag_list.pushObject(tag);
        } else {
          Ember.set(component, 'tag_list', []);
          component.tag_list.pushObject(tag);
        }

        this.saveBlock(component);
      },
      removeTag: function removeTag(component, tagIndex) {
        component.tag_list.removeAt(tagIndex);
        this.saveBlock(component);
      },
      updateContactParams: function updateContactParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateTagParams: function updateTagParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateStatusParams: function updateStatusParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      },
      updateDueDateParams: function updateDueDateParams(component, name, value) {
        Ember.set(component, name, value);
        this.saveBlock(component);
      }
    },
    saveIfElseConditions: function saveIfElseConditions(component) {
      this.saveBlock(component);
    },
    updateDepartmentUsers: function updateDepartmentUsers(departmentId) {
      var _this14 = this;

      var propertyId = this.get('flow.properties.0.id');

      if (!propertyId || !departmentId) {
        return;
      }

      var hash = {
        department: departmentId,
        propertyId: propertyId
      };
      this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
        _this14.set('departmentUsers', result);
      });
    },
    saveBlock: function saveBlock(block) {
      var _this15 = this;

      if (this.get('saveTimer')) clearTimeout(this.get('saveTimer'));
      this.set('saveTimer', setTimeout(function () {
        _this15.get('workflows').saveBlock(block, _this15.get('flow'), function () {
          _this15.showStatus();
        });
      }), 1000);
    },
    updateSelectActions: function updateSelectActions(action, checkAction) {
      var actionsFlow = this.get('selectedComponent.actionsFlow');
      actionsFlow.removeObject(action);
      checkAction && actionsFlow.addObject(checkAction);
    },
    saveTriggerMutation: function saveTriggerMutation() {
      var triggerRules = this.get('triggerRules');
      var conditions = {};
      var any = [];
      var all = [];
      var objectType;
      triggerRules.forEach(function (r, index) {
        var rule = {};

        if (r.selectedColumn === 'Ticket/Task') {
          objectType = 'TICKET';
          rule.name = 'age';

          switch (r.selectedCondition) {
            case 'Is created':
              rule.operator = 'equal_to';
              rule.value = 'created';
              break;

            case 'Is updated':
              rule.operator = 'equal_to';
              rule.value = 'updated';
              break;

            case 'Is deleted':
              rule.operator = 'equal_to';
              rule.value = 'deleted';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
          }
        } else if (r.selectedColumn === 'Conversation') {
          objectType = 'CONVERSATION';

          switch (r.selectedCondition) {
            case 'New incoming':
              rule.name = 'is_new_incomming';
              rule.operator = 'is_true';
              break;

            case 'Visitor has responded':
              rule.name = 'visitor_has_responded';
              rule.operator = 'is_true';
              break;

            case 'Is idled':
              rule.name = 'idled';
              rule.operator = 'greater_than';
              rule.value = Math.floor(r.idledPeriod) * (r.idledPeriodTimes === 'days' ? 3600 : r.idledPeriodTimes === 'hours' ? 60 : 1);
              rule.module = 'Conversation';
          }
        }

        if (triggerRules[index - 1]) {
          if (triggerRules[index - 1].operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        } else {
          if (r.operator === 'AND') {
            all.addObject(rule);
          } else {
            any.addObject(rule);
          }
        }
      });

      if (any.length) {
        conditions.any = any;
      }

      if (all.length) {
        conditions.all = all;
      }

      Ember.set(this.get('flow.trigger'), 'conditions', conditions);
      this.get('workflows').updateTrigger(this.get('flow'), this.get('flow.objectType') !== objectType ? objectType : '');
    },
    saveConditionsMutation: function saveConditionsMutation() {
      var _this16 = this;

      var flowId = this.get('flowId');
      var ruleId = this.get('ruleId');
      var rules = this.get('rulesConditions');
      var checkCondition = this.get('checkCondition');
      var ruleStruct = this.get('ruleStruct');
      var convertedRules = [];
      rules.forEach(function (item) {
        convertedRules.push({
          name: item.selectedColumn ? item.selectedColumn.name : '',
          operator: item.selectedCondition ? item.selectedCondition.name : '',
          value: parseFloat(item.selectedConditionValue) || item.selectedConditionValue || ''
        });
      });
      ruleStruct.conditions.all = convertedRules;
      ruleStruct.actions.push({
        name: 'send_chat_flow',
        params: {
          flow_id: Number(flowId)
        }
      });
      ruleStruct.checkCondition = checkCondition;
      var hashRule = {
        name: this.get('flowName'),
        description: '',
        isActive: this.get('isActiveFlow'),
        rule: JSON.stringify(ruleStruct),
        flowId: flowId
      };

      if (ruleId) {
        hashRule.ruleId = ruleId;
      }

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_RULE, 'createOrUpdateRule', hashRule, function (data) {
        _this16.set('ruleId', data.rule.id);
      });
    },
    updateActionsComponent: function updateActionsComponent() {
      var _this17 = this;

      var selectedActions = this.get('selectedComponent.actions');
      var actionsFlow = this.get('selectedComponent.actionsFlow');
      var hash = {
        flowId: this.get('flowId'),
        blockId: this.get('selectedComponent.id')
      };
      var rules = this.get('selectedComponent.rules');
      var checkCondition = this.get('selectedComponent.checkCondition');
      var conditions = [];
      rules && rules.forEach(function (item) {
        conditions.push({
          name: item.selectedColumn ? item.selectedColumn.name : '',
          operator: item.selectedCondition ? item.selectedCondition.name : '',
          value: isNaN(Number(item.selectedConditionValue)) ? item.selectedConditionValue : Number(item.selectedConditionValue)
        });
      });
      var action = {
        rules: conditions,
        checkCondition: checkCondition,
        actions: selectedActions,
        actionsFlow: actionsFlow,
        alwaysBtn: this.get('selectedComponent.alwaysBtn')
      };
      hash.actions = JSON.stringify(action);
      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hash).then(function () {
        _this17.mutationFlow();

        _this17.updateFlow(_this17.get('selectedComponent.id'));
      });
    },
    updateCardsComponent: function updateCardsComponent(prevBlockId) {
      var _this18 = this;

      var hashSlider = {
        flowId: this.get('flowId'),
        blockType: 'BUTTON',
        buttonType: 'URL',
        prevBlockId: prevBlockId
      };
      this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashSlider).then(function () {
        _this18.mutationFlow();

        _this18.updateFlow(prevBlockId);
      });
    },
    updateOptionsComponent: function updateOptionsComponent(prevBlockId) {
      var _this19 = this;

      var childrenId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var isUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var hashOption = {
        flowId: this.get('flowId'),
        blockType: 'BUTTON',
        buttonType: 'NEXT',
        prevBlockId: prevBlockId
      };

      if (childrenId) {
        hashOption.nextBlockId = childrenId;
      }

      this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW_BLOCK, 'createOrUpdateFlowBlock', hashOption).then(function (_ref2) {
        var block = _ref2.block;

        if (isUpdate) {
          _this19.mutationFlow();

          _this19.updateFlow(block.id);
        }
      });
    },
    triggerOpenStartConfigure: function triggerOpenStartConfigure() {
      (0, _jquery.default)('.item-flow').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      (0, _jquery.default)('.item-flow-wrap').addClass('active');
      this.set('isShowComponent', false);
      this.set('isShowListComponents', false);
      this.set('isActiveStartBlockFlow', true);
      this.set('selectedFlowTab', 'configure');
    },
    dragCanvas: function dragCanvas() {
      var ele = document.querySelector('.canvas-block');
      var clicked = false,
          clickY,
          clickX;
      (0, _jquery.default)(ele).on({
        'mousemove': function mousemove(e) {
          clicked && updateScrollPos(e);
        },
        'mousedown': function mousedown(e) {
          clicked = true;
          clickY = e.pageY;
          clickX = e.pageX;
        },
        'mouseup': function mouseup() {
          clicked = false;
          (0, _jquery.default)(ele).css('cursor', 'grab');
        }
      });

      var updateScrollPos = function updateScrollPos(e) {
        (0, _jquery.default)(ele).css('cursor', 'grabbing');
        (0, _jquery.default)(ele).scrollTop((0, _jquery.default)(ele).scrollTop() + (clickY - e.pageY));
        (0, _jquery.default)(ele).scrollLeft((0, _jquery.default)(ele).scrollLeft() + (clickX - e.pageX));
      };
    },
    openConfigureComponent: function openConfigureComponent(id) {
      var _this20 = this;

      var blocks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var newBlocks = blocks || this.get('blocks');
      var prevActiveBlock = newBlocks.findBy('id', this.get('selectedComponent.id'));
      var activeBlock = newBlocks.findBy('id', id);
      console.log('OPEN CONFIG', activeBlock);
      this.set('cursor', {});
      (0, _jquery.default)('.item-flow-wrap').removeClass('active');
      (0, _jquery.default)('.add-component-flow').removeClass('active');
      prevActiveBlock && Ember.set(prevActiveBlock, 'active', false);
      this.set('selectedFlowTab', 'configure');
      this.set('isActiveStartBlockFlow', false);

      if (activeBlock) {
        if (activeBlock.blockType === 'FORM') {
          var extraData = JSON.parse(activeBlock.extraData);
          Ember.set(activeBlock, 'dataCollectorsSelect', extraData.dataCollectorsSelect);
        } else if (activeBlock.name === 'Action') {
          var ruleOptions = this.get('ruleOptions');

          if (typeof activeBlock.actions === 'string') {
            (function () {
              var extraData = JSON.parse(activeBlock.actions);
              Ember.set(activeBlock, 'actions', extraData.actions);
              Ember.set(activeBlock, 'alwaysBtn', extraData.alwaysBtn);
              Ember.set(activeBlock, 'checkCondition', extraData.checkCondition);
              Ember.set(activeBlock, 'actionsFlow', extraData.actionsFlow);
              Ember.set(activeBlock, 'rules', []);

              if (extraData.rules.length) {
                extraData.rules.forEach(function (item) {
                  Object.keys(item).forEach(function (key) {
                    if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
                  });
                });

                var _loop = function _loop(i) {
                  var rulesConditions = {};
                  var fieldType = ruleOptions.variables.filter(function (el) {
                    return el.name === extraData.rules[i].name.replace(' ', '_');
                  });
                  fieldType.length && ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
                    if (val.name === extraData.rules[i].operator.replace(' ', '_')) {
                      rulesConditions.selectedCondition = val;
                    }
                  });
                  rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
                  ruleOptions.variables.forEach(function (val) {
                    if (val.name === extraData.rules[i].name.replace(' ', '_')) {
                      rulesConditions.selectedColumn = val;
                    }
                  });
                  rulesConditions.selectedConditionValue = extraData.rules[i].value;
                  activeBlock.rules.pushObject(rulesConditions);
                };

                for (var i = 0; i < extraData.rules.length; i++) {
                  _loop(i);
                }
              }
            })();
          }
        } else if (activeBlock.blockType === 'BUTTON') {
          var parentFlow = newBlocks.findBy('id', activeBlock.parent.id);

          if (parentFlow) {
            activeBlock = parentFlow;
          }
        } else if (activeBlock.blockType === 'SLIDER') {
          Ember.set(activeBlock, 'children', activeBlock.children.filter(function (child) {
            return child.blockType === 'BUTTON';
          }));
        } else if (activeBlock.blockType === 'COMMENT') {
          this.changeParamModule(activeBlock);
          this.resetCommentModule(activeBlock);
        } else if (activeBlock.blockType === 'TAG') {
          this.changeParamModuleTags(activeBlock);
          this.resetCommentModule(activeBlock);
        }

        Ember.set(activeBlock, 'active', true);
        this.set('selectedComponent', activeBlock);
      } else {
        this.set('selectedComponent', null);
        this.set('isActiveStartBlockFlow', true);
        this.triggerOpenStartConfigure();
      }

      this.set('isLoadingComponent', false);
      setTimeout(function () {
        var activeElement = document.querySelector('.item-flow.active');

        _this20.set('reRender', true);

        activeElement && document.querySelector('.item-flow.active').scrollIntoView({
          behavior: 'smooth'
        });
      }, 500);
    },
    showStatus: function showStatus() {
      var _this21 = this;

      this.set('showStatusSave', true);
      setTimeout(function () {
        _this21.set('showStatusSave', false);
      }, 2000);
    },
    updateFlow: function updateFlow(id) {
      var _this22 = this;

      this.ajax.query(_query.default.FLOW, 'flow', {
        flowId: this.get('flowId')
      }).then(function (data) {
        _this22.set('flow', data);

        _this22.set('blocks', data.blocks);

        _this22.showStatus();

        _this22.updateDataCollectorsSelect(data.blocks);

        _this22.openConfigureComponent(id, data.blocks);

        _this22.isDisabledFlowActive;
      });
    },
    updateDataCollectorsSelect: function updateDataCollectorsSelect(blocks) {
      var filterBlocks = [];
      blocks.forEach(function (block) {
        if (block.blockType === 'FORM') {
          filterBlocks.push.apply(filterBlocks, _toConsumableArray(JSON.parse(block.extraData).dataCollectorsSelect));
        }
      });
      this.set('dataCollectorsSelect', this.get('dataCollectors').filter(function (a) {
        return !filterBlocks.some(function (b) {
          return b.id === a.id;
        });
      }));
    },
    mutationFlow: function mutationFlow() {
      var _this23 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var extraData = {
        isDisabled: this.get('isDisabledFlowActive')
      };

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        flowId: this.get('flowId'),
        extraData: JSON.stringify(extraData)
      });

      this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', data).then(function () {
        _this23.showStatus();
      });
    },
    mutationWorkflow: function mutationWorkflow() {
      var _this24 = this;

      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (hash.title) {
        hash.name = hash.title;
      }

      var data = _objectSpread(_objectSpread({}, hash), {}, {
        workflowId: this.get('flow.id')
      });

      return this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', data).then(function () {
        _this24.showStatus();
      });
    }
  });

  _exports.default = _default;
});