define("inboxfront/helpers/convert-capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertCapitalize = convertCapitalize;
  _exports.default = void 0;

  function convertCapitalize(text) {
    return text[0].charAt(0).toUpperCase() + text[0].toLowerCase().slice(1);
  }

  var _default = Ember.Helper.helper(convertCapitalize);

  _exports.default = _default;
});