define("inboxfront/routes/organizations/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    customfields: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var organizations = this.ajax.query(_query.default.ORGANIZATIONS);
      var users = this.ajax.query(_query.default.PROPERTY_USERS);
      var settings = this.ajax.query(_query.default.ORGANIZATION_SETTINGS);
      return Ember.RSVP.all([organizations, users, settings]).then(function (res) {
        var fields = _this.get('customfields').prepare([], res[2]);

        return {
          organizations: !res[0].errors ? res[0] : [],
          users: res[1],
          settings: res[2],
          fields: fields
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('organizations', model.organizations);
      var columns = [{
        id: 'phone',
        title: this.intl.t('phone')
      }, {
        id: 'country',
        title: this.intl.t('country')
      }, {
        id: 'company',
        title: this.intl.t('company')
      }, {
        id: 'state',
        title: this.intl.t('state')
      }, {
        id: 'contactOwner',
        title: this.intl.t('owner')
      }, {
        id: 'industry',
        title: this.intl.t('industry')
      }, {
        id: 'created',
        title: this.intl.t('created'),
        type: 'DATE'
      }];
      model.fields.forEach(function (cf) {
        if (!cf.isSystem) {
          columns.pushObject({
            id: cf.id,
            title: cf.label,
            fieldType: cf.fieldType
          });
        }
      });
      var columnSettings;

      try {
        columnSettings = JSON.parse(model.settings.fields);
      } catch (e) {
        columnSettings = [];
      }

      if (!Array.isArray(columnSettings)) {
        columnSettings = [];
      }

      columns.forEach(function (c) {
        var data = columnSettings.findBy('id', c.id) || {
          pos: 0,
          vis: true
        };
        Ember.set(c, 'position', +data.pos);
        Ember.set(c, 'visible', !!data.vis);
      });
      columns = columns.sortBy('position');
      controller.set('model.columns', columns);
    }
  });

  _exports.default = _default;
});