define("inboxfront/templates/forgotpassword", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/oLlRoa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"forgot-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"intro-image\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/assets/images/forgotpassword.png\"],[10,\"alt\",\"forgot-password\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"showSuccessMessage\"]]],null,{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"forgot_password\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                        \"],[1,[28,\"t\",[\"check_email\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"                \"],[9],[0,\"\\n\"],[4,\"paper-input\",null,[[\"disabled\",\"type\",\"required\",\"autofocus\",\"value\",\"onChange\",\"placeholder\"],[[24,[\"showSuccessMessage\"]],\"email\",true,true,[24,[\"email\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],null],[28,\"t\",[\"email\"],null]]],{\"statements\":[[0,\"                    \"],[7,\"div\",true],[10,\"class\",\"svg-icon-wrap\"],[8],[1,[28,\"svg-icon\",[\"email\"],[[\"class\"],[\"email-icon\"]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"end-justify-align\"],[8],[0,\"\\n\"],[4,\"common/buttons/primary\",null,[[\"disabled\",\"onClick\"],[[24,[\"showSuccessMessage\"]],[28,\"action\",[[23,0,[]],\"forgotPassword\"],null]]],{\"statements\":[[0,\"                    \"],[1,[28,\"t\",[\"actions.send\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/forgotpassword.hbs"
    }
  });

  _exports.default = _default;
});