define("inboxfront/templates/components/escalations/time-interval-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "br0fZ7jc",
    "block": "{\"symbols\":[\"m\",\"h\",\"d\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"time-interval-select\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onChange\"],[[23,0,[\"days\"]],[23,0,[\"day\"]],[28,\"action\",[[23,0,[]],\"updateValue\",\"day\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,3,[]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"time.day_1\"],null],false],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onChange\"],[[23,0,[\"hours\"]],[23,0,[\"hour\"]],[28,\"action\",[[23,0,[]],\"updateValue\",\"hour\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"time.hour_1\"],null],false],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onChange\"],[[23,0,[\"minutes\"]],[23,0,[\"minute\"]],[28,\"action\",[[23,0,[]],\"updateValue\",\"minute\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"time.minute_1\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/escalations/time-interval-select.hbs"
    }
  });

  _exports.default = _default;
});