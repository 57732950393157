define("inboxfront/components/common/buttons/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    icon: Ember.computed('params.[]', function () {
      return this.params[0] || '';
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});