define("inboxfront/components/paper-data-table-cell", ["exports", "paper-data-table/components/paper-data-table-cell"], function (_exports, _paperDataTableCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperDataTableCell.default;
    }
  });
});