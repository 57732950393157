define("inboxfront/components/tasks/cf-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paperToaster: Ember.inject.service(),
    customfields: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    showDeleteFieldDialog: false,
    showDiscardChangesDialog: false,
    showSections: false,
    fieldBlured: Ember.computed('editedField', 'editedSection', function () {
      var editedField = this.get('editedField');
      return editedField && editedField !== this.get('field') || this.get('editedSection');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== "";
        }
      }];
      this.get('field._edited') && this.checkField();
    },
    actions: {
      deleteField: function deleteField() {
        var _this = this;

        var field = this.get('field');
        this.get('customfields').deleteField(field, function () {
          _this.set('showDeleteFieldDialog', false);

          _this.get('paperToaster').show(_this.intl.t('custom_fields.field_deleted'));

          _this.onDeleteField(field);
        });
      },
      startEdit: function startEdit() {
        if (this.get('isEditMode')) {
          return;
        }

        var field = this.get('field');

        if (field.fieldType === 'GROUP') {
          return;
        }

        Ember.set(field, '_edited', {
          'label': field.label,
          'description': field.description,
          'required': field.required
        });

        if (field.fieldType === 'BOOLEAN' || field.fieldType === 'SELECTION') {
          field.children.forEach(function (c) {
            Ember.set(c, 'editLabel', c.label);
          });
        }

        this.set('editedField', field);
        this.checkField();
      },
      endEdit: function endEdit(confirmed) {
        if (this.get('field.isSystem')) {
          this.set('showDiscardChangesDialog', false);
          this.set('editedField', null);
          return;
        }

        var newField = !this.get('field.id');

        if (!confirmed && (this.get('fieldIsUpdated') || newField)) {
          this.set('showDiscardChangesDialog', true);
        } else {
          var field = this.get('field');

          if (newField) {
            this.onDeleteField(field);
          } else {
            var children;

            switch (field.fieldType) {
              case 'SELECTION':
                children = [];
                field.children.forEach(function (c) {
                  if (c.added) {
                    return;
                  }

                  Ember.set(c, 'deleted', false);
                  children.push(c);
                });
                Ember.set(field, 'children', children);
              // eslint-disable-next-line no-fallthrough

              case 'BOOLEAN':
                field.children.forEach(function (c) {
                  Ember.set(c, 'editLabel', undefined);
                });
            }
          }

          this.set('showDiscardChangesDialog', false);
          this.set('editedField', null);
        }
      },
      update: function update(name, value) {
        Ember.set(this.get('field._edited'), name, value);
        this.checkField();
      },
      saveField: function saveField() {
        var _this2 = this;

        var settingsId = this.get('settingsId');
        var field = this.get('field');

        if (field.isSystem) {
          Ember.set(field, 'required', field._edited.required);
          this.set('editedField', null);
          this.get('paperToaster').show(this.intl.t('custom_fields.field_updated'));
          this.onSaveSystemField();
          return;
        }

        Ember.set(field, 'label', field._edited.label);
        Ember.set(field, 'description', field._edited.description);
        Ember.set(field, 'required', field._edited.required);
        this.get('customfields').updateField(field, settingsId, function (res) {
          _this2.set('editedField', null);

          if (field.id === undefined) {
            Ember.set(field, 'id', res.field.id);

            _this2.get('paperToaster').show(_this2.intl.t('custom_fields.field_created'));
          } else {
            _this2.get('paperToaster').show(_this2.intl.t('custom_fields.field_updated'));
          }

          _this2.onSaveField();

          if (field.fieldType === 'BOOLEAN' || field.fieldType === 'SELECTION') {
            var mutations = [];
            field.children.toArray().forEach(function (choice) {
              if (choice.deleted) {
                mutations.push(_this2.get('customfields').deleteField(choice, function () {
                  field.children.removeObject(choice);
                  field.sections.forEach(function (s) {
                    return s.conditions.removeObject(choice);
                  });
                }));
              } else if (choice.updated) {
                Ember.set(choice, 'label', choice.editLabel);
                Ember.set(choice, 'added', false);
                mutations.push(_this2.get('customfields').updateField(choice, settingsId, function (res) {
                  if (choice.id === undefined) {
                    Ember.set(choice, 'id', res.field.id);
                  }
                }));
              }
            });
            Ember.RSVP.all(mutations).then(function () {});
          }
        });
      },
      onUpdateChoice: function onUpdateChoice(choice, value) {
        choice && Ember.set(choice, 'editLabel', value);
        this.checkField();
      },
      onAddChoice: function onAddChoice(label) {
        var types = this.get('customfields').types;
        this.get('field.children').addObject({
          added: true,
          editLabel: label,
          parentField: this.get('field'),
          type: types[0]
        });
        this.checkField();
      },
      onDeleteChoice: function onDeleteChoice(choice) {
        if (choice.added) {
          this.get('field.children').removeObject(choice);
        } else {
          Ember.set(choice, 'deleted', true);
        }

        this.checkField();
      },
      addSection: function addSection() {
        if (this.get('isEditMode')) {
          return;
        }

        var field = this.get('field');
        var section = {
          parentField: field,
          conditions: [],
          subfields: [],
          _edited: {
            title: ''
          }
        };
        section.subfields.parentSection = section;
        field.sections.addObject(section);
        this.set('showSections', true);
        this.set('editedSection', section);
      },
      onDeleteSection: function onDeleteSection(section) {
        this.get('field').sections.removeObject(section);
      },
      onSubFieldDragEnd: function onSubFieldDragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            sourceArgs = _ref.sourceArgs,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex,
            targetArgs = _ref.targetArgs;
        this.onDragEnd({
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          sourceArgs: sourceArgs,
          targetList: targetList,
          targetIndex: targetIndex,
          targetArgs: targetArgs
        });
      },
      onChoicesDragEnd: function onChoicesDragEnd(_ref2) {
        var sourceList = _ref2.sourceList,
            sourceIndex = _ref2.sourceIndex,
            sourceArgs = _ref2.sourceArgs,
            targetList = _ref2.targetList,
            targetIndex = _ref2.targetIndex,
            targetArgs = _ref2.targetArgs;
        this.onChoicesDragEnd({
          sourceList: sourceList,
          sourceIndex: sourceIndex,
          sourceArgs: sourceArgs,
          targetList: targetList,
          targetIndex: targetIndex,
          targetArgs: targetArgs
        });
      },
      onDeleteSubField: function onDeleteSubField(field) {
        this.onDeleteField(field);
      },
      onSaveSubField: function onSaveSubField() {
        this.onSaveField();
      }
    },
    checkField: function checkField() {
      var _this3 = this;

      var field = this.get('field');
      var updated = field._edited.label !== field.label || field._edited.description !== field.description || field._edited.required !== field.required;
      var valid = this.trimValidation[0].validate(field._edited.label) && field._edited.label.length > 2;
      var choiceCount;

      switch (field.fieldType) {
        case 'SELECTION':
          choiceCount = 0;
          field.children.forEach(function (c) {
            if (c.deleted) {
              updated = true;
            } else {
              choiceCount++;
            }
          });
          Ember.set(field, 'choiceCount', choiceCount);
        // eslint-disable-next-line no-fallthrough

        case 'BOOLEAN':
          field.children.forEach(function (c) {
            if (!valid) return;

            if (!_this3.trimValidation[0].validate(c.editLabel)) {
              valid = false;
            } else {
              c.updated = c.editLabel !== c.label;

              if (c.updated && !updated) {
                updated = true;
              }
            }
          });
      }

      this.set('fieldIsUpdated', updated);
      this.set('fieldIsValid', updated && valid);
    }
  });

  _exports.default = _default;
});