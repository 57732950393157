define("inboxfront/templates/components/companies/dialogs/confirm-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M49XyqkA",
    "block": "{\"symbols\":[\"dialog\"],\"statements\":[[4,\"common/dialogs/warning\",null,[[\"open\",\"onClose\",\"onConfirm\"],[[24,[\"open\"]],[24,[\"onCancel\"]],[24,[\"onConfirm\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"title\"]],\"expected `dialog.title` to be a contextual component but found a string. Did you mean `(component dialog.title)`? ('inboxfront/templates/components/companies/dialogs/confirm-delete.hbs' @ L7:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"actions.delete_company\"],null],false],[7,\"br\",true],[8],[9],[1,[24,[\"company\",\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `dialog.body` to be a contextual component but found a string. Did you mean `(component dialog.body)`? ('inboxfront/templates/components/companies/dialogs/confirm-delete.hbs' @ L11:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[28,\"svg-icon\",[\"trash2\"],null],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[1,[28,\"t\",[\"delete_company_msg\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"btn-cancel\"]],false],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"btn-confirm\"]],\"expected `dialog.btn-confirm` to be a contextual component but found a string. Did you mean `(component dialog.btn-confirm)`? ('inboxfront/templates/components/companies/dialogs/confirm-delete.hbs' @ L19:C7) \"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"actions.delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/companies/dialogs/confirm-delete.hbs"
    }
  });

  _exports.default = _default;
});