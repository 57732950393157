define("inboxfront/templates/components/common/buttons/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oCyiYamZ",
    "block": "{\"symbols\":[\"&default\",\"@action\",\"@class\"],\"statements\":[[4,\"common/buttons/primary\",null,[[\"class\",\"onClick\"],[[23,3,[]],[28,\"or\",[[23,2,[]],[24,[\"action\"]]],null]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"defaultText\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/buttons/confirm.hbs"
    }
  });

  _exports.default = _default;
});