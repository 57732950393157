define("inboxfront/controllers/onboarding", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment", "inboxfront/gql/query"], function (_exports, _mutation, _environment, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    progressValue: 20,
    currentStep: 0,
    urlAttachment: null,
    attachmentId: null,
    department: null,
    selectedProperty: null,
    selectedDepartment: null,
    isEmail: true,
    isAgent: false,
    isProfileCheck: false,
    isPersonalizeCheck: false,
    isMailboxCheck: false,
    inboxAccess: false,
    taskAccess: false,
    companies: [],
    departments: [],
    steps: [],
    stepActive: 'profile',
    selectedLocale: Ember.computed('profile.staffprofile.language', function () {
      var _this = this;

      if (this.get('model.locales')) {
        if (!this.get('profile.staffprofile.language')) {
          return null;
        } else {
          return this.get('model.locales').find(function (obj) {
            return obj.code === _this.get('profile.staffprofile.language');
          });
        }
      }
    }),
    mailboxAddress: Ember.computed('mailboxName', 'domain', function () {
      var mailboxName = this.get('mailboxName') || '';
      var domain = this.get('domain') || '';
      return mailboxName + '@' + domain + '.raincheck.co';
    }),
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
        controller.set('urlAttachment', _environment.default.mediaUrl + result.data.file.attachment.datafile);
        this.removeAllFiles();
        controller.get('sessionAccount').update();
      };
    }),
    options: Ember.computed(function () {
      var token = this.get('session').get('data.authenticated.access_token') || undefined;
      var userId = this.get('model.profile.id');
      return {
        url: _environment.default.apollo.apiURL,
        maxFiles: 1,
        dictDefaultMessage: this.intl.t('upload_image'),
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Accept': null,
          'Authorization': 'Bearer ' + token
        },
        params: {
          'operationName': 'uploadAttachment',
          'query': _mutation.default.UPLOAD_ATTACHMENT,
          'variables': '{"appLabel": "auth", "model": "user", "objectId": "' + userId + '"}'
        }
      };
    }),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.systemRoles = [{
        id: 'admin',
        name: this.intl.t('user_role_admin')
      }, {
        id: 'owner',
        name: this.intl.t('user_role_owner')
      }, {
        id: 'member',
        name: this.intl.t('user_role_member')
      }];
      this.stepsProgress = [{
        title: this.intl.t('actions_update_profile'),
        value: 'profile',
        isComplete: false
      }, {
        title: this.intl.t('actions_personalize'),
        value: 'personalization',
        isComplete: false
      }, {
        title: this.intl.t('actions_setup_email'),
        value: 'mailbox',
        isComplete: false
      }, {
        title: this.intl.t('actions_invite_team'),
        value: 'invite',
        isComplete: false
      }];
      this.ajax.query(_query.default.ONBOARDING_PROGRESS, 'onboardingProgress').then(function (progress) {
        var progressValue = Number(progress[0]);
        var steps = progress[1] ? JSON.parse(progress[1]) : [];

        if (progressValue >= 80) {
          _this2.router.transitionTo('dashboard');
        } else {
          _this2.set('progressValue', progressValue);

          _this2.set('steps', steps);

          _this2.get('stepsProgress').forEach(function (step) {
            Ember.set(step, 'isComplete', steps.includes(step.value));
          });
        }
      });
    },
    actions: {
      deleteAttachment: function deleteAttachment() {
        var _this3 = this;

        this.ajax.query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
          attachmentId: this.get('attachmentId')
        }).then(function () {
          _this3.set('urlAttachment', 'assets/images/upload_avatar.png');

          _this3.set('attachmentId', null);

          _this3.get('sessionAccount').update();
        });
      },
      confirmDomain: function confirmDomain() {
        var hash = {
          objId: this.get('profile.staffprofile.company.id'),
          domain: this.get('domain')
        };
        this.ajax.query(_mutation.default.UPDATE_COMPANY, 'updateCompany', hash);
      },
      copyMailbox: function copyMailbox(text) {
        var _this4 = this;

        navigator.clipboard.writeText(text).then(function () {
          _this4.paperToaster.show(_this4.intl.t('copying_success'));
        }, function (err) {
          _this4.paperToaster.show(_this4.intl.t('copying_failed'), err);
        });
      },
      updateConfirmation: function updateConfirmation() {
        var _this5 = this;

        var hash = {
          userId: this.get('profile').id,
          language: this.get('selectedLocale.code')
        };
        this.set('stepActive', 'mailbox');
        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          if (!_this5.get('steps').includes('personalization')) {
            _this5.updateOnboardingProcess('personalization');
          }
        });
      },
      nextStep: function nextStep() {
        if (this.get('currentStep') === 0) {
          var hash = {
            name: this.get('mailboxName'),
            emailAddress: this.get('mailboxAddress'),
            application: 'EMAIL',
            emailProvider: 'SENDGRID',
            propertyId: this.get('selectedProperty').id,
            department: this.get('selectedDepartment').id
          };
          this.ajax.query(_mutation.default.CREATE_OR_UPDATE_EMAIL, 'createOrUpdateAccount', hash);
        }

        this.set('currentStep', this.get('currentStep') + 1);
      },
      updateMailbox: function updateMailbox() {
        this.set('stepActive', 'invite');

        if (!this.get('steps').includes('mailbox')) {
          this.updateOnboardingProcess('mailbox');
        }
      },
      saveInvite: function saveInvite() {
        var _this6 = this;

        var hash = {
          isAgent: this.get('isAgent'),
          hasInboxAccess: this.get('inboxAccess'),
          hasHelpdeskAccess: this.get('taskAccess'),
          systemGroup: this.get('selectedRole.id') || '',
          propertiesId: this.get('selectedPropertyInvite.id') ? [this.get('selectedPropertyInvite.id')] : [],
          department: this.get('department.id') || '',
          password: this.get('password'),
          email: this.get('email'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName')
        };
        this.ajax.query(_mutation.default.CREATE_USER, 'createUser', hash).then(function () {
          _this6.paperToaster.show(_this6.intl.t('user_created'));

          _this6.clearField();

          if (!_this6.get('steps').includes('invite')) {
            _this6.updateOnboardingProcess('invite');
          }
        }); //this.transitionToRoute('dashboard');
      },
      onFocusInput: function onFocusInput() {
        document.querySelector('.domain-input input').focus();
      },
      onChangeInputDomain: function onChangeInputDomain(value) {
        var input = document.querySelector('.domain-input input');
        this.set('domain', value);
        input.style.width = (value.length + 1) * 8 + 'px';
      },
      onDepartmentChange: function onDepartmentChange(department) {
        this.set('selectedDepartment', department);
      },
      updateProfile: function updateProfile() {
        var _this7 = this;

        var profile = this.get('profile');
        var hash = {
          userId: profile.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          displayName: profile.staffprofile.displayName,
          phone: profile.staffprofile.phone,
          email: profile.email,
          department: this.get('selectedDepartment.id'),
          position: profile.staffprofile.position,
          language: this.get('selectedLocale.code')
        };
        this.ajax.query(_mutation.default.UPDATE_USER_PROFILE, 'updateProfile', hash).then(function () {
          _this7.set('stepActive', 'personalization');

          if (!_this7.get('steps').includes('profile')) {
            _this7.updateOnboardingProcess('profile');
          }
        });
      },
      onStep: function onStep(step) {
        this.set('stepActive', step);
      }
    },
    updateOnboardingProcess: function updateOnboardingProcess(step) {
      this.get('steps').pushObject(step);
      this.get('stepsProgress').forEach(function (item) {
        if (item.value === step) {
          Ember.set(item, 'isComplete', true);
        }
      });
      this.set('progressValue', this.get('progressValue') + 20);
      this.ajax.query(_mutation.default.UPDATE_ONBOARDING_PROCESS, 'updateOnboardingProcess', {
        progress: String(this.get('progressValue')),
        steps: JSON.stringify(this.get('steps'))
      });
    },
    clearField: function clearField() {
      this.set('firstName', '');
      this.set('lastName', '');
      this.set('isEmail', true);
      this.set('email', '');
      this.set('password', '');
      this.set('department', undefined);
      this.set('selectedPropertyInvite', undefined);
      this.set('isAgent', true);
      this.set('inboxAccess', false);
      this.set('taskAccess', false);
      this.set('selectedRole', undefined);
    }
  });

  _exports.default = _default;
});