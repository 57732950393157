define("inboxfront/components/compact-menu", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    conversationCount: 0,
    tagName: '',
    isActiveSettingsMenu: Ember.computed('currentLink', function () {
      return ['companies', 'group', 'departments', 'contactfields', 'orgfields', 'issuetypes', 'ticketfields', 'users', 'roles', 'permissions', 'channels', 'response', 'ecommerce'].includes(this.get('currentLink'));
    }),
    isActiveContactsMenu: Ember.computed('currentLink', function () {
      return this.get('currentLink') === 'organizations';
    }),
    currentLink: Ember.computed('router.currentRoute.queryParams', function () {
      return this.router.currentRouteName.split('.')[0];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.menuItems = ['dashboard', 'chats', 'assistants', 'kb', 'contacts', 'tasks', 'escalations', 'settings'];
      this.ajax.query(_query.default.THREADS_COUNT).then(function (res) {
        if (res.edges && res.edges.length > 0) {
          var count = (res.edges.filter(function (obj) {
            return obj.node && obj.node.unreadCount > 0;
          }) || []).length;

          _this.set('conversationCount', count);
        }
      });
    }
  });

  _exports.default = _default;
});