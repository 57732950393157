define("inboxfront/components/dashboard/channels-rating", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _component = this;

      _highcharts.default.chart({
        chart: {
          height: 315,
          renderTo: 'channels-rating',
          type: 'bar'
        },
        title: {
          text: this.intl.t('messages_by_channel'),
          align: 'left'
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: 'count'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: _component.intl.t('channels') + '<b>{point.y:.1f} ' + _component.intl.t('count') + '</b>'
        },
        exporting: {
          enabled: false
        },
        series: [{
          name: 'Count',
          data: _component.calcChannels(),
          dataLabels: {
            enables: true,
            color: '#ffffff',
            align: 'right' //format: '{point.y:.1f}',
            //y: 10,

          }
        }]
      });
    },
    calcChannels: function calcChannels() {
      var comp = this.get('pickGraph');
      var newArr = [];
      var arr = [{
        'count': 0,
        'name': 'Facebook'
      }, {
        'count': 0,
        'name': 'Line'
      }, {
        'count': 0,
        'name': 'Telegram'
      }, {
        'count': 0,
        'name': 'Email'
      }, {
        'count': 0,
        'name': 'Whatsapp'
      }, {
        'count': 0,
        'name': 'Twitter'
      }, {
        'count': 0,
        'name': 'Livechat'
      }, {
        'count': 0,
        'name': 'Wechat'
      }];

      for (var i = 0; i < comp.length; i++) {
        arr[0].count += comp[i].node.totalMsgsFacebook;
        arr[1].count += comp[i].node.totalMsgsLine;
        arr[2].count += comp[i].node.totalMsgsTelegram;
        arr[3].count += comp[i].node.totalMsgsEmail;
        arr[4].count += comp[i].node.totalMsgsWhatsapp;
        arr[5].count += comp[i].node.totalMsgsTwitter;
        arr[6].count += comp[i].node.totalMsgsLivechat;
        arr[7].count += comp[i].node.totalMsgsWechat;
      }

      for (var _i = 0; _i < 8; _i++) {
        var s = ['', 0];
        s[0] = arr[_i].name;
        s[1] = arr[_i].count;
        newArr.push(s);
      }

      return newArr;
    }
  });

  _exports.default = _default;
});