define("inboxfront/translations/ar-ar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "Administration": "الادارة",
    "Assistant": "Assistant",
    "Contacts": "جهات الاتصال",
    "Conversation": "Conversation",
    "Escalations": {
      "escalation_activated": "Escalation activated",
      "escalation_created": "Escalation created",
      "escalation_deactivated": "Escalation deactivated",
      "escalation_deleted": "Escalation deleted",
      "first_response_target": "First Response Target",
      "next_response_target": "Next Response Target",
      "operation_hours": "Operation Hours",
      "reminder": "Reminder",
      "reminders": "Reminders",
      "resolution_target": "Resolution Target",
      "sla_target": "SLA target",
      "sla_target_approaches_in": "approaches in",
      "sla_target_is_not_met": "is not met, escalate",
      "target_Conversation": "Conversation",
      "target_Tasks": "Tasks",
      "target_module": "Target Module"
    },
    "Forms": "Forms",
    "IMAP_server": "IMAP Server",
    "Import": "Import",
    "LINE_channel_id": "LINE Channel ID",
    "LINE_secret": "LINE Secret",
    "LINE_webhook_URL": "LINE Webhook URL",
    "Notification": "Notification",
    "Organizations": "Organisations",
    "Permissions": {
      "create_add": "Create/Add",
      "delegate_assign": "Delegate/Assign",
      "delete": "Delete",
      "edit": "Edit",
      "full_control": "Full Control",
      "item": "Item",
      "respond_reply": "Respond/Reply",
      "view_read": "View/Read"
    },
    "Raincheck_field": "Raincheck field",
    "SMTP_server": "SMTP Server",
    "Status": "Status",
    "Status_desc": "The status of the support ticket/task",
    "Tasks": "مهام",
    "Templates": "Templates",
    "about": "About",
    "access_info": "Access Information",
    "account_settings": "Account Settings",
    "action_button_colour": "Action Button Colour",
    "actions": {
      "activate": "Activate",
      "add": "Add",
      "add_action": "Add action",
      "add_another_email": "Add another email",
      "add_card": "Add Card",
      "add_child_company": "Add Child Company",
      "add_choice": "Add Choice",
      "add_company": "Add Company",
      "add_custom_group": "Add Custor Group",
      "add_emoji_reaction": "Add reaction",
      "add_field": "Add Field",
      "add_filter": "Add Filter",
      "add_member": "Add Member",
      "add_msg": "Add a message",
      "add_parent_company": "Add Parent Company",
      "add_rule": "Add Rule",
      "add_section": "Add Section",
      "add_tag": "Add Tag",
      "archive": "Archive",
      "assign": "Assign",
      "assign_to": "Assign to",
      "associate": "Associate",
      "back": "Back",
      "bulk_update": "Bulk Update",
      "cancel": "Cancel",
      "change": "Change",
      "check_condition": "Check Condition",
      "choose_block": "Choose block",
      "choose_field": "Choose Field",
      "choose_fields_form": "Choose Fields Form",
      "choose_font_family": "Choose font family",
      "choose_lookup": "Choose Lookup",
      "choose_time": "Choose time",
      "choose_type": "Choose Type",
      "choose_type_block": "Choose type block",
      "choose_type_button": "Choose type button",
      "choose_user": "Choose user",
      "choose_users": "Choose users",
      "clear": "Clear",
      "clear_filter": "Clear filter",
      "click": "Click here...",
      "close": "Close",
      "close_conversation": "Close conversation",
      "confirm": "Confirm",
      "confirm_details": "Confirm Profile Details",
      "confirm_lang": "Confirm your default language",
      "confirm_url": "Confirm your Raincheck URL",
      "copy": "Copy",
      "copy_link": "Copy link",
      "create": "Create",
      "create_another": "Create Another",
      "create_block": "Create Block",
      "create_bot": "Create Bot",
      "create_channel": "Create Channel",
      "create_company": "Create Company",
      "create_company_group": "Create Group",
      "create_contact": "Create Contact",
      "create_contact_field": "Create Contact Field",
      "create_department": "Create Department",
      "create_direct_chat": "Create Direct Chat",
      "create_escalation": "Create Escalation",
      "create_flow": "Create Chat Bot",
      "create_group_chat": "Create Group Chat",
      "create_issue_type": "Create Issue Type",
      "create_new_assistant": "Create new flow",
      "create_new_block": "Create New Block",
      "create_next_block": "Create Next Block",
      "create_note": "Create Note",
      "create_one_now": "Create one now",
      "create_org": "Create Organisation",
      "create_org_field": "Create Organisation Field",
      "create_quick_response": "Create Quick Response",
      "create_response": "Create Response",
      "create_role": "Create Role",
      "create_tag": "Create New Tag",
      "create_task": "Create Task",
      "create_user": "Create User",
      "create_workflow": "Create Orchestration",
      "deactivate": "Deactivate",
      "deactivate_user": "Deactivate User",
      "delete": "Delete",
      "delete_block": "Delete Block",
      "delete_choice": "Delete Choice",
      "delete_company": "Delete Company",
      "delete_company_group": "Delete Group",
      "delete_contacts": "Delete Contacts",
      "delete_escalation": "Delete Escalation",
      "delete_field": "Delete Field",
      "delete_flow_block_all": "Delete Full Path",
      "delete_flow_block_single": "Delete Selected Component",
      "delete_issue_type": "Delete Issue Type",
      "delete_member": "Delete member",
      "delete_msg": "Delete message",
      "delete_orgs": "Delete Organisations",
      "delete_role": "Delete Role",
      "delete_room": "Delete Room",
      "delete_section": "Delete Section",
      "delete_selected_tasks": "Delete Selected Tasks",
      "delete_task": "Delete Task",
      "delete_user": "Delete User",
      "discard": "Discard",
      "display_system_fields": "Display system fields",
      "download": "Download",
      "drag_field_to_create": "Drag and drop to create fields",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "edit_address": "Edit Address",
      "edit_block": "Edit Block",
      "edit_channel": "Edit Channel",
      "edit_company_code": "Edit Company Code",
      "edit_department": "Edit Department",
      "edit_desc": "Edit Description",
      "edit_issue_type": "Edit Issue Type",
      "edit_msg": "Edit message",
      "edit_name": "Edit Name",
      "edit_phone_number": "Edit Phone Number",
      "edit_postal_code": "Edit Postal Code",
      "edit_response": "Edit Response",
      "edit_section": "Edit Section",
      "edit_website": "Edit Website",
      "enabled": "Enabled",
      "enter_value": "Enter the value",
      "export": "Export",
      "export_contact": "Export Contact",
      "export_org": "Export Organisation",
      "filter": "Filter",
      "filter_on": "Filter On",
      "filter_tasks": "Filter tasks",
      "finish": "Finish",
      "go_to": "go to",
      "import": "Import",
      "import_contact": "Import Contact",
      "import_org": "Import Organisation",
      "invite": "Invite",
      "leave_chat": "Leave chat",
      "login": "Login",
      "merge": "Merge",
      "merge_contacts": "Merge Contacts",
      "merge_orgs": "Merge Organisations",
      "next": "Next",
      "pin_msg": "Pin message",
      "re_assign": "Re-Assign",
      "remove": "Remove",
      "remove_image": "Remove Image",
      "reply_msg": "Reply message",
      "resend_invite": "Resend Invite",
      "reset": "Reset",
      "reset_password": "Reset Password",
      "save": "Save",
      "select": "Select",
      "select_agent": "Select Agent",
      "select_child_company": "Select Child Company",
      "select_company": "Select Company",
      "select_company_group": "Select Company Group",
      "select_custom_role": "Select Custom Role",
      "select_facebook_page": "Select Facebook Page",
      "select_parent_company": "Select Parent Company",
      "select_user": "Select User",
      "send": "Send",
      "sign_out": "Sign-Out",
      "sign_up": "Sign-up",
      "snooze": "Snooze Notifications",
      "start_new_import": "Start New Import",
      "tag_customer": "Tag the customer",
      "turn_off": "Turn Off",
      "type_msg": "Type message...",
      "unpin_msg": "Unpin message",
      "update": "Update",
      "update_flow": "Update Flow",
      "verify": "Verify"
    },
    "actions_authenticate_to_facebook": "Authenticate to Facebook",
    "actions_back_to_orders": "Back to orders",
    "actions_bring_team": "Bring your support team to Raincheck",
    "actions_browse": "Browse",
    "actions_choose_facebook_page": "Choose the Facebook Page for receiving messages",
    "actions_choose_field": "Choose or create a field",
    "actions_define_and_copy": "Define & Copy",
    "actions_define_and_copy_info": "Define the mailbox with the team and link your support mailbox, without interfering your existing support workflow, to receive a copy of all your support emails in your Raincheck application",
    "actions_drag_or": "Drag and drop file here or",
    "actions_embed_widget": "Embed the widget",
    "actions_invite_team": "Invite team members",
    "actions_map_contact_fields": "Map columns in your files to Raincheck contact fields",
    "actions_map_org_fields": "Map columns in your files to Raincheck organisation fields",
    "actions_personalize": "Personalize your helpdesk",
    "actions_refund": "Refund",
    "actions_replace_file": "Replace file",
    "actions_select_email_provider": "Select Email Provider",
    "actions_select_facebook_page": "Select Facebook Page",
    "actions_set_widget_response_time": "Set Response Time",
    "actions_setup_account": "Setup your account",
    "actions_setup_email": "Setup support email",
    "actions_setup_forwarding": "Setup Forwarding",
    "actions_setup_forwarding_info": "When you create a Raincheck account, you're provided with an email address that looks like this: support@yourcompany.raincheck.co. You can share this with your customers, and ask them to send their questions to this address. Any email sent to this address becomes a ticket in Raincheck. The customer who sent in the question becomes the requester of the ticket. If you already have an email like support@yourcompany.com, you can continue to share that email address with your customers for reaching out to you. But, you'll have to forward emails coming into that address to Raincheck so that they get captured as tickets in your account. We'll walk you through how you can do this.",
    "actions_signup_for": "Signup for Raincheck",
    "actions_update_profile": "Update your profile details",
    "actions_upload_file": "Upload a file",
    "actions_upload_file_info": "You can upload CSV or XLS file to import your {target}",
    "actions_use_ur_own_server": "Use your own mail server",
    "actions_validate_and_continue": "Validate and continue",
    "actions_verify_account": "Verify your account",
    "actions_view_details": "View details",
    "activate_flow": "Activate Flow {name}",
    "activate_workflow": "Activate Workflow {name}",
    "add_a_condition": "Add a condition",
    "add_card": "Add card",
    "add_chat_widget_home": "Add to Chat Widget Home",
    "add_condition": "Add Condition",
    "add_one_company": "Add at least one company",
    "add_option": "Add option",
    "add_trigger": "Add Trigger",
    "add_users_to_role": "Add member on the next step?",
    "additional_info": "Additional Information",
    "address": "Address",
    "address_updated": "Address updated",
    "addresses": "Addresses",
    "agent": "Agent",
    "agent_matrix": "Agent Performance Matrix",
    "agent_response": "Agent Response Performance",
    "agent_selected": "Agent selected",
    "all_companies": "All Companies",
    "all_pages": "All Pages",
    "allow_webhooks": "Allow Webhooks",
    "already_have_account": "Already have an account?",
    "and": "and",
    "another": "Another",
    "appearance": "Appearance",
    "assign_agent": "Assign chat to team member",
    "assigned_agent": "Assigned Agent",
    "assigned_by": "Reporter",
    "assigned_by_desc": "The person who reports the support ticket/task",
    "assigned_to": "Assignee",
    "assigned_to_desc": "The person who is responsible for the support ticket/task",
    "assignee_changed": "Assignee changed",
    "assistant_basic_setting": "Assistant Basic Setting",
    "assistants": "يطفو",
    "attachment": "Attachment",
    "attachments": "Attachments",
    "audience": "Audience",
    "authentication": "Authentication",
    "author": "Author",
    "auto": "Auto",
    "average_resolution_time": "Average ticket resolution time",
    "avg_resolution_time": "Avg resolution time",
    "avg_response_time": "Avg response time",
    "background_button": "Background Button",
    "background_form": "Background Form",
    "basic_configuration_ip": "Go to \"Basic Configuration”, and add these IP addresses to your whitelist, separated by new lines.",
    "benchmark": "Benchmark",
    "big_commerce": "BigCommerce",
    "big_commerce_description": "Automatically sync customers and orders from BigCommerce into Raincheck",
    "block": "Block",
    "body": "Body",
    "bookmark": "المرجعية",
    "bookmarks": "إشارات مرجعية",
    "brand_logo": "Brand Logo",
    "business_email": "Business email",
    "business_hours": "Business Hours",
    "button": "Button",
    "button_name": "Button Name",
    "button_url": "Button URL",
    "by": "By",
    "call_action": "Call To Action",
    "cancel_order": "Cancel Order {id}",
    "cancellation": "Cancellation",
    "card": "card",
    "category": "Category",
    "channel": "Channel",
    "channel_activated": "Channel activated",
    "channel_added": "Channel {type} added",
    "channel_created": "Channel Created",
    "channel_deactivated": "Channel deactivated",
    "channel_id": "Channel ID",
    "channel_id_info": "Copy the value of your Channel ID",
    "channel_name": "Channel Name",
    "channel_removed": "Channel removed",
    "channel_secret": "Channel Secret",
    "channel_secret_info": "Copy the value of your Channel Secret",
    "channel_settings": "Channel Settings",
    "channel_setup": "Channel Setup",
    "channel_type": "Channel Type",
    "channel_updated": "Channel Updated",
    "channels": "Channels",
    "channels_integrations": "Channels Integrations",
    "chat": "Chat",
    "chat_handled": "Chat handled",
    "chat_link": "Chat Link",
    "chat_link_info": "Copy chat link and send it to guest",
    "chats": "Chats",
    "chats_by_country": "Chats Distribution by Country",
    "check_email": "Please check your email ID",
    "check_order": "Check my order",
    "check_order_status": "Check my order status",
    "child_company": "Child Company",
    "child_company_deleted": "Child company deleted",
    "choice": "Choice",
    "choices": "Choices",
    "choose_organization": "Choose organization...",
    "choose_social_type": "Choose social type...",
    "choose_the_components": "Choose the components",
    "city": "City",
    "color_text_button": "Color Text Button",
    "column": "Column",
    "columns_added": "Columns added",
    "columns_can_be_added": "Columns can be added",
    "comments": "Comments",
    "companies": "Companies",
    "company": "Company",
    "company_activated": "Company activated",
    "company_added_to_group": "Company added to group",
    "company_address": "Company Address",
    "company_code": "Company Code",
    "company_created": "Company created",
    "company_deactivated": "Company deactivated",
    "company_deleted": "Company deleted",
    "company_group": "Company Group",
    "company_group_created": "Company group created",
    "company_group_deleted": "Company group deleted",
    "company_group_exists": "Company group \"{name}\" already exists",
    "company_has_no_roles": "Company has no custom roles",
    "company_matrix": "Company Performance Matrix",
    "company_name": "Company Name",
    "company_removed_from_group": "Company removed from group",
    "company_settings": "Company Settings",
    "company_timezone": "You company is on (GMT +08:00) Singapore timezone",
    "complaints": "Complaints",
    "condition": "Condition",
    "conditions": "Conditions",
    "conditions_desc": "When these conditions are met",
    "configure": "Configure",
    "confirm_new_password": "Confirm New Password",
    "confirm_url_info": "Make it convenient for your customers to connect with you by associating your brand name with your Raincheck account. You can opt to use your own custom domain after this",
    "confirmation": "Confirmation",
    "confirmed_password": "Confirmed Password",
    "connect_app": "Connect to an app",
    "connect_us": "Connect with us on",
    "connected_email": "Connected Email",
    "contact": "Contact",
    "contact_URL": "Contact URL",
    "contact_company_updated": "Contact company updated",
    "contact_created": "Contact created",
    "contact_email": "Contact Email",
    "contact_email_desc": "Contact's email",
    "contact_fields": "Contact Fields",
    "contact_firstname": "Contact First Name",
    "contact_firstname_desc": "Contact's first name",
    "contact_info": "Contact Info",
    "contact_lang_updated": "Contact language updated",
    "contact_lastname": "Contact Last Name",
    "contact_lastname_desc": "Contact's last name",
    "contact_owner": "Contact Owner",
    "contact_phone": "Contact Phone Number",
    "contact_phone_desc": "Contact's phone number",
    "contact_settings": "Contact Settings",
    "contact_updated": "Contact Updated",
    "contact_url_info": "Copy the value of your Contact URL",
    "contacts": "جهات الاتصال",
    "contacts_deleted": "Contacts deleted",
    "contacts_not_found": "Contacts not found",
    "conversations": "المحادثات",
    "copied": "Copied to clipboard",
    "copying_failed": "Could not copy text: ",
    "copying_success": "Copying to clipboard was successful!",
    "count": "count",
    "country": "Country",
    "country_doesnt_exist": "Oops this country doesn't exist.",
    "country_rating": "Country rating",
    "create_chat": "إنشاء ارتباط الدردشة",
    "created": "Created",
    "current_password": "Current Password",
    "custom": "Custom",
    "custom_fields": {
      "boolean": "Boolean",
      "boolean_desc": "True or False",
      "date": "Date",
      "date_desc": "Date picker",
      "decimals": "Decimals",
      "decimals_desc": "Amount, price, ratings and etc.",
      "delete_field_msg": "This field will be deleted permanently and this action cannot be reversed.",
      "delete_section_msg": "This section will be deleted permanently and this action cannot be reversed.",
      "drop_fields": "Drop fields here",
      "field_created": "Field created",
      "field_deleted": "Field deleted",
      "field_has_sections": "Field has sections",
      "field_label": "Field label",
      "field_updated": "Field updated",
      "hide_sections": "Hide sections",
      "multi_line": "Multiple-line Text",
      "multi_line_desc": "Description",
      "number": "Number",
      "number_desc": "ID, quantity and etc.",
      "rich_text": "Rich Text",
      "rich_text_desc": "Text editor with formatting",
      "selection": "Selection",
      "selection_desc": "Category, country and etc.",
      "show_section_if": "Show section if <b>{value}</b> is",
      "show_sections": "Show sections",
      "single_line": "Single-line Text",
      "single_line_desc": "Headings and titles",
      "subsection_props": "Subsection Properties",
      "subsection_title": "Subsection title",
      "unsaved_changes": "Unsaved Changes",
      "unsaved_changes_warn": "You have unsaved changes, and the data will be lost. Are you sure to continue?"
    },
    "custom_group": "Custom Group",
    "custom_role": "Custom Role",
    "custom_role_added": "Role added",
    "custom_role_removed": "Role removed",
    "custom_snooze": "Custom Snooze",
    "customer_success": "Customer Success",
    "customise_widget": "Customise Your Widget",
    "dashboard": "لوحة القيادة",
    "data_collector": "Data Fields (from Contacts)",
    "data_fields": "Data Fields",
    "date_format": "Date Format",
    "deactivate_company_info": "Data associated to this company will be kept.",
    "deactivate_company_msg": "Deactivated companies can be restored as long as they're not deleted.",
    "deactivate_flow": "Deactivate Flow {name}",
    "deactivate_user_info": "Deactivated users can be restored as long as they're not deleted.",
    "deactivate_user_msg": "The user's data, messages, and files will be kept.",
    "deactivate_user_warn": "The user will be logged out immediately and no longer have access to the application.",
    "deactivate_workflow": "Deactivate Worklow {name}",
    "default": "Default",
    "default_date_format": "Default Date Format",
    "delete_company_group_info": "Companies associated with this group will remain unchanged.",
    "delete_company_group_msg": "This group will be deleted permanently and this action cannot be reversed.",
    "delete_company_msg": "Data associated with this company will be deleted permanently and this action cannot be reversed.",
    "delete_contacts_msg": "Data associated with this contacts will be deleted permanently and this action cannot be reversed.",
    "delete_escalation_msg": "Rules associated with this escalation will be deleted permanently and this action cannot be reversed.",
    "delete_flow": "Delete Flow {name}",
    "delete_issue_type_msg": "This issue type will be deleted permanently and this action cannot be reversed.",
    "delete_orgs_msg": "Data associated with this organisations will be deleted permanently and this action cannot be reversed.",
    "delete_question": "Do you want to delete it?",
    "delete_role_msg": "The role will be deleted permanently and no longer have access to the application.",
    "delete_role_warn": "All data not transferred will be deleted permanently.",
    "delete_room_msg": "Room will be deleted permanently.",
    "delete_task_msg": "Data associated with this task(s) will be moved to Trash.",
    "delete_task_warn": "You have 90 days before it is deleted permanently and this action cannot be reversed.",
    "delete_user_info": "The user will be deleted permanently and no longer have access to the application.",
    "delete_user_msg": "Transfer ownership of this user's data to another user, like a manager.",
    "delete_user_warn": "All data not transferred will be deleted permanently.",
    "department": "Department",
    "department_activated": "Department activated",
    "department_created": "Department \"{name}\" created",
    "department_deactivated": "Department deactivated",
    "department_desc": "The department that is responsible for the support ticket/task",
    "department_updated": "Department updated",
    "departments": "Departments",
    "description": "Description",
    "description_desc": "The detailed description of the support ticket/task",
    "desktop_notifications": "Desktop Notifications",
    "desktop_notifications_info": "Receive notifications to stay on top of things at all times",
    "details": "Details",
    "direct": "Direct Messages",
    "dislike": "Dislike",
    "display_name": "Display Name",
    "dob": "Date of Birth",
    "domains_info1": "List all your trusted domains and subdomains, separated by commas (,).",
    "domains_info2": "Use asterisk as the placeholder to mark all the subdomain as trusted, e.g. *.example.com",
    "domains_info3": "If this field is blank, widget can be loaded from any domain",
    "dont_have_account": "Don't have an account?",
    "dont_have_facebook_page": "Do not have a Facebook page yet?",
    "dont_have_line_account": "Don't have a LINE account yet?",
    "dont_have_permissions": "You don't have permission to view this section.",
    "dont_have_wechat_account": "Don't have a WeChat account yet?",
    "drop_files": "Drop files here or click to upload (Max. {count}MB)",
    "drop_line": "Drop us a line",
    "duplicate_escalation_msg": "A copy of this escalation will be created. You will be redirected to the detail page for further modification. Any changes made to this duplicate will not have an impact to the original copy.",
    "duplicate_flow": "Duplicate Flow {name}_copy",
    "email": "Email",
    "email_address": "Email Address",
    "email_customer": "Your email (e.g email@domain.com)",
    "email_exists": "Email already exists",
    "email_provider": "Email Provider",
    "email_provider_other": "Other",
    "embed_option_1": "Option 1: Copy the following script and insert into your website's HTML source code before <code>&lt;body/&gt;</code> tag ends",
    "embed_option_2": "Option 2: If you are using Wordpress as CMS, you may consider downloading the plugin.",
    "employee_count": "Employee Count",
    "enter_reason_for_cancellation_order": "Enter reason for cancellation",
    "enter_reason_for_refund_order": "Enter reason for refund",
    "enter_your_description": "Enter your description here...",
    "enter_your_title": "Enter your title...",
    "error_count": "Error count",
    "error_count_info": "The number of records with errors and being rejected from the import",
    "escalation": "Escalation",
    "escalation_active": "Escalation is active",
    "escalation_inactive": "Escalation is inactive",
    "escalations": "التصعيد",
    "escalations_desc": "Escalate to the next level when the SLA target is violated",
    "escalations_rating": "Number of Escalations by Priority",
    "explore_more_app": "Explore the Marketplace for more apps",
    "explore_more_app_description": "More ways to connect, communicate and collaborate with your visitors/customers",
    "facebook_page": "Facebook Page",
    "facebook_pages": "Facebook Pages",
    "fax": "Fax",
    "fax_number": "Fax Number",
    "field": "Field",
    "fields": "Fields",
    "file_col_header": "Column header from file",
    "file_uploaded": "File Uploaded",
    "files": "Files",
    "fill_fields": "This {name} cannot be activated. Please ensure all the components are correctly set up.",
    "filter": "Filter",
    "filters": {
      "all_tasks": "All Tasks",
      "archived_tasks": "All Archived Tasks",
      "closed_tasks": "All Closed Tasks",
      "contains": "Contains",
      "deleted_tasks": "All Deleted Tasks",
      "endswith": "EndsWith",
      "exact": "Exact",
      "exclude": "Exclude",
      "opened_tasks": "All Open Tasks",
      "personal_tasks": "My Tasks",
      "resolved_tasks": "All Resolved Tasks",
      "startswith": "StartsWith"
    },
    "firstname": "First Name",
    "fixed_columns": "Fixed columns",
    "flow": "Flow",
    "flow_active": "Flow is Active",
    "flow_block_created": "Flow block created",
    "flow_block_saved": "Flow block saved",
    "flow_created": "Flow created",
    "flow_inactive": "Flow is Inactive",
    "flow_name": "Flow Name",
    "flow_per_page": "Flow Per Page",
    "flow_updated": "Flow updated",
    "flows": "flows",
    "follow_up_actions": "Follow Up Actions",
    "for": "for",
    "for_following_conf": "for the following configuration.",
    "forgot_current_password": "Forgot Password",
    "forgot_password": "Forgot password?",
    "form": "Form",
    "form_fields": "Form Fields",
    "form_for_guest": "Form for guest",
    "formatting_and_preview": "Formatting and Preview",
    "forms": {
      "invalid_domain": "Please provide valid domain",
      "invalid_email": "This is an invalid email",
      "invalid_signup_email": "Please ensure this is a valid business email",
      "invalid_url": "Please provide valid url",
      "login_failed": "You have entered an invalid email and password. Please try again.",
      "minimum_password_length": "Minimum password length is 8",
      "password_doesnt_match": "Password does not match!",
      "password_lowercase_char": "At least one lowercase charater",
      "password_num_or_sym": "At least one number or symbol",
      "password_uppercase_char": "At least one UPPERCASE charater",
      "passwords_matched": "Passwords matched",
      "provide_email": "Please provide email in a valid format",
      "provide_website": "Please provide website in a valid format",
      "strong_password": "Please enter a strong password",
      "this_is_required": "This is required"
    },
    "forwarding_instruction": "Forwarding Instruction",
    "from": "From",
    "full_refund": "Full Refund",
    "general_enquiry": "General Enquiry",
    "general_preferences": "General Preferences",
    "general_settings": "General Settings",
    "get_started": "Get started {percent}%",
    "get_started_with": "Get started with Raincheck",
    "greeting": "Greeting",
    "greetings": "Greetings",
    "greetings_title": "Greet your visitors/customers and provide a quick intro to your team",
    "group": "Group",
    "group_chat_desc": "Group chat is where your teammates could communicate easily and efficiently. The group can be best orgainised through a common topic e.g. \\#Leads or department e.g. \\#Sales and Marketing.",
    "group_chat_settings": "Group Chat Settings",
    "group_name": "Group Name",
    "group_name_desc": "Provide this group chat a unique, simple and short name.",
    "guest_info": "Guest info",
    "hi": "Hi",
    "high": "High",
    "history_of_import": "Import history",
    "home_address": "Home Address",
    "if": "If",
    "image_url": "Image URL",
    "import_complete": "Complete",
    "import_complete_info": "The file has been successfully imported.",
    "import_confirm_msg": "You are about to import a new file for {target}. Are you sure you want to proceed?",
    "import_contacts_info": "The customers, clients or leads that you work with.",
    "import_error_file_format": "Please, provide file with column headers",
    "import_map": "Map",
    "import_org_info": "The businesses where the customers, clients or leads work for.",
    "import_process_name": "Import Process Name",
    "import_required_fields_msg": "Data import is invalid and will not be able to proceed. Please validate your file.",
    "import_required_fields_warn": "There are {count} unmapped required fields!",
    "import_unmapped_fields_msg": "Data from unmapped columns will NOT be imported. Please validate your file before proceeding.",
    "import_unmapped_fields_warn": "There are {count} unmapped columns!",
    "imported_date": "Imported date",
    "inbound_traffic": "Inbound Messages Traffic",
    "inbox_channels": "Inbox Channels",
    "incident": "Incident",
    "industry": "Industry",
    "info": "Info",
    "info_activate_flow": "Things to keep in mind:",
    "info_activate_flow1": "This Flow will be online the moment it is activated.",
    "info_activate_flow2": "To make any further modification, please make it inactive again.",
    "information": "Information",
    "insert_variables": "Insert variables",
    "installation": "Installation",
    "integration": "Integration",
    "integration_title": "Setup your widget with other integration",
    "internal_error": "Internal server error.",
    "internal_note": "Internal Note",
    "introduction_team": "Introduction to your team",
    "invite_opt": "Invite (optional)",
    "invite_sent": "Invite sent",
    "issue_type": "Issue Type",
    "issue_type_activated": "Issue type activated",
    "issue_type_created": "Issue type created",
    "issue_type_deactivated": "Issue type deactivated",
    "issue_type_deleted": "Issue type deleted",
    "issue_type_desc": "The classification for the support ticket/task",
    "issue_type_updated": "Issue type updated",
    "issue_types": "Issue Types",
    "items": "Items",
    "joined": "has joined the chat",
    "language": "Language",
    "lastname": "Last Name",
    "left": "Left",
    "like": "Like",
    "line": "Line",
    "line_developer_center": "LINE Developer Center",
    "link_copied": "Link copied",
    "link_not_copied": "Could not copy link",
    "links": "Links",
    "livechat": "LiveChat",
    "locale_doesnt_exist": "Oops this locale doesn't exist.",
    "localization": "Localization",
    "login_with_smt": "Login with",
    "lookup": "Lookup",
    "low": "Low",
    "magic_styling": "Magic Styling",
    "magic_styling_title": "Personalised your widget with your own magic flavour",
    "mail_server": "Mail Server",
    "mailbox": "Mailbox",
    "mailbox_address": "Mailbox Address",
    "mailbox_name": "Mailbox Name",
    "mapping": "Mapping",
    "matches_all_conditions": "Matches all of the following conditions",
    "matches_any_conditions": "Matches any of the following conditions",
    "max_available": "Max. available",
    "max_cards": "Cards (maximum 6 cards)",
    "max_options_select": "Options (maximum 6 cards)",
    "media": "Media",
    "median_1st_response_time": "Median 1st response time",
    "medium": "Medium",
    "member": "Member",
    "members": "Members",
    "mention_member": "Mention your team member (type @ follow by their name) and they will see this message",
    "merge_contacts_desc": "Click on the contact information to select which fields to be updated",
    "merge_contacts_msg": "The contact will be merge permanently.",
    "merge_orgs_msg": "The organisation will be merge permanently.",
    "merge_tasks_info": "Interactions from secondary ticket(s)/task(s) will be added to the primary ticket/task",
    "merge_tasks_search": "Look for tickets/tasks (i.e. ID, Subject or Contact)",
    "message": "Message",
    "messages": "Messages",
    "messages_by_channel": "Inbound Messages by Channel",
    "messages_on_something": "messages on",
    "messenger": "Messenger",
    "mobile_phone_number": "Mobile Number",
    "module": "Module",
    "move_starred_thread": "Jump",
    "my_orders": "My orders",
    "na": "NA",
    "name": "Name",
    "network_error": "Network Error.",
    "new_chat": "New Chat",
    "new_condition": "New Condition",
    "new_escalation": "New Escalation",
    "new_password": "New Password",
    "new_records": "New records",
    "new_records_info": "The number of new records being added to the system",
    "new_reminder": "New Reminder",
    "new_user_without_email": "You have created user without email, please, copy link {link} and send to {user}",
    "next": "Next",
    "no": "No",
    "no_company_groups_msg": "There is no company group available, would you like to create one?",
    "no_company_msg": "There is no company available, would you like to create one?",
    "no_custom_roles": "No custom roles",
    "no_documents": "No documents",
    "no_links": "No links",
    "no_media": "No media files",
    "no_roles_msg": "There is no role available, would you like to create one?",
    "no_tasks_msg": "There is no tasks available, would you like to create one?",
    "no_users_msg": "There is no user available, would you like to create one?",
    "non_rule_action": "Non-rule based action",
    "note": "Note",
    "note_deleted": "Note deleted",
    "note_desc": "What is the note about?",
    "notes": "Notes",
    "notification": {
      "cancel": "I'll do it later",
      "confirm": "Yes",
      "message": "Never miss out messages! Allow Raincheck to send you desktop notifications?"
    },
    "notification_disabled_msg": "You've disallowed notifications in your browser. You'll need to open your browser preferences to change that.",
    "notification_email": "Notification email",
    "notify_me": "Notify me when...",
    "num_of_users": "Number of users",
    "office_address": "Office Address",
    "office_hours": "Office Hours",
    "online_user": "Online User",
    "operations_settings": "Operations Settings",
    "option": "Option",
    "option1_widget": "Option 1: Embed Basic JavaScript for Website’s Visitors",
    "option2_widget": "Option 2: Download plugin for your CMS",
    "option_name": "Option name",
    "or": "OR",
    "order": "order(s)",
    "order_cancelled": "Order cancelled",
    "order_number": "Order number (e.g S10001)",
    "order_refunded": "Order refunded",
    "order_status": {
      "any": "Any",
      "cancelled": "Cancelled",
      "closed": "Closed",
      "open": "Open"
    },
    "order_title": "Order",
    "orders": "Orders",
    "orders_info": "Track your recent order(s) here",
    "orders_status": "Orders Status",
    "org_address_updated": "Organization address updated",
    "org_created": "Organization created",
    "org_fields": "Organisation Fields",
    "org_name": "Organization Name",
    "org_not_found": "Organisation not found",
    "org_updated": "Organisation updated",
    "organization": "Organisation",
    "organizations": "organisations",
    "orgs_deleted": "Organizations deleted",
    "other_apps": "Other Apps",
    "other_custom_groups": "Other Custom Groups",
    "other_settings_opt": "Other Settings (optional)",
    "overdue": "Overdue",
    "owner": "Owner",
    "page_channels_desc": "This integration allows your visitors/guests to interact with your team with all the available channels",
    "parent_company": "Parent Company",
    "password": "Password",
    "password_generator": "Password Generator",
    "password_set": "Password has been set successfully",
    "period_due": "Period Due",
    "permissions": "Permissions",
    "permissions_updated": "Permissions updated",
    "personalised_helpdesk": "Personalised your helpdesk",
    "phone": "Phone",
    "phone_number": "Phone Number",
    "play_sound": "Play a sound when there is a notification",
    "popup_create_channel": "There is no {channel} integration being setup. Would you like to add this channel?",
    "position": "Position",
    "postal_code": "Postal Code",
    "preferences": "Preferences",
    "preview": "Preview",
    "priorities": {
      "high": "High",
      "low": "Low",
      "medium": "Medium",
      "urgent": "Urgent"
    },
    "priority": "Priority",
    "priority_desc": "The priority of the support ticket/task",
    "priority_updated": "Priority updated",
    "processed_records": "Processed records",
    "processed_records_info": "The number of records being processed",
    "product_catalog": "Catalog",
    "profile": "Profile",
    "profile_details": "Profile Details",
    "profile_info": "Profile Qualification",
    "profile_updated": "Profile updated",
    "property": "Property",
    "public_chat_msg": "Anyone in your organisation can view and join this PUBLIC group chat.",
    "quick_response": "Quick Response",
    "r_u_sure_to_delete": "Data and rules associated with this flow will be deleted permanently and this action cannot be reversed",
    "r_u_sure_to_duplicate": "A copy of this flow will be created. You will be redirected to the detail page for further modification. Any changes made to this duplicate will not have an impact on the original copy",
    "reason": "Reason",
    "recipients": "Recipients",
    "redirect_url": "Redirect Url",
    "refund_amount": "Refund amount",
    "refund_order": "Refund Order {id}",
    "related_companies": "Related Companies",
    "related_orgs": "Related Organizations",
    "related_orgs_desc": "Add a parent or child company to track the organization structure",
    "reminders_desc": "Reminds users when the SLA target approaches",
    "reply": "Reply",
    "reply_buttons": "Reply Buttons",
    "reset_user_password": "Please, copy link {link} and send to {user}",
    "reset_user_password_email": "Email with reset link has been sent to {user}",
    "respond_soon": "We will respond as soon as possible",
    "response": "Response",
    "response_created": "Response \"{shortcut}\" created",
    "response_removed": "Response \"{shortcut}\" removed",
    "response_time": "Response Time",
    "response_updated": "Response \"{shortcut}\" updated",
    "restock_items": "Restock items",
    "right": "Right",
    "role": "Role",
    "role_basic_settings": "Role Basic Settings",
    "role_type": "Type",
    "roles": "Roles",
    "room_deleted": "Room deleted",
    "room_details": "Room details",
    "rule_action": "Rule based action",
    "rule_saved": "Rule saved",
    "satisfaction": "Satisfaction",
    "satisfaction_rating": "Satisfaction rating",
    "satisfaction_ratings": "Satisfaction ratings",
    "satisfaction_trends": "Satisfaction Trends",
    "search": "Search",
    "search_by_title": "Search by title",
    "search_for_alt_user": "Search for alternative user",
    "search_members": "Search team members",
    "search_on_filter": "Search on filter",
    "search_order": "Search order",
    "search_user": "Search user...",
    "section": "Section",
    "security": "Security",
    "security_title": "Keep your conversation secured by setting up trusted domain",
    "select_sla": "Select one SLA response",
    "select_values": "Select one or more values",
    "selected_contact": "Selected contact",
    "selected_pages": "Selected Pages",
    "selected_tasks": "Selected Tasks",
    "set_audience": "Set your target audience",
    "set_ur_audience": "Set Your Audience",
    "settings": "Settings",
    "settings_saved": "Settings saved",
    "settings_updated": "Settings updated",
    "shop_cart": "Cart",
    "shopify": "Shopify",
    "shopify_description": "Automatically sync customers and orders from Shopify into Raincheck",
    "shortcut": "Shortcut",
    "signup_confirm_msg": "We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.",
    "slider": "Slider",
    "snooze_until": "Snooze until",
    "social_channels": "Social Channels",
    "social_id": "Social ID",
    "social_profiles": "Social Profiles",
    "social_type": "Social Type",
    "someone_added_note": "added a note on",
    "sound": "Sound",
    "source": "Source",
    "source_desc": "This is to determine where the support ticket/task is originated from, e.g. Live Chat, FB Messenger, WeChat & etc.",
    "starred_items": "Starred Items",
    "start_new_conversation": "Start a new conversation",
    "state": "State",
    "status": {
      "active": "Active",
      "added": "Added",
      "archived": "Archived",
      "assigned_to_me": "Assigned to (Me)",
      "assigned_to_others": "Assigned to Others",
      "changes_saved": "Changes saved",
      "channel_being_created": "Channel is being created...",
      "channel_being_edited": "Channel is being edited...",
      "closed": "Closed",
      "completed": "Completed",
      "confirmed": "Confirmed",
      "created": "Created",
      "deleted": "Deleted",
      "deleted_by_u": "You deleted this message",
      "deleted_msg": "This message was deleted",
      "delivered": "Delivered",
      "edited": "Edited",
      "enganged": "Enganged",
      "fulfilled": "Fulfilled",
      "in_progress": "In Progress",
      "inactive": "Inactive",
      "last_updated_by": "Last updated by",
      "loading": "Loading...",
      "loading_contacts": "Loading contacts...",
      "loading_msg": "Loading messages...",
      "open": "Open",
      "pending": "Pending",
      "pinned": "Pinned",
      "pinned_by_u": "Pinned by you",
      "primary": "Primary",
      "re_opened": "Re-Opened",
      "read": "Read",
      "removed": "Removed",
      "required": "Required",
      "resolved": "Resolved",
      "searching_country": "Searching country...",
      "searching_locales": "Searching locales...",
      "searching_timezones": "Searching timezones...",
      "sent": "Sent",
      "starred": "Starred",
      "success": "Success!",
      "system": "System",
      "tasks_selected": "{count} tasks selected",
      "unassigned": "Unassigned",
      "updated": "Updated",
      "user_defined": "User Defined",
      "was_created_on": "was created on"
    },
    "status_updated": "Status updated",
    "sub_title": "Subtitle/Description",
    "subject": "Subject",
    "subject_desc": "The title for the support ticket/task",
    "subtitle": "Sub Title",
    "tag": "Tag",
    "tags": "Tags",
    "target": "Target",
    "task": "Task",
    "task_created": "Task created",
    "task_id": "Task ID",
    "task_settings": "Task Settings",
    "tasks": "مهام",
    "tasks_filtered": "Tasks filtered",
    "tasks_merged": "Tasks merged",
    "tasks_notice": "Tasks notice",
    "teams": "Teams",
    "teams_availability": "Team’s Availability",
    "teams_availability_title": "Set your team’s availability (office hours) and response time",
    "telegram": "Telegram",
    "telegram_bot_info": "You may skip this step if you have an existing bot",
    "telegram_bot_token": "Bot Token",
    "telegram_bot_token_info": "Follow the instructions from BotFather to create a bot. Then copy and paste your bot token below.",
    "terminates_orchestration": "Terminates orchestration",
    "test_preview_email": "Test with a preview email",
    "test_recipient": "Test recipient",
    "test_recipient_email": "Test recipient email",
    "text": "Text",
    "text_button": "Text Button",
    "text_copied": "{title} copied",
    "theme": "Theme",
    "theme_color": "Theme Color",
    "theme_colour": "Theme Colour",
    "theme_info": "This allows you to change the theme of the widget",
    "things_to_remember": "Things to keep in mind",
    "ticket": "Ticket",
    "ticket_fields": "Ticket Fields",
    "ticket_task_subject": "Ticket/task subject",
    "tickets_by_category": "Tickets By Category",
    "tickets_source": "Tickets Distribution By Source",
    "time": {
      "after_secs": "After {count} seconds",
      "always": "Always",
      "closed_date": "Closed Date",
      "created_on": "Created on",
      "creation_date": "Creation Date",
      "day_1": "d",
      "due_date": "Due Date",
      "friday": "Friday",
      "hour": "hour",
      "hour_1": "h",
      "hours": "hours",
      "hrs": "hrs",
      "last_login": "Last Login",
      "last_seen": "Last seen",
      "last_seen_never": "Last seen never",
      "last_seen_on": "Last seen on",
      "last_updated": "Last Updated",
      "last_updated_date": "Last Updated Date",
      "mins": "mins",
      "minute_1": "m",
      "minutes": "minutess",
      "modified_on": "Modified on",
      "monday": "Monday",
      "never": "Never",
      "period": "Period",
      "saturday": "Saturday",
      "seconds": "seconds",
      "sunday": "Sunday",
      "thursday": "Thursday",
      "today": "Today",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "yesterday": "Yesterday"
    },
    "time_closed_date_desc": "The closure date of the support ticket/task where the status changed to \"CLOSED\"",
    "time_creation_date_desc": "The creation date of the support ticket/task",
    "time_due_date_desc": "The due date/time of the support ticket/task",
    "time_format": "Time Format",
    "time_last_updated_date_desc": "The last modified date of the support ticket/task. i.e. transition of status, change of assignee, comment, etc.",
    "time_on_site": "Time On Site",
    "time_title": "Time",
    "timeline": "Timeline",
    "timezone": "Timezone",
    "timezone_doesnt_exist": "Oops this timezone doesn't exist.",
    "title": "Title",
    "title_and_subtitle_color": "Title & SubTitle Color",
    "to": "to",
    "total": "Total",
    "total_tickets": "Total tickets",
    "trusted_domain": "Trusted Domain",
    "try_later": "Try again later.",
    "twitter": "Twitter DM",
    "twitter_access_token": "Access Token",
    "twitter_access_token_secret": "Access Token Secret",
    "twitter_consumer_key": "Consumer Key",
    "twitter_consumer_secret": "Consumer Secret",
    "twitter_environment": "Environment",
    "type": "Type",
    "u_r_currently": "You're currently",
    "unable_to_copy": "Oops, unable to copy",
    "unified_inbox": "Unified Inbox",
    "until": "until",
    "updated_records": "Updated records",
    "updated_records_info": "The number of existing records being updated to the system",
    "upload": "Upload",
    "upload_image": "Upload an Image",
    "ur_msg_here": "Your message here...",
    "urgent": "Urgent",
    "use_webhooks_ensure": "Ensure that \"Use webhooks\" setting is set to",
    "user": "User",
    "user_access_enabled": "Access enabled",
    "user_access_revoked": "Access revoked",
    "user_activated": "User Activated",
    "user_added_to_group": "User added to group",
    "user_created": "User Created",
    "user_deactivated": "User Deactivated",
    "user_deleted": "User deleted",
    "user_has_been_activated": "User has been activated",
    "user_has_been_deactivated": "User has been deactivated",
    "user_product_access": "Product Access",
    "user_product_role": "Product Role",
    "user_product_role_changed": "Product role changed",
    "user_removed_from_group": "User removed from group",
    "user_role_admin": "Administrator",
    "user_role_member": "Member",
    "user_role_owner": "Owner",
    "user_with_email": "With email?",
    "username": "Username",
    "users": "Users",
    "visit": "Visit",
    "visitor": "Visitor",
    "visitor_idle_time": "Visitor Idle Time",
    "visitor_idle_warning_msg": "Visitor Idle Warning Message",
    "visitor_session_timeout": "Visitor Session Time-out",
    "visitor_timeout_msg": "Visitor Time-out Message",
    "visitors": "Visitors",
    "wait": "Wait",
    "watcher": "Watcher",
    "watchers": "Watchers",
    "webhook_URL": "Webhook URL",
    "webhook_URL_info": "Copy and past the webhook URL here into \"Modify Configuration\" -> URL settings.",
    "webhook_token": "Webhook Token",
    "webhook_token_info": "Copy and past the webhook URL here into \"Modify Configuration\" -> Token settings.",
    "website": "Website",
    "wechat": "WeChat",
    "wechat_app_id": "App ID",
    "wechat_app_id_info": "Copy the value of your App ID",
    "wechat_app_secret": "App Secret",
    "wechat_app_secret_info": "Copy the value of your App Secret",
    "wechat_dashboard": "WeChat Dashboard",
    "wechat_id": "WeChat ID",
    "wechat_safe_mode": "Safe Encryption mode (Optional)",
    "wechat_safe_mode_info": "To configure message encryption in safe mode, copy the value of your EncodingAESKey here",
    "wechat_secret": "WeChat Secret",
    "welcome_back_to": "Welcome back to",
    "welcome_msg": "Welcome to Raincheck family",
    "whatsapp_number": "WhatsApp number",
    "when": "When",
    "whitelist_our_ip": "Whitelist Our IP",
    "whitelisted_domain": "Whitelisted domain",
    "whitelisted_domains": "Whitelisted Domains",
    "widget_avatar": "Custom Launcher Avatar",
    "widget_cfg": "Widget Configuration",
    "widget_configuration": "Widget Configuration",
    "widget_control": "Widget Control",
    "widget_default_assistant": "Default Flow",
    "widget_installation": "Widget Installation",
    "widget_installation_title": "Ready to rock and roll, install the widget on your site now.",
    "widget_launcher": "Widget Launcher",
    "widget_launcher_visibility": "Widget Launcher Visibility",
    "widget_position": "Lancher Position",
    "widget_position_info": "This allows you to change the position of the widget",
    "widget_position_title": "Play a sound when there is incoming message",
    "widget_pre_chat_form": "Pre-chat Form",
    "widget_replies_in_day": "Typically replies in a day",
    "widget_replies_in_hours": "Typically replies in a few hours",
    "widget_replies_in_mins": "Typically replies in a few minutes",
    "widget_trigger": "Trigger",
    "widget_welcome_message": "Welcome Message",
    "yes": "Yes",
    "you": "You"
  };
  _exports.default = _default;
});