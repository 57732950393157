define("inboxfront/components/tasks/create-sidenav", ["exports", "inboxfront/config/environment", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _environment, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    isFiledUploaded: true,
    dropzone: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
      this.set('fileUploadPath', _environment.default.apollo.apiURL);
      this.set('uploadQuery', _mutation.default.UPLOAD_ATTACHMENT); // this.resetTaskForm();

      var systemFormFields = ['title', 'description', 'issueType', 'source', 'category', 'guest', 'assignedTo', 'department', 'priority', 'status', 'dueDate'];
      this.get('fields') && this.get('fields').forEach(function (f) {
        if (f.isSystem) {
          f.visible = systemFormFields.indexOf(f.id) >= 0;
        } else {
          f.visible = true;
        }
      });
    },
    uploadSuccess: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        var attachment = result.data.file.attachment;
        controller.get("attachmentIds").pushObject(attachment);
      };
    }),
    options: Ember.computed('task.id', function () {
      var uploadQuery = this.get('uploadQuery');
      var variables = '{"appLabel": "helpdesk", "model": "ticket"}';
      var token = this.get('session').get('data.authenticated.access_token') || undefined;
      return {
        paramName: 'file',
        url: _environment.default.apollo.apiURL,
        addRemoveLinks: false,
        autoProcessQueue: true,
        clickable: true,
        dictDefaultMessage: this.intl.t('drop_files', {
          count: 16
        }),
        method: 'POST',
        headers: {
          "Cache-Control": null,
          "X-Requested-With": null,
          Accept: null,
          Authorization: "Bearer " + token
        },
        params: {
          operationName: "uploadAttachment",
          query: uploadQuery,
          variables: variables
        }
      };
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.resetTaskForm();
    },
    actions: {
      closeSidenav: function closeSidenav() {
        this.set('open', false);
        this.onClose && this.onClose();
      },
      createTask: function createTask() {
        var _this = this;

        var task = this.get('task');
        this.get('tasks').save(task, function () {
          _this.saveTags(task.id);

          if (_this.get('attachmentIds')) {
            _this.updateAttachment(task.id);

            _this.set('open', false);
          }

          _this.onCreate && _this.onCreate(task);
        });
      },
      checkTaskForm: function checkTaskForm() {
        this._validateTask();
      },
      searchContact: function searchContact(fieldId, value) {
        var _this2 = this;

        var propertyId = this.get('task.propertyId');

        if (this.get('readOnlyFields.guest') || !propertyId) {
          return;
        }

        if (value && value.length > 2) {
          this.set('showSearch', true);
          var hash = {
            page: 'GUEST',
            q: value,
            properties: [propertyId]
          };
          this.ajax.query(_query.default.SEARCH_GUEST, 'search', hash).then(function (data) {
            _this2.set('filterContacts', data);

            _this2.set('guestPropertyId', propertyId);
          });

          this._validateTask();
        } else {
          this.set('filterContacts', []);
          this.set('showSearch', false);
        }
      },
      selectContact: function selectContact(contact) {
        contact.primaryEmail = contact.email;
        contact.personalPhone = contact.phone;
        contact.propertyId = this.get('guestPropertyId');
        var readOnlyFields = this.get('readOnlyFields') || {};
        Ember.set(readOnlyFields, 'firstName', !!contact.firstName);
        Ember.set(readOnlyFields, 'lastName', !!contact.lastName);
        this.set('readOnlyFields', readOnlyFields);
        this.get('task').setGuest(contact);
        this.set('filterContacts', []);
        this.set('showSearch', false);
      },
      deselectContact: function deselectContact() {
        this._deselectContact();
      },
      updateDepartmentUsers: function updateDepartmentUsers() {
        var _this3 = this;

        var propertyId = this.get('task.propertyId');
        var guestPropertyId = this.get('task.guest.propertyId');

        if (guestPropertyId && guestPropertyId !== propertyId) {
          this._deselectContact();
        }

        var departmentId = this.get('task.department.id');

        if (!propertyId || !departmentId) {
          return;
        }

        var hash = {
          department: departmentId,
          propertyId: propertyId
        };
        this.ajax.query(_query.default.HAVE_TASK_ACCESS, 'haveAccess', hash).then(function (result) {
          _this3.set('departmentUsers', result);

          var selectedUser = _this3.get('task.assignedTo');

          if (selectedUser && !result.findBy('id', selectedUser.id)) {
            _this3.set('task.assignedTo', null);
          }

          _this3._validateTask();
        });
      },
      updateAssignedTo: function updateAssignedTo(value) {
        this.set('task.assignedTo', value);

        this._validateTask();
      }
    },
    resetTaskForm: function resetTaskForm() {
      var data = {
        priority: 'LOW',
        status: 'OPEN',
        source: 'NA',
        category: 'TASK',
        assignedBy: this.get('user')
      };
      var additionalData = this.get('additionalData');

      if (additionalData) {
        Object.keys(additionalData).forEach(function (k) {
          data[k] = additionalData[k];
        });
      }

      var properties = this.get('user.properties');

      if (properties.length === 1) {
        data.companyProperty = properties[0];
      }

      var task = this.get('tasks').create(this.get('fields'), data);
      this.set('task', task);
      this.set('attachmentIds', []);
      this.set('selectedTags', []);
      console.log('RESET CREATE TASK', task);

      this._validateTask();
    },
    _validateTask: function _validateTask() {
      this.get('task').validate();
    },
    _deselectContact: function _deselectContact() {
      var readOnlyFields = this.get('readOnlyFields') || {};
      Ember.set(readOnlyFields, 'firstName', false);
      Ember.set(readOnlyFields, 'lastName', false);
      this.set('readOnlyFields', readOnlyFields);
      this.get('task').setGuest(null);

      this._validateTask();
    },
    saveTags: function saveTags(taskId) {
      var _this4 = this;

      this.get('selectedTags').forEach(function (t) {
        var hash = {
          appLabel: 'helpdesk',
          objectModel: 'ticket',
          objectId: taskId,
          tag: t.tag
        };

        _this4.ajax.mutation(_mutation.default.CREATE_TAG, 'createTag', hash);
      });
    },
    updateAttachment: function updateAttachment(taskId) {
      var ids = this.get('attachmentIds');

      for (var i = ids.length - 1; i >= 0; i--) {
        var hash = {
          appLabel: "helpdesk",
          model: "ticket",
          objectId: taskId,
          id: ids[i].id
        };
        this.ajax.mutation(_mutation.default.UPLOAD_ATTACHMENT, 'uploadAttachment', hash);
      }
    }
  });

  _exports.default = _default;
});