define("inboxfront/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "inboxfront/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var grant = {};

  switch (_environment.default.environment) {
    case 'staging':
      grant = {
        clientId: 'B3nTLetcXMuBu6NZW1FOrOFkS6X8AbesK0xF9DBt',
        serverTokenEndpoint: 'https://dev-backend.raincheck.co/oauth/token/'
      };
      break;

    case 'production':
      grant = {
        clientId: 'B3nTLetcXMuBu6NZW1FOrOFkS6X8AbesK0xF9DBt',
        // TODO: Change Client Id
        serverTokenEndpoint: 'https://api.raincheck.co/oauth/token/'
      };
      break;

    case 'demo':
      grant = {
        clientId: 'B3nTLetcXMuBu6NZW1FOrOFkS6X8AbesK0xF9DBt',
        // TODO: Change Client Id
        serverTokenEndpoint: 'https://demo-backend.ultro.io/oauth/token/'
      };
      break;

    default:
      grant = {
        clientId: 'B3nTLetcXMuBu6NZW1FOrOFkS6X8AbesK0xF9DBt',
        serverTokenEndpoint: 'https://dev-backend.raincheck.co/oauth/token/'
      };
      break;
  }

  var _default = _oauth2PasswordGrant.default.extend(grant);

  _exports.default = _default;
});