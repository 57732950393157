define("inboxfront/components/chat/ecommerce/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    totalPrice: Ember.computed('cart.items.[]', function () {
      var totalPrice = 0;
      (this.get('cart.items') || []).forEach(function (p) {
        totalPrice += +p.price * p.quantity;
      });
      return totalPrice;
    })
  });

  _exports.default = _default;
});