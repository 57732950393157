define("inboxfront/controllers/setpassword", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    isSuccess: false,
    isLongEnough: false,
    isUpperCase: false,
    isLowerCase: false,
    isSymbol: false,
    password: '',
    isValidPassword: Ember.computed('password', 'confirmPassword', function () {
      var password = this.get('password');
      var confirmPassword = this.get('confirmPassword');
      var upperCasePattern = /[A-Z]+/;
      var lowerCasePattern = /[a-z]+/;
      var numberOrSymbolPattern = /^(?=^.{1,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*)(?=.*).*$$/;
      var newPasswordValid = password.length > 7 && upperCasePattern.test(password) && lowerCasePattern.test(password) && numberOrSymbolPattern.test(password);
      var confirmedValid = confirmPassword === password;
      this.set('isLongEnough', password.length > 7);
      this.set('isUpperCase', upperCasePattern.test(password));
      this.set('isLowerCase', lowerCasePattern.test(password));
      this.set('isSymbol', numberOrSymbolPattern.test(password));
      return newPasswordValid && confirmedValid;
    }),
    actions: {
      setPassword: function setPassword() {
        var _this = this;

        var hash = {
          activationKey: this.get('model.key'),
          password: this.get('password')
        };
        this.ajax.query(_mutation.default.SET_PASSWORD, 'setPassword', hash).then(function (result) {
          if (result.success) {
            _this.set('isSuccess', true);
          } else {
            _this.get('paperToaster').show(_this.intl.t('network_error'));
          }
        }).catch(function () {
          return _this.get('paperToaster').show(_this.intl.t('network_error'));
        });
      },
      togglePassword: function togglePassword(e) {
        if (e.currentTarget.classList.contains('svg-icon-wrap')) {
          e.currentTarget.parentNode.childNodes.forEach(function (childNode) {
            if (childNode.tagName === 'INPUT') {
              childNode.type = childNode.type === 'text' ? 'password' : 'text';
            }
          });
        }
      },
      redirectToLogin: function redirectToLogin() {
        this.transitionToRoute('login');
      }
    }
  });

  _exports.default = _default;
});