define("inboxfront/templates/components/dashboard/agent-responsiveness-heatmap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lb4q4tFY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"agent-response-heatmap\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/dashboard/agent-responsiveness-heatmap.hbs"
    }
  });

  _exports.default = _default;
});