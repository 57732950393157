define("inboxfront/templates/components/kb/preview-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAGmfN7B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"open\",\"clickOutsideToClose\",\"class\",\"onClose\"],[[24,[\"open\"]],true,\"preview-article\",[28,\"action\",[[23,0,[]],[23,0,[\"closePopupPreview\"]]],null]]],{\"statements\":[[4,\"paper-dialog-content\",null,null,{\"statements\":[[4,\"unless\",[[24,[\"isLoadContent\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"kb/preview\",null,[[\"base\",\"section\",\"category\",\"article\"],[[24,[\"base\"]],[24,[\"section\"]],[24,[\"category\"]],[24,[\"article\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isLoadContent\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,\"application-loading\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/kb/preview-dialog.hbs"
    }
  });

  _exports.default = _default;
});