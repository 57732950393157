define("inboxfront/components/companies/create-sidenav", ["exports", "inboxfront/helpers/countries-list", "inboxfront/helpers/industries-list"], function (_exports, _countriesList, _industriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // companies: service(),
    tagName: '',
    COUNTRIES_LIST: _countriesList.COUNTRIES_LIST,
    INDUSTRIES_LIST: _industriesList.INDUSTRIES_LIST,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._resetCompanyForm();
    },
    companyCodeRequired: Ember.computed('company.industry', function () {
      return this.get('company.industry') === 'Travel, Hospitality, & Tourism';
    }),
    companyFields: Ember.computed('company', function () {
      return [{
        id: 'industry',
        label: this.intl.t('industry'),
        fieldType: 'SELECT',
        options: _industriesList.INDUSTRIES_LIST,
        required: true
      }, {
        id: 'code',
        label: this.intl.t('company_code'),
        fieldType: 'INPUT'
      }, {
        id: 'title',
        label: this.intl.t('company_name'),
        fieldType: 'INPUT',
        required: true
      }, {
        id: 'address',
        label: this.intl.t('company_address'),
        fieldType: 'TEXTAREA',
        required: true
      }, {
        id: 'postalCode',
        label: this.intl.t('postal_code'),
        fieldType: 'INPUT',
        required: true
      }, {
        id: 'propertyCountry',
        label: this.intl.t('country'),
        fieldType: 'SELECT',
        options: _countriesList.COUNTRIES_LIST,
        showProp: 'name',
        required: true
      }, {
        id: 'phoneNumber',
        label: this.intl.t('phone_number'),
        fieldType: 'PHONE'
      }, {
        id: 'website',
        label: this.intl.t('website'),
        required: true,
        fieldType: 'URL'
      }];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(value, field) {
          var valid = true;

          if (field.required === true) {
            valid = typeof value === 'string' ? value.trim() !== '' : value;
          }

          return valid;
        }
      }];
      this.websiteValidation = [this.trimValidation[0], {
        message: this.intl.t('forms.provide_website'),
        validate: function validate(value, field) {
          var valid = true;

          if (value && value.length) {
            var websitePatter = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; //eslint-disable-line

            valid = websitePatter.test(value);
          }

          return valid;
        }
      }];
    },
    actions: {
      closeSidenav: function closeSidenav() {
        this.set('open', false);
        this.onClose && this.onClose();
      },
      createCompany: function createCompany(createAnotherCompany) {
        var company = this.get('company');
        var hash = {
          industry: this.get('company.industry'),
          code: this.get('company.code'),
          title: this.get('company.title'),
          address: this.get('company.address'),
          postalCode: this.get('company.postalCode'),
          country: this.get('company.propertyCountry.iso2'),
          phoneNumber: this.get('company.phoneNumber'),
          website: this.get('company.website')
        }; // this.get('companies').save(company, () => {

        this.onCreate && this.onCreate(company, createAnotherCompany);

        if (createAnotherCompany) {
          this._resetCompanyForm();
        } else {
          this.set('open', false);
          this.onClose && this.onClose(true);
        } // });

      },
      validateCompany: function validateCompany() {
        this._validateCompany();
      }
    },
    _resetCompanyForm: function _resetCompanyForm() {
      var company = {
        industry: 'Travel, Hospitality, & Tourism',
        code: '',
        title: '',
        address: null,
        postalCode: '',
        propertyCountry: {},
        phoneNumber: '',
        website: '',
        isValid: false
      }; // this.get('companies').create(company, () => {

      this.set('company', company);

      this._validateCompany();
    },
    _validateCompany: function _validateCompany() {
      var _this = this;

      // this.get('company').validate();
      // validate() {
      var valid = true;

      try {
        var company = this.get('company');
        this.get('companyFields').forEach(function (field) {
          if (valid && _this.isInvalid(field, company[field.id])) {
            valid = false;
          }
        });
      } catch (e) {
        console.error(e);
      }

      this.set('company.isValid', valid); // }
    },
    isInvalid: function isInvalid(field, value) {
      var valid = true;

      if (field.fieldType === 'URL') {
        this.websiteValidation.forEach(function (validator) {
          valid = valid && validator.validate(value, field);
        });
      } else {
        this.trimValidation.forEach(function (validator) {
          valid = valid && validator.validate(value, field);
        });
      }

      return !valid;
    }
  });

  _exports.default = _default;
});