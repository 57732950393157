define("inboxfront/components/kb/preview", ["exports", "quill", "inboxfront/gql/query", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, Quill, _query, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    isLoadContent: false,
    isLoadContentInner: false,
    viewAll: false,
    viewScreen: '',
    viewContent: 'article',
    searchList: [],
    config: _environment.default,
    featuredArticles: Ember.computed('base.Featured.articles.[]', 'viewAll', function () {
      if (this.get('viewAll')) {
        return this.get('base.Featured.articles');
      }

      return (this.get('base.Featured.articles') || []).slice(0, 3);
    }),
    sections: Ember.computed('base.sections', function () {
      return this.get('base.sections');
    }),
    categories: Ember.computed('section', function () {
      return this.get('section.categories');
    }),
    logoUrl: Ember.computed('base.styles.logoUrl', function () {
      if (this.get('base.styles.logoUrl')) {
        return _environment.default.mediaUrl + this.get('base.styles.logoUrl');
      }

      return '';
    }),
    logoSize: Ember.computed('base.styles.logoSize', function () {
      return this.get('base.styles.logoSize') || '';
    }),
    backgroundStyle: Ember.computed('base.styles.{bgColor,bgUrl,}', function () {
      var styles = 'background-color:' + this.get('base.styles.bgColor');

      if (this.get('base.styles.bgUrl')) {
        styles += ';background-image:url(' + _environment.default.mediaUrl + this.get('base.styles.bgUrl') + ');';
      }

      return styles;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initEditor();
    },
    initEditor: function initEditor(data) {
      var options = {
        modules: {
          toolbar: false
        },
        readonly: true,
        theme: 'snow'
      };
      var quillEditor = new Quill('#editor-container-preview', options);

      if (quillEditor.container) {
        try {
          quillEditor.root.innerHTML = JSON.parse(this.get('article.body') || data.body);
        } catch (e) {
          quillEditor.root.innerHTML = '';
        }

        quillEditor.disable();
      }
    },
    actions: {
      onClickOutside: function onClickOutside() {
        this.set('filterArticles', []);
      },
      onChangeInput: function onChangeInput(val) {
        var _this = this;

        var hash = {
          page: 'KB',
          level: 'article',
          q: val
        };
        this.set('searchArticle', val);

        if (val.length > 1) {
          this.ajax.query(_query.default.SEARCH_ARTICLE, 'search', hash).then(function (data) {
            _this.set('filterArticles', data);
          });
        }
      },
      screenHome: function screenHome() {
        this.set('sections', this.get('base.sections'));
        this.set('section', null);
        this.set('category', null);
        this.set('article', null);
        this.set('viewScreen', 'home');
      },
      viewSection: function viewSection(section, category) {
        this.set('viewContent', 'section');
        this.set('viewScreen', 'section');
        this.set('section', section);
        this.set('article', null);

        if (category) {
          this.selectCategory(section.categories.find(function (item) {
            return item.id === category.id;
          }));
        } else {
          this.set('category', section.categories[0] || null);
        }
      },
      searchTagArticles: function searchTagArticles(tag) {
        var _this2 = this;

        var hash = {
          isTag: true,
          page: 'KB',
          level: 'article',
          q: tag.tag
        };
        this.ajax.query(_query.default.SEARCH_ARTICLE, 'search', hash).then(function (data) {
          _this2.set('viewScreen', 'tagArticle');

          _this2.set('selectTag', tag.tag);

          _this2.set('tagArticles', data);
        });
      },
      likeOrDislikeArticle: function likeOrDislikeArticle(article, action) {
        var _this3 = this;

        var hash = {
          articleId: article.id,
          action: action
        };
        this.ajax.query(_mutation.default.LIKE_DISLIKE_ARTICLE, 'likeDislikeArticle', hash).then(function () {
          _this3.set("article.".concat(action), _this3.get("article.".concat(action)) + 1);
        });
      },
      chooseCategory: function chooseCategory(category) {
        this.selectCategory(category);
      },
      chooseArticle: function chooseArticle(article, category) {
        this.showArticle(article, category);
      },
      showSearchArticle: function showSearchArticle(_ref) {
        var _this4 = this;

        var id = _ref.id,
            categoriesIds = _ref.categoriesIds,
            sectionId = _ref.sectionId;
        var categoryId = categoriesIds.split(',')[0];
        this.ajax.query(_query.default.ARTICLES, 'articles', {
          id: id,
          categories_Id: categoryId
        }).then(function (data) {
          _this4.ajax.query(_query.default.CATEGORIES_KB, 'categories', {
            sectionId: sectionId
          }).then(function (categories) {
            var article = data[0];
            var category = categories.find(function (c) {
              return c.id === categoryId;
            });

            _this4.set('searchArticle', null);

            _this4.set('filterArticles', []);

            _this4.set('article', article);

            _this4.set('category', category);

            _this4.set('categories', categories);

            _this4.set('section', category.section);

            _this4.set('viewScreen', 'section');

            _this4.set('viewContent', 'article');

            setTimeout(function () {
              _this4.initEditor(article);
            }, 0);
          });
        });
      }
    },
    selectCategory: function selectCategory(category) {
      this.set('article', null);
      this.set('category', category);
      this.set('section', category.section);
      this.set('categories', category.section.categories);
      this.set('viewScreen', 'section');
      this.set('viewContent', 'category');
    },
    showArticle: function showArticle(article, category) {
      var _this5 = this;

      this.set('article', article);
      category = category || article.categories[0] || {};
      this.set('category', category);
      this.set('categories', category.section.categories);
      this.set('section', category.section);
      this.set('viewScreen', 'section');
      this.set('viewContent', 'article');
      setTimeout(function () {
        _this5.initEditor(article);
      }, 0);
    }
  });

  _exports.default = _default;
});