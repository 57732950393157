define("inboxfront/controllers/signup/index", ["exports", "inboxfront/gql/mutation"], function (_exports, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    inputType: 'password',
    inputConfirmedType: 'password',
    validEmail: false,
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.emailValidation = [{
        message: this.intl.t('forms.invalid_signup_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (emailPattern.test(inputValue)) _this2.changeEmail(inputValue);
          return emailPattern.test(inputValue);
        }
      }];
      this.passwordValidation = [{
        message: this.intl.t('forms.password_doesnt_match'),
        validate: function validate(confirmedPassword) {
          return confirmedPassword === _this2.get('password');
        }
      }];

      function jwtDecode(token) {
        if (typeof token !== "string") {
          throw new Error("Invalid token specified");
        }

        try {
          return JSON.parse(base64_url_decode(token.split(".")[1]));
        } catch (e) {
          throw new Error("Invalid token specified: " + e.message);
        }
      }

      function base64_url_decode(str) {
        var output = str.replace(/-/g, "+").replace(/_/g, "/");

        switch (output.length % 4) {
          case 0:
            break;

          case 2:
            output += "==";
            break;

          case 3:
            output += "=";
            break;

          default:
            throw "Illegal base64url string!";
        }

        try {
          return b64DecodeUnicode(output);
        } catch (err) {
          return atob(output);
        }
      }

      function b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
          var code = p.charCodeAt(0).toString(16).toUpperCase();

          if (code.length < 2) {
            code = "0" + code;
          }

          return "%" + code;
        }));
      }

      var _this = this;

      var session = this.get('session');

      window.googleSignUpCallback = function (response) {
        var hash = {
          token: response.credential
        };

        _this2.ajax.query(_mutation.default.GOOGLE_AUTH, 'googleJwtAuthentication', hash).then(function (res) {
          if (res.okay === 'true') {
            var responsePayload = jwtDecode(response.credential);
            var email = responsePayload.email;
            var password = response.credential;
            session.authenticate('authenticator:oauth2', email, password).catch(function (reason) {
              if (reason.responseJSON.error_description) {
                _this.get('paperToaster').show(_this2.intl.t('forms.login_failed'));
              }
            });
          }
        });
      };
    },
    changeEmail: function changeEmail(email) {
      var _this3 = this;

      this.ajax.query(_mutation.default.CHECK_NEW_EMAIL, 'checkEmail', {
        email: email
      }).then(function (data) {
        if (data.status === 400) {
          _this3.set('validEmail', false);

          _this3.get('paperToaster').show(_this3.intl.t('forms.invalid_email'));
        } else {
          _this3.set('validEmail', true);
        }
      }).catch(function () {
        return _this3.get('paperToaster').show(_this3.intl.t('network_error'));
      });
    },
    actions: {
      togglePassword: function togglePassword() {
        if (this.inputType === 'password') {
          this.set('inputType', 'text');
        } else {
          this.set('inputType', 'password');
        }
      },
      toggleConfirmedPassword: function toggleConfirmedPassword() {
        if (this.inputConfirmedType === 'password') {
          this.set('inputConfirmedType', 'text');
        } else {
          this.set('inputConfirmedType', 'password');
        }
      },
      register: function register() {
        var _this4 = this;

        var hash = {
          email: this.get('email'),
          password: this.get('password'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          category: this.get('categoryName')
        };

        if (!this.get('validEmail')) {
          this.get('paperToaster').show(this.intl.t('forms.invalid_email'));
          return;
        }

        this.ajax.query(_mutation.default.REGISTER_USER, 'registerUser', hash).then(function () {
          _this4.transitionToRoute('signup.active');
        }).catch(function () {
          return _this4.get('paperToaster').show(_this4.intl.t('network_error'));
        });
      }
    }
  });

  _exports.default = _default;
});