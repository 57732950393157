define("inboxfront/controllers/import", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation", "inboxfront/config/environment", "papaparse"], function (_exports, _query, _mutation, _environment, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    customfields: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['tab'],
    currentStep: 0,
    showConfirmDialog: false,
    showCreateFieldDialog: false,
    stepIsValid: false,
    initDropZone: false,
    updateAttachments: Ember.computed(function () {
      var controller = this;
      return function (response) {
        var result = JSON.parse(response.xhr.response);
        controller.set('attachmentId', result.data.file.attachment.id);
      };
    }),
    options: Ember.computed(function () {
      var uploadQuery = this.get('uploadQuery');
      var variables = '{"appLabel": "fileimport", "model": "csvmaps"}';
      var token = this.get('session').get('data.authenticated.access_token') || undefined;
      return {
        paramName: 'file',
        success: this.get('updateAttachments'),
        url: _environment.default.apollo.apiURL,
        maxFilesize: 1,
        addRemoveLinks: true,
        autoProcessQueue: true,
        previewsContainer: null,
        clickable: '#select-file',
        method: 'POST',
        acceptedFiles: '.csv,.xlsx',
        headers: {
          "Cache-Control": null,
          "X-requested-with": null,
          Accept: null,
          Authorization: "Bearer " + token
        },
        params: {
          operationName: "uploadAttachment",
          query: uploadQuery,
          variables: variables
        }
      };
    }),
    addedfile: Ember.computed(function () {
      var controller = this;
      return function (file) {
        if (file.name.indexOf('.xlsx') === file.name.length - 5) {
          var reader = new FileReader();

          reader.onload = function (e) {
            var results;

            try {
              var workbook = XLSX.read(e.target.result, {
                type: 'binary'
              });
              results = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                header: 1
              });
            } catch (e) {
              results = [];
            }

            controller.processData(file.name, results);
          };

          reader.readAsBinaryString(file);
        } else {
          _papaparse.default.parse(file, {
            complete: function complete(results) {
              controller.processData(file.name, results.data);
            }
          });
        }
      };
    }),
    availableFields: Ember.computed('unmappedFields.[]', function () {
      var fields = _toConsumableArray(this.get('unmappedFields'));

      fields.unshiftObject({
        id: 'ignore',
        label: 'Do not select'
      });
      fields.pushObject({
        id: 'create',
        label: 'Create custom field'
      });
      return fields;
    }),
    isContacts: Ember.computed('model.moduleName', function () {
      return this.get('model.moduleName') === 'Contacts';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('uploadQuery', _mutation.default.UPLOAD_ATTACHMENT);
      this.set('dateFormats', ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD']);
      this.set('importHistory', []);
      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    actions: {
      cancelImport: function cancelImport() {
        if (this.get('currentStep') === 0) {
          if (this.get('isContacts')) {
            this.transitionToRoute('contacts');
          } else {
            this.transitionToRoute('organizations');
          }
        } else {
          this.set('currentStep', 0);
        }
      },
      startImport: function startImport() {
        this.resetImportForm();
        this.set('currentStep', 1);
        this.checkStep();
      },
      nextStep: function nextStep() {
        this.set('currentStep', this.get('currentStep') + 1);
        this.checkStep();
      },
      previousStep: function previousStep() {
        this.set('currentStep', this.get('currentStep') - 1);
        this.checkStep();
      },
      closeImport: function closeImport() {
        this.updateImportData();
        this.set('currentStep', 0);
      },
      selectModule: function selectModule(moduleName) {
        if (this.get('model.moduleName') !== moduleName) {
          this.set('model.moduleName', moduleName);
          this.updateImportData();
        }
      },
      onUpdateProperty: function onUpdateProperty() {
        this.checkStep();
      },
      onUpdateProcessName: function onUpdateProcessName(value) {
        this.set('importProcessName', value);
        this.checkStep();
      },
      replaceFile: function replaceFile() {
        this.resetDropZone();
        this.checkStep();
      },
      mapField: function mapField(column, field) {
        if (field.id === 'create') {
          this.set('selectedColumn', column);
          this.set('newField', {
            label: '',
            description: '',
            required: false,
            type: this.get('model.fieldTypes')[0]
          });
          this.checkFieldForm();
          this.set('showCreateFieldDialog', true);
        } else {
          this._mapField(column, field);
        }
      },
      updateField: function updateField(name, value) {
        Ember.set(this.get('newField'), name, value);
        this.checkFieldForm();
      },
      createContactField: function createContactField() {
        var _this = this;

        var settingsId = this.get('isContacts') ? this.get('model.guestSettings.id') : this.get('model.organizationSettings.id');
        this.get('customfields').updateField(this.get('newField'), settingsId, function (res) {
          if (res.field) {
            var field = res.field;
            Ember.set(field, 'variants', []);
            Ember.set(field, 'required', false); //ignore required custom fields

            _this.get('fields').pushObject(field);

            _this._mapField(_this.get('selectedColumn'), field);

            _this.set('showCreateFieldDialog', false);
          } else {
            _this.get('paperToaster').show(_this.intl.t('internal_error') + ' ' + _this.intl.t('try_later'));
          }
        });
      },
      sendData: function sendData() {
        var _this2 = this;

        if (!this.get('attachmentId')) {
          this.get('paperToaster').show(this.intl.t('internal_error') + ' ' + this.intl.t('try_later'));
        }

        var hash = {
          typeId: this.get('isContacts') ? 0 : 2,
          propertyId: this.get('selectedPropertyId'),
          processName: this.get('importProcessName'),
          hasHeader: true,
          delimiter: ',',
          attachmentId: this.get('attachmentId'),
          dateFormat: this.get('selectedDateFormat')
        };
        var mappings = [];
        this.get('columns').forEach(function (c) {
          if (c.field && c.field.id !== 'ignore') {
            mappings.pushObject({
              value: c.field.id,
              order: c.index
            });
          }
        });
        hash.mappings = mappings;
        this.ajax.mutation(_mutation.default.UPLOAD_CSV, 'uploadCsv', hash, function (res) {
          _this2.set('showConfirmDialog', false);

          _this2.set('currentStep', 3);
        });
      }
    },
    processData: function processData(fileName, data) {
      var fields = this.get('fields');
      var headersFromFile = data[0];
      var previews = data[1];
      var columns = [];
      var unmappedColumns = [];
      headersFromFile && headersFromFile.forEach(function (header, index) {
        var headerLC = header.toLowerCase();
        var mapped = false;
        var column = {
          index: index,
          label: header,
          field: null,
          preview: previews[index] || ''
        };
        fields.forEach(function (f) {
          if (f.variants.indexOf(headerLC) >= 0 || f.label.toLowerCase() === headerLC || f.id === headerLC) {
            column.field = f;
            mapped = true;
          }
        });

        if (!mapped) {
          unmappedColumns.pushObject(column);
        }

        columns.pushObject(column);
      });

      if (columns.length === unmappedColumns.length) {
        this.set('errorFileFormat', true);
        this.set('isFileUploaded', false);
      } else {
        this.set('errorFileFormat', false);
        this.set('fileName', fileName);

        if (!this.get('importProcessName')) {
          this.set('importProcessName', fileName);
        }

        this.set('isFileUploaded', true);
      }

      this.set('columns', columns);
      this.set('unmappedColumns', unmappedColumns);
      this.updateUnmappedFields();
      this.checkStep();
    },
    _mapField: function _mapField(column, field) {
      Ember.set(column, 'field', field);
      this.get('unmappedColumns').removeObject(column);
      this.updateUnmappedFields();
    },
    updateUnmappedFields: function updateUnmappedFields() {
      var columns = this.get('columns');
      var fields = this.get('fields');
      var unmappedFields = [];
      var requiredUnmappedFields = [];
      fields.forEach(function (f) {
        if (!columns.findBy('field', f)) {
          unmappedFields.pushObject(f);

          if (f.required) {
            requiredUnmappedFields.pushObject(f);
          }
        }
      });
      this.set('unmappedFields', unmappedFields);
      this.set('requiredUnmappedFields', requiredUnmappedFields);
      this.checkStep();
    },
    checkStep: function checkStep() {
      var stepIsValid = true;

      switch (this.get('currentStep')) {
        case 1:
          //upload
          if (!this.trimValidation[0].validate(this.get('importProcessName'))) {
            stepIsValid = false;
          } else if (!this.get('selectedPropertyId')) {
            stepIsValid = false;
          } else if (!this.get('isFileUploaded')) {
            stepIsValid = false;
          }

          break;

        case 2:
          //map
          if (this.get('requiredUnmappedFields.length')) {
            stepIsValid = false;
          }

          break;
      }

      this.set('stepIsValid', stepIsValid);
    },
    checkFieldForm: function checkFieldForm() {
      var label = this.get('newField.label');
      this.set('fieldFormIsValid', this.trimValidation[0].validate(label) && label.length > 2);
    },
    updateImportData: function updateImportData() {
      var _this3 = this;

      var isContacts = this.get('isContacts');
      this.set('importHistory', []);
      this.set('fields', this.get(isContacts ? 'model.contactFields' : 'model.organizationFields'));
      this.ajax.query(isContacts ? _query.default.GUEST_IMPORT_HISTORY : _query.default.ORGANIZATION_IMPORT_HISTORY).then(function (result) {
        _this3.set('importHistory', result);
      });
    },
    resetImportForm: function resetImportForm() {
      this.set('selectedPropertyId', null);
      this.set('importProcessName', '');
      this.set('selectedDateFormat', this.get('dateFormats')[0]);
      this.resetDropZone();
    },
    resetDropZone: function resetDropZone() {
      var _this4 = this;

      this.set('isFileUploaded', false);
      this.set('errorFileFormat', false);
      this.set('initDropZone', false);
      setTimeout(function () {
        return _this4.set('initDropZone', true);
      }, 100);
    }
  });

  _exports.default = _default;
});