define("inboxfront/components/popups/emoji-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    emoji: Ember.inject.service(),
    isOpen: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.groupsEmojis = [{
        title: 'Smileys & People',
        category: 'people',
        icon: 'emoji-people',
        isActive: true
      }, {
        title: 'Animals & Nature',
        category: 'nature',
        icon: 'emoji-nature',
        isActive: false
      }, {
        title: 'Food & Drink',
        category: 'foods',
        icon: 'emoji-food',
        isActive: false
      }, {
        title: 'Activity',
        category: 'activity',
        icon: 'emoji-activity',
        isActive: false
      }, {
        title: 'Travel & Places',
        category: 'places',
        icon: 'emoji-travel',
        isActive: false
      }, {
        title: 'Objects',
        category: 'objects',
        icon: 'emoji-objects',
        isActive: false
      }, {
        title: 'Symbols',
        category: 'symbols',
        icon: 'emoji-symbols',
        isActive: false
      }, {
        title: 'Flags',
        category: 'flags',
        icon: 'emoji-flags',
        isActive: false
      }];
      this.set('emojis', this.get('emoji').peopleGroup);
    },
    actions: {
      selectEmoji: function selectEmoji(emoji) {
        this.onSelectEmoji(emoji, this.get('messageId'));
        this.set('isOpen', false);
      },
      selectCategoryEmoji: function selectCategoryEmoji(group, event) {
        event.stopPropagation();
        var selectedGroup = this.get('groupsEmojis').map(function (emoji) {
          return _objectSpread(_objectSpread({}, emoji), {}, {
            isActive: emoji === group
          });
        });
        document.querySelector('.emojis-list').scrollTop = 0;
        this.set('groupsEmojis', selectedGroup);
        this.set('emojis', this.get('emoji').filterByCategory(group.category));
      },
      togglePicker: function togglePicker() {
        this.set('isOpen', !this.get('isOpen'));
      },
      onClickOutside: function onClickOutside() {
        this.set('isOpen', false);
      }
    }
  });

  _exports.default = _default;
});