define("inboxfront/routes/contacts/index", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    customfields: Ember.inject.service(),
    textLoading: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('textLoading', this.get('intl').t('status.loading_contacts'));
    },
    model: function model() {
      var _this = this;

      var guests = this.ajax.query(_query.default.GUEST_CONTACTS);
      var users = this.ajax.query(_query.default.PROPERTY_USERS);
      var guestSettings = this.ajax.query(_query.default.GUEST_SETTINGS, 'guestSettings');
      var properties = this.ajax.query(_query.default.PROPERTY_LIST);
      return Ember.RSVP.all([guests, users, guestSettings, properties]).then(function (res) {
        var fields = _this.get('customfields').prepare([], res[2]);

        return {
          guests: !res[0].errors ? res[0] : [],
          users: res[1].filter(function (user) {
            return user.staffprofile.chatAgent[0] && user.staffprofile.chatAgent[0].status === 'ONLINE';
          }),
          guestSettings: res[2],
          properties: res[3],
          fields: fields
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('guests', model.guests);
      var columns = [{
        id: 'phone',
        title: this.intl.t('phone')
      }, {
        id: 'country',
        title: this.intl.t('country')
      }, {
        id: 'company',
        title: this.intl.t('company')
      }, {
        id: 'organization',
        title: this.intl.t('organization')
      }, {
        id: 'contactOwner',
        title: this.intl.t('contact_owner')
      }, {
        id: 'created',
        title: this.intl.t('created'),
        type: 'DATE'
      }];
      model.fields.forEach(function (cf) {
        if (!cf.isSystem) {
          columns.pushObject({
            id: cf.id,
            title: cf.label,
            fieldType: cf.fieldType
          });
        }
      });
      var columnSettings;

      try {
        columnSettings = JSON.parse(model.guestSettings.fields);
      } catch (e) {
        columnSettings = [];
      }

      if (!Array.isArray(columnSettings)) {
        columnSettings = [];
      }

      columns.forEach(function (c) {
        var data = columnSettings.findBy('id', c.id) || {
          pos: 0,
          vis: true
        };
        Ember.set(c, 'position', +data.pos);
        Ember.set(c, 'visible', !!data.vis);
      });
      columns = columns.sortBy('position');
      controller.set('model.columns', columns);
    }
  });

  _exports.default = _default;
});