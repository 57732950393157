define("inboxfront/controllers/security", ["exports", "inboxfront/gql/mutation", "inboxfront/config/environment"], function (_exports, _mutation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    paperToaster: Ember.inject.service(),
    profile: Ember.inject.service(),
    isLongEnough: false,
    isUpperCase: false,
    isLowerCase: false,
    isSymbol: false,
    newPassword: '',
    profileUser: Ember.computed('sessionAccount.loggeduser', function () {
      return this.get('sessionAccount.loggeduser');
    }),
    profileAvatar: Ember.computed('profileUser', function () {
      return this.get('profileUser.attachment') ? _environment.default.mediaUrl + this.get('profileUser.attachment.datafile') : '';
    }),
    userStatus: Ember.computed('profile.userStatus', function () {
      return this.profile.userStatus;
    }),
    isValidPassword: Ember.computed('currentPassword', 'newPassword', 'confirmPassword', function () {
      var currentPassword = this.get('currentPassword');
      var newPassword = this.get('newPassword');
      var confirmPassword = this.get('confirmPassword');
      var upperCasePattern = /[A-Z]+/;
      var lowerCasePattern = /[a-z]+/;
      var numberOrSymbolPattern = /^(?=^.{1,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*)(?=.*).*$$/;
      var newPasswordValid = newPassword.length > 7 && upperCasePattern.test(newPassword) && lowerCasePattern.test(newPassword) && numberOrSymbolPattern.test(newPassword);
      var confirmedValid = confirmPassword === newPassword && confirmPassword !== currentPassword;
      this.set('isLongEnough', newPassword.length > 7);
      this.set('isUpperCase', upperCasePattern.test(newPassword));
      this.set('isLowerCase', lowerCasePattern.test(newPassword));
      this.set('isSymbol', numberOrSymbolPattern.test(newPassword));
      return newPasswordValid && confirmedValid;
    }),
    actions: {
      clearFields: function clearFields() {
        this._clearFields();
      },
      updatePassword: function updatePassword() {
        var _this = this;

        var hash = {
          userId: this.get('profileUser.id'),
          currentPassword: this.get('currentPassword'),
          password: this.get('newPassword')
        };
        var toaster = this.get('paperToaster');
        this.ajax.query(_mutation.default.UPDATE_PASSWORD, 'updatePassword', hash).then(function (res) {
          if (res.success) {
            _this._clearFields();

            toaster.show('Password Updated'); //this.get('session').invalidate();
          } else {
            toaster.show(res.error);
          }
        }).catch(function () {
          toaster.show('Invalid Current Password');
        });
      },
      togglePassword: function togglePassword(e) {
        if (e.currentTarget.classList.contains('svg-icon-wrap')) {
          e.currentTarget.parentNode.childNodes.forEach(function (childNode) {
            if (childNode.tagName === 'INPUT') {
              childNode.type = childNode.type === 'text' ? 'password' : 'text';
            }
          });
        }
      }
    },
    _clearFields: function _clearFields() {
      this.set('currentPassword', '');
      this.set('newPassword', '');
      this.set('confirmPassword', '');
    }
  });

  _exports.default = _default;
});