define("inboxfront/helpers/highchart-mock", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HIGH_CHART_MOCK = void 0;
  var HIGH_CHART_MOCK = {
    id: "Q29tcGFueVN0YXRpc3RpY3NUeXBlOjExODY1",
    date: (0, _moment.default)().format('YYYY-MM-DD'),
    totalMsgs: 3,
    totalMsgsFacebook: 4,
    totalMsgsLine: 24,
    totalMsgsTelegram: 12,
    totalMsgsEmail: 34,
    totalMsgsWhatsapp: 44,
    totalMsgsTwitter: 66,
    totalMsgsLivechat: 22,
    totalMsgsWechat: 10,
    totalChats: 100,
    visitorsByHours: "10",
    satisfactionRating: 40,
    avgResponseTime: 50,
    inboundMessagesPeak: JSON.stringify({
      '00-03': 3,
      '03-06': 2,
      '06-09': 4,
      '09-12': 0,
      '12-15': 12,
      '15-18': 1,
      '18-21': 10,
      '21-00': 4
    }),
    agentResponsiveness: JSON.stringify({
      '00-03': 39,
      '03-06': 21,
      '06-09': 4,
      '09-12': 10,
      '12-15': 42,
      '15-18': 17,
      '18-21': 12,
      '21-00': 3
    }),
    visitorsByLang: {
      edges: [{
        node: {
          id: "VG9wTGFuZ1R5cGU6NDE3MjA=",
          objectId: 11865,
          name: "ru-RU",
          count: 23
        }
      }, {
        node: {
          id: "VG9wTGFuZ1R5cGU6NDE3MjE=",
          objectId: 11865,
          name: "ru",
          count: 10
        }
      }, {
        node: {
          id: "VG9wTGFuZ1R5cGU6NDE3MjI=",
          objectId: 11865,
          name: "en-GB",
          count: 1
        }
      }, {
        node: {
          id: "VG9wTGFuZ1R5cGU6NDE3MjM=",
          objectId: 11865,
          name: "en-US",
          count: 103
        }
      }]
    },
    visitorsByCountry: {
      edges: [{
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NTk3",
          name: "Kyrgyzstan",
          count: 14,
          code: "KG"
        }
      }, {
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NTk4",
          name: "Unated States",
          count: 70,
          code: "US"
        }
      }, {
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NTk5",
          name: "Russia",
          count: 5,
          code: "RU"
        }
      }, {
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NjAw",
          name: "United States",
          count: 10,
          code: "DE"
        }
      }, {
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NjAx",
          name: "Kyrgyzstan",
          count: 1,
          code: "KG"
        }
      }, {
        node: {
          id: "VG9wQ291bnRyeVR5cGU6MTc3NjA3",
          name: "Singapore",
          count: 2,
          code: "SG"
        }
      }]
    },
    likes: 20,
    dislikes: 50,
    avgResolutionTime: 10,
    overdueTickets: 25,
    closedTickets: 10,
    openTickets: 22,
    totalTickets: 48,
    enquiryTickets: 1,
    incidentTickets: 3,
    cancellationTickets: 2,
    complaintTickets: 10,
    company: {
      id: "Q29tcGFueVR5cGU6ZmNiYTVjYjUtY2EyYy00ZTY0LWFiM2QtNmI0M2E5OGYzMjE1",
      title: "Molecule"
    }
  };
  _exports.HIGH_CHART_MOCK = HIGH_CHART_MOCK;
  var _default = {
    HIGH_CHART_MOCK: HIGH_CHART_MOCK
  };
  _exports.default = _default;
});