define("inboxfront/components/dashboard/agent-responsiveness-heatmap", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _component = this;

      _highcharts.default.chart({
        chart: {
          renderTo: 'agent-response-heatmap',
          height: 315
        },
        title: {
          text: this.intl.t('agent_response'),
          align: 'left',
          floating: true
        },
        xAxis: {
          categories: this.get('listDateMin'),
          title: null,
          opposite: true
        },
        yAxis: {
          categories: ['09-12am', '06-09pm', '03-06pm', '12-03pm', '09-12pm', '06-09am', '03-06am', '12-03am'],
          title: null
        },
        legend: {
          align: 'right',
          borderColor: '#ffffff',
          verticalAlign: 'top',
          margin: 0,
          title: {
            style: {
              'fontWeight': 'normal'
            }
          }
        },
        colorAxis: {
          minColor: '#cbebff',
          maxColor: '#215db2',
          labels: {
            enabled: false
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          formatter: function formatter() {
            return this.point.value + "</b> " + _component.intl.t('messages_on_something') + " <br><b>" + this.series.xAxis.categories[this.point.x] + " - " + this.series.yAxis.categories[this.point.y] + "</b>";
          }
        },
        series: [{
          type: 'heatmap',
          borderWidth: 2,
          borderColor: '#ffffff',
          showInLegend: false,
          data: _component.calcDate()
        }]
      });
    },
    calcDate: function calcDate() {
      var pickGraph = this.get('pickGraph');
      var listDate = this.get('listDate');
      var arr = [];

      var _loop = function _loop(l) {
        var filterDate = pickGraph.filter(function (el) {
          return el.node.date === listDate[l];
        });

        if (filterDate.length) {
          var newArr = [];
          filterDate.forEach(function (el) {
            var parseObj = el.node.agentResponsiveness ? Object.entries(JSON.parse(el.node.agentResponsiveness)) : null;

            if (parseObj) {
              if (newArr.length) {
                for (var t = 0; t < 8; t++) {
                  newArr[t][2] += parseObj[t][1];
                }
              } else {
                for (var _t = 0; _t < 8; _t++) {
                  var obj = [0, 0, 0];
                  obj[0] = l;
                  obj[1] = _t;
                  obj[2] = parseObj[_t][1];
                  newArr.push(obj);
                }
              }
            }
          });
          arr.push.apply(arr, newArr);
        } else {
          for (var t = 0; t < 8; t++) {
            var obj = [0, 0, 0];
            obj[0] = l;
            obj[1] = t;
            arr.push(obj);
          }
        }
      };

      for (var l = 0; l < 7; l++) {
        _loop(l);
      }

      return arr;
    }
  });

  _exports.default = _default;
});