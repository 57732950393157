define("inboxfront/components/helpdesk/task-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onClick: function onClick(e) {
        if (e.ctrlKey) {
          this.onSelect();
        }
      },
      selectWatcher: function selectWatcher(task) {
        this.onSelectWatcher(task);
      }
    }
  });

  _exports.default = _default;
});