define("inboxfront/components/common/buttons/cancel", ["exports", "inboxfront/components/common/buttons/secondary-2"], function (_exports, _secondary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _secondary.default.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('defaultText', this.get('intl').t('actions.cancel'));
    }
  });

  _exports.default = _default;
});