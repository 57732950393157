define("inboxfront/components/internal-chat-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    emoji: Ember.inject.service(),
    raincheckbot: {
      firstName: '',
      lastName: '',
      username: 'raincheck.bot'
    },
    isToday: Ember.computed('message', function () {
      var currentDate = new Date(this.get('message.node.created')).getDate();
      var now = new Date().getDate();
      return currentDate === now;
    }),
    currentDate: Ember.computed('message', function () {
      var date = new Date();
      var currentDate = new Date(this.get('message.node.created')).getDate();
      var today = date.getDate();
      var yesterday = date.setDate(date.getDate() - 1);

      if (currentDate === today) {
        return this.intl.t('time.today');
      } else if (currentDate === yesterday) {
        return this.intl.t('time.yesterday');
      } else {
        return (0, _moment.default)(this.get('message.node.created')).format('LL');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      moveThread: function moveThread(id, user) {
        this.moveThread(id, user);
      },
      deleteMessage: function deleteMessage(id) {
        this.deleteMessage(id);
      },
      onDeleteEmoji: function onDeleteEmoji(data, messageId) {
        this.onDeleteEmoji(data, messageId);
      },
      onAddEmoji: function onAddEmoji(data, messageId) {
        this.onAddEmoji(data, messageId);
      },
      editMessage: function editMessage(message) {
        this.editMessage(message);
      },
      onSelectEmoji: function onSelectEmoji(emoji, messageId) {
        this.onSelectEmoji(emoji, messageId);
      },
      pinMessage: function pinMessage(msgId) {
        this.pinMessage(msgId);
      },
      bookmarkMessage: function bookmarkMessage(msg) {
        this.bookmarkMessage(msg);
      },
      deleteMessageReminder: function deleteMessageReminder(reminder) {
        this.deleteMessageReminder(reminder);
      }
    }
  });

  _exports.default = _default;
});