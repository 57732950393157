define("inboxfront/components/chat-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateMessagesThread: function updateMessagesThread(thread) {
        this.updateMessagesThread(thread);
      },
      updateThreadQuery: function updateThreadQuery(data) {
        this.updateThreadQuery(data);
      }
    }
  });

  _exports.default = _default;
});