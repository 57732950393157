define("inboxfront/templates/components/common/grid/action-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/4wu3av/",
    "block": "{\"symbols\":[\"menu\",\"content\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[24,[\"permission\"]]],null],[28,\"user-has-permission\",[[24,[\"permission\"]]],null]],null]],null,{\"statements\":[[4,\"paper-menu\",null,[[\"position\"],[\"target-right target\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"trigger\"]],\"expected `menu.trigger` to be a contextual component but found a string. Did you mean `(component menu.trigger)`? ('inboxfront/templates/components/common/grid/action-dropdown.hbs' @ L3:C11) \"],null]],null,{\"statements\":[[4,\"paper-button\",null,[[\"iconButton\"],[true]],{\"statements\":[[0,\"                \"],[1,[28,\"paper-icon\",[\"more_vert\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `menu.content` to be a contextual component but found a string. Did you mean `(component menu.content)`? ('inboxfront/templates/components/common/grid/action-dropdown.hbs' @ L8:C11) \"],null]],null,{\"statements\":[[0,\"            \"],[14,3,[[28,\"hash\",null,[[\"item\"],[[28,\"component\",[\"common/grid/action-dropdown-item\"],[[\"content\"],[[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/grid/action-dropdown.hbs"
    }
  });

  _exports.default = _default;
});