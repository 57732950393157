define("inboxfront/templates/components/page-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hysHq5iQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"page-header\",null,null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"empty\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[11,\"class\",[29,[\"page-wrapper admin \",[28,\"if\",[[24,[\"class\"]],[24,[\"class\"]]],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"admin-menu\",[28,\"if\",[[24,[\"isCollapsed\"]],\" closed\",\"\"],null]]]],[8],[0,\"\\n\"],[4,\"ember-scrollable\",null,null,{\"statements\":[[0,\"            \"],[1,[28,\"settings-menu\",null,[[\"currentIcon\",\"menuCollection\",\"currentLink\"],[[24,[\"currentIcon\"]],[24,[\"menuCollection\"]],[24,[\"currentLink\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"page-inner-wrapper\"],[8],[0,\"\\n        \"],[14,1,[[28,\"hash\",null,[[\"title\"],[[28,\"component\",[\"page-title\"],[[\"icon\",\"showButton\",\"isCollapsed\"],[[24,[\"currentIcon\"]],[24,[\"showButton\"]],[24,[\"isCollapsed\"]]]]]]]]]],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"page-inner-content\"],[8],[0,\"\\n            \"],[14,1,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"page-content\"],null]]]]]],[0,\"\\n        \"],[9],[0,\"\\n        \"],[14,1,[[28,\"hash\",null,[[\"footer\"],[[28,\"component\",[\"page-footer\"],null]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/page-admin.hbs"
    }
  });

  _exports.default = _default;
});