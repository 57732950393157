define("inboxfront/components/chat/users-whos-read-last-message", ["exports", "inboxfront/gql/query"], function (_exports, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    lastMessageId: null,
    whosRead: Ember.computed('users', function () {
      var users = this.get('users') || [];
      return {
        firstTen: users.slice(0, 10),
        last: users.length - 10
      };
    }),
    didRender: function didRender() {
      var _this = this;

      var messages = this.get('messages');

      if (messages.length === 0) {
        this.set('lastMessageId', null);
        return;
      }

      if (this.get('lastMessageId') === messages[messages.length - 1].node.id) {
        //to prevent rerendering
        return;
      }

      this.set('lastMessageId', messages[messages.length - 1].node.id);
      this.ajax.query(_query.default.GET_WHOS_READ_CHAT_MESSAGE, 'chatMessage', {
        messageId: this.get('lastMessageId')
      }).then(function (res) {
        return _this.set('users', res.whosRead);
      }).catch(function () {
        return _this.set('users', []);
      });
    }
  });

  _exports.default = _default;
});