define("inboxfront/routes/settings", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var locales = this.ajax.query(_query.default.LOCALES, 'locales');
      var timezones = this.ajax.query(_query.default.TIMEZONES, 'timezones');
      var companySettings = this.ajax.query(_query.default.COMPANY_SETTINGS);
      return Ember.RSVP.all([locales, timezones, companySettings]).then(function (res) {
        return {
          locales: res[0],
          timezones: res[1],
          companySettings: !res[2].errors ? res[2] : []
        };
      });
    },
    setupController: function setupController(controller, model) {
      var _model$companySetting = model.companySettings,
          timezone = _model$companySetting.timezone,
          locale = _model$companySetting.locale,
          dateformat = _model$companySetting.dateformat,
          timeformat = _model$companySetting.timeformat,
          employeeCount = _model$companySetting.employeeCount,
          company = _model$companySetting.company;
      model.locales = JSON.parse(model.locales);
      model.timezones = JSON.parse(model.timezones);
      controller.set('selectedTimezone', model.timezones.find(function (t) {
        return t.name === timezone;
      }));
      controller.set('selectedLocale', model.locales.find(function (l) {
        return l.name === locale;
      }));
      controller.set('selectedDateFormat', controller.get('dateFormats').find(function (d) {
        return d.name === dateformat;
      }));
      controller.set('selectedTimeFormat', controller.get('timeFormats').find(function (t) {
        return t.name === timeformat;
      }));
      controller.set('selectedEmployeeCount', controller.get('employeeCounts').find(function (e) {
        return e.id === employeeCount;
      }));
      controller.set('companyName', company === null || company === void 0 ? void 0 : company.title);

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});