define("inboxfront/components/dashboard/satisfaction-rating-combo", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      _highcharts.default.chart({
        chart: {
          renderTo: 'satisfaction-rating',
          height: 315
        },
        title: {
          text: this.intl.t('satisfaction_trends'),
          margin: 20,
          align: 'left'
        },
        xAxis: {
          title: null
        },
        yAxis: [{
          title: null
        }, {
          max: 100,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            format: "{value} %",
            style: {
              color: "#000000"
            }
          },
          opposite: true
        }],
        legend: {
          align: 'left',
          padding: 4
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'column',
          name: this.intl.t('like'),
          color: '#34c675',
          data: this.get('likes')
        }, {
          type: 'column',
          name: this.intl.t('dislike'),
          color: '#ffc131',
          data: this.get('dislikes')
        }, {
          type: 'line',
          name: this.intl.t('satisfaction_rating'),
          color: '#5d5e64',
          yAxis: 1,
          marker: {
            enabled: false
          },
          data: this.get('satisfactionRating')
        }]
      });
    }
  });

  _exports.default = _default;
});