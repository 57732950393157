define("inboxfront/templates/components/common/dialogs/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yX9T8KCV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"cancel\",\"confirm\"],[[28,\"component\",[\"common/buttons/cancel\"],[[\"action\"],[[24,[\"onCancel\"]]]]],[28,\"component\",[\"common/buttons/confirm\"],[[\"action\"],[[24,[\"onConfirm\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/dialogs/buttons.hbs"
    }
  });

  _exports.default = _default;
});