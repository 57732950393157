define("inboxfront/services/kb", ["exports", "inboxfront/gql/kb", "inboxfront/gql/mutation"], function (_exports, _kb, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ErrorsForm = Ember.Object.extend({
    init: function init() {
      this._super();

      this.errors = {};
    },
    isInvalid: function isInvalid(value) {
      return value === undefined || typeof value === 'string' && value.trim() === '';
    },
    setError: function setError(field, valid) {
      this.set("errors.".concat(field), valid);
      return this;
    }
  });
  var KBEntity = ErrorsForm.extend({
    init: function init() {
      this._super();

      this._original = _objectSpread({}, this._defaultValues);
      this.setData({}).commit();
    },
    setData: function setData(data) {
      var _this = this;

      var defaults = this._defaultValues;

      this._fields.forEach(function (f) {
        _this[f] = data[f] || defaults[f];
      });

      return this;
    },
    getData: function getData() {
      var _this2 = this;

      var data = {};

      this._fields.forEach(function (f) {
        return data[f] = _this2[f];
      });

      return data;
    },
    validate: function validate(valid) {
      this.set('isValid', valid);
      return this._checkUpdates();
    },
    _checkUpdates: function _checkUpdates() {
      var _this3 = this;

      this.set('isUpdated', this.get('isUpdated') || this._fields.some(function (f) {
        return _this3[f] !== _this3._original[f];
      }));
      return this;
    },
    commit: function commit() {
      var _this4 = this;

      this._fields.forEach(function (f) {
        return _this4._original[f] = _this4[f];
      });

      return this.validate();
    },
    restore: function restore() {
      var _this5 = this;

      this._fields.forEach(function (f) {
        return _this5.set(f, _this5._original[f]);
      });

      return this.validate();
    },
    isUnpublished: function isUnpublished() {
      return this.status === 'DRAFT' && this.status !== this._original.status;
    }
  });
  var Section = KBEntity.extend({
    init: function init() {
      this._defaultValues = Section._defaultValues || (Section._defaultValues = {
        'title': '',
        'description': '',
        'status': 'DRAFT',
        'entityType': 'PUBLIC',
        'slug': ''
      });
      this._fields = Section._fields || (Section._fields = Object.keys(Section._defaultValues));
      this.categories = []; // this.articles = [];

      this._super();
    },
    setData: function setData(data) {
      this.id = data.id || undefined;
      return this._super(data);
    },
    setBase: function setBase(base) {
      this.set('base', base);
    },
    validate: function validate(valid) {
      valid = !this.isInvalid(this.title);
      this.setError('title', !valid).setError('slug', this.isInvalid(this.slug));
      valid = valid && !this.isInvalid(this.slug);
      this.set('isUpdated', false);
      return this._super(valid);
    },
    isEmpty: function isEmpty() {
      return this.categories.length === 0;
    }
  });
  var Category = KBEntity.extend({
    init: function init() {
      this._defaultValues = Category._defaultValues || (Category._defaultValues = {
        'title': '',
        'description': '',
        'status': 'DRAFT',
        'entityType': 'PUBLIC',
        'slug': '',
        'image': {}
      });
      this._fields = Category._fields || (Category._fields = Object.keys(Category._defaultValues));
      this.articles = [];

      this._super();
    },
    setData: function setData(data) {
      this.id = data.id || undefined;
      this.image = data.image || {};
      this.author = data.author;

      try {
        this.createdAt = new Date(data.createdAt);
      } catch (e) {
        this.createdAt = new Date();
      }

      try {
        this.updatedAt = new Date(data.updatedAt);
      } catch (e) {
        this.updatedAt = new Date();
      }

      return this._super(data);
    },
    setSection: function setSection(section) {
      this.set('section', section);
      return this.setError('section', !this.section);
    },
    validate: function validate(valid) {
      valid = !this.isInvalid(this.title);
      this.setError('title', !valid).setError('slug', this.isInvalid(this.slug)).setError('section', !this.section);
      valid = valid && !this.isInvalid(this.slug);
      valid = valid && this.section;
      this.set('isUpdated', false);
      return this._super(valid);
    },
    isEmpty: function isEmpty() {
      return this.articles.length === 0;
    }
  });
  var Article = KBEntity.extend({
    init: function init() {
      this._defaultValues = Article._defaultValues || (Article._defaultValues = {
        'title': '',
        'description': '',
        'body': '',
        'status': 'DRAFT',
        'entityType': 'PUBLIC',
        'slug': '',
        'featured': false
      });
      this._fields = Article._fields || (Article._fields = Object.keys(Article._defaultValues));
      this.categories = [];

      this._super();
    },
    setData: function setData(data) {
      this.id = data.id || undefined;
      this.tags = Array.isArray(data.tags) ? data.tags : [];
      this.views = data.views;
      this.likes = data.likes;
      this.dislikes = data.dislikes;

      try {
        this.createdAt = new Date(data.createdAt);
      } catch (e) {
        this.createdAt = new Date();
      }

      try {
        this.updatedAt = new Date(data.updatedAt);
      } catch (e) {
        this.updatedAt = new Date();
      }

      this.author = data.author;
      return this._super(data);
    },
    getData: function getData() {
      var data = this._super();

      data.categories = this.categories.map(function (c) {
        return c.id;
      });
      data.tags = this.tags.map(function (c) {
        return c.tag;
      });
      return data;
    },
    setCategories: function setCategories(categories) {
      this.set('categories', categories);
      this._original.categories = _toConsumableArray(categories);
      this.setError('categories', this.categories.length === 0);
    },
    validate: function validate(valid) {
      var _this6 = this;

      valid = !this.isInvalid(this.title);
      this.setError('title', !valid).setError('slug', this.isInvalid(this.slug)).setError('body', this.isInvalid(this.body)).setError('categories', this.categories.length === 0);
      valid = valid && !this.isInvalid(this.slug);
      valid = valid && !this.isInvalid(this.body);
      valid = valid && this.categories.length > 0;
      var updated = this.categories.length !== this._original.categories.length;

      if (!updated) {
        var diff = this._original.categories.filter(function (cat) {
          return !_this6.categories.includes(cat);
        });

        updated = diff.length > 0;
      }

      this.set('isUpdated', updated);
      return this._super(valid);
    },
    commit: function commit() {
      this._original.categories = _toConsumableArray(this.categories);
      return this._super();
    },
    restore: function restore() {
      this.set('categories', _toConsumableArray(this._original.categories));
      return this._super();
    }
  });
  var Styles = KBEntity.extend({
    init: function init() {
      this._defaultValues = Styles._defaultValues || (Styles._defaultValues = {
        'bgColor': '#2B78E4',
        'fontColor': '#FFFFFF',
        'brandColor': '#2B78E4',
        'header': 'What are you looking for?',
        'subheader': 'Get help straight from our team...',
        'searchText': 'Search for answers...',
        'allowRatings': true,
        'showCount': true,
        'logoAttachmentId': null,
        'logoUrl': null,
        'logoSize': 'medium',
        'bgAttachmentId': null,
        'bgUrl': null
      });
      this._fields = Styles._fields || (Styles._fields = Object.keys(Styles._defaultValues));

      this._super();
    },
    setData: function setData(data) {
      var styles = {};

      try {
        styles = JSON.parse(data);
      } catch (e) {}

      return this._super(styles);
    },
    getData: function getData() {
      return JSON.stringify(this._super());
    },
    validate: function validate() {
      this.set('isUpdated', false);
      return this._super(true); //styles are always valid
    }
  });

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bases', []);
    },
    formatSlug: function formatSlug(val) {
      return val.toLowerCase().replace(/-+/g, ' ').replace(/[^\w ]+/g, '').replace(/ +/g, '-');
    },
    checkSlug: function checkSlug(object, entity, base) {
      var _this7 = this;

      return new Promise(function (resolve, reject) {
        if (object.slug === object._original.slug) {
          return resolve();
        }

        var hash = {
          entity: entity,
          kbId: base.id,
          slug: object.slug
        };

        _this7.ajax.query(_kb.default.CHECK_SLUG, 'checkSlug', hash).then(function (res) {
          if (res && !res.exists) {
            resolve();
          } else {
            Ember.set(object, 'isValid', false);
            reject(res);
          }
        });
      });
    },
    getBases: function getBases() {
      var _this8 = this;

      console.time('BASES LOADED BY');
      return new Promise(function (resolve) {
        _this8.ajax.query(_kb.default.GET_KB).then(function (res) {
          var bases = _this8.get('bases');

          if (Array.isArray(res)) {
            bases.clear();
            res.forEach(function (base) {
              return bases.addObject(_this8._deserializeKB(base));
            });
          }

          console.log('ALL BASES', bases);
          console.timeEnd('BASES LOADED BY');
          resolve(bases);
        });
      });
    },
    _deserializeKB: function _deserializeKB(base) {
      var _this9 = this;

      console.groupCollapsed('DESERIALIZE BASE', base);
      console.time('BASE DESERIALIZED BY');
      base.categories = [];
      base.articles = [];
      base._original = {
        name: base.name,
        domainName: base.domainName,
        visible: base.visible
      };
      var orders = {};

      try {
        orders = JSON.parse(base.entitiesOrdering);
      } catch (e) {} finally {
        if (!Array.isArray(orders.sections)) {
          orders.sections = [];
        }

        if (!orders.categories) {
          orders.categories = {};
        }

        if (!orders.articles) {
          orders.articles = {};
        }
      }

      base.orders = orders;
      var sections = [];
      (Array.isArray(base.sectionSet) ? base.sectionSet : []).forEach(function (section) {
        section = _this9._deserializeSection(section, base);
        sections.addObject(section);
        base.categories.addObjects(section.categories);
      });
      base.sections = sections.sortBy('position');
      base.styles = Styles.create().setData(base.magicStyles).commit();
      base.styles.base = base;

      this._createVirtualCategoryForFeaturedArticles(base);

      console.groupEnd();
      console.timeEnd('BASE DESERIALIZED BY');
      return base;
    },
    _createVirtualCategoryForFeaturedArticles: function _createVirtualCategoryForFeaturedArticles(base) {
      var featuredSection = this.createSection(base);
      featuredSection.title = 'Featured articles';
      featuredSection.status = 'PUBLISHED';
      featuredSection.isSystem = true;
      var featured = base.Featured = this.createCategory(base, featuredSection);
      featured.title = 'Featured articles';
      featured.status = 'PUBLISHED';
      featured.isSystem = true;
      featured.updatedAt = false;
      featured.commit();
      base.Featured.articles.addObjects(base.articles.filter(function (a) {
        return a.featured;
      }));
    },
    updateSectionOrders: function updateSectionOrders(base) {
      base.orders.sections = base.sections.map(function (s) {
        return s.id;
      });

      this._saveOrders(base);
    },
    updateCategoriesOrders: function updateCategoriesOrders(base) {
      var orders = {};
      base.sections.forEach(function (s) {
        orders[s.id] = s.categories.map(function (c) {
          return c.id;
        });
      });
      base.orders.categories = orders;

      this._saveOrders(base);
    },
    updateArticlesOrders: function updateArticlesOrders(base) {
      var orders = {};
      base.sections.forEach(function (s) {
        s.categories.forEach(function (c) {
          orders[c.id] = c.articles.map(function (a) {
            return a.id;
          });
        });
      });
      base.orders.articles = orders;

      this._saveOrders(base);
    },
    _saveOrders: function _saveOrders(base) {
      var hash = {
        kbId: base.id,
        entitiesOrdering: JSON.stringify(base.orders)
      };
      this.ajax.mutation(_kb.default.CREATE_UPDATE_KB, 'createUpdateKb', hash);
    },
    _deserializeSection: function _deserializeSection(data, base) {
      var _this10 = this;

      var section = this.createSection(base);
      section.setData(data);
      var categories = [];
      var orders = Array.isArray(base.orders.categories[section.id]) ? base.orders.categories[section.id] : [];
      (Array.isArray(data.categorySet) ? data.categorySet : []).forEach(function (category) {
        category = _this10._deserializeCategory(category, section);
        category.position = orders.indexOf(category.id);
        categories.addObject(category);
        base.articles.addObjects(category.articles);
      });
      section.categories = categories.sortBy('position');
      section.position = base.orders.sections.indexOf(section.id);
      section.commit();
      return section;
    },
    _deserializeCategory: function _deserializeCategory(data, section) {
      var base = section.base;
      var category = this.createCategory(base, section);
      category.setData(data);
      var articles = [];
      var orders = Array.isArray(base.orders.articles[category.id]) ? base.orders.articles[category.id] : [];
      (Array.isArray(data.articleSet) ? data.articleSet : []).forEach(function (a) {
        var article = base.articles.findBy('id', a.id);

        if (!article) {
          article = Article.create();
          article.setData(a);
        }

        Ember.set(article, 'position', orders.indexOf(article.id));
        articles.addObject(article);
        article.categories.addObject(category);
        article.commit();
      });
      category.articles = articles.sortBy('position');
      category.commit();
      return category;
    },
    addBase: function addBase(title) {
      var _this11 = this;

      return new Promise(function (resolve, reject) {
        _this11.ajax.mutation(_kb.default.CREATE_UPDATE_KB, 'createUpdateKb', {
          name: title
        }, function (res) {
          if (res.kb) {
            _this11._deserializeKB(res.kb);

            _this11.get('bases').addObject(res.kb);

            resolve(res.kb);
          } else {
            reject(res);
          }
        });
      });
    },
    saveBase: function saveBase(base) {
      var _this12 = this;

      var hash = {
        kbId: base.id
      };
      var updated = false;

      if (base._original.name !== base.name) {
        hash.name = base.name;
        updated = true;
      }

      if (base._original.domainName !== base.domainName) {
        hash.domainName = base.domainName;
        updated = true;
      }

      if (base._original.visible !== base.visible) {
        hash.visible = base.visible;
        updated = true;
      }

      return new Promise(function (resolve, reject) {
        if (!updated) {
          reject('BASE NOT UPDATED');
          return;
        }

        _this12.ajax.mutation(_kb.default.CREATE_UPDATE_KB, 'createUpdateKb', hash, function (res) {
          if (res.kb) {
            //base.commit()
            base._original.name = base.name;
            base._original.domainName = base.domainName;
            base._original.visible = base.visible;
            resolve(base);
          } else {
            reject(res);
          }
        });
      });
    },
    deleteBase: function deleteBase(base) {
      var _this13 = this;

      return new Promise(function (resolve) {
        _this13.ajax.mutation(_kb.default.DELETE_KB, 'deleteKb', {
          kbId: base.id
        }, function (res) {
          _this13.get('bases').removeObject(base);

          resolve(_this13.get('bases'));
        });
      });
    },
    createSection: function createSection(base) {
      var section = Section.create();
      section.setBase(base);
      return section;
    },
    createCategory: function createCategory(base, section) {
      var category = Category.create();
      category.setSection(section);
      return category;
    },
    createArticle: function createArticle(category) {
      var article = Article.create();
      article.setCategories(category && !category.isSystem ? [category] : []);

      if (category.isSystem) {
        Ember.set(article, 'featured', true);
      }

      return article;
    },
    saveSection: function saveSection(section, newBase) {
      var _this14 = this;

      return new Promise(function (resolve, reject) {
        var hash = section.getData();

        if (section.id) {
          hash.sectionId = section.id;
        }

        hash.kbId = newBase ? newBase.id : section.base.id;

        _this14.ajax.mutation(_kb.default.CREATE_UPDATE_KB_SECTION, 'createUpdateKbSection', hash, function (res) {
          if (res.section) {
            if (newBase) {
              var oldBase = section.base;

              if (newBase.id !== oldBase.id) {
                _this14._updateSectionBase(section, oldBase, newBase);
              }
            }

            if (!section.id) {
              var data = _this14._deserializeSection(res.section, section.base);

              Ember.set(section, 'id', data.id);
              section.base.sections.addObject(section);

              _this14.updateSectionOrders(section.base);
            }

            if (section.isUnpublished()) {
              _this14._updateChildrenStatus(section);
            }

            section.commit();
            resolve(section);
          } else {
            reject(res);
          }
        });
      });
    },
    _updateSectionBase: function _updateSectionBase(section, oldBase, newBase) {
      oldBase.sections.removeObject(section);
      newBase.sections.addObject(section);
      this.updateSectionOrders(newBase);
      oldBase.categories.removeObjects(section.categories);
      newBase.categories.addObjects(section.categories); //TODO check all articles removed from oldBase, check categories in oldBase

      section.categories.forEach(function (c) {
        oldBase.articles.removeObjects(c.articles);
        newBase.articles.addObjects(c.articles);
      });
      section.setBase(newBase);
    },
    _updateChildrenStatus: function _updateChildrenStatus(section) {
      var _this15 = this;

      section.categories.forEach(function (c) {
        if (c.status === 'PUBLISHED') {
          Ember.set(c, 'status', 'DRAFT');

          _this15.saveCategory(c);
        }
      });
    },
    deleteSection: function deleteSection(section) {
      var _this16 = this;

      return new Promise(function (resolve) {
        _this16.ajax.mutation(_kb.default.DELETE_KB_SECTION, 'deleteKbSection', {
          sectionId: section.id
        }, function (res) {
          section.base.sections.removeObject(section);
          resolve();
        });
      });
    },
    saveCategory: function saveCategory(category, newSection) {
      var _this17 = this;

      return new Promise(function (resolve, reject) {
        var hash = category.getData();

        if (category.id) {
          hash.categoryId = category.id;
        }

        if (category.image) {
          hash.attachmentId = category.image.id;
        }

        hash.sectionId = newSection ? newSection.id : category.section.id;

        _this17.ajax.mutation(_kb.default.CREATE_UPDATE_KB_CATEGORY, 'createUpdateCategory', hash, function (res) {
          if (res.category) {
            if (newSection) {
              category.section.categories.removeObject(category);
              newSection.categories.addObject(category);
              category.setSection(newSection);

              _this17.updateCategoriesOrders(category.section.base);
            }

            if (!category.id) {
              var data = _this17._deserializeCategory(res.category, category.section);

              Ember.set(category, 'id', data.id);
              category.section.categories.addObject(category);
              category.section.base.categories.addObject(category);
              category.author = data.author;

              _this17.updateCategoriesOrders(category.section.base);
            }

            if (category.isUnpublished()) {
              category.articles.forEach(function (a) {
                if (a.status === 'PUBLISHED') {
                  if (!a.categories.some(function (c) {
                    return c.status === 'PUBLISHED';
                  })) {
                    Ember.set(a, 'status', 'DRAFT');

                    _this17.saveArticle(a, category.section.base);
                  }
                }
              });
            }

            category.commit();
            resolve(category);
          } else {
            reject(res);
          }
        });
      });
    },
    duplicateCategory: function duplicateCategory(category, base) {
      var _this18 = this;

      return new Promise(function (resolve, reject) {
        _this18.ajax.mutation(_kb.default.DUPLICATE_KB_CATEGORY, 'duplicateCategory', {
          categoryId: category.id
        }, function (res) {
          if (res.category) {
            var section = base.sections.findBy('id', res.category.section.id);

            var _category = _this18._deserializeCategory(res.category, section);

            section.categories.addObject(_category);
            base.categories.addObject(_category);
            resolve(_category);
          } else {
            reject(res);
          }
        });
      });
    },
    deleteCategory: function deleteCategory(category, base) {
      var _this19 = this;

      return new Promise(function (resolve) {
        _this19.ajax.mutation(_kb.default.DELETE_KB_CATEGORY, 'deleteKbCategory', {
          categoryId: category.id
        }, function (res) {
          category.section.categories.removeObject(category);
          base.categories.removeObject(category);
          resolve();
        });
      });
    },
    saveArticle: function saveArticle(article, base) {
      var _this20 = this;

      return new Promise(function (resolve, reject) {
        var hash = article.getData();

        if (article.id) {
          hash.articleId = article.id;
        }

        _this20.ajax.mutation(_kb.default.CREATE_UPDATE_ARTICLE, 'createUpdateArticle', hash, function (res) {
          if (res.article) {
            var art = Article.create();
            art.setData(res.article);
            Ember.set(article, 'createdAt', art.createdAt);
            Ember.set(article, 'updatedAt', art.updatedAt);

            if (!article.id) {
              Ember.set(article, 'id', art.id);
              article.categories.forEach(function (c) {
                return c.articles.addObject(article);
              });
              base.articles.addObject(article);
              Ember.set(article, 'author', art.author);

              _this20.updateArticlesOrders(base);
            } else {
              var removed = article._original.categories.filter(function (cat) {
                return !article.categories.includes(cat);
              });

              var added = article.categories.filter(function (cat) {
                return !article._original.categories.includes(cat);
              });
              removed.forEach(function (c) {
                return c.articles.removeObject(article);
              });
              added.forEach(function (c) {
                return c.articles.addObject(article);
              });

              if (added.length > 0) {
                _this20.updateArticlesOrders(base);
              }
            }

            if (article.featured !== article._original.featured) {
              if (article.featured) {
                base.Featured.articles.addObject(article);
              } else {
                base.Featured.articles.removeObject(article);
              }
            }

            article.commit();
            resolve(article);
          } else {
            reject(res);
          }
        });
      });
    },
    removeArticle: function removeArticle(article, category) {
      article.categories.removeObject(category);
      category.articles.removeObject(article);
      this.saveArticle(article, category.section.base);
    },
    duplicateArticle: function duplicateArticle(article, base) {
      var _this21 = this;

      return new Promise(function (resolve, reject) {
        _this21.ajax.mutation(_kb.default.DUPLICATE_ARTICLE, 'duplicateArticle', {
          articleId: article.id
        }, function (res) {
          if (res.article) {
            var art = Article.create();
            art.setData(res.article);

            if (art.featured) {
              base.Featured.articles.addObject(art);
            }

            var categories = [];
            res.article.categories.forEach(function (c) {
              var category = base.categories.findBy('id', c.id);

              if (!category) {
                console.error('CATEGORY NOT FOUND', c.id);
                return;
              }

              categories.addObject(category);
              category.articles.addObject(art);
            });
            art.setCategories(categories);
            art.commit();
            base.articles.addObject(art);

            _this21.updateArticlesOrders(base);

            resolve(art);
          } else {
            reject(res);
          }
        });
      });
    },
    deleteArticle: function deleteArticle(article, base) {
      this.ajax.query(_kb.default.DELETE_ARTICLE, 'deleteArticle', {
        articleId: article.id
      }).then(function () {
        article.categories.forEach(function (c) {
          return c.articles.removeObject(article);
        });
        base.articles.removeObject(article);
      });
    },
    saveStyles: function saveStyles(base) {
      var _this22 = this;

      return new Promise(function (resolve, reject) {
        var styles = base.styles;
        var hash = {
          kbId: base.id,
          magicStyles: styles.getData()
        };

        _this22.ajax.mutation(_kb.default.CREATE_UPDATE_KB, 'createUpdateKb', hash, function (res) {
          if (res.kb) {
            styles.commit();
            resolve(res.kb);
          } else {
            reject(res);
          }
        });
      });
    },
    deleteLogo: function deleteLogo(styles) {
      return this.get('ajax').query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
        attachmentId: styles.logoAttachmentId
      }).then(function () {
        Ember.set(styles, 'logoAttachmentId', null);
        Ember.set(styles, 'logoUrl', null);
        Ember.set(styles._original, 'logoAttachmentId', null);
        Ember.set(styles._original, 'logoUrl', null);
      });
    },
    deleteBackground: function deleteBackground(styles) {
      return this.get('ajax').query(_mutation.default.DELETE_ATTACHMENT, 'deleteAttachment', {
        attachmentId: styles.bgAttachmentId
      }).then(function () {
        Ember.set(styles, 'bgAttachmentId', null);
        Ember.set(styles, 'bgUrl', null);
        Ember.set(styles._original, 'bgAttachmentId', null);
        Ember.set(styles._original, 'bgUrl', null);
      });
    },
    updateVisibility: function updateVisibility(base, value) {
      Ember.set(base, 'visible', value);
      return this.saveBase(base);
    },
    addArticles: function addArticles(selected, category) {
      var _this23 = this;

      category.articles.addObjects(selected);
      selected.forEach(function (art) {
        if (category.isSystem) {
          Ember.set(art, 'featured', true);
        } else {
          art.categories.addObject(category);
        }

        _this23.saveArticle(art, category.section.base);
      });
    },
    findSection: function findSection(sectionId) {
      var section;
      this.get('bases').forEach(function (b) {
        var s = b.sections.findBy('id', sectionId);

        if (s) {
          section = s;
        }
      });
      return section;
    }
  });

  _exports.default = _default;
});