define("inboxfront/components/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.textLoading = this.text ? this.intl.t(this.text) : '';
    }
  });

  _exports.default = _default;
});