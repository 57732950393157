define("inboxfront/routes/permissions", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var grid = this.ajax.query(_query.default.GRID);
      var roles = this.ajax.query(_query.default.COMPANY_CUSTOM_ROLES);
      return Ember.RSVP.all([grid, roles]).then(function (res) {
        return {
          grid: !res[0].errors ? res[0] : 'null',
          roles: !res[1].errors ? res[1] : []
        };
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var parse = JSON.parse(model.grid);
      controller.set('grid', parse ? this.sortGrid(parse) : []);
      model.roles.forEach(function (el) {
        el.readyGrid = el.readyGrid !== null ? JSON.parse(el.readyGrid) : _this.sortGrid(parse);
      });
      controller.set('currentGrid', model.roles.length ? model.roles[0].readyGrid[0].arr : {});
      controller.set('selectedRole', model.roles.length ? model.roles[0] : {});
      controller.set('selectedTab', parse ? Object.keys(parse)[0] : {});
    },
    sortGrid: function sortGrid(parse) {
      var _this2 = this;

      return Object.keys(parse).map(function (key) {
        return {
          name: key,
          arr: _this2.mappingGrid(parse[key])
        };
      });
    },
    mappingGrid: function mappingGrid(val) {
      return Object.keys(val).map(function (key) {
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          enabled: false,
          subArr: val[key].map(function (item) {
            return {
              name: item[0],
              id: item[1],
              enabled: false,
              type: key.charAt(0).toUpperCase() + key.slice(1)
            };
          })
        };
      });
    }
  });

  _exports.default = _default;
});