define("inboxfront/routes/tasks/board", ["exports", "inboxfront/routes/common", "inboxfront/gql/query"], function (_exports, _common, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    tasks: Ember.inject.service(),
    customfields: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var settings = this.ajax.query(_query.default.TICKET_SETTINGS);
      var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
      var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
      return Ember.RSVP.all([settings, issueTypes, propertyUsers]).then(function (res) {
        var issueTypes = res[1].filter(function (t) {
          return t.isActive;
        });
        var propertyUsers = !res[2].errors ? res[2] : [];
        propertyUsers.forEach(function (u) {
          return Ember.set(u, 'fullName', "".concat(u.firstName, " ").concat(u.lastName));
        });
        return {
          propertyUsers: propertyUsers,
          tasks: {
            edges: [],
            pageInfo: {
              startCursor: 0,
              endCursor: 0
            }
          },
          ticketSettings: res[0],
          fields: _this.get('customfields').prepare(_this.get('tasks').systemFields, res[0]),
          issueTypes: issueTypes,
          isPrevDisabled: true,
          isNextDisabled: true
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.updateTasks();
    }
  });

  _exports.default = _default;
});