define("inboxfront/templates/components/message-emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "72gtkFz4",
    "block": "{\"symbols\":[\"author\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"emoji-wrap \",[28,\"if\",[[24,[\"data\",\"isCurrentUser\"]],\"current-user\",\"\"],null]]]],[11,\"onmouseenter\",[28,\"action\",[[23,0,[]],\"onMouseEnter\"],null]],[11,\"onmouseleave\",[28,\"action\",[[23,0,[]],\"onMouseLeave\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onDeleteEmoji\",[24,[\"data\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"emoji\"],[11,\"style\",[29,[\"background-position: \",[24,[\"data\",\"value\"]]]]],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"emoji-count\"],[8],[0,\"\\n        \"],[1,[24,[\"data\",\"authors\",\"length\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isAuthorFieldOpen\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"emoji-author-field\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\",\"authors\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[8],[1,[23,1,[\"firstName\"]],false],[0,\" \"],[1,[23,1,[\"lastName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/message-emoji.hbs"
    }
  });

  _exports.default = _default;
});