define("inboxfront/controllers/organizations/index", ["exports", "inboxfront/gql/mutation", "inboxfront/gql/query", "inboxfront/helpers/countries-list"], function (_exports, _mutation, _query, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    intl: Ember.inject.service(),
    limit: 10,
    page: 0,
    countriesList: _countriesList.COUNTRIES_LIST,
    chosenDetail: 'Contains',
    optionDate: Date,
    startDate: Date,
    endDate: Date,
    showDeleteDialog: false,
    isVisibleMergeButton: false,
    showMergeConfirmDialog: false,
    selectedCountry: null,
    selectedContactOwner: null,
    incrementedPage: Ember.computed('page', 'cloneOrganizations', function () {
      return this.get('cloneOrganizations').length ? "".concat(this.page + 1) : 0;
    }),
    organizationPage: Ember.computed('page', 'limit', 'cloneOrganizations', function () {
      var cloneOrganizations = this.get('cloneOrganizations');
      var page = this.get('page');
      var limit = this.get('limit');
      return cloneOrganizations.slice(page * limit, (page + 1) * limit);
    }),
    cloneOrganizations: Ember.computed('organizations', function () {
      return this.get('organizations');
    }),
    lastPage: Ember.computed('page', function () {
      return parseInt(this.get('organizations').length / 10);
    }),
    countPages: Ember.computed('organizations', function () {
      return Math.ceil(this.get('organizations').length / 10);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.listSelectedOrganizations = [];
      this.properties = [];
      this.listMergeSelectedOrganizations;
      this.listTitleMerge = ['Name', 'Fax Number', 'Phone Number', 'Industry', 'Website', 'Home Address Line 1', 'Home Address Line 2', 'Home Postal Code', 'Home State', 'Home Country'];
      this.listFilterFields = [{
        name: this.intl.t('name'),
        param: 'name'
      }, {
        name: this.intl.t('phone'),
        param: 'phone'
      }, {
        name: this.intl.t('fax'),
        param: 'fax'
      }, {
        name: this.intl.t('country'),
        param: 'country'
      }, {
        name: this.intl.t('tag'),
        param: 'tag'
      }, {
        name: this.intl.t('industry'),
        param: 'industry'
      }, //{name: this.intl.t('company'), param: 'company'},
      {
        name: this.intl.t('owner'),
        param: 'owner'
      }];
      this.listFilterLookup = [{
        name: this.intl.t('filters.startswith'),
        param: 'startswith'
      }, {
        name: this.intl.t('filters.contains'),
        param: 'contains'
      }, {
        name: this.intl.t('filters.exact'),
        param: 'exact'
      }, {
        name: this.intl.t('filters.endswith'),
        param: 'endswith'
      }, {
        name: this.intl.t('filters.exclude'),
        param: 'exclude'
      }];
      this.selectedFilterField = this.listFilterFields[0];
      this.selectedFilterLookup = this.listFilterLookup[0];
      this.trimValidation = [{
        message: this.intl.t('forms.this_is_required'),
        validate: function validate(inputValue) {
          return inputValue && inputValue.trim() !== '';
        }
      }];
    },
    actions: {
      updateColumns: function updateColumns() {
        try {
          var columnSettings = [];
          this.get('model.columns').forEach(function (c, i) {
            columnSettings.pushObject({
              id: c.id,
              pos: i,
              vis: c.visible
            });
          });
          var hash = {
            id: this.get('model.settings.id'),
            fields: JSON.stringify(columnSettings)
          };
          this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
        } catch (e) {
          console.error('ON UPDATE ORG COLUMNS', e);
        }
      },
      prevPage: function prevPage(page) {
        if (Number(page) > 0) {
          this.set('page', Number(page) - 1);
        }
      },
      nextPage: function nextPage(page) {
        if (Number(page) < this.get('lastPage')) {
          this.set('page', Number(page) + 1);
        }
      },
      allCheckOrganizations: function allCheckOrganizations(val) {
        this.set('allCheck', val);
        this.get('organizationPage').map(function (el) {
          return Ember.set(el, 'checked', val);
        });
        this.set('isVisibleActions', val);
      },
      checkOrganization: function checkOrganization(val) {
        Ember.set(val, 'checked', !val.checked);
        this.set('isVisibleActions', this.get('organizationPage').isAny('checked'));

        if (this.get('organizationPage').filter(function (el) {
          return el.checked;
        }).length === 2) {
          this.set('isVisibleMergeButton', true);
        } else {
          this.set('isVisibleMergeButton', false);
        }
      },
      showDeleteOrganizationModal: function showDeleteOrganizationModal() {
        this.set('showDeleteDialog', true);
      },
      showAssignOrganizationSidebar: function showAssignOrganizationSidebar() {
        this.set('showPopupAssign', true);
      },
      mergeOrganizationsConfirm: function mergeOrganizationsConfirm() {
        var _this = this;

        var filterOrganizations = this.get('organizationPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var organization = this.get('listMergeSelectedOrganizations');
        var hash = {
          secondaryOrganizationId: filterOrganizations[0],
          primaryOrganizationId: filterOrganizations[1],
          name: organization[1],
          fax: organization[2],
          phone: organization[3],
          industry: organization[4],
          website: organization[5],
          homeAddress1: organization[6],
          homeAddress2: organization[7],
          homeCity: organization[8],
          homeState: organization[9],
          homeCountry: organization[10]
        };
        this.ajax.query(_mutation.default.MERGE_ORGANIZATIONS, 'mergeOrganizations', hash).then(function () {
          _this.set('showPopupMerge', false);

          _this.set('listSelectedOrganizations', []);

          _this.set('listMergeSelectedOrganizations', null);

          _this.set('isVisibleMergeButton', false);

          _this.set('showMergeConfirmDialog', false);

          _this.updateQueryOrganizations();
        });
      },
      showMergeOrganizationSidebar: function showMergeOrganizationSidebar() {
        var filterOrganizations = this.get('organizationPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return [{
            avatar: {
              created: item.createdAt,
              name: item.name
            }
          }, item.name || '-', item.fax || '-', item.phone || '-', item.industry || '-', item.website || '-', item.address ? item.address.line1 || '-' : '-', item.address ? item.address.line2 || '-' : '-', item.address ? item.address.city || '-' : '-', item.address ? item.address.state || '-' : '-', item.address ? item.address.country || '-' : '-'
          /*
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'whatsapp') ? item.guestcontactSet.findBy('contactType', 'whatsapp').contactValue : '-') : '-',
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'line') ? item.guestcontactSet.findBy('contactType', 'line').contactValue : '-') : '-',
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'telegram') ? item.guestcontactSet.findBy('contactType', 'telegram').contactValue : '-') : '-',
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'facebook') ? item.guestcontactSet.findBy('contactType', 'facebook').contactValue : '-') : '-',
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'twitter') ? item.guestcontactSet.findBy('contactType', 'twitter').contactValue : '-') : '-',
          item.guestcontactSet[0] ? (item.guestcontactSet.findBy('contactType', 'wechat') ? item.guestcontactSet.findBy('contactType', 'wechat').contactValue : '-') : '-',
          */
          ];
        });
        var firstFilterOrganization = Object.assign([], filterOrganizations[0]);
        this.get('listSelectedOrganizations').pushObjects(filterOrganizations);
        this.set('listMergeSelectedOrganizations', firstFilterOrganization);
        this.set('showPopupMerge', true);
        setTimeout(function () {
          document.querySelectorAll('.row-merge:nth-of-type(2) > div').forEach(function (el) {
            el.classList.add('active');
          });
        }, 300);
      },
      assignOrganizations: function assignOrganizations() {
        var _this2 = this;

        var filterContacts = this.get('organizationPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        var hash = {
          ids: filterContacts,
          contactOwnerId: this.get('selectedUser.id')
        };
        this.ajax.query(_mutation.default.BULK_ORGANIZATION_OWNER, 'bulkOrganizationOwner', hash).then(function () {
          _this2.set('showPopupAssign', false);

          _this2.set('selectedUser', undefined);

          _this2.updateQueryOrganizations();
        });
      },
      mergeOrganizations: function mergeOrganizations() {
        this.set('showMergeConfirmDialog', true);
      },
      deleteOrganizations: function deleteOrganizations() {
        var _this3 = this;

        var filterMapping = this.get('organizationPage').filter(function (el) {
          return el.checked;
        }).map(function (item) {
          return item.id;
        });
        this.ajax.query(_mutation.default.DELETE_ORGANIZATION, 'deleteOrganization', {
          organizationIds: filterMapping
        }).then(function (res) {
          if (res.status === 400) {
            _this3.get('paperToaster').show(_this3.intl.t('org_not_found'));

            return;
          }

          _this3.get('paperToaster').show(_this3.intl.t('orgs_deleted'));

          _this3.updateQueryOrganizations();
        }).finally(function () {
          return _this3.set('showDeleteDialog', false);
        });
      },
      chooseOrganization: function chooseOrganization(val, index, indexWrap) {
        var mapGuests = this.get('listMergeSelectedOrganizations').map(function (el, i) {
          return index === i ? val : el;
        });
        var previousIndex = indexWrap === 1 ? 0 : 1;
        this.set('listMergeSelectedOrganizations', mapGuests);
        document.querySelectorAll(".row-merge:nth-of-type(".concat(indexWrap + 2, ") > div"))[index].classList.add('active');
        document.querySelectorAll(".row-merge:nth-of-type(".concat(previousIndex + 2, ") > div"))[index].classList.remove('active');
      },
      closeAssignDialog: function closeAssignDialog() {
        this.set('selectedUser', undefined);
        this.set('showPopupAssign', false);
      },
      closeMergeDialog: function closeMergeDialog() {
        this.set('listSelectedOrganizations', []);
        this.set('listMergeSelectedOrganizations', null);
        this.set('showPopupMerge', false);
      },
      saveOrganization: function saveOrganization() {
        var _this4 = this;

        var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var hash = {
          contactOwnerId: this.get('selectedContactOwner.id'),
          country: this.get('selectedCountry.name'),
          propertyId: this.get('selectedProperty.id'),
          fax: this.get('faxNumber'),
          industry: this.get('industry'),
          line1: this.get('addressLine1'),
          line2: this.get('addressLine2'),
          name: this.get('organizationName'),
          phone: this.get('phone'),
          state: this.get('addressState'),
          website: this.get('website'),
          zipOrPostcode: this.get('addressCode')
        };
        this.ajax.mutation(_mutation.default.CREATE_OR_UPDATE_ORGANIZATION, 'createUpdateOrganization', hash, function () {
          _this4.set('showAddOrganization', false);

          _this4.clearForm();

          _this4.get('paperToaster').show(_this4.intl.t('org_created'));

          _this4.updateQueryOrganizations(isOpen);
        });
      },
      clearFilter: function clearFilter() {
        var page = this.get('page');
        var limit = this.get('limit');
        var organizationPage = this.get('cloneOrganizations').slice(page * limit, (page + 1) * limit);
        this.set('searchOrganizations', null);
        this.set('isShowClearFilterBtn', false);
        this.set('organizationPage', organizationPage);
      },
      filterOrganizations: function filterOrganizations() {
        var _this5 = this;

        var search = this.get('searchOrganizations');

        if (!search.length) {
          this.set('organizationPage', this.get('cloneOrganizations'));
          return;
        }

        if (search.length > 2) {
          var hash = {
            search: [{
              field: this.get('selectedFilterField.param'),
              lookup: this.get('selectedFilterLookup.param'),
              value: search
            }]
          };
          this.set('isLoader', true);
          this.set('isShowClearFilterBtn', true);
          this.ajax.query(_query.default.ORGANIZATIONS, 'organizations', hash).then(function (data) {
            _this5.set('organizationPage', data);

            _this5.set('isLoader', false);
          });
        }
      },
      showAddOrganization: function showAddOrganization() {
        var _this6 = this;

        this.set('showAddOrganization', true);
        this.ajax.query(_query.default.PROPERTY_SELECT).then(function (data) {
          _this6.set('properties', data);
        });
      },
      showFilterContact: function showFilterContact() {
        this.set('filterPopup', true);
        this.set('isFilterInserted', true);
        this.set('showFilterDialog', true);
      },
      closeContactDialog: function closeContactDialog() {
        this.set('filterPopup', false);
        this.set('showAddOrganization', false);
        this.clearForm();
      }
    },
    updateMainEntity: function updateMainEntity() {
      var hash = {
        id: this.get('model.settings.id'),
        fields: JSON.stringify(this.get('addedColumns')),
        orders: JSON.stringify(this.get('removedColumns'))
      };
      this.ajax.mutation(_mutation.default.UPDATE_CUSTOM_SETTINGS, 'updateMainEntitySettings', hash);
    },
    updateQueryOrganizations: function updateQueryOrganizations() {
      var _this7 = this;

      var isOpen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set('isLoader', true);
      this.ajax.query(_query.default.ORGANIZATIONS).then(function (data) {
        _this7.set('isVisibleActions', false);

        _this7.set('organizations', data);

        isOpen && _this7.set('showAddOrganization', true);

        _this7.set('isLoader', false);
      });
    },
    clearForm: function clearForm() {
      this.set('selectedContactOwner', null);
      this.set('selectedCountry', null);
      this.set('faxNumber', '');
      this.set('industry', '');
      this.set('addressLine1', '');
      this.set('addressLine2', '');
      this.set('organizationName', '');
      this.set('phone', '');
      this.set('addressState', '');
      this.set('website', '');
      this.set('addressCode', '');
    }
  });

  _exports.default = _default;
});