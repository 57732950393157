define("inboxfront/components/dashboard/escalations-priority", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      _highcharts.default.chart({
        chart: {
          renderTo: 'escalations-rating',
          height: 315
        },
        title: {
          text: this.intl.t('escalations_rating'),
          align: 'left'
        },
        xAxis: {
          title: null,
          categories: this.get('listDateMin')
        },
        yAxis: {
          title: null
        },
        exporting: {
          enabled: false
        },
        series: [{
          type: 'spline',
          name: this.intl.t('urgent'),
          color: '#f63333',
          data: [3, 24, 13, 5, 4, 10, 12]
        }, {
          type: 'spline',
          name: this.intl.t('high'),
          color: '#ffc131',
          data: [10, 3, 24, 3, 3, 15, 4]
        }, {
          type: 'spline',
          name: this.intl.t('medium'),
          color: '#fffc1b',
          data: [11, 3, 4, 23, 13, 5, 24]
        }, {
          type: 'spline',
          name: this.intl.t('low'),
          color: '#34c675',
          data: [21, 3, 4, 3, 3, 15, 42]
        }, {
          type: 'spline',
          name: this.intl.t('na'),
          color: '#3f69cc',
          data: [20, 35, 40, 32, 30, 54, 41]
        }]
      });
    }
  });

  _exports.default = _default;
});