define("inboxfront/components/kb/bases-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onDrop: function onDrop(base, event) {
        event.preventDefault();
        this.moveToBase(base, event.dataTransfer.getData('sectionId'));
      },
      onDragOver: function onDragOver(base, event) {
        if (!this.get('viewSections') || base === this.get('selectedBase')) {
          return;
        }

        event.preventDefault();
      }
    }
  });

  _exports.default = _default;
});