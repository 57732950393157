define("inboxfront/templates/components/common/buttons/secondary-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfUuXJlc",
    "block": "{\"symbols\":[\"&default\",\"@disabled\",\"@iconButton\",\"@class\"],\"statements\":[[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"disabled\",\"onClick\"],[[28,\"concat\",[\"secondary-2-button \",[23,4,[]]],null],[28,\"or\",[[23,3,[]],false],null],[23,2,[]],[24,[\"onClick\"]]]],{\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"svg-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/common/buttons/secondary-2.hbs"
    }
  });

  _exports.default = _default;
});