define("inboxfront/controllers/dashboard", ["exports", "inboxfront/gql/query", "inboxfront/helpers/highchart-mock"], function (_exports, _query, _highchartMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    isAllProperty: Ember.computed('selectedProperty', function () {
      return this.get('selectedProperty').id === '*';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.startDate = {};
      this.endDate = {};
      this.filterProp = {};
      this.filterComp = {};
      this.listDate = [];
      this.listDateMin = [];
      this.likes = [];
      this.dislikes = [];
      this.satisfactionRating = [];
      this.pickGraph = [];
      this.props = [];
    },
    mergeStatistics: function mergeStatistics() {
      var res = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var listDate = this.get('listDate');
      var list = {};
      var likes = [];
      var dislikes = [];
      var satisfactionRating = [];

      for (var i = 0; i < res.length; i++) {
        Object.keys(res[i].node).forEach(function (key) {
          list[key] = 0;
        });
      }

      var _loop = function _loop(_i) {
        Object.keys(res[_i].node).forEach(function (key) {
          list[key] += res[_i].node[key];
        });
      };

      for (var _i = 0; _i < res.length; _i++) {
        _loop(_i);
      }

      listDate.forEach(function (item) {
        var isDate = res.filter(function (el) {
          return item === el.node.date;
        });

        if (isDate.length) {
          likes.push(isDate[0].node.likes);
          dislikes.push(isDate[0].node.dislikes);
          satisfactionRating.push(isDate[0].node.satisfactionRating);
        } else {
          likes.push(0);
          dislikes.push(0);
          satisfactionRating.push(0);
        }
      });
      this.set('likes', likes);
      this.set('dislikes', dislikes);
      this.set('satisfactionRating', satisfactionRating);
      this.get('isAllProperty') ? this.set('filterComp', list) : this.set('filterProp', list);
    },
    addListDates: function addListDates(startDate, endDate) {
      var list = [];
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var daysLag = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));

      for (var i = 0; i <= daysLag; i++) {
        list.push(date1.toISOString().slice(0, 10));
        date1.setDate(date1.getDate() + 1);
      }

      var arr = list.map(function (el) {
        return el.slice(5).replace('-', '/');
      });
      this.set('listDate', list);
      this.set('listDateMin', arr);
    },
    updateCompanyQuery: function updateCompanyQuery(startDate, endDate) {
      var _this = this;

      this.ajax.query(_query.default.COMPANY_STATS, 'companyStats', {
        date: "[".concat(startDate, ", ").concat(endDate, "]")
      }).then(function (_ref) {
        var edges = _ref.edges;

        if (!edges.length && !_this.get('isJoinedUser')) {
          _this.set('isShowStartScreen', true);

          edges = [{
            node: _highchartMock.HIGH_CHART_MOCK
          }];
        }

        _this.set('pickGraph', edges);

        _this.calcCompanies(edges);

        _this.mergeStatistics(edges);
      });
    },
    updateAgentQuery: function updateAgentQuery(startDate, endDate) {
      var _this2 = this;

      this.ajax.query(_query.default.AGENT_STATS, 'agentStats', {
        date: "[".concat(startDate, ", ").concat(endDate, "]")
      }).then(function (res) {
        var arr = [];
        res.edges.forEach(function (i) {
          var f = arr.some(function (el) {
            return el.id === i.node.agent.id;
          });

          if (!f) {
            arr.push({
              'id': i.node.agent.id,
              'name': i.node.agent.user.firstName + ' ' + i.node.agent.user.lastName,
              'likes': i.node.likes,
              'dislikes': i.node.dislikes,
              'chatHandled': i.node.chatHandled,
              'avgResponseTime': parseInt(i.node.avgResponseTime),
              'satisfactionRating': parseInt(i.node.satisfactionRating),
              'avgResolutionTime': 0,
              'overdueTickets': i.node.overdueTickets,
              'closedTickets': i.node.closedTickets,
              'openTickets': i.node.openTickets,
              'totalTickets': i.node.totalTickets
            });
          } else {
            var filter = arr.filter(function (el) {
              return el.id === i.node.agent.id;
            })[0];
            filter.likes += i.node.likes;
            filter.dislikes += i.node.dislikes;
            filter.chatHandled += i.node.chatHandled;
            filter.avgResponseTime += parseInt(i.node.avgResponseTime);
            filter.satisfactionRating += parseInt(i.node.satisfactionRating);
            filter.avgResolutionTime += 0;
            filter.overdueTickets += i.node.overdueTickets;
            filter.closedTickets += i.node.closedTickets;
            filter.openTickets += i.node.openTickets;
            filter.totalTickets += i.node.totalTickets;
          }
        });
        arr.map(function (el) {
          var filter = res.edges.filter(function (i) {
            return i.node.agent.id === el.id && i.node.satisfactionRating !== 0;
          });
          el.satisfactionRating = filter.length ? parseInt(el.satisfactionRating / filter.length) : el.satisfactionRating;
        });

        _this2.set('props', arr);
      });
    },
    updatePropertyQuery: function updatePropertyQuery(startDate, endDate) {
      var _this3 = this;

      this.ajax.query(_query.default.PROPERTY_STATS, 'propertyStats', {
        date: "[".concat(startDate, ", ").concat(endDate, "]"),
        estateId: this.get('selectedProperty').id
      }).then(function (_ref2) {
        var edges = _ref2.edges;

        if (!edges.length && !_this3.get('isJoinedUser')) {
          _this3.set('isShowStartScreen', true);

          edges = [{
            node: _highchartMock.HIGH_CHART_MOCK
          }];

          _this3.mergeStatistics(edges);
        } else {
          if (edges.length) {
            var properties = edges.filter(function (item) {
              return item.node.estate.id === _this3.get('selectedProperty.id');
            });

            _this3.mergeStatistics(properties);
          }
        }

        _this3.set('pickGraph', edges);
      });
    },
    calcCompanies: function calcCompanies(data) {
      var prop = this.get('model.properties');
      var arr = [];

      for (var i = 0; i < prop.length; i++) {
        var obj = {};
        obj['dislikes'] = 0;
        obj['likes'] = 0;
        obj['avgResponseTime'] = 0;
        obj['satisfactionRating'] = 0;
        obj['chatHandled'] = 0;
        obj['totalTickets'] = 0;
        obj['openTickets'] = 0;
        obj['closedTickets'] = 0;
        obj['overdueTickets'] = 0;
        obj['avgResolutionTime'] = 0;
        obj['name'] = prop[i].title;
        arr.push(obj);
      }

      var _loop2 = function _loop2(_i2) {
        arr[_i2].name = prop[_i2].title;
        data.forEach(function (item) {
          if (item.node.company.id === prop[_i2].id) {
            arr[_i2].dislikes += item.node.dislikes;
            arr[_i2].likes += item.node.likes;
            arr[_i2].chatHandled += item.node.chatHandled;
            arr[_i2].totalTickets += item.node.totalTickets;
            arr[_i2].openTickets += item.node.openTickets;
            arr[_i2].closedTickets += item.node.closedTickets;
            arr[_i2].overdueTickets += item.node.overdueTickets; //arr[i].avgResolutionTime += item.node.avgResolutionTime || 0;

            arr[_i2].avgResponseTime += item.node.avgResponseTime;
            arr[_i2].satisfactionRating += item.node.satisfactionRating;
          }
        });
      };

      for (var _i2 = 0; _i2 < prop.length; _i2++) {
        _loop2(_i2);
      }

      this.set('props', arr);
    },
    actions: {
      onSelectChange: function onSelectChange(value) {
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        this.set('selectedProperty', value);

        if (value.id === '*') {
          this.updateCompanyQuery(startDate, endDate);
        } else {
          this.updatePropertyQuery(startDate, endDate);
          this.updateAgentQuery(startDate, endDate);
        }
      },
      dismissData: function dismissData() {
        this.set('pickGraph', []);
        this.set('isShowStartScreen', false);
      },
      changeDate: function changeDate(dates) {
        var startDate = dates.startDate.toLocaleDateString().split('.').reverse().join('-');
        var endDate = dates.endDate.toLocaleDateString().split('.').reverse().join('-');
        this.set('startDate', startDate);
        this.set('endDate', endDate);
        this.addListDates(startDate, endDate);

        if (this.get('isAllProperty')) {
          this.updateCompanyQuery(startDate, endDate);
        } else {
          this.updatePropertyQuery(startDate, endDate);
          this.updateAgentQuery(startDate, endDate);
        }
      }
    }
  });

  _exports.default = _default;
});