define("inboxfront/routes/assistants/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "inboxfront/helpers/countries-list"], function (_exports, _common, _query, _countriesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    workflows: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('assistants');
      } else {
        var flow = this.ajax.query(_query.default.FLOW, 'flow', {
          flowId: params.id
        });
        var workflow = this.ajax.query(_query.default.WORKFLOW, 'workflow', {
          workflowId: params.id
        });
        var propertyUsers = this.ajax.query(_query.default.PROPERTY_USERS);
        var ruleOptions = this.ajax.query(_query.default.OPTIONS);
        var departments = this.ajax.query(_query.default.DEPARTMENT);
        var accounts = this.ajax.query(_query.default.CHANNEL);
        var issueTypes = this.ajax.query(_query.default.ISSUE_TYPES);
        var rooms = this.ajax.query(_query.default.ROOMS_FLOW);
        return Ember.RSVP.all([flow, propertyUsers, ruleOptions, workflow, departments, accounts, rooms, issueTypes]).then(function (res) {
          if (res[0].errors && res[3].data && !res[3].data.data) {
            _this.transitionTo('assistants');
          } else {
            var emailAccounts = res[5].errors ? [] : res[5].filter(function (acc) {
              return acc.application === 'EMAIL' && acc.active;
            });
            var users = [];
            (res[1].errors ? [] : res[1]).forEach(function (u) {
              users.addObject({
                pk: u.pk,
                name: u.firstName + ' ' + u.lastName
              });
            });

            var _issueTypes = res[7].errors ? [] : res[7].filter(function (t) {
              return t.isActive;
            });

            _issueTypes.forEach(function (t) {
              t.name = t.issueType;
            });

            var _departments = [];
            var sortDepartments = !res[4].errors ? res[4].filter(function (obj) {
              return obj.active === true;
            }).sort(function (a, b) {
              var x = a.name.toLowerCase();
              var y = b.name.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }) : [];
            sortDepartments.forEach(function (d) {
              _departments.addObject({
                id: d.id,
                pk: d.pk,
                name: d.displayName || d.name
              });
            });
            return {
              flow: res[0].errors ? _this.get('workflows').deserialize(res[3], _departments, emailAccounts, users) : res[0],
              propertyUsers: !res[1].errors ? res[1] : [],
              ruleOptions: res[2],
              issueTypes: _issueTypes,
              rooms: res[6]
            };
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var ruleOptions = JSON.parse(model.ruleOptions);
      var dataCollectors = [{
        id: 1,
        type: 'email',
        label: 'Email',
        text: 'Your email please?',
        icon: 'email'
      }, {
        id: 2,
        type: 'firstName',
        label: 'First Name',
        text: 'Your first name please?',
        icon: 'user-circle'
      }, {
        id: 3,
        type: 'lastName',
        label: 'Last Name',
        text: 'Your last name please?',
        icon: 'user-circle'
      }, {
        id: 4,
        type: 'number',
        label: 'Phone Number',
        text: 'Your number please?',
        icon: 'phone'
      }, {
        id: 5,
        type: 'mobile',
        label: 'Mobile Number',
        text: 'Your mobile please?',
        icon: 'mobile'
      }];
      var flow = model.flow;
      var arrayFormBlocks = [];
      flow.blocks.forEach(function (block) {
        if (block.blockType === 'FORM') {
          arrayFormBlocks.push.apply(arrayFormBlocks, _toConsumableArray(JSON.parse(block.extraData).dataCollectorsSelect));
        }
      });
      controller.set('dataCollectors', dataCollectors);
      controller.set('dataCollectorsSelect', dataCollectors.filter(function (a) {
        return !arrayFormBlocks.some(function (b) {
          return b.id === a.id;
        });
      }));
      controller.set('ruleOptions', ruleOptions);
      controller.set('flow', flow);
      controller.set('flowId', flow.id);
      controller.set('blocks', flow.blocks);
      controller.set('isActiveFlow', flow.isActive);

      if (flow.type === 1) {
        controller.set('flowName', flow.title);
      } else {
        controller.set('flowName', flow.name);
      }

      controller.set('rulesConditions', []);

      if (flow.type !== 1) {
        var rules = [];

        if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.any) {
          var rulesObj = this.buildRules(flow.trigger.conditions.any, rules, 'OR');
          rulesObj && rulesObj.forEach(function (obj) {
            rules.addObject(obj);
          });
        }

        rules[rules.length - 1] && (rules[rules.length - 1].operator = 'AND');

        if (flow.trigger && flow.trigger.conditions && flow.trigger.conditions.all) {
          var _rulesObj = this.buildRules(flow.trigger.conditions.all, rules, 'AND');

          _rulesObj && _rulesObj.forEach(function (obj) {
            rules.addObject(obj);
          });
        }

        rules[rules.length - 1] && (rules[rules.length - 1].operator = '');
        controller.set('triggerRules', rules);
      } else if (flow.rules) {
        (function () {
          var rules = JSON.parse(flow.rules.rules);
          controller.set('checkCondition', rules.checkCondition);
          rules.conditions.all.forEach(function (item) {
            Object.keys(item).forEach(function (key) {
              if (typeof item[key] === 'string') item[key] = item[key].replace('_', ' ');
            });
          });

          var _loop = function _loop(i) {
            var rulesConditions = {};
            var fieldType = ruleOptions.variables.filter(function (el) {
              return el.name === rules.conditions.all[i].name.replace(' ', '_');
            });

            if (!fieldType[0]) {
              return "continue";
            }

            ruleOptions.variable_type_operators[fieldType[0].field_type].forEach(function (val) {
              if (val.name === rules.conditions.all[i].operator.replace(' ', '_')) {
                rulesConditions.selectedCondition = val;
              }
            });
            rulesConditions.ruleOptions = ruleOptions.variable_type_operators[fieldType[0].field_type];
            ruleOptions.variables.forEach(function (val) {
              if (val.name === rules.conditions.all[i].name.replace(' ', '_')) {
                rulesConditions.selectedColumn = val;
              }
            });
            rulesConditions.selectedConditionValue = rules.conditions.all[i].value;
            controller.rulesConditions.pushObject(rulesConditions);
          };

          for (var i = 0; i < rules.conditions.all.length; i++) {
            var _ret = _loop(i);

            if (_ret === "continue") continue;
          }
        })();
      }

      controller.set('conditionOptions', {
        'Status': [{
          id: "OPEN",
          name: this.intl.t('status.open')
        }, {
          id: "RE_OPENED",
          name: this.intl.t('status.re_opened')
        }, {
          id: "IN_PROGRESS",
          name: this.intl.t('status.in_progress')
        }, {
          id: "RESOLVED",
          name: this.intl.t('status.resolved')
        }, {
          id: "CLOSED",
          name: this.intl.t('status.closed')
        }, {
          id: "ARCHIVED",
          name: this.intl.t('status.archived')
        }, {
          id: "DELETED",
          name: this.intl.t('status.deleted')
        }],
        'Priority': [{
          id: "URGENT",
          name: this.intl.t('priorities.urgent')
        }, {
          id: "HIGH",
          name: this.intl.t('priorities.high')
        }, {
          id: "MEDIUM",
          name: this.intl.t('priorities.medium')
        }, {
          id: "LOW",
          name: this.intl.t('priorities.low')
        }],
        'Issue Type': model.issueTypes,
        'Source': [{
          id: "NA",
          name: "NA"
        }, {
          id: "PHONE",
          name: "Phone"
        }, {
          id: "LIVECHAT",
          name: "Live Chat"
        }, {
          id: "WECHAT",
          name: "WeChat"
        }, {
          id: "TELEGRAM",
          name: "Telegram"
        }, {
          id: "LINE",
          name: "LINE"
        }, {
          id: "TWITTER",
          name: "Twitter DM"
        }, {
          id: "WHATSAPP",
          name: "WhatsApp"
        }, {
          id: "FACEBOOK",
          name: "Facebook"
        }, {
          id: 'INSTAGRAM',
          name: 'Instagram'
        }],
        'Department': flow.departments,
        'Assignee': flow.users,
        'Attachment': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Channel/source': [{
          id: "LIVECHAT",
          name: "Live Chat"
        }, {
          id: "WECHAT",
          name: "WeChat"
        }, {
          id: "TELEGRAM",
          name: "Telegram"
        }, {
          id: "LINE",
          name: "LINE"
        }, {
          id: "TWITTER",
          name: "Twitter DM"
        }, {
          id: "WHATSAPP",
          name: "WhatsApp"
        }, {
          id: "FACEBOOK",
          name: "Facebook"
        }, {
          id: 'INSTAGRAM',
          name: 'Instagram'
        }, {
          id: 'SHOPIFY',
          name: 'Shopify'
        }, {
          id: 'BIGCOMMERCE',
          name: 'BigCommerce'
        }],
        'Country': _countriesList.COUNTRIES_LIST,
        'Refund': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Cancel': [{
          name: 'Is true'
        }, {
          name: 'Is false'
        }],
        'Profile qualification': [{
          name: 'Is complete'
        }, {
          name: 'Is incomplete'
        }]
      });
    },
    buildRules: function buildRules(flow, rules, operator) {
      var options = {
        'Ticket/Task': ['Is created', 'Is updated', 'Is deleted', 'Is idled'],
        'Conversation': ['New incoming', 'Visitor has responded', 'Is idled']
      };
      var selectedColumn = 'Ticket/Task';
      var selectedCondition;
      var idledPeriod = 0;
      var idledPeriodTimes = 'minutes';
      var arrayRules = [];
      flow.forEach(function (r) {
        switch (r.name) {
          case 'age':
            selectedColumn = 'Ticket/Task';
            break;

          case 'idled':
            if (r.module) {
              selectedColumn = 'Conversation';
            } else {
              selectedColumn = 'Ticket/Task';
            }

            break;

          case 'is_new_incomming':
            selectedColumn = 'Conversation';
            selectedCondition = 'New incoming';
            break;

          case 'visitor_has_responded':
            selectedColumn = 'Conversation';
            selectedCondition = 'Visitor has responded';
            break;
        }

        switch (r.operator) {
          case 'greater_than':
            selectedCondition = 'Is idled';
            idledPeriod = +r.value;

            if (idledPeriod >= 3600) {
              idledPeriodTimes = 'days';
              idledPeriod = Math.floor(idledPeriod / 3600);
            } else if (idledPeriod >= 60) {
              idledPeriodTimes = 'hours';
              idledPeriod = Math.floor(idledPeriod / 60);
            }

            break;

          case 'equal_to':
            selectedCondition = r.value === 'created' ? 'Is created' : r.value === 'updated' ? 'Is updated' : 'Is deleted';
        }

        arrayRules.addObject({
          selectedColumn: selectedColumn,
          selectedCondition: selectedCondition,
          idledPeriod: idledPeriod,
          idledPeriodTimes: idledPeriodTimes,
          variables: ['Ticket/Task', 'Conversation'],
          operator: operator,
          ruleOptions: options[selectedColumn]
        });
      });
      return arrayRules;
    }
  });

  _exports.default = _default;
});