define("inboxfront/controllers/assistants/index", ["exports", "inboxfront/gql/query", "inboxfront/gql/mutation"], function (_exports, _query, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    paperToaster: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    limit: 10,
    page: 0,
    currentStep: 0,
    showPopupDeleteFlow: false,
    showPopupCopyFlow: false,
    awaitCreateFlow: false,
    showPopupActivateFlow: false,
    searchContacts: null,
    incrementedPage: Ember.computed('page', function () {
      return "".concat(this.page + 1);
    }),
    flowsPage: Ember.computed('page', 'cloneFlows', function () {
      var page = this.get('page');
      var limit = this.get('limit');

      var flows = _toConsumableArray(this.get('cloneFlows'));

      var pageFlows = flows.slice(page * limit, (page + 1) * limit);
      return pageFlows.map(function (flow) {
        if (flow.extraData) {
          var extraData = JSON.parse(flow.extraData);
          return _objectSpread(_objectSpread({}, flow), {}, {
            isDisabled: Boolean(extraData.isDisabled)
          });
        } else {
          return _objectSpread({}, flow);
        }
      });
    }),
    countPages: Ember.computed('collectionFlows', function () {
      return Math.ceil(this.get('collectionFlows').length / this.limit);
    }),
    lastPage: Ember.computed('page', function () {
      return parseInt(this.get('collectionFlows').length / 10);
    }),
    cloneFlows: Ember.computed('collectionFlows', function () {
      return this.get('collectionFlows');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.isReverse = {
        title: true,
        triggered: true,
        engaged: true,
        completed: true,
        updatedDate: true,
        createdDate: true
      };
      this.filterFlows = [{
        type: 'all',
        title: 'All flow'
      }, {
        type: 'flow',
        title: 'Chat Bot'
      }, {
        type: 'workflow',
        title: 'Orchestration'
      }];
      this.selectedFilter = {
        type: 'all',
        title: 'All flow'
      };
    },
    actions: {
      prevPage: function prevPage(page) {
        if (Number(page) > 0) {
          this.set('page', Number(page) - 1);
        }
      },
      nextPage: function nextPage(page) {
        if (Number(page) < this.get('lastPage')) {
          this.set('page', Number(page) + 1);
        }
      },
      firstPage: function firstPage() {
        this.set('page', 0);
      },
      lastPage: function lastPage() {
        this.set('page', this.get('lastPage'));
      },
      onFilterFlows: function onFilterFlows(val) {
        this.set('selectedFilter', val);

        if (val.type === 'flow') {
          this.set('collectionFlows', this.get('flows'));
        } else if (val.type === 'workflow') {
          this.set('collectionFlows', this.get('workFlows'));
        } else {
          this.set('collectionFlows', this.get('model.flows'));
        }
      },
      clearFilter: function clearFilter() {
        this.set('searchContacts', null);
        this.set('collectionFlows', this.get('cloneFlows'));
      },
      sortFlow: function sortFlow(fieldName) {
        var sortedFlows;
        var flows = this.get('collectionFlows');
        var isReverse = this.get('isReverse');

        if (fieldName === "Assistant Name") {
          if (isReverse.title) {
            sortedFlows = flows.sortBy("title").reverse();
            this.toggleProperty("isReverse.title");
          } else {
            sortedFlows = flows.sortBy("title");
            this.toggleProperty("isReverse.title");
          }
        }

        if (fieldName === "Sent") {
          if (isReverse.triggered) {
            sortedFlows = flows.sortBy("triggered").reverse();
            this.toggleProperty("isReverse.triggered");
          } else {
            sortedFlows = flows.sortBy("triggered");
            this.toggleProperty("isReverse.triggered");
          }
        }

        if (fieldName === "Engaged") {
          if (isReverse.engaged) {
            sortedFlows = flows.sortBy("engaged").reverse();
            this.toggleProperty("isReverse.engaged");
          } else {
            sortedFlows = flows.sortBy("engaged");
            this.toggleProperty("isReverse.engaged");
          }
        }

        if (fieldName === "Completed") {
          if (isReverse.completed) {
            sortedFlows = flows.sortBy("completed").reverse();
            this.toggleProperty("isReverse.completed");
          } else {
            sortedFlows = flows.sortBy("completed");
            this.toggleProperty("isReverse.completed");
          }
        }

        if (fieldName === "Modified On") {
          if (isReverse.updatedDate) {
            sortedFlows = flows.sortBy("updatedDate").reverse();
            this.toggleProperty("isReverse.updatedDate");
          } else {
            sortedFlows = flows.sortBy("updatedDate");
            this.toggleProperty("isReverse.updatedDate");
          }
        }

        this.set('collectionFlows', sortedFlows);
      },
      createFlow: function createFlow() {
        var _this = this;

        var hash = {
          title: "Flow".concat(String(Math.random()).slice(2)),
          description: '',
          idleTime: 20,
          timeOut: 20,
          warningMessage: 'Hi, we have not heard anything from you for the past 20 seconds, ' + 'please type something to remain active in chat',
          timeOutMessage: 'This chat session has been terminated due to time-out, we are still here with you if you need anything',
          isActive: false,
          auto: true,
          type: 1,
          companyProperty: this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash, function (_ref) {
          var flow = _ref.flow;

          _this.set('awaitCreateFlow', false);

          _this.paperToaster.show(_this.intl.t('flow_created'));

          _this.transitionToRoute('assistants.edit', {
            queryParams: {
              id: flow.id
            }
          });
        });
      },
      createWorkflow: function createWorkflow() {
        var _this2 = this;

        var hash = {
          name: "Workflow".concat(String(Math.random()).slice(2)),
          properties: [this.get('companyId')],
          description: '',
          isActive: false,
          trigger: '',
          objectType: 'TICKET'
        };
        this.set('awaitCreateFlow', true);
        this.ajax.mutation(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', hash, function (_ref2) {
          var workflow = _ref2.workflow;

          _this2.set('awaitCreateFlow', false);

          _this2.paperToaster.show(_this2.intl.t('flow_created'));

          _this2.transitionToRoute('assistants.edit', {
            queryParams: {
              id: workflow.id
            }
          });
        });
      },
      statusChangedItemFlow: function statusChangedItemFlow(flow) {
        var _this3 = this;

        this.set('selectedFlow', flow);

        if (flow.type === 1) {
          this.set('isDisabledFlow', flow.isDisabled);
          this.set('flowName', flow.title);

          if (!flow.isActive) {
            Ember.set(flow, 'isActive', false);
            this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          } else {
            var hash = {
              flowId: flow.id,
              isActive: !flow.isActive
            };
            Ember.set(flow, 'isActive', !flow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
              var status = flow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this3.paperToaster.show(_this3.intl.t(status, {
                name: flow.title
              }));

              _this3.queryFlows();
            });
          }
        } else {
          this.set('isDisabledFlow', !flow.isValid);
          this.set('flowName', flow.name);

          if (!flow.isActive) {
            Ember.set(flow, 'isActive', false);
            this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
          } else {
            var _hash = {
              workflowId: flow.id,
              isActive: !flow.isActive
            };
            Ember.set(flow, 'isActive', !flow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', _hash).then(function () {
              var status = flow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this3.paperToaster.show(_this3.intl.t(status, {
                name: flow.name
              }));

              _this3.queryFlows();
            });
          }
        }
      },
      togglePopupActivateFlow: function togglePopupActivateFlow() {
        this.set('showPopupActivateFlow', !this.get('showPopupActivateFlow'));
      },
      togglePopupDeleteFlow: function togglePopupDeleteFlow(flow) {
        if (flow) this.set('selectedFlow', flow);
        this.set('showPopupDeleteFlow', !this.get('showPopupDeleteFlow'));
      },
      togglePopupCopyFlow: function togglePopupCopyFlow(flow) {
        if (flow) this.set('selectedFlow', flow);
        this.set('showPopupCopyFlow', !this.get('showPopupCopyFlow'));
      },
      deleteFlow: function deleteFlow() {
        var _this4 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);

        if (selectedFlow.type === 1) {
          this.ajax.query(_mutation.default.DELETE_FLOW, 'deleteFlow', {
            flowId: selectedFlow.id
          }).then(function () {
            _this4.set('showPopupDeleteFlow', false);

            _this4.paperToaster.show(_this4.intl.t('delete_flow', {
              name: selectedFlow.title
            }));

            _this4.queryFlows();
          });
        } else {
          this.ajax.query(_mutation.default.DELETE_WORKFLOW, 'deleteWorkflow', {
            workflowId: selectedFlow.id
          }).then(function () {
            _this4.set('showPopupDeleteFlow', false);

            _this4.paperToaster.show(_this4.intl.t('delete_flow', {
              name: selectedFlow.title
            }));

            _this4.queryFlows();
          });
        }
      },
      copyFlow: function copyFlow() {
        var _this5 = this;

        var selectedFlow = this.get('selectedFlow');
        this.set('isLoader', true);
        this.ajax.query(_mutation.default.DUPLICATE_FLOW, 'duplicateFlow', {
          flowId: selectedFlow.id
        }).then(function () {
          _this5.set('showPopupCopyFlow', false);

          _this5.paperToaster.show(_this5.intl.t('duplicate_flow', {
            name: selectedFlow.title
          }));

          _this5.queryFlows();
        });
      },
      activateFlow: function activateFlow() {
        var _this6 = this;

        var selectedFlow = this.get('selectedFlow');

        if (selectedFlow.type === 1) {
          if (!this.get('isDisabledFlow')) {
            var hash = {
              flowId: selectedFlow.id,
              isActive: !selectedFlow.isActive
            };
            Ember.set(selectedFlow, 'isActive', !selectedFlow.isActive);
            this.ajax.query(_mutation.default.CREATE_UPDATE_FLOW, 'createOrUpdateFlow', hash).then(function () {
              var status = selectedFlow.isActive ? 'activate_flow' : 'deactivate_flow';

              _this6.paperToaster.show(_this6.intl.t(status, {
                name: selectedFlow.title
              }));

              _this6.queryFlows();
            });
          }
        } else {
          var _hash2 = {
            workflowId: selectedFlow.id,
            isActive: !selectedFlow.isActive
          };
          Ember.set(selectedFlow, 'isActive', !selectedFlow.isActive);
          this.ajax.query(_mutation.default.CREATE_UPDATE_WORKFLOW, 'createOrUpdateWorkflow', _hash2).then(function () {
            var status = _hash2.isActive ? 'activate_workflow' : 'deactivate_workflow';

            _this6.paperToaster.show(_this6.intl.t(status, {
              name: selectedFlow.name
            }));

            _this6.queryFlows();
          });
        }
      }
    },
    queryFlows: function queryFlows() {
      var _this7 = this;

      var hash = {
        properties: [this.get('sessionAccount.loggeduser.staffprofile.estates')[0].id]
      };
      var flows = [];
      var requests = [this.ajax.query(_query.default.FLOWS).then(function (data) {
        !data.errors && data.forEach(function (f) {
          flows.addObject(f);
        });

        _this7.set('flows', data);
      }), this.ajax.query(_query.default.WORKFLOWS, 'workflows', hash).then(function (data) {
        var workflows = data.map(function (item) {
          return _objectSpread({
            title: item.name,
            updatedDate: item.updatedAt
          }, item);
        });
        !data.errors && workflows.forEach(function (workflow) {
          flows.addObject(workflow);
        });

        _this7.set('workFlows', workflows);
      })];
      return Ember.RSVP.all(requests).then(function () {
        var selectedFilter = _this7.get('selectedFilter');

        _this7.set('model.flows', flows);

        _this7.set('isLoader', false);

        if (selectedFilter.type === 'flow') {
          _this7.set('collectionFlows', _this7.get('flows'));
        } else if (selectedFilter.type === 'workflow') {
          _this7.set('collectionFlows', _this7.get('workFlows'));
        } else {
          _this7.set('collectionFlows', flows);
        }
      });
    }
  });

  _exports.default = _default;
});