define("inboxfront/components/kb/sections-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    articlesCount: Ember.computed('section', function () {
      var count = 0;
      this.get('section.categories').forEach(function (c) {
        count += c.articles.length;
      });
      return count;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onDragStart: function onDragStart(section, event) {
        event.dataTransfer.setData('sectionId', section.id);
      },
      onDragOver: function onDragOver(section, event) {
        if (section.hilighted || section.isSystem || !this.get('isShort') || this.get('selected')) {
          return;
        }

        event.preventDefault();
      },
      onDrop: function onDrop(section, event) {
        event.preventDefault();

        if (event.dataTransfer.getData('categoryId')) {
          this.moveToSection(section, event.dataTransfer.getData('categoryId'));
        }
      }
    }
  });

  _exports.default = _default;
});