define("inboxfront/components/escalations/section-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    tagName: '',
    conditionRules: Ember.computed('targetType', function () {
      var target = this.get('targetType');

      if (!target) {
        return [];
      }

      var ret = [];

      if (target === 'tasks') {
        ret = ['Issue Type', 'Department', 'Property'];
      } else {
        ret = ['Channel', 'Property'];
      }

      return ret;
    }),
    restRules: Ember.computed('conditionRules.length', 'conditions.length', 'updateRules', function () {
      var rules = _toConsumableArray(this.get('conditionRules'));

      this.get('conditions').forEach(function (c) {
        rules.removeObject(c.rule);
      });
      return rules;
    }),
    addButtonVisible: Ember.computed('conditions.length', function () {
      var target = this.get('targetType');
      var len = this.get('conditions.length');

      if (target === 'tasks') {
        return len < 3;
      } else {
        return len < 2;
      }
    }),
    actions: {
      addCondition: function addCondition() {
        this.get('conditions').addObject({
          values: []
        });
        this.onChange();
      },
      deleteCondition: function deleteCondition(condition) {
        this.get('conditions').removeObject(condition);
        this.onChange();
      },
      updateConditions: function updateConditions() {
        this.set('updateRules', !this.get('updateRules'));
        this.onChange();
      }
    }
  });

  _exports.default = _default;
});