define("inboxfront/templates/components/message-emojis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2M5bmqRO",
    "block": "{\"symbols\":[\"emoji\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"emojis\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"emojisArr\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"message-emoji\",null,[[\"tagName\",\"data\",\"onDeleteEmoji\",\"onAddEmoji\"],[\"\",[23,1,[]],[28,\"action\",[[23,0,[]],\"onDeleteEmoji\"],null],[28,\"action\",[[23,0,[]],\"onAddEmoji\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"message\",\"reaction\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"popups/emoji-picker\",null,[[\"tagName\",\"class\",\"onSelectEmoji\",\"messageId\"],[\"\",\"inline\",[28,\"action\",[[23,0,[]],\"onSelectEmoji\"],null],[24,[\"message\",\"id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/message-emojis.hbs"
    }
  });

  _exports.default = _default;
});