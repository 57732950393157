define("inboxfront/components/companies/dialogs/confirm-delete", ["exports", "inboxfront/components/common/dialogs/warning"], function (_exports, _warning) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _warning.default.extend({
    tagName: ''
  });

  _exports.default = _default;
});