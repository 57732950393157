define("inboxfront/routes/profile", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "inboxfront/gql/query", "inboxfront/config/environment"], function (_exports, _authenticatedRouteMixin, _query, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      var profile = this.ajax.query(_query.default.CURRENT_USER);
      var locales = this.ajax.query(_query.default.LOCALES, 'locales');
      return Ember.RSVP.all([profile, locales]).then(function (res) {
        return {
          profile: res[0],
          locales: JSON.parse(res[1])
        };
      });
    },
    setupController: function setupController(c, m) {
      this._super(c, m);

      var url = m.profile.attachment !== null ? "".concat(_environment.default.mediaUrl).concat(m.profile.attachment.datafile) : null;
      c.set('urlAttachment', url);
      c.set('attachmentId', m.profile.attachment !== null ? m.profile.attachment.id : null);
    }
  });

  _exports.default = _default;
});