define("inboxfront/components/dashboard/tickets-source", ["exports", "highcharts"], function (_exports, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      _highcharts.default.chart({
        chart: {
          type: 'bar',
          renderTo: 'tickets-source',
          height: 315
        },
        title: {
          text: this.intl.t('tickets_source'),
          align: 'left'
        },
        xAxis: {
          title: null,
          categories: this.get('listDateMin')
        },
        yAxis: {
          title: null //categories: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']

        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        legend: {
          align: 'left'
        },
        series: [{
          name: this.intl.t('email'),
          color: '#f63333',
          data: [5, 3, 2, 7, 2, 4, 3]
        }, {
          name: this.intl.t('phone'),
          color: '#b01089',
          data: [4, 3, 4, 3, 2, 3, 2]
        }, {
          name: this.intl.t('messenger'),
          color: '#abf633',
          data: [5, 4, 4, 1, 2, 4, 3]
        }, {
          name: this.intl.t('line'),
          color: '#f6bf33',
          data: [5, 3, 1, 3, 1, 4, 1]
        }, {
          name: this.intl.t('wechat'),
          color: '#2a186e',
          data: [5, 2, 3, 7, 2, 4, 6]
        }, {
          name: this.intl.t('telegram'),
          color: '#b0a9a9',
          data: [4, 3, 4, 3, 2, 2, 2]
        }, {
          name: this.intl.t('twitter'),
          color: '#8ef633',
          data: [7, 4, 4, 4, 2, 4, 6]
        }, {
          name: this.intl.t('livechat'),
          color: '#2f74c8',
          data: [8, 3, 6, 7, 7, 4, 7]
        }]
      });
    }
    /*calcDate() {
    	const pickGraph = this.get('pickGraph');
    	const listDate = this.get('listDate');
    	let arr = [[], [], [], []];
    
    	for (let l = 0; l < 7; l++) {
    		let filterDate = pickGraph.filter(el => el.node.date === listDate[l]);
    		let enquiryCount = 0;
    		let incidentCount = 0;
    		let cancel = 0;
    		let complaint = 0;
    
    		if (filterDate.length) {
    			filterDate.forEach(el => {
    				enquiryCount += el.node.enquiryTickets;
    				incidentCount += el.node.incidentTickets;
    				cancel += el.node.cancellationTickets;
    				complaint += el.node.complaintTickets;
    			});
    		}
    		arr[0].push(enquiryCount);
    		arr[1].push(incidentCount);
    		arr[2].push(cancel);
    		arr[3].push(complaint);
    	}
    	return arr;
    }*/

  });

  _exports.default = _default;
});