define("inboxfront/templates/components/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ygy0hCA/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"number-page\"],[8],[1,[22,\"incrementedPage\"],false],[0,\" of \"],[1,[22,\"countPages\"],false],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"prevPage\",[24,[\"page\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"svg-icon\",[\"arrow-circle\"],[[\"class\"],[\"arrow-circle-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"class\",\"onClick\"],[\"navigate-button\",[28,\"action\",[[23,0,[]],\"nextPage\",[24,[\"page\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"svg-icon\",[\"arrow-circle\"],[[\"class\"],[\"arrow-circle-icon _right\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "inboxfront/templates/components/pagination.hbs"
    }
  });

  _exports.default = _default;
});