define("inboxfront/components/dates-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    isOpen: false,
    init: function init() {
      this._super.apply(this, arguments);

      var startDate = this.getMondayOfCurrentWeek(new Date());
      var endDate = this.getSundayOfCurrentWeek(new Date());
      var dates = {
        startDate: startDate,
        endDate: endDate
      };
      var selectedDate = this.formatDate(dates.startDate) + ' - ' + this.formatDate(dates.endDate);
      this.datesSelector = [{
        groupName: 'Week',
        options: [{
          title: 'This week',
          value: 'THIS_WEEK'
        }, {
          title: 'Last week',
          value: 'LAST_WEEK'
        }]
      }, {
        groupName: 'Month',
        options: [{
          title: 'This month',
          value: 'THIS_MONTH'
        }, {
          title: 'Last month',
          value: 'LAST_MONTH'
        }]
      }, {
        title: '<span class="bold">Custom Dates</span>',
        value: 'CUSTOM'
      }];
      this.dates = dates;
      this.selectedDate = {
        title: selectedDate
      };
      this.changeDate(dates);
    },
    actions: {
      onChangeSelect: function onChangeSelect(date) {
        switch (date.value) {
          case 'THIS_WEEK':
            {
              var startDate = this.getMondayOfCurrentWeek(new Date());
              var endDate = this.getSundayOfCurrentWeek(new Date());
              this.set('dates', {
                startDate: startDate,
                endDate: endDate
              });
              this.setDateSelector({
                startDate: startDate,
                endDate: endDate
              });
              break;
            }

          case 'LAST_WEEK':
            {
              var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
              var beforeOneWeek2 = new Date(beforeOneWeek);
              var day = beforeOneWeek.getDay();
              var diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);

              var _startDate = new Date(beforeOneWeek.setDate(diffToMonday));

              var _endDate = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

              this.set('dates', {
                startDate: _startDate,
                endDate: _endDate
              });
              this.setDateSelector({
                startDate: _startDate,
                endDate: _endDate
              });
              break;
            }

          case 'THIS_MONTH':
            {
              var _startDate2 = this.getFirstDayOfCurrentMonth(new Date());

              var _endDate2 = new Date();

              this.set('dates', {
                startDate: _startDate2,
                endDate: _endDate2
              });
              this.setDateSelector({
                startDate: _startDate2,
                endDate: _endDate2
              });
              break;
            }

          case 'LAST_MONTH':
            {
              var lastMonth = new Date();
              lastMonth.setMonth(lastMonth.getMonth() - 1);

              var _startDate3 = this.getFirstDayOfCurrentMonth(lastMonth);

              var _endDate3 = this.getLastDayOfCurrentMonth(lastMonth);

              this.set('dates', {
                startDate: _startDate3,
                endDate: _endDate3
              });
              this.setDateSelector({
                startDate: _startDate3,
                endDate: _endDate3
              });
              break;
            }

          case 'CUSTOM':
            {
              this.set('isOpen', true);
              break;
            }

          default:
            break;
        }

        this.changeDate(this.get('dates'));
      },
      updateDateRange: function updateDateRange(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            startDate = _ref2[0],
            endDate = _ref2[1];

        var datesObj = {
          startDate: startDate,
          endDate: endDate
        };
        this.set('dates', datesObj);

        if (endDate) {
          this.setDateSelector(datesObj);
          this.set('isOpen', false);
          this.changeDate(datesObj);
        }
      },
      onClickOutside: function onClickOutside() {
        this.set('isOpen', false);
      }
    },
    setDateSelector: function setDateSelector(dates) {
      var selectedDate = this.formatDate(dates.startDate) + " - " + this.formatDate(dates.endDate);
      this.set('selectedDate', {
        title: selectedDate
      });
    },
    getFirstDayOfCurrentMonth: function getFirstDayOfCurrentMonth(d) {
      return new Date(d.getFullYear(), d.getMonth(), 1);
    },
    getLastDayOfCurrentMonth: function getLastDayOfCurrentMonth(d) {
      return new Date(d.getFullYear(), d.getMonth() + 1, 0);
    },
    getMondayOfCurrentWeek: function getMondayOfCurrentWeek(d) {
      var day = d.getDay();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
    },
    getSundayOfCurrentWeek: function getSundayOfCurrentWeek(d) {
      var day = d.getDay();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? 0 : 7) - day);
    },
    formatDate: function formatDate(date) {
      var dd = date.getDate();
      var mm = date.getMonth();
      var m_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var yyyy = date.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      return dd + " " + m_names[mm] + ", " + yyyy;
    }
  });

  _exports.default = _default;
});