define("inboxfront/components/channels/email-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    mailBoxType: 'default',
    init: function init() {
      this._super.apply(this, arguments);

      this.emailProviders = [{
        name: 'Gmail',
        value: 'GMAIL'
      }, {
        name: 'Office365',
        value: 'OFFICE365'
      }, {
        name: 'Yahoo',
        value: 'YAHOO'
      }, {
        name: 'Sendgrid',
        value: 'SENDGRID'
      }, {
        name: this.intl.t('email_provider_other'),
        value: 'OTHER'
      }];
      this.emailValidation = [{
        message: this.intl.t('forms.provide_email'),
        validate: function validate(inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
      }];
    },
    actions: {
      onDepartmentChange: function onDepartmentChange(department) {
        this.set('channel.selectedDepartment', department);
      }
    }
  });

  _exports.default = _default;
});