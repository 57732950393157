define("inboxfront/routes/companies/edit", ["exports", "inboxfront/routes/common", "inboxfront/gql/query", "inboxfront/config/environment", "inboxfront/helpers/countries-list", "inboxfront/helpers/timezones-list"], function (_exports, _common, _query, _environment, _countriesList, _timezonesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _common.default.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (params.id == null) {
        this.transitionTo('companies');
      } else {
        var properties = this.ajax.query(_query.default.COMPANY_PROPERTY);
        var groups = this.ajax.query(_query.default.CUSTOM_GROUPS);
        return Ember.RSVP.all([properties, groups]).then(function (res) {
          if (res[0].errors) {
            return _this.transitionTo('companies');
          }

          var companies = res[0];
          companies.forEach(function (company) {
            if (company.parent) {
              company.parent = companies.findBy('id', company.parent.id);
            }

            var children = [];
            company.children.forEach(function (child) {
              var modelCompany = companies.findBy('id', child.id);
              modelCompany && children.push(modelCompany);
            });
            company.children = children;
          });
          var company = companies.findBy('id', params.id);
          companies.removeObject(company);
          var groups = !res[1].errors ? res[1] : [];
          var companyGroupSet = [];
          company.companygroupSet.forEach(function (group) {
            var modelGroup = groups.findBy('id', group.id);
            modelGroup && companyGroupSet.push(modelGroup);
          });
          company.companygroupSet = companyGroupSet;

          var country = _countriesList.COUNTRIES_LIST.findBy('iso2', company.country);

          company.countryName = country ? country.name : company.country;
          var edit = {};
          ['title', 'code', 'address', 'postalCode', 'phoneNumber', 'website'].forEach(function (field) {
            edit[field] = company[field];
          });
          edit.country = country;
          edit.timezone = _timezonesList.TIMEZONES_LIST.findBy('tzCode', company.timezone);
          var hours = [{
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: true,
            from: '08:00',
            to: '17:30'
          }, {
            on: false,
            from: '08:00',
            to: '17:30'
          }, {
            on: false,
            from: '08:00',
            to: '17:30'
          }];

          if (company.workingHours) {
            try {
              hours = JSON.parse(company.workingHours);
            } catch (e) {
              console.error('ERROR PARSING WORKING HOURS', e);
            }
          }

          company.hours = hours;
          company.workingHours = JSON.stringify(hours);
          return {
            companies: companies,
            company: company,
            edit: edit,
            groups: groups
          };
        });
      }
    },
    setupController: function setupController(c, m) {
      this._super(c, m);

      var url = m.company.logo !== null ? "".concat(_environment.default.mediaUrl).concat(m.company.logo.datafile) : '/assets/images/upload_avatar.png';
      c.set('urlAttachment', url);
      c.set('attachmentId', m.company.logo !== null ? m.company.logo.id : null);
      m.company.accounts.edges.forEach(function (channel) {
        var node = channel.node;
        var temp;

        switch (node.application) {
          case 'TELEGRAM':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'telegram_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-TELEGRAM';
            break;

          case 'WHATSAPP':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'whatsapp_number';
            });
            node.accountPhone = temp ? temp.node.value : '';
            node.icon = 'source-WHATSAPP';
            break;

          case 'FACEBOOK':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'facebook_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-FACEBOOK';
            break;

          case 'TWITTER':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'twitter_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-TWITTER';
            break;

          case 'LINE':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'line_contact_url';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'source-LINE';
            break;

          case 'BIGCOMMERCE':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'bc_store_domain';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'EC-bigcommerce-logo';
            break;

          case 'SHOPIFY':
            temp = node.accountsettingSet.edges.find(function (edge) {
              return edge.node.key === 'shop_name';
            });
            node.accountUrl = temp ? temp.node.value : '';
            node.icon = 'EC-shopify-logo';
            break;

          case 'WECHAT':
            node.icon = 'source-WECHAT';
            break;
        }
      });
    }
  });

  _exports.default = _default;
});